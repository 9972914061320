import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import { SingleSelect, TextEdit } from '@components';

export default function DisplayTabBarOptions(props) {
    const { t } = useTranslation();

    const changeFillOpacity = (fill) => {
        if (!isNumeric(fill)) {
            return;
        }
        props.graph.configOptions.fillOpacity = fill;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeBarRatio = (ratio) => {
        if (!isNumeric(ratio)) {
            return;
        }
        props.graph.configOptions.barRatio = parseFloat(ratio);
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeLineWidth = (lineWidth) => {
        props.graph.configOptions.strokeWidth = lineWidth;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeAlignment = (alignment) => {
        props.graph.configOptions.alignment = alignment;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 620px)' });
    const max1300 = useMediaQuery({ query: '(max-width: 1300px)' });
    const max1000 = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: '40px',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <SingleSelect
                        title={t(
                            'pages_addgraph_displaytabbaroptions_linewidth'
                        )}
                        onChange={changeLineWidth}
                        value={props.graph?.configOptions?.strokeWidth ?? 2}
                        data={[
                            { value: 1, description: '1' },
                            { value: 2, description: '2' },
                            { value: 3, description: '3' },
                            { value: 4, description: '4' },
                            { value: 5, description: '5' },
                            { value: 8, description: '8' },
                            { value: 12, description: '12' },
                        ]}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <TextEdit
                        label={t(
                            'pages_addgraph_displaytabbaroptions_fillopacity'
                        )}
                        onChange={changeFillOpacity}
                        value={
                            isNullOrUndefined(
                                props.graph?.configOptions?.fillOpacity
                            )
                                ? '0.5'
                                : `${props.graph.configOptions.fillOpacity}`
                        }
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <TextEdit
                        label={t(
                            'pages_addgraph_displaytabbaroptions_barratio'
                        )}
                        onChange={changeBarRatio}
                        value={
                            isNullOrUndefined(
                                props.graph?.configOptions?.barRatio
                            )
                                ? '0.8'
                                : `${props.graph.configOptions.barRatio}`
                        }
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <SingleSelect
                        title={t(
                            'pages_addgraph_displaytabbaroptions_baralign'
                        )}
                        onChange={changeAlignment}
                        value={
                            props.graph?.configOptions?.alignment ?? 'middle'
                        }
                        data={[
                            {
                                value: 'start',
                                description: t(
                                    'pages_addgraph_displaytabbaroptions_start'
                                ),
                            },
                            {
                                value: 'middle',
                                description: t(
                                    'pages_addgraph_displaytabbaroptions_middle'
                                ),
                            },
                            {
                                value: 'end',
                                description: t(
                                    'pages_addgraph_displaytabbaroptions_end'
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
}
