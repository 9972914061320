import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { validatePassword } from '@utils/helpers/forms.helpers';
import { TextEdit } from '@components';

export default function ResetPasswordModal(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    const confirmPasswordChange = (txt) => {
        setConfirmPassword(txt);
    };

    const passwordChanged = (txt) => {
        setPassword(txt);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (
            !isNullOrUndefined(password) &&
            password.trim() !== '' &&
            validatePassword(password) &&
            password === confirmPassword
        ) {
            props.confirm(password, confirmPassword);
        } else {
            if (!validatePassword(password)) {
                setErrorMsg(t('pages_users_createusermodal_badpassword'));
            } else if (password !== confirmPassword) {
                setErrorMsg("The passwords don't match");
            } else {
                setErrorMsg(t('pages_users_createusermodal_allfields'));
            }
        }
    };

    const onClose = () => {
        props.handleClose && props.handleClose();
        setErrorMsg(null);
        setPassword('');
        setConfirmPassword('');
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_login_forgotpasswordmodal_reset')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_login_loginpage_password')}
                        type="password"
                        onChange={passwordChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_profile_profilepage_confirm')}
                        type="password"
                        onChange={confirmPasswordChange}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => onClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_edit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
