import { makeStyles } from '@material-ui/core';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((props) => ({
    div: {
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
    },
    btn: {
        backgroundColor: 'var(--white,#fff)',
        borderColor: (props) => props.color ?? '#303030',
        borderRadius: '100px',
        borderWidth: '1px',
        borderStyle: 'solid',
        width: '22px',
        height: '22px',
        margin: '0',
        padding: '0',
        position: 'relative',
        verticalAlign: 'middle',
        display: 'inline-flex',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: (props) =>
                props.backgroundColor ?? 'var(--white,#fff)',
            cursor: 'pointer',
        },
    },
    svg: {
        pointerEvents: 'none',
    },
    icon: {
        width: '15px !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'block',
        zIndex: 2,
        overflow: 'visible',
        color: (props) => props.color ?? '',
    },
}));

export default function JobStatusIndicator(props) {
    const statusStyles = {
        created: {
            color: 'var(--gray-500, #666)',
            backgroundColor: 'var(--gray-100, #dbdbdb)',
        },
        pending: {},
        warning: { color: '#ab6100', backgroundColor: '#f5d9a8' },
        running: { color: '#1f75cb', backgroundColor: '#cbe2f9' },
        failed: { color: '#c91c00', backgroundColor: '#fdd4cd' },
        success: { color: '#108548', backgroundColor: '#c3e6cd' },
        canceled: { color: '#1f1f1f', backgroundColor: '#666' },
        skipped: {
            color: 'var(--gray-400, #868686)',
            backgroundColor: 'var(--gray-100, #dbdbdb)',
        },
        manual: { color: '#303030', backgroundColor: '#666' },
    };
    const styles = useStyles(props.statusStyle ?? statusStyles[props.status]);
    const { t } = useTranslation();

    let icon = null;
    if (props.status === 'created') {
        icon = <CircleIcon className={styles.icon} />;
    } else if (props.status === 'warning') {
        icon = <PriorityHighIcon className={styles.icon} />;
    } else if (props.status === 'running') {
        icon = <DataUsageIcon className={styles.icon} />;
    } else if (props.status === 'failed') {
        icon = <ClearIcon className={styles.icon} />;
    } else if (props.status === 'success') {
        icon = <CheckIcon className={styles.icon} />;
    } else if (props.status === 'canceled') {
        icon = <BlockIcon className={styles.icon} />;
    } else if (props.status === 'skipped') {
        icon = <DoubleArrowIcon className={styles.icon} />;
    } else if (props.status === 'manual') {
        icon = <PlayArrowIcon className={styles.icon} />;
    }

    return (
        <Tooltip
            placement="top"
            title={`${t('pages_devices_devicepage_status')}: ${
                props.statusText ?? props.status
            }`}>
            <div className={styles.div}>
                <button className={styles.btn}>
                    <span className={styles.svg}>{icon}</span>
                </button>
            </div>
        </Tooltip>
    );
}
