import { FilterApi } from '@aurum/nucleus-client-api';
import {
    PbiAlarmsPage,
    PbiHomeScreen,
    PbiMenuScreen,
    PbiSettingsScreen,
} from '@components';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PbiServiceControlScreen } from './PbiServiceControlScreen';

import './pbiStyling.css';

export function PbiScreenContainer(props) {
    const { id } = useParams();
    const [realTimeMeasurements, setRealTimeMeasurements] = useState([]);
    const [lastFetchingTime, setLastFetchingTime] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [screenDisplayed, setScreenDisplayed] = useState(0);
    const [fetchingInProgress, setFetchingInProgress] = useState(false);

    const timeoutRef = useRef(null);

    useEffect(() => {
        forceParamUpdate(false);
        getRealTimeData();

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [id]);

    /**
     * This hook will be invoked when the data fetching is in progress. It will poll (in 500ms intervals) the new
     * data until the last fetched timestamp is updated. Then, the interval will be cleared.
     */
    useEffect(() => {
        if (fetchingInProgress) {
            const interval = setInterval(() => {
                new FilterApi().filterGetParametersFromPlc(
                    parseInt(id, 10),
                    { group: null },
                    function (_, data, response) {
                        if (response.ok) {
                            setParameters(response.body);
                            const newDate = getLatestTimestamp(response.body);
                            if (lastFetchingTime < newDate) {
                                setFetchingInProgress(false);
                                setLastFetchingTime(newDate);
                                clearInterval(interval);
                            }
                        }
                    }
                );
            }, 500);
            return () => clearInterval(interval);
        }
    }, [fetchingInProgress]);

    const getLatestTimestamp = (params) => {
        return new Date(
            Math.max.apply(
                null,
                params?.map((p) => new Date(p.lastFetched))
            )
        );
    };

    const forceParamUpdate = (forceReload = true) => {
        new FilterApi().filterGetParametersFromPlc(
            parseInt(id, 10),
            { group: null },
            function (_, data, response) {
                if (response.ok) {
                    setParameters(response.body);
                    const lastFetchedTimestamp = getLatestTimestamp(
                        response.body
                    );
                    if (
                        forceReload &&
                        lastFetchingTime >= lastFetchedTimestamp
                    ) {
                        setFetchingInProgress(true);
                    }
                    setLastFetchingTime(lastFetchedTimestamp);
                }
            }
        );
    };

    const getRealTimeData = () => {
        new FilterApi().filterGetRealTimeFilterStatus(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setRealTimeMeasurements(data);
                    const displaySecTime =
                        data?.find((p) => p.measurement === 'DISPLAY_SEC_TIME')
                            ?.value ?? 0;
                    if (displaySecTime !== 0) {
                        timeoutRef.current = setTimeout(getRealTimeData, 1000);
                    } else {
                        timeoutRef.current = setTimeout(getRealTimeData, 5000);
                    }
                }
            }
        );
    };

    const onFetchMeasurements = () => {
        forceParamUpdate(true);
    };

    const onScreenDisplayed = (newScreen) => {
        setScreenDisplayed(newScreen);
    };
    const height = props.height + 100;

    let screen = (
        <PbiHomeScreen
            width={props.width}
            data={props.data}
            parameters={parameters}
            realTimeMeasurements={realTimeMeasurements}
            height={height}
            fetchingInProgress={fetchingInProgress}
            lastFetchingTime={lastFetchingTime}
            user={props.user}
            triggerChange={onScreenDisplayed}
            onFetchMeasurements={onFetchMeasurements}
        />
    );
    switch (screenDisplayed) {
        case 1:
            screen = (
                <PbiMenuScreen
                    width={props.width}
                    parameters={parameters}
                    height={height}
                    user={props.user}
                    triggerChange={onScreenDisplayed}
                />
            );
            break;
        case 2:
            screen = (
                <PbiAlarmsPage
                    width={props.width}
                    parameters={parameters}
                    height={height}
                    triggerChange={onScreenDisplayed}
                />
            );
            break;
        case 3:
            screen = (
                <PbiSettingsScreen
                    width={props.width}
                    parameters={parameters}
                    height={height}
                    user={props.user}
                    triggerChange={onScreenDisplayed}
                    forceParamUpdate={forceParamUpdate}
                />
            );
            break;
        case 4:
            screen = (
                <PbiServiceControlScreen
                    width={props.width}
                    parameters={parameters}
                    height={height}
                    triggerChange={onScreenDisplayed}
                    forceParamUpdate={forceParamUpdate}
                />
            );
            break;
    }

    return screen;
}
