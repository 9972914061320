import { AccountApi } from '@aurum/nucleus-client-api';
import { AppLayout, NotFound } from '@components';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import {
    getPathToLogos,
    getProperRoute,
    isNullOrUndefined,
} from '@utils/helpers/app.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import CookiePolicy from '@views/cookies/CookiePolicy';
import Impressum from '@views/cookies/Impressum';
import PrivacyPolicy from '@views/cookies/PrivacyPolicy';
import DevicePage from '@views/devices/device-page/DevicePage';
import DevicesPage from '@views/devices/devices-page/DevicesPage';
import MoveDevicesPage from '@views/devices/move-devices/MoveDevicesPage';
import DiagnosticsPage from '@views/diagnostics/diagnostics-page/DiagnosticsPage';
import DiagnosticsGatewayPage from '@views/diagnostics/gateway-page/DiagnosticsGatewayPage';
import DiagnosticsSensorPage from '@views/diagnostics/sensor-page/DiagnosticsSensorPage';
import GroupDevicesPage from '@views/groups/devices/devices-page/GroupDevicesPage';
import GatewayGroupPage from '@views/groups/devices/gateway-page/GatewayGroupPage';
import GroupSettingsPage from '@views/groups/general-settings/general-settings-page/GroupSettingsPage';
import GroupMembersPage from '@views/groups/members/members-page/GroupMembersPage';
import GroupProjectsPage from '@views/groups/projects/projects-page/GroupProjectsPage';
import CustomerServerPage from '@views/infrastructure/customers/customer-page/CustomerServerPage';
import CustomerServersPage from '@views/infrastructure/customers/customers-page/CustomerServersPage';
import PipelinesPage from '@views/infrastructure/pipelines/pipelines-page/PipelinesPage';
import LoginPage from '@views/login/LoginPage';
import ProfilePage from '@views/profile/profile-page/ProfilePage';
import AlarmEntryPage from '@views/sites/alarms/alarm-page/AlarmEntryPage';
import AlarmsSitePage from '@views/sites/alarms/alarms-page/AlarmsSitePage';
import AlertsSitePage from '@views/sites/alerts/alerts-page/AlertsSitePage';
import DashboardPage from '@views/sites/dashboards/dashboard-page/DashboardPage';
import DashboardsPage from '@views/sites/dashboards/dashboards-page/DashboardsPage';
import AddGraph from '@views/sites/dashboards/graphs/create-graph-page/AddGraph';
import ViewGraph from '@views/sites/dashboards/graphs/view-graph-page/ViewGraph';
import ManualMeasurementsPage from '@views/sites/manual-measurements/manual-measurements-page/ManualMeasurementsPage';
import GatewayEntryPage from '@views/sites/settings/gateways/gateway-page/GatewayEntryPage';
import GatewaysSitePage from '@views/sites/settings/gateways/gateways-page/GatewaysSitePage';
import MeasurementPointPage from '@views/sites/settings/measurement-points/measurement-point-page/MeasurementPointPage';
import MemberEntryPage from '@views/sites/settings/members/member-page/MemberEntryPage';
import MembersSitePage from '@views/sites/settings/members/members-page/MembersSitePage';
import ParametersSitePage from '@views/sites/settings/parameters/parameters-page/ParametersSitePage';
import SitesPage from '@views/sites/site-page/SitesPage';
import SiteSettingsPage from '@views/sites/sites-page/SiteSettingsPage';
import SiteTcSettingsPage from '@views/sites/sites-page/temperature-control/SiteTcSettingsPage';
import UserPage from '@views/users/user-page/UserPage';
import UsersPage from '@views/users/users-page/UsersPage';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

// All the following keys are optional, as default values are provided.
export const customTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            light: '#008ECB',
            main: '#008ECB',
            dark: '#008ECB',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FF3A0C',
            main: '#FF3A0C',
            dark: '#008ECB',
            contrastText: '#000',
        },
    },
});

const App = (props) => {
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        new AccountApi().accountGetLoggedUser(function (_, data, response) {
            if (response.ok && !isNullOrUndefined(data)) {
                setLoggedIn(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }, []);

    const createRoutes = (path, codeToRender) => {
        return [path].map((p) => (
            <Route key={makeRandomStr()} path={p} element={codeToRender} />
        ));
    };

    document
        .getElementById('favicon')
        .setAttribute(
            'href',
            getProperRoute(`${getPathToLogos()}/favicon.ico`)
        );

    if (loading) {
        return <></>;
    }

    if (loggedIn) {
        return (
            <div>
                <ThemeProvider theme={customTheme}>
                    <Routes>
                        {createRoutes(
                            '/cookie-policy',
                            <AppLayout>
                                <CookiePolicy />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/impressum',
                            <AppLayout>
                                <Impressum />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/privacy-policy',
                            <AppLayout>
                                <PrivacyPolicy />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/',
                            <AppLayout>
                                <SitesPage />
                            </AppLayout>
                        )}
                        <Route path="/login" element={<Navigate to="/" />} />
                        {createRoutes(
                            '/profile',
                            <AppLayout>
                                <ProfilePage />
                            </AppLayout>
                        )}
                        {/* <Route  path={["/accounting"]} render={() => <AppLayout><AccountingPage /></AppLayout>} /> */}
                        {createRoutes(
                            '/admin/customers',
                            <AppLayout admin active="customers">
                                <CustomerServersPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/admin/customers/:id',
                            <AppLayout admin active="customers">
                                <CustomerServerPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/admin/pipelines',
                            <AppLayout admin active="pipelines">
                                <PipelinesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/admin/pipelines/:id',
                            <AppLayout admin active="pipelines">
                                <PipelinesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/diagnostics',
                            <AppLayout admin active="diagnostics">
                                <DiagnosticsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/diagnostics/gateways/:id',
                            <AppLayout admin active="diagnostics">
                                <DiagnosticsGatewayPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/diagnostics/sensors/:id',
                            <AppLayout admin active="diagnostics">
                                <DiagnosticsSensorPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/users',
                            <AppLayout>
                                <UsersPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/users/:userId',
                            <AppLayout>
                                <UserPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/devices',
                            <AppLayout>
                                <DevicesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/devices/move',
                            <AppLayout>
                                <MoveDevicesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/devices/gateways/:id',
                            <AppLayout>
                                <DevicePage gateway />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/devices/sensors/:id',
                            <AppLayout>
                                <DevicePage sensor />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups',
                            <AppLayout group active="settings/general">
                                <GroupSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/projects',
                            <AppLayout group active="settings/projects">
                                <GroupProjectsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/members',
                            <AppLayout group active="settings/members">
                                <GroupMembersPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/devices',
                            <AppLayout group active="settings/devices">
                                <GroupDevicesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/settings',
                            <AppLayout group active="settings/general">
                                <GroupSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/projects',
                            <AppLayout group active="settings/projects">
                                <GroupProjectsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/members',
                            <AppLayout group active="settings/members">
                                <GroupMembersPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/members/:memberId',
                            <AppLayout group active="settings/members">
                                <MemberEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/devices',
                            <AppLayout group active="settings/devices">
                                <GroupDevicesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/devices/:deviceId',
                            <AppLayout group active="settings/devices">
                                <GatewayGroupPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/sites',
                            <AppLayout>
                                <SitesPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/settings',
                            <AppLayout site active="settings/general">
                                <SiteSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/settings',
                            <AppLayout site active="settings/general">
                                <SiteTcSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/settings',
                            <AppLayout site active="settings/general">
                                <SiteSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/parameters',
                            <AppLayout site filter active="settings/parameters">
                                <ParametersSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/settings/:gid',
                            <AppLayout site active="settings/general">
                                <SiteSettingsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/gateways',
                            <AppLayout site active="settings/gateways">
                                <GatewaysSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/gateways',
                            <AppLayout site filter active="settings/gateways">
                                <GatewaysSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/gateways',
                            <AppLayout site active="settings/gateways">
                                <GatewaysSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/members',
                            <AppLayout site active="settings/members">
                                <MembersSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/members',
                            <AppLayout site active="settings/members">
                                <MembersSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/members',
                            <AppLayout site filter active="settings/members">
                                <MembersSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/alerts',
                            <AppLayout site filter active="alerts">
                                <AlertsSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/alarms',
                            <AppLayout site active="alarms">
                                <AlarmsSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/alarms',
                            <AppLayout site active="alarms">
                                <AlarmsSitePage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/alarms/:alarmId',
                            <AppLayout site active="alarms">
                                <AlarmEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/alarms/:alarmId',
                            <AppLayout site active="alarms">
                                <AlarmEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/gateways/:gwId',
                            <AppLayout site active="settings/gateways">
                                <GatewayEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/gateways/:gwId',
                            <AppLayout site filter active="settings/gateways">
                                <GatewayEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/gateways/:gwId',
                            <AppLayout site active="settings/gateways">
                                <GatewayEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/members/:memberId',
                            <AppLayout site active="settings/members">
                                <MemberEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/members/:memberId',
                            <AppLayout site filter active="settings/members">
                                <MemberEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/members/:memberId',
                            <AppLayout site active="settings/members">
                                <MemberEntryPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/groups/:gid/mps/:mpId',
                            <AppLayout site active="settings/general">
                                <MeasurementPointPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/dashboards',
                            <AppLayout site active="dashboards">
                                <DashboardsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/dashboards',
                            <AppLayout site active="dashboards">
                                <DashboardsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/dashboards',
                            <AppLayout site filter active="dashboards">
                                <DashboardsPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/dashboards/:dbId',
                            <AppLayout site active="dashboards">
                                <DashboardPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/temperature-control/:id/dashboards/:dbId',
                            <AppLayout site active="dashboards">
                                <DashboardPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/filter/:id/dashboards/:dbId',
                            <AppLayout site filter active="dashboards">
                                <DashboardPage />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/dashboards/:dbId/graphs/:graphId/view',
                            <AppLayout site active="dashboards">
                                <ViewGraph />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/dashboards/:dbId/graphs/create',
                            <AppLayout site active="dashboards">
                                <AddGraph />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/dashboards/:dbId/graphs/edit/:graphId',
                            <AppLayout site active="dashboards">
                                <AddGraph />
                            </AppLayout>
                        )}
                        {createRoutes(
                            '/groups/:groupId/sites/:id/manual_measurements',
                            <AppLayout site active="manual">
                                <ManualMeasurementsPage />
                            </AppLayout>
                        )}
                        <Route element={NotFound} />
                    </Routes>
                </ThemeProvider>
            </div>
        );
    } else {
        return (
            <div>
                <ThemeProvider theme={customTheme}>
                    <Routes>
                        {createRoutes('/impressum', <Impressum />)}
                        {createRoutes('/privacy-policy', <PrivacyPolicy />)}
                        {createRoutes('/cookie-policy', <CookiePolicy />)}
                        <Route path="/*" element={<LoginPage />} />
                    </Routes>
                </ThemeProvider>
            </div>
        );
    }
};

export default App;
