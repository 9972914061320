import GroupApi from '@aurum/nucleus-client-api/dist/api/GroupApi';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import MoveGroupsModal from '@views/groups/general-settings/move-group/MoveGroupsModal';
import GroupTreeStructure from '@views/groups/group-page/GroupTreeStructure';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function GroupSettingsPage(props) {
    const [moveGroups, setMoveGroups] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const { t } = useTranslation();

    const deleteSelectedGroups = (selected) => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupprojectspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && selected.length > 0) {
                let totalDeleted = 0;
                selected?.forEach((group) => {
                    new GroupApi().groupDeleteGroup(
                        group.id,
                        function (_, data, response) {
                            totalDeleted += 1;
                            if (totalDeleted === selected.length) {
                                onGroupsDeleted();
                            }
                        }
                    );
                });
            }
        });
    };

    const confirmMoveGroups = (newId) => {
        let groupsMoved = 0;
        selectedGroups?.forEach((gr) => {
            if (isNullOrUndefined(newId)) {
                gr.parent.id = null;
            } else {
                if (isNullOrUndefined(gr.parent)) {
                    gr.parent = {};
                }
                gr.parent.id = newId;
            }
            new GroupApi().groupUpdateGroup(
                gr.id,
                gr,
                function (_, data, response) {
                    groupsMoved += 1;
                    if (groupsMoved === selectedGroups.length) {
                        onGroupsMoved(newId);
                    }
                }
            );
        });
    };

    const onGroupsDeleted = () => {
        window.location.reload();
    };

    const onGroupsMoved = (groupId) => {
        if (isNullOrUndefined(groupId)) {
            window.location.href = getProperRoute(`groups`);
        } else {
            window.location.href = getProperRoute(`groups/${groupId}/settings`);
        }
    };

    const showMoveDialog = (selected) => {
        setSelectedGroups(selected);
        setMoveGroups(true);
    };

    const recursive = (levelGroups, total, items) => {
        if (isNullOrUndefined(levelGroups) || levelGroups?.length === 0) {
            return '';
        }
        let newTotal = total;
        for (let lg of levelGroups) {
            if (selectedGroups?.some((g) => g.id === lg.id)) {
                continue;
            }
            newTotal = total + ' / ' + lg.name;
            items.push({ value: lg.id, description: newTotal });
            recursive(
                props.groups.filter((g) => g.parent?.id === lg.id),
                newTotal,
                items
            );
        }
    };

    const itemsToBeMoved = [{ value: null, description: '/' }];
    let levelGroups = props.groups?.filter((g) =>
        isNullOrUndefined(g.parent?.id)
    );
    let total = '';
    recursive(levelGroups, total, itemsToBeMoved);

    return (
        <>
            <GroupTreeStructure
                user={props.user}
                groups={props.groups}
                moveSelected={(selected) => showMoveDialog(selected)}
                deleteSelected={(selected) => deleteSelectedGroups(selected)}
            />
            <MoveGroupsModal
                data={itemsToBeMoved}
                open={moveGroups}
                handleClose={() => setMoveGroups(false)}
                confirm={confirmMoveGroups}
            />
        </>
    );
}
