import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { RoundedText } from '@components';

const useStyles = makeStyles((props) => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: (props) => (props.isMobile ? '50px' : '80px'),
        width: (props) => (props.isMobile ? '50px' : '80px'),
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) => getAvatarColor(props.id),
        fontSize: (props) => (props.isMobile ? '25px' : '40px'),
        lineHeight: (props) => (props.isMobile ? '50px' : '80px'),
        marginLeft: '10px',
        marginTop: '10px',
    },
    icon: {
        marginLeft: '10px',
        cursor: 'pointer',
        width: '24px',
        height: '24px',
    },
    avatarContainer: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        overflow: 'hidden',
        float: 'left',
        marginRight: '20px',
    },
    textContainer: {
        minWidth: 0,
        alignItems: 'center',
        flex: '1 1 auto',
        display: 'flex',
    },
    infoContainer: {
        flexBasis: '100%',
        minWidth: 0,
        flexShrink: 1,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
    },
}));

export default function SiteTitle(props) {
    const { id } = useParams();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const styles = useStyles({ id: id, isMobile: isMobile });

    const { t } = useTranslation();

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <div style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <Grid container spacing={2}>
                <div className={styles.avatarContainer}>
                    {props.site.avatarPath ? (
                        <img
                            alt="avatar"
                            className={styles.defaultAvatar}
                            src={props.site.avatarPath}
                        />
                    ) : (
                        <div className={styles.defaultAvatar}>
                            {props.site.name[0]?.toUpperCase()}
                        </div>
                    )}
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.infoContainer}>
                        <div style={{ display: 'flex' }}>
                            <b
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}>
                                <span
                                    style={{
                                        fontSize: isMobile ? '18px' : '22px',
                                        marginTop: '20px',
                                        marginLeft: isMobile ? '0' : '20px',
                                    }}>
                                    {props.site.name}
                                </span>
                            </b>
                            {props.site.reducedSamplingIntervalActive ? (
                                <div
                                    style={{
                                        marginTop: '25px',
                                        marginLeft: '20px',
                                    }}>
                                    <RoundedText
                                        color="info"
                                        width="150px"
                                        text={t(
                                            'pages_sitesettings_sitetitle_installationmode'
                                        )}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <span style={{ marginLeft: isMobile ? '0' : '20px' }}>
                            {t('pages_sitesettings_sitetitle_projid')}:{' '}
                            {props.site.id}
                        </span>
                    </div>
                </div>
            </Grid>
            <p style={{ marginTop: '30px', marginLeft: '15px' }}>
                {' '}
                {props.site.description}{' '}
            </p>
        </div>
    );
}
