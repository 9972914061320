import { TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import {
    isNullOrUndefined,
    parseAdditionalProperties,
} from '@utils/helpers/app.helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AssignPropertiesModal(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [updateProperties, setUpdatedProperties] = useState([]);

    const { t } = useTranslation();

    const propertyChanged = (txt, propName) => {
        const propToUpdate = props.properties?.find((p) => p.name === propName);
        if (isNullOrUndefined(propToUpdate)) {
            return;
        }
        const updatedProp = updateProperties.find((p) => p.name === propName);
        if (isNullOrUndefined(updatedProp)) {
            setUpdatedProperties([
                ...updateProperties,
                { name: propName, value: txt },
            ]);
            return;
        }
        updatedProp.value = txt;
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        props.confirm && props.confirm(updateProperties);
        onClose();
    };

    const onClose = () => {
        setUpdatedProperties([]);
        setErrorMsg(null);
        props.handleClose && props.handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Assign Properties</DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                {props.properties.map((p) => {
                    return (
                        <div style={{ marginTop: '20px' }} key={p.name}>
                            <TextEdit
                                label={t(parseAdditionalProperties(p.name))}
                                placeholder={p.value}
                                value={p.value}
                                onChange={(txt) => propertyChanged(txt, p.name)}
                            />
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => onClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_assign')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
