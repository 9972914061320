import { Input } from '@mui/material';
import React, { useEffect, useState } from 'react';

export function TableTextEdit(props) {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (event) => {
        setValue(event?.target?.value ?? '');
        props.onChange && props.onChange(event?.target?.value ?? '');
    };

    return (
        <Input
            ref={props.reference}
            style={{ width: props.width ?? '100%' }}
            placeholder={props.placeholder}
            value={value}
            onChange={handleChange}
            margin="none"
            inputProps={{ 'aria-label': 'description' }}
            autoFocus={true}
            onKeyDown={(evt) => props.onKeyDown && props.onKeyDown(evt)}
        />
    );
}
