import _ from 'lodash';

export function getProperRoute(route) {
    return '/' + route;
}

export function groupBy(items, key) {
    return items?.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {}
    );
}

export function userIsAtLeast(user, role) {
    const roles = ['reporter', 'analyst', 'maintainer', 'admin'];
    const desiredRoleIndex = roles.indexOf(role);
    if (desiredRoleIndex === -1) {
        return false;
    }
    const usersRoleIndex = roles.indexOf(user?.role ?? 'reporter');
    return usersRoleIndex >= desiredRoleIndex;
}

export function isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
}

export function addMemberPayload(site) {
    const siteClone = _.cloneDeep(site);
    delete siteClone.createdAt;
    delete siteClone.lastUpdated;
    for (let siteUser of siteClone.users ?? []) {
        delete siteUser.lastLogin;
        delete siteUser.createdAt;
        delete siteUser.sites;
    }
    return siteClone;
}

export function addMemberPayloadGroup(group) {
    const groupClone = _.cloneDeep(group);
    if (!isNullOrUndefined(group.parent)) {
        groupClone.parent = addMemberPayloadGroup(group.parent);
    }
    delete groupClone.updatedAt;
    delete groupClone.createdAt;
    for (let site of groupClone.sites ?? []) {
        delete site.createdAt;
        delete site.lastUpdated;
    }
    for (let gateway of groupClone.gateways ?? []) {
        delete gateway.lastActiveTime;
        delete gateway.site;
        delete gateway.upTime;
    }
    for (let sensor of groupClone.sensors ?? []) {
        delete sensor.lastMeasurementTime;
        delete sensor.site;
        delete sensor.upTime;
    }
    for (let member of groupClone.memberGroups ?? []) {
        delete member.user.lastLogin;
        delete member.user.createdAt;
        delete member.user.sites;
        delete member.user.createdBy;
    }
    return groupClone;
}

export function getFullGroups(groups, site) {
    let parentGroup = groups?.find((g) => g.id === site?.groupId);
    const fullGroups = [];
    while (!isNullOrUndefined(parentGroup)) {
        if (parentGroup.id !== 1) {
            fullGroups.push({
                link: getProperRoute(`groups/${parentGroup.id}/settings`),
                text: parentGroup.name,
            });
        }
        parentGroup = groups?.find((g) => g.id === parentGroup.parent?.id);
    }
    fullGroups.reverse();
    return fullGroups;
}

export function getProperSiteSettingsPage(groupId, siteId, siteType) {
    if (!siteType) return;
    const allSiteTypes = siteType.split('_');
    if (allSiteTypes.includes('filter')) {
        return `groups/${groupId}/sites/filter/${siteId}/parameters`;
    } else {
        return `groups/${groupId}/sites/${siteId}/settings`;
    }
}

export function getPathToLogos() {
    if (window.location.hostname.toLowerCase().includes('pb')) {
        return 'pbi';
    }
    return 'aquacloud';
}

export function redirectToSiteTypeApp(navigate, site, url, redirectTo = null) {
    if (!isNullOrUndefined(site)) {
        const allSiteTypes = site.type.split('_');
        if (
            window.location.href.includes('sites/filter') &&
            !allSiteTypes.includes('filter')
        ) {
            navigate(getProperRoute(url));
        } else if (
            !window.location.href.includes('sites/filter') &&
            allSiteTypes.includes('filter')
        ) {
            if (isNullOrUndefined(redirectTo)) {
                url = url.replace('/sites/', '/sites/filter/');
                navigate(getProperRoute(url));
            } else {
                navigate(getProperRoute(url));
            }
        }
    }
}

export function getUrlDependingOnSiteType(url, siteType) {
    const allSiteTypes = siteType.split('_');
    if (allSiteTypes.includes('filter')) {
        return url.replace('/{siteType}', '/filter');
    } else if (allSiteTypes.includes('temperatureControl')) {
        return url.replace('/{siteType}', '/temperature-control');
    } else {
        return url.replace('/{siteType}', '');
    }
}

export function siteTypeIncludes(siteType, type) {
    const allSiteTypes = siteType?.split('_') ?? [];
    return allSiteTypes.includes(type);
}

export function parseProgStep(val) {
    const progStepDict = {
        0: 'FILTRATION',
        1: 'PRETEST',
        2: 'INTEGRITY TEST',
        3: 'BACK FLUSH',
        4: 'FORWARD FLUSH',
        5: 'FILTER DELAY',
        6: 'RESET STAGE 1',
        10: 'TEMP. FLUSH',
        11: 'PLUS FLUSH',
        12: 'BACK PLUS FLUSH',
        99: 'NO FILTERS SET',
        100: 'START UP',
        101: 'RESET STAGE 2',
        999: 'START SCREEN',
    };
    return progStepDict[val];
}

export function parseAdditionalProperties(val) {
    const additionalPropertiesDict = {
        phone: 'additional_property_phone',
    };
    return additionalPropertiesDict[val];
}
