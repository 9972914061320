import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';

export function DraggableWrapper(props) {
    const { children, ...restProps } = props;

    return children instanceof Array && children.length > 1 ? (
        <SortableContext
            items={children.map((child) => child.key)}
            strategy={verticalListSortingStrategy}
            {...restProps}>
            <tbody {...restProps}> {children} </tbody>
        </SortableContext>
    ) : (
        <tbody {...restProps}>{children}</tbody>
    );
}
