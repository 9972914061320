import CookieImage from '@assets/cookie.svg';
import { getProperRoute } from '@utils/helpers/app.helpers';
import { useCookieConsent } from '@views/cookies/CookieConsentContext';
import React from 'react';

import './cookies.css';

export function CookieConsentBanner() {
    const [visible, setVisible] = React.useState(false);
    const { cookieConsentChoice, acceptCookieConsent, declineCookieConsent } =
        useCookieConsent();

    React.useEffect(() => {
        setVisible(cookieConsentChoice === 'undecided');
    }, [cookieConsentChoice]);

    if (!visible) {
        return null;
    }

    return (
        <CookieBanner
            onAccept={acceptCookieConsent}
            onDecline={declineCookieConsent}
        />
    );
}

export default function CookieBanner(props) {
    return (
        <div className="fixed-bottom cookie-box d-lg-flex d-sm-flex align-items-center">
            <div className="cookie-content d-flex align-items-center">
                <div className="cookie-img-box">
                    <img
                        className="cookie-img"
                        src={CookieImage}
                        alt="cookie-img"
                    />
                </div>
                <div>
                    <div className="cookie-box-title">Cookies policy</div>
                    <div className="cookie-box-content">
                        Our website uses cookies to analyze how the site is used
                        and to ensure your experience is consistent between
                        visits.
                        <a
                            className="cookie-box-link"
                            href={getProperRoute('cookie-policy')}
                            rel="noopener noreferrer"
                            target="_blank">
                            Learn more »
                        </a>
                    </div>
                </div>
            </div>
            <div className="button-wrapper ml-auto">
                <button
                    className="cookie-button btn btn-primary ml-auto mr-2"
                    onClick={props.onAccept}>
                    Accept
                </button>
                <button
                    className="cookie-button btn btn-secondary ml-auto"
                    onClick={props.onDecline}>
                    Decline
                </button>
            </div>
        </div>
    );
}
