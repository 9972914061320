import AccountApi from '@aurum/nucleus-client-api/dist/api/AccountApi';
import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getProperRoute, userIsAtLeast } from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import { FilesTable, MoreSettingsMenu } from '@components';
import CreateUserModal from '@views/users/create-user/CreateUserModal';
import UserEntryTable from '@views/users/user-entry/UserEntryTable';

export default function UsersPage(props) {
    const [users, setUsers] = useState([]);
    const [createUser, setCreateUser] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        new AccountApi().accountGetUsers(function (_, data, response) {
            if (response.ok) {
                setUsers(response.body);
            }
        });
    }, []);

    const onCreateUser = (name, email, password, role) => {
        new AccountApi().accountRegister(
            { userName: name, mail: email, password: password, role: role },
            function (_, data, response) {
                if (response.ok) {
                    setUsers([...users, response.body]);
                    setCreateUser(false);
                }
            }
        );
    };

    const rows = [];
    users?.forEach((user) => {
        rows.push([
            {
                link: null,
                flex: true,
                text: user?.userName,
                value: (
                    <UserEntryTable
                        link={getProperRoute(`users/${user.id}`)}
                        user={user}
                    />
                ),
            },
            {
                link: null,
                text: new Date(user.createdAt),
                value: dateToString(
                    new Date(user.createdAt),
                    props.user?.timeZone
                ),
            },
            {
                link: null,
                text: new Date(user.lastLogin),
                value:
                    new Date(user.lastLogin).getFullYear() === 2001
                        ? t('pages_devices_devicepage_never')
                        : dateToString(
                              new Date(user.lastLogin),
                              props.user?.timeZone
                          ),
            },
        ]);
    });

    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_users_userpage_users')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'maintainer')}>
                    {userIsAtLeast(props.user, 'maintainer') ? (
                        <MenuItem onClick={() => setCreateUser(true)}>
                            {t('pages_users_userspage_create')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr />
            <br />
            <FilesTable
                responsive
                sort
                pagination
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_users_userspage_createdon'),
                    t('pages_users_userspage_lastactivity'),
                ]}
                rows={rows}
            />
            <CreateUserModal
                open={createUser}
                handleClose={() => setCreateUser(false)}
                confirm={(name, email, password, role) =>
                    onCreateUser(name, email, password, role)
                }
            />
        </div>
    );
}
