import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { SingleSelect } from '@components';

export default function AssignUserModal(props) {
    const [user, setUser] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    const userChanged = (id) => {
        setUser(id);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (!isNullOrUndefined(user) && user.trim() !== '') {
            props.confirm(user);
        } else {
            setErrorMsg(t('pages_sitesettings_assignusermodal_errortxt'));
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {' '}
                {t('pages_sitesettings_assignusermodal_assign')}{' '}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <SingleSelect
                        title={t(
                            'pages_sitesettings_assignusermodal_assignuser'
                        )}
                        data={props.data}
                        onChange={userChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {props.edit ? t('modal_edit') : t('modal_assign')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
