import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { VictoryAxis, VictoryChart, VictoryLegend } from 'victory';

export function MovableLegend(props) {
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(300);
    const [itemsPerLine, setItemsPerLine] = useState(9);

    useEffect(() => {
        recalculateLegendLines();
    }, []);

    const toVictoryLegend = (line) => {
        return line.color
            ? { name: line.name, symbol: { fill: line.color } }
            : { name: line.name };
    };

    const resize = (_event, { size }) => {
        setWidth(size.width);
        setHeight(size.height);
        recalculateLegendLines();
    };

    const recalculateLegendLines = () => {
        const maxLengthName =
            props.series.length > 1
                ? props.series?.reduce((a, b) =>
                      a.length > b.name?.length ? a : b.name
                  )
                : 'a';
        const numberOfUpperChars = (maxLengthName.match(/[A-Z]/g) || []).length;
        let ipl = Math.ceil(
            width /
                (8 * (maxLengthName.length - numberOfUpperChars) +
                    10 * numberOfUpperChars)
        );
        ipl = ipl % 2 === 0 ? ipl : ipl - 1;
        ipl = ipl > 18 ? 18 : ipl;
        if (!isNullOrUndefined(props.series)) {
            setItemsPerLine(ipl);
        }
    };

    return (
        <div style={{ position: 'absolute', zIndex: 999999999 }}>
            <Draggable handle="#handleDrag">
                <ResizableBox
                    width={width}
                    height={height}
                    minConstraints={[300, 300]}
                    maxConstraints={[2000, 2000]}
                    onResize={resize}>
                    <div
                        key={makeRandomStr()}
                        style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '4px',
                        }}>
                        <div
                            key={makeRandomStr()}
                            id="handleDrag"
                            className="row"
                            style={{
                                cursor: 'move',
                                borderBottom: '1px solid black',
                                height: '40px',
                                width: '100%',
                                marginLeft: 0,
                            }}>
                            <div
                                key={makeRandomStr()}
                                className="col-md-12 d-flex justify-content-center align-items-center">
                                <strong>
                                    <div>Legend</div>
                                </strong>
                            </div>
                        </div>
                        <div
                            key={makeRandomStr()}
                            style={{ marginTop: '20px' }}>
                            <VictoryChart
                                key={makeRandomStr()}
                                style={{ zIndex: 0 }}
                                width={width}
                                height={height}
                                events={
                                    props.buildEvents && props.buildEvents()
                                }>
                                <VictoryAxis
                                    key={makeRandomStr()}
                                    height={0}
                                    style={{
                                        axis: { stroke: 'transparent' },
                                        ticks: { stroke: 'transparent' },
                                        tickLabels: { fill: 'transparent' },
                                    }}
                                />
                                <VictoryLegend
                                    key={makeRandomStr()}
                                    name="legend"
                                    height={50}
                                    x={50}
                                    y={0}
                                    itemsPerRow={itemsPerLine}
                                    orientation="horizontal"
                                    data={props.allData.map((s, idx) => {
                                        const item = toVictoryLegend(s);
                                        if (props.hiddenSeries.has(idx)) {
                                            return {
                                                ...item,
                                                symbol: { fill: '#999' },
                                            };
                                        }
                                        return item;
                                    })}
                                />
                            </VictoryChart>
                        </div>
                    </div>
                </ResizableBox>
            </Draggable>
        </div>
    );
}
