import { makeStyles } from '@material-ui/core';
import { getProperRoute } from '@utils/helpers/app.helpers';
import JobStatusIndicator from '@views/infrastructure/pipelines/job-status/JobStatusIndicator';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    div: {
        padding: '2px 7px 4px',
        border: (props) =>
            `1px solid ${props.color ?? 'var(--border-color, #dbdbdb)'}`,
        whiteSpace: 'nowrap',
        borderRadius: '4px',
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

export default function PipelineTableElement(props) {
    const statusStyles = {
        created: {
            color: 'var(--gray-500, #666)',
            backgroundColor: 'var(--gray-100, #dbdbdb)',
        },
        pending: {},
        running: { color: '#1f75cb', backgroundColor: '#cbe2f9' },
        failed: { color: '#c91c00', backgroundColor: '#fdd4cd' },
        success: { color: '#108548', backgroundColor: '#c3e6cd' },
        canceled: { color: '#1f1f1f', backgroundColor: '#666' },
        skipped: {
            color: 'var(--gray-400, #868686)',
            backgroundColor: 'var(--gray-100, #dbdbdb)',
        },
        manual: { color: '#303030', backgroundColor: '#666' },
    };
    const styles = useStyles(statusStyles[props.pipeline.status]);

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <Link
                to={`${getProperRoute('admin/pipelines')}/${props.pipeline.id}`}
                className={styles.div}>
                <JobStatusIndicator
                    status={props.pipeline.status}
                    statusStyle={statusStyles[props.pipeline.status]}
                />
                <span
                    style={{
                        marginLeft: '10px',
                        color: statusStyles[props.pipeline.status].color,
                    }}>
                    {props.pipeline.status}
                </span>
            </Link>
            <div style={{ marginLeft: '20px', fontWeight: 'bold' }}>
                #{props.pipeline.id}
            </div>
        </div>
    );
}
