import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import { FilesTable } from '@components';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './diagnosticStyles.css';

export default function DiagnosticsGatewayEvents(props) {
    const [events, setEvents] = useState([]);
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new GatewayApi().gatewayGetGatewayEvents(
            id,
            function (_, data, response) {
                if (response.ok) {
                    setEvents(data);
                }
            }
        );
    }, [id]);

    const statusRows = [];
    events.sort((a, b) => b.timestamp - a.timestamp);
    events.forEach((e) => {
        statusRows.push([
            {
                link: null,
                text: dateToString(e.timestamp, props.user?.timeZone),
                value: dateToString(e.timestamp, props.user?.timeZone),
            },
            {
                link: null,
                text: `${e.level}`,
                value: `${e.level}`,
            },
            {
                link: null,
                text: `${e.message}`,
                value: `${e.message}`,
            },
        ]);
    });

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            pagination
                            ids={['timestamp', 'level', 'msg']}
                            columns={['Timestamp', 'Level', 'Message']}
                            rows={statusRows}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
