import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import * as React from 'react';

import './tree-structure.css';

function MinusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    return <Collapse {...props} />;
}

function ContextMenu(props) {
    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const visualizeData = () => {
        setContextMenu(null);
        if (props.type === 'mp') {
            window.location.href = getProperRoute(
                `groups/${props.site.groupId}/sites/${props.site.id}/dashboards/${props.site.defaultDashboardId}?mp=${props.id}`
            );
        } else if (props.type === 'group') {
            window.location.href = getProperRoute(
                `groups/${props.site.groupId}/sites/${props.site.id}/dashboards/${props.site.defaultDashboardId}?gid=${props.id}`
            );
        }
    };

    return (
        <div onContextMenu={handleContextMenu} style={{ cursor: 'pointer' }}>
            {props.children}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }>
                <MenuItem onClick={visualizeData}>Visualize Data</MenuItem>
            </Menu>
        </div>
    );
}

const StyledTreeItem = styled((props) => (
    <TreeItem
        {...props}
        className={`${
            props.selected ? 'select-tree-node' : 'select-tree-node-focus'
        }`}
        selected={props.selected}
        TransitionComponent={TransitionComponent}
        label={
            props.showContextMenu ? (
                <ContextMenu id={props.id} site={props.site} type={props.type}>
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-start">
                            <span>{props.label}</span>
                        </div>
                    </div>
                </ContextMenu>
            ) : (
                <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-start">
                        <span>{props.label}</span>
                    </div>
                </div>
            )
        }></TreeItem>
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`&:hover .${treeItemClasses.content}`]: {
        backgroundColor: 'rgba(0,0,0,0)',
    },
}));

export function TreeStructure(props) {
    const generateTreeRecursive = (items) => {
        if (!isNullOrUndefined(items)) {
            const allItems = [];
            items.forEach((item, idx) => {
                const subItems = generateTreeRecursive(item.items);
                if (isNullOrUndefined(subItems)) {
                    allItems.push(
                        <StyledTreeItem
                            id={item.id}
                            site={props.site}
                            nodeId={makeRandomStr(6)}
                            label={item.name}
                            type={item.type}
                        />
                    );
                } else {
                    allItems.push(
                        <StyledTreeItem
                            id={item.id}
                            site={props.site}
                            nodeId={makeRandomStr(6)}
                            selected={props.selectedIndex === idx}
                            onClick={() =>
                                props.itemClicked &&
                                props.itemClicked(item, idx)
                            }
                            label={item.name}
                            type={item.type}>
                            {subItems}
                        </StyledTreeItem>
                    );
                }
            });
            return allItems;
        } else {
            return null;
        }
    };

    const structure = generateTreeRecursive(props.items);
    return (
        <TreeView
            aria-label="tree-like structure"
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            sx={{ height: 400, flexGrow: 1, overflowY: 'auto' }}>
            {structure}
        </TreeView>
    );
}
