import { FilterApi } from '@aurum/nucleus-client-api';
import { AquaFilterGraphContainer } from '@components';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { saveLayoutCommon } from './DashboardCommonFunctions';

const Wrapper = React.forwardRef((props, ref) => {
    if (props.style.transform) {
        const translateRegex = /translate\((.+),\s*(.+)\)/;
        const matches = translateRegex.exec(props.style.transform);
        props.style.left = `${parseFloat(matches[1])}px`;
        props.style.top = `${parseFloat(matches[2])}px`;
        for (let p in props.style) {
            if (
                props.style.hasOwnProperty(p) &&
                p.toLowerCase().includes('transform')
            ) {
                delete props.style[p];
            }
        }
    }

    let newChildren = React.useMemo(() => {
        return React.Children.map(props.children, function (child) {
            return React.cloneElement(child, {
                forceRender: props.forceRender,
            });
        });
    });
    return (
        <div ref={ref} {...props}>
            {newChildren}
        </div>
    );
});

export function AquaFilterAppDashboard(props) {
    const [width] = useWindowSize();
    const [graphData, setGraphData] = useState([]);
    const [forceRender, setForceRender] = useState(0);
    const [lastRefresh, setLastRefresh] = useState(-1);

    const ref = React.createRef();
    const { id } = useParams();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const onLayoutChange = (layout) => {
        if (isMobile) {
            return;
        }
        saveLayout(layout);
    };

    useEffect(() => {
        if (props.refreshCounter === lastRefresh) {
            return;
        }
        setLastRefresh(props.refreshCounter);

        let from = props.from;
        let to = props.to;
        from.setMilliseconds(0);
        to.setMilliseconds(0);

        new FilterApi().filterGetFilterValues(
            parseInt(id, 10),
            {
                windowPeriod: props.windowPeriod,
                startTimestamp: from,
                endTimestamp: to,
            },
            function (error, data, response) {
                fetchingProgress(-1);
                setGraphData(data);
            }
        );
    }, [
        props.refreshCounter,
        props.from,
        props.to,
        props.windowPeriod,
        props.mps,
    ]);

    const fetchingProgress = (counter, total) => {
        props.fetchingProgress && props.fetchingProgress(counter, total);
    };

    const saveLayout = (value) => {
        const force = saveLayoutCommon(value, id, props.dashboard);
        if (force) {
            setForceRender(forceRender + 1);
        }
    };

    let layout = props.dashboard?.configOptions?.layout;
    if (isMobile) {
        layout = [];
        let y = 0;
        props.dashboard?.configOptions?.layout?.forEach((element) => {
            const elementClone = _.cloneDeep(element);
            elementClone.w = 12;
            elementClone.y = y;
            y += elementClone.h;
            layout.push(elementClone);
        });
    }

    let index = 0;
    const graphs = [];
    props.dashboard?.graphs?.forEach((graph) => {
        graphs.push(
            <Wrapper ref={ref} key={`${++index}`} forceRender={forceRender}>
                <AquaFilterGraphContainer
                    user={props.user}
                    data={graphData?.filter((g) =>
                        graph.configOptions?.parameters?.includes(g.measurement)
                    )}
                    loading={props.loading}
                    protected={props.dashboard.configOptions?.secured}
                    graph={graph}
                    groups={props.groups}
                />
            </Wrapper>
        );
    });

    return isNullOrUndefined(props.dashboard) ? (
        <></>
    ) : (
        <GridLayout
            className="layout"
            onResizeStop={onLayoutChange}
            onDragStop={onLayoutChange}
            layout={layout}
            minH={275}
            minW={275}
            cols={12}
            rowHeight={30}
            width={isMobile ? width * 0.8 + 30 : width * 0.8}
            draggableHandle=".DraggableDashboard">
            {graphs}
        </GridLayout>
    );
}
