import { FilterApi, UpdateParameterDTO } from '@aurum/nucleus-client-api';
import { FilesTable, NavButton } from '@components';
import { MenuItem, MenuList, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getProperRoute } from '@utils/helpers/app.helpers';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import './pbiStyling.css';

export function PbiAlarmsPage(props) {
    const backIcon = 'filter/return.png';
    const { t } = useTranslation();
    const [height, setHeight] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        new FilterApi().filterGetErrorCodeAlerts(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setAlerts(data);
                }
            }
        );
    }, [id]);

    useEffect(() => {
        setHeight(props.height);
    }, [props.height]);

    const goBack = () => {
        props.triggerChange && props.triggerChange(1);
    };

    const triggerResetActiveProcess = () => {
        const resetProcess = props.parameters?.find(
            (p) => p.name === 'RESET_PROCES'
        );
        triggerParamChange(resetProcess, 1);
    };

    const triggerResetUserErrors = () => {
        const resetErrors = props.parameters?.find(
            (p) => p.name === 'RESET_ERRORS2'
        );
        triggerParamChange(resetErrors, 0);
    };

    const triggerParamChange = (param, newValue) => {
        if (isNullOrUndefined(param)) {
            return;
        }

        const paramsToUpdate = [];
        const paramObj = new UpdateParameterDTO();
        paramObj.name = param.name;
        paramObj.value = newValue;
        paramsToUpdate.push(paramObj);
        new FilterApi().filterUpdateParameterValues(
            parseInt(id, 10),
            paramsToUpdate,
            function (_, data, response) {
                props.triggerChange && props.triggerChange(0);
            }
        );
    };

    // sort alerts by timestamp. Last alerts will be shown first
    const rows = [];
    alerts.sort((a, b) => b.timestamp - a.timestamp);
    alerts.forEach((alert) => {
        rows.push([
            {
                text: dateToString(alert.timestamp, props.user?.timeZone),
                value: dateToString(alert.timestamp, props.user?.timeZone),
            },
            {
                text: alert.errorCode,
                value: alert.errorCode,
            },
            {
                text: alert.description,
                value: alert.description,
            },
        ]);
    });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-6 start-just full">
                    <h2>{t('pages_pbialarms_alarm')}</h2>
                </div>
                <div className="col-md-5 start-just full">
                    <NavButton
                        padding
                        id="menu-reset-alarms"
                        text={t('pages_login_forgotpasswordmodal_resetbtn')}
                        color="black"
                        icon={<ExpandMoreIcon />}>
                        <MenuList id="menu-reset-user-errors">
                            <MenuItem onClick={() => triggerResetUserErrors()}>
                                {t('pages_pbialarm_userreseterrors')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => triggerResetActiveProcess()}>
                                {t('pages_pbialarm_resetactive')}
                            </MenuItem>
                        </MenuList>
                    </NavButton>
                </div>
                <div className="col-md-1 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_addgraph_addgraph_back')}>
                        <img
                            className="clickable-icon"
                            style={{ width: '40px', height: '40px' }}
                            onClick={() => goBack()}
                            src={`${getProperRoute(backIcon)}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${(height / 6) * 5}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                    overflow: 'auto',
                }}>
                <div className="col-md-12 full">
                    <FilesTable
                        responsive
                        pagination
                        ids={['timestamp', 'error', 'msg']}
                        columns={['Date and Time', 'Error', 'Message']}
                        rows={rows}
                    />
                </div>
            </div>
        </>
    );
}
