import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import ValveAutoRegulation from '@views/sites/sites-page/temperature-control/regulation/ValveAutoRegulation';
import ValveManualRegulation from '@views/sites/sites-page/temperature-control/regulation/ValveManualRegulation';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SiteTcRegulationModal(props) {
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {}, [props]);

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const isConfirmBtnDisabled = () => {
        return true;
    };

    const onOkClick = () => {
        if (isConfirmBtnDisabled()) {
            return;
        }
        props.confirm && props.confirm();
        props.handleClose && props.handleClose();
    };

    const keyDownCallback = useCallback((evt) => keyDown(evt), []);
    const dialogCloseCallback = useCallback(
        () => props.handleClose && props.handleClose(),
        [props.handleClose]
    );
    const dialogConfirmCallback = useCallback(
        () => props.confirm && onOkClick(),
        [props.confirm]
    );
    const alertCloseCallback = useCallback(() => setErrorMsg(null), []);

    let regulationUsed = <></>;
    switch (props.site?.configOptions?.algorithm) {
        case 0: // manual regulation
        case 2: // auto-adjust regulation
            regulationUsed = <ValveManualRegulation site={props.site} />;
            break;
        case 1: // auto regulation
            regulationUsed = <ValveAutoRegulation />;
            break;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={keyDownCallback}
            onClose={dialogCloseCallback}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_sitetc_regulationmodal_regulateproject')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert onClose={alertCloseCallback} severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <div className="row">{regulationUsed}</div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={dialogCloseCallback}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Save"
                    disabled={isConfirmBtnDisabled()}
                    onClick={dialogConfirmCallback}
                    color="primary">
                    {t('pages_pbisettings_save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
