import { customTheme } from '@/App';
import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React from 'react';

const useStyles = makeStyles(({ shadows, palette }) => ({
    root: {
        borderRadius: 100,
        minHeight: 30,
        padding: '0 1em',
    },
    label: {
        textTransform: 'none',
        fontSize: 15,
        fontWeight: 700,
    },
    outlined: {
        padding: '0 1em',
    },
    outlinedPrimary: {
        borderColor: customTheme.palette.primary.main,
        color: 'transparent',
        '&:hover': {
            borderColor: customTheme.palette.primary.main,
            color: 'transparent',
            backgroundColor: 'rgb(63, 81, 181, 0.1)',
        },
    },
    contained: {
        minHeight: 30,
        boxShadow: shadows[0],
        '&:hover': {
            boxShadow: shadows[0],
        },
        '&:active': {
            boxShadow: shadows[0],
        },
    },
    containedPrimary: {
        borderColor: customTheme.palette.primary.main,
        border: '2px solid black',
        backgroundColor: '#329128',
        color: customTheme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0, 143, 203, 0.5)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&:focus': {
            outline: 0,
        },
    },
    sizeLarge: {
        padding: '0 1em',
        minHeight: 39,
    },
}));

export function RoundedButton(props) {
    const styles = useStyles();

    return (
        <Tooltip enterDelay={1000} title={props.tooltip ?? ''}>
            <Button
                aria-label="RoundedButton"
                classes={styles}
                style={{
                    width: props.width ?? '100%',
                    marginTop: props.marginTop ?? 0,
                    marginLeft: props.marginLeft ?? 0,
                    color: props.color ?? '000',
                    borderColor: props.borderColor ?? 'f5f5f5',
                }}
                variant={'outlined'}
                size={isNullOrUndefined(props.size) ? 'large' : props.size}
                onClick={() => props.onClick && props.onClick()}>
                {props.text}
            </Button>
        </Tooltip>
    );
}
