import { Typography } from '@material-ui/core';
import React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import { CustomSwitch, SingleSelect, TextEdit } from '@components';

export default function DisplayTabLineOptions(props) {
    const { t } = useTranslation();

    const fillGraph = (fill) => {
        props.graph.configOptions.fill = fill;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeLineWidth = (lineWidth) => {
        props.graph.configOptions.strokeWidth = lineWidth;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const setHLineValue = (val) => {
        if (isNumeric(val)) {
            props.graph.configOptions.hlineValue = val;
            props.graphChanged && props.graphChanged(props.graph);
        }
    };

    const setHLine2Value = (val) => {
        if (isNumeric(val)) {
            props.graph.configOptions.hline2Value = val;
            props.graphChanged && props.graphChanged(props.graph);
        }
    };

    const setHLine = (hline) => {
        props.graph.configOptions.hline = hline;
        if (isNullOrUndefined(props.graph?.configOptions?.hlineValue)) {
            props.graph.configOptions.hlineValue = '26';
        }
        props.graphChanged && props.graphChanged(props.graph);
    };

    const setHLine2 = (hline) => {
        props.graph.configOptions.hline2 = hline;
        if (isNullOrUndefined(props.graph?.configOptions?.hline2Value)) {
            props.graph.configOptions.hline2Value = '26';
        }
        props.graphChanged && props.graphChanged(props.graph);
    };

    const setHLineColor = (color) => {
        props.graph.configOptions.hlineColor = color;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const setHLine2Color = (color) => {
        props.graph.configOptions.hline2Color = color;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const usePoints = (points) => {
        props.graph.configOptions.usePoints = points;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changePointWidth = (pointWidth) => {
        props.graph.configOptions.pointWidth = pointWidth;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeInterpolation = (interpolation) => {
        props.graph.configOptions.interpolation = interpolation;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 620px)' });
    const max1300 = useMediaQuery({ query: '(max-width: 1300px)' });
    const max1000 = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: '40px',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '20%'
                            : max1000
                            ? '40%'
                            : '10%',
                        marginBottom: '10px',
                    }}>
                    <Typography style={{ color: '#616161' }}>
                        {t('pages_addgraph_displaytablineoptions_usepoints')}:{' '}
                    </Typography>
                    <CustomSwitch
                        checked={props.graph?.configOptions?.usePoints ?? false}
                        onChecked={usePoints}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                    }}>
                    <SingleSelect
                        title={t(
                            'pages_addgraph_displaytablineoptions_pointwidth'
                        )}
                        onChange={changePointWidth}
                        value={props.graph?.configOptions?.pointWidth ?? 2}
                        data={[
                            { value: 2, description: '2' },
                            { value: 3, description: '3' },
                            { value: 4, description: '4' },
                            { value: 5, description: '5' },
                            { value: 6, description: '6' },
                            { value: 8, description: '8' },
                            { value: 12, description: '12' },
                        ]}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '20%'
                            : max1000
                            ? '40%'
                            : '10%',
                        marginBottom: '10px',
                    }}>
                    <Typography style={{ color: '#616161' }}>
                        {t('pages_addgraph_displaytablineoptions_fill')}:{' '}
                    </Typography>
                    <CustomSwitch
                        checked={props.graph?.configOptions?.fill ?? false}
                        onChecked={fillGraph}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                    }}>
                    <SingleSelect
                        title={t(
                            'pages_addgraph_displaytablineoptions_linewidth'
                        )}
                        onChange={changeLineWidth}
                        value={props.graph?.configOptions?.strokeWidth ?? 2}
                        data={[
                            { value: 1, description: '1' },
                            { value: 2, description: '2' },
                            { value: 3, description: '3' },
                            { value: 4, description: '4' },
                            { value: 5, description: '5' },
                            { value: 8, description: '8' },
                            { value: 12, description: '12' },
                        ]}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '20%'
                            : max1000
                            ? '40%'
                            : '10%',
                        marginBottom: '10px',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Typography style={{ color: '#616161' }}>
                        HLine 1:{' '}
                    </Typography>
                    <CustomSwitch
                        checked={props.graph?.configOptions?.hline ?? false}
                        onChecked={setHLine}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                        marginRight: '20px',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                    <TextEdit
                        onChange={(val) => setHLineValue(val)}
                        label="y="
                        placeholder="26"
                        value={props.graph?.configOptions?.hlineValue ?? '26'}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                    <HexColorInput
                        style={{
                            display: 'block',
                            boxSizing: 'border-box',
                            width: '90px',
                            padding: '6px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            background: '#fff',
                            outline: 'none',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            marginRight: '25px',
                            marginTop: '20px',
                        }}
                        color={
                            props.graph?.configOptions?.hlineColor ?? '#000000'
                        }
                        onChange={(val) => setHLineColor(val)}
                    />
                    <HexColorPicker
                        style={{ width: '120px', height: '80px' }}
                        color={
                            props.graph?.configOptions?.hlineColor ?? '#000000'
                        }
                        onChange={(val) => setHLineColor(val)}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '20%'
                            : max1000
                            ? '40%'
                            : '10%',
                        marginBottom: '10px',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Typography style={{ color: '#616161' }}>
                        HLine 2:{' '}
                    </Typography>
                    <CustomSwitch
                        checked={props.graph?.configOptions?.hline2 ?? false}
                        onChecked={setHLine2}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                        marginRight: '20px',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                    <TextEdit
                        onChange={(val) => setHLine2Value(val)}
                        label="y="
                        placeholder="20"
                        value={props.graph?.configOptions?.hline2Value ?? '20'}
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '50%'
                            : max1000
                            ? '60%'
                            : '25%',
                        marginBottom: '10px',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                    <HexColorInput
                        style={{
                            display: 'block',
                            boxSizing: 'border-box',
                            width: '90px',
                            padding: '6px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            background: '#fff',
                            outline: 'none',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            marginRight: '25px',
                            marginTop: '20px',
                        }}
                        color={
                            props.graph?.configOptions?.hline2Color ?? '#000000'
                        }
                        onChange={(val) => setHLine2Color(val)}
                    />
                    <HexColorPicker
                        style={{ width: '120px', height: '80px' }}
                        color={
                            props.graph?.configOptions?.hline2Color ?? '#000000'
                        }
                        onChange={(val) => setHLine2Color(val)}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1300
                            ? '70%'
                            : max1000
                            ? '100%'
                            : '35%',
                        marginBottom: '10px',
                    }}>
                    <SingleSelect
                        title={t(
                            'pages_addgraph_displaytablineoptions_interpolation'
                        )}
                        onChange={changeInterpolation}
                        value={
                            props.graph?.configOptions?.interpolation ??
                            'linear'
                        }
                        data={[
                            {
                                value: 'linear',
                                description: t(
                                    'pages_addgraph_displaytablineoptions_linear'
                                ),
                            },
                            {
                                value: 'natural',
                                description: t(
                                    'pages_addgraph_displaytablineoptions_natural'
                                ),
                            },
                            {
                                value: 'step',
                                description: t(
                                    'pages_addgraph_displaytablineoptions_step'
                                ),
                            },
                            {
                                value: 'basis',
                                description: t(
                                    'pages_addgraph_displaytablineoptions_basis'
                                ),
                            },
                            {
                                value: 'cardinal',
                                description: t(
                                    'pages_addgraph_displaytablineoptions_cardinal'
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
}
