import { FilterApi, UpdateParameterDTO } from '@aurum/nucleus-client-api';
import { Button, Tooltip } from '@material-ui/core';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import './pbiStyling.css';

export function PbiServiceControlScreen(props) {
    const { id } = useParams();
    const [parameters, setParameters] = useState([]);
    const { t } = useTranslation();
    const backIcon = 'filter/return.png';

    const { height } = props;

    useEffect(() => {
        setParameters(props.parameters);
    }, [props.parameters]);

    const getParameterValue = (paramName) => {
        return parameters?.find((p) => p.name === paramName)?.value ?? '';
    };

    const goBack = () => {
        props.triggerChange && props.triggerChange(1);
    };

    const triggerParameter = (name) => {
        const param = parameters?.find((p) => p.name === name);
        if (!isNullOrUndefined(param)) {
            const paramValue = getParameterValue(name);
            const paramsToUpdate = [];
            const paramObj = new UpdateParameterDTO();
            paramObj.name = param.name;
            paramObj.value = paramValue === 0 ? 1 : 0;
            paramsToUpdate.push(paramObj);
            new FilterApi().filterUpdateParameterValues(
                parseInt(id, 10),
                paramsToUpdate,
                function (_, _data, _response) {
                    let lastValue = paramValue;
                    const interval = setInterval(() => {
                        new FilterApi().filterGetParametersFromPlc(
                            parseInt(id, 10),
                            { group: null },
                            function (_, data, response) {
                                if (response.ok) {
                                    setParameters(response.body);
                                    lastValue =
                                        response.body?.find(
                                            (p) => p.name === name
                                        )?.value ?? '';
                                    if (lastValue !== paramValue) {
                                        clearInterval(interval);
                                    }
                                }
                            }
                        );
                    }, 500);
                }
            );
        }
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const valves = [];
    for (let i = 1; i <= 8; i++) {
        valves.push(
            <div className="col-md-1 margin-service">
                <div className="row middle">{i}</div>
                <div className="row middle">
                    <Button
                        className={
                            getParameterValue(`INLET_${i}`) === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        style={{ marginTop: '10px' }}
                        aria-label={`${t('pages_pbiservicecontrol_in')}${i}`}
                        onClick={() => triggerParameter(`INLET_${i}`)}
                        color="primary">
                        {t('pages_pbiservicecontrol_in')}
                    </Button>
                </div>
                <div className="row middle">
                    <Button
                        className={
                            getParameterValue(`FLUSH_${i}`) === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        style={{ marginTop: '10px' }}
                        aria-label={`${t('pages_pbiservicecontrol_flush')}${i}`}
                        onClick={() => triggerParameter(`FLUSH_${i}`)}
                        color="primary">
                        {t('pages_pbiservicecontrol_flush')}
                    </Button>
                </div>
                <div className="row middle">
                    <Button
                        className={
                            getParameterValue(`PERM_${i}`) === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        style={{ marginTop: '10px' }}
                        aria-label={`${t('pages_pbiservicecontrol_out')}${i}`}
                        onClick={() => triggerParameter(`PERM_${i}`)}
                        color="primary">
                        {t('pages_pbiservicecontrol_out')}
                    </Button>
                </div>
                <div className="row middle">
                    <Button
                        className={
                            getParameterValue(`FILTER_ON_OFF${i}`) === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        style={{ marginTop: '10px' }}
                        aria-label={`${i}onoff`}
                        onClick={() => triggerParameter(`FILTER_ON_OFF${i}`)}
                        color="primary">
                        {getParameterValue(`FILTER_ON_OFF${i}`) === 0
                            ? t('pages_pbiservicecontrol_off')
                            : t('pages_pbiservicecontrol_on')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-11 start-just full">
                    <h2>{t('pages_pbiservicecontrol_service')}</h2>
                </div>
                <div className="col-md-1 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_addgraph_addgraph_back')}>
                        <img
                            className="clickable-icon"
                            style={{ width: '40px', height: '40px' }}
                            onClick={() => goBack()}
                            src={`${getProperRoute(backIcon)}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row middle"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${(height / 6) * 4}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                {valves}
            </div>

            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-4 middle">
                    <Button
                        className={
                            getParameterValue('MANUAL_CENTRAL') === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        aria-label={t('pages_pbiservicecontrol_centralflush')}
                        onClick={() => triggerParameter('MANUAL_CENTRAL')}
                        color="primary">
                        {t('pages_pbiservicecontrol_centralflush')}
                    </Button>
                </div>
                <div className="col-md-4 middle">
                    <Button
                        className={
                            getParameterValue('MANUAL_PUMP') === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        aria-label={t('pages_pbiservicecontrol_airpump')}
                        onClick={() => triggerParameter('MANUAL_PUMP')}
                        color="primary">
                        {t('pages_pbiservicecontrol_airpump')}
                    </Button>
                </div>
                <div className="col-md-4 middle">
                    <Button
                        className={
                            getParameterValue('AIR_INPUT') === 1
                                ? 'pbi-button button-on'
                                : 'pbi-button button-off'
                        }
                        aria-label={t('pages_pbiservicecontrol_airpressure')}
                        onClick={() => triggerParameter('AIR_INPUT')}
                        color="primary">
                        {t('pages_pbiservicecontrol_airpressure')}
                    </Button>
                </div>
            </div>
        </>
    );
}
