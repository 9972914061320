import { isNullOrUndefined } from '@utils/helpers/app.helpers';

export function alphabetPosition(text) {
    return parseInt(text, 36) - 10;
}

export function makeRandomStr(length = 16) {
    let result = [];
    let characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join('');
}

export function capitalize(str) {
    if (isNullOrUndefined(str)) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function isNumeric(str) {
    if (typeof str != 'string') return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
}
