import { customTheme } from '@/App';
import ManualMeasurementIcon from '@assets/mmicon.svg';
import RocketIcon from '@assets/rocketicon.svg';
import RocketIconActive from '@assets/rocketiconactive.svg';
import { makeStyles } from '@material-ui/core/styles';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    liStyle: {
        boxShadow: (props) =>
            !props.nested && props.active
                ? `inset 4px 0 0 ${customTheme.palette.primary.main}`
                : 0,
        background: (props) => (props.active ? 'rgba(0,0,0,0.04)' : 0),
        paddingLeft: '12px',
        fontWeight: (props) => (props.active ? 600 : 0),
        textDecoration: 'none',
        '&:hover': {
            background: 'rgba(0,0,0,0.08)',
            cursor: 'pointer',
        },
    },
    grp: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
    },
    aStyle: {
        '&:hover': {
            color: (props) =>
                props.active ? customTheme.palette.primary.main : '#000000',
            textDecoration: 'none',
        },
    },
    icon: {
        fill: (props) =>
            props.active
                ? `${customTheme.palette.primary.main} !important`
                : '#000000 !important',
        width: '20px',
        height: '20px',
        display: 'flex',
        marginRight: '8px',
        '&:hover': {
            fill: (props) =>
                props.active ? customTheme.palette.primary.main : '#000000',
        },
    },
    spanStyle: {
        marginLeft: (props) => (props.nested ? '15px' : 0),
        color: (props) =>
            props.active ? customTheme.palette.primary.main : '#000000',
        '&:hover': {
            color: (props) =>
                props.active ? customTheme.palette.primary.main : '#000000',
            textDecoration: 'none',
        },
    },
}));

export function SideListItem(props) {
    const styles = useStyles(props);

    let icon = <></>;
    if (props.icon === 'settings') {
        icon = <SettingsOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'dashboards') {
        icon = <DashboardOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'alarms') {
        icon = <NotificationsActiveOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'user') {
        icon = <PersonOutlineOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'manual') {
        icon = (
            <img
                className={styles.icon}
                src={ManualMeasurementIcon}
                alt={'manual measurement'}></img>
        );
    } else if (props.icon === 'customers') {
        icon = <DnsOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'pipelines') {
        if (props.active) {
            icon = (
                <img
                    className={styles.icon}
                    src={RocketIconActive}
                    alt={'pipelines'}></img>
            );
        } else {
            icon = (
                <img
                    className={styles.icon}
                    src={RocketIcon}
                    alt={'pipelines'}></img>
            );
        }
    } else if (props.icon === 'diagnostics') {
        icon = <FindInPageOutlinedIcon className={styles.icon} />;
    } else if (props.icon === 'move') {
        icon = <CompareArrowsOutlinedIcon className={styles.icon} />;
    }

    return (
        <li className={styles.liStyle}>
            <Link className={styles.aStyle} to={props.link}>
                <div className={styles.grp}>
                    {icon}
                    <span className={styles.spanStyle}>{props.text}</span>
                </div>
            </Link>
            {props.active ? props.children : <></>}
        </li>
    );
}
