import { parseProgStep } from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React from 'react';
import { LazyLog } from 'react-lazylog';

import './graphstyles.css';

export function LogFileGraph(props) {
    const { height } = props;

    const showData = (data, measurement) => {
        if (measurement === 'PROG_STEP') {
            return parseProgStep(data);
        }
        return data;
    };

    const text =
        props.series
            ?.map((s) =>
                s.datapoints.map(
                    (d) =>
                        `[${dateToString(
                            d.x,
                            props.user?.timeZone ?? 'Europe/Berlin',
                            'de-DE',
                            true
                        )}] - ${showData(d.y, s.name)}\n`
                )
            )[0]
            ?.join('') ?? [];

    return (
        <div className={'chart-wrapper'} style={{ height: '100%' }}>
            <LazyLog extraLines={1} enableSearch text={text} caseInsensitive />
        </div>
    );
}
