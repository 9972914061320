import { SiteApi } from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    FilesTable,
    MoreSettingsMenu,
    RoundedText,
} from '@components';
import { MenuItem } from '@material-ui/core';
import {
    addMemberPayload,
    getFullGroups,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { capitalize } from '@utils/helpers/text.helpers';
import AssignUserModal from '@views/sites/settings/members/assign-member/AssignUserModal';
import MemberEntryTable from '@views/sites/settings/members/member-entry/MemberEntryTable';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function MembersSitePage(props) {
    const [allUsers, setAllUsers] = useState([]);
    const [assignUserModal, setAssignUserModal] = useState(false);
    const { groupId, id } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isNullOrUndefined(props.site)) {
            new SiteApi().siteGetUnassignedUsersFromSite(
                parseInt(groupId, 10),
                parseInt(id, 10),
                function (_err, data, response) {
                    if (response.ok) {
                        setAllUsers(data);
                    }
                }
            );
        }
    }, [props.site]);

    useEffect(() => {
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/members`
        );
    }, [props]);

    const assignUser = (uid) => {
        if (!isNullOrUndefined(uid) && uid.trim() !== '') {
            const user = allUsers?.find((usr) => usr.id === uid);
            if (!isNullOrUndefined(user)) {
                props.site.users.push(user);
                const siteClone = addMemberPayload(props.site);
                new SiteApi().siteUpdateSite(
                    parseInt(groupId, 10),
                    parseInt(id, 10),
                    siteClone,
                    function (_err, data, response) {
                        if (response.ok) {
                            setAllUsers(
                                allUsers?.filter((usr) => usr.id !== uid)
                            );
                            setAssignUserModal(false);
                        }
                    }
                );
            }
        }
    };

    const rows = [];
    props.site?.users.forEach((user) => {
        rows.push([
            {
                flex: true,
                link: null,
                text: user?.userName,
                value: (
                    <MemberEntryTable
                        link={getProperRoute(
                            props.site?.type === 'filter'
                                ? `groups/${groupId}/sites/filter/${id}/members/${user.id}`
                                : `groups/${groupId}/sites/${id}/members/${user.id}`
                        )}
                        user={user}
                    />
                ),
            },
            {
                link: null,
                text: user?.inherited
                    ? 'Inherited'
                    : user?.role ?? t('pages_users_userentrytable_unknown'),
                value: (
                    <RoundedText
                        margin="10px"
                        color="outlined"
                        width="80px"
                        text={
                            user?.inherited
                                ? 'Inherited'
                                : capitalize(user?.role) ??
                                  t('pages_users_userentrytable_unknown')
                        }
                    />
                ),
            },
        ]);
    });

    const unassignedData = [{}];
    allUsers?.forEach((usr) => {
        unassignedData.push({ value: usr.id, description: usr.userName });
    });

    const fullGroups = getFullGroups(props.groups, props.site);

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: t('pagelayout_sidebarsite_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_members'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute('/'),
                            text: t('pagelayout_sidebarsite_members'),
                        },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <MenuItem onClick={() => setAssignUserModal(true)}>
                            {t('pages_groups_grouptreestructure_assignmember')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_users_createusermodal_role'),
                ]}
                rows={rows}
            />
            <AssignUserModal
                data={unassignedData}
                open={assignUserModal}
                handleClose={() => setAssignUserModal(false)}
                confirm={assignUser}
            />
        </>
    );
}
