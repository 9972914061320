import { MenuList } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';

import { NavButton } from '@components';

export function MoreSettingsMenu(props) {
    return (
        <div
            data-cy={props.datacy}
            disabled={props.disabled}
            style={{ justifySelf: 'flex-end', alignSelf: 'center' }}>
            <NavButton
                disabled={props.disabled}
                color={props.color ?? 'black'}
                background={props.background ?? 'gray'}
                id="menu-add-group"
                text={<MoreVertIcon />}>
                <MenuList id="menu-add-group" style={{ width: '100%' }}>
                    {props.children}
                </MenuList>
            </NavButton>
        </div>
    );
}
