// handle window period for graphs
export const UNIT_TO_APPROX_MS = {
    ns: 1 / 1000000,
    µs: 1 / 1000,
    us: 1 / 1000,
    ms: 1,
    s: 1000,
    m: 1000 * 60,
    h: 1000 * 60 * 60,
    d: 1000 * 60 * 60 * 24,
    w: 1000 * 60 * 60 * 24 * 7,
    mo: 1000 * 60 * 60 * 24 * 30,
    y: 1000 * 60 * 60 * 24 * 365,
};

export const NUMBER_OF_DESIRED_POINTS = 360;

export const defaultDateTime = '0001-01-01T00:00:00';
