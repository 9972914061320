import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import CustomerServerTableEntry from '@views/infrastructure/customers/customer-entry/CustomerServerTableEntry';

export default function CustomerServersPage(props) {
    const [customers, setCustomers] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        // TODO: send api request to get the customer server (parse them from main.tf file, prob.)
    }, []);

    const valuesTopGroup = [
        [{ text: '', value: <CustomerServerTableEntry /> }],
    ];
    customers?.forEach((customer) => {
        valuesTopGroup.push([
            { text: '', value: <CustomerServerTableEntry /> },
        ]);
    });

    const links = [
        { link: getProperRoute('admin/customers'), text: 'Admin Area' },
        { link: getProperRoute('admin/customers'), text: 'Customer Servers' },
    ];
    return isNullOrUndefined(customers) ? (
        <> </>
    ) : (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs breadcrumbs={links} />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'admin')}>
                    <MenuItem onClick={() => {}}>
                        Create Customer Server
                    </MenuItem>
                </MoreSettingsMenu>
            </div>
            <hr /> <br />
            <FilesTable
                pagination
                search
                columns={['Customer']}
                rows={valuesTopGroup}
            />
        </div>
    );
}
