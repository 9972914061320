import { TemperatureControlApi } from '@aurum/nucleus-client-api';
import { FilesTable, TableTextEdit } from '@components';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';

import { groupBy } from '../../utils/helpers/app.helpers';

export function StringsTableGraph(props) {
    const [curRegulationValue, setCurRegulationValue] = useState(null);
    const [editRegulation, setEditRegulation] = useState(-1);

    useEffect(() => {
        if (editRegulation === -1) {
            setCurRegulationValue(null);
        }
    }, [editRegulation]);

    const getLatest = (array) =>
        array.reduce((latest, current) => {
            if (!latest || current.x > latest.x) {
                return current;
            } else {
                return latest;
            }
        }, null);

    const onRegulationChanged = (txt) => {
        if (!isNumeric(txt) || txt === '') {
            return;
        }
        setCurRegulationValue(txt);
    };

    const onSaveRegulationValue = (stringId) => {
        if (isNullOrUndefined(curRegulationValue)) {
            setEditRegulation(-1);
            return;
        }
        const regulationUsed = props.site?.configOptions?.algorithm;
        let value = 0;
        if (regulationUsed === 0) {
            value = parseInt(curRegulationValue, 10);
            value = value > 100 ? 100 : value;
            value = value < 0 ? 0 : value;
        } else {
            value = parseFloat(curRegulationValue);
            value = value > 70 ? 70 : value;
            value = value < 40 ? 40 : value;
        }

        new TemperatureControlApi().temperatureControlUpdateStringRegulationValue(
            props.site.id,
            stringId,
            { regulationValue: value },
            function (_, data, response) {
                if (response.ok) {
                    const regulationOptions =
                        props.site.configOptions?.options?.strings;
                    const stringRegulation = regulationOptions?.find(
                        (o) => o.id === stringId
                    );
                    if (regulationUsed === 0) {
                        stringRegulation.valvePosition = value;
                    } else if (regulationUsed === 1) {
                        stringRegulation.desiredTemperature = value;
                    }
                }
                setEditRegulation(-1);
            }
        );
    };

    const commonRegulationOnTable = (component, icon) => {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'inline-flex',
                    alignItems: 'center',
                }}>
                {component}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '30px',
                        alignContent: 'center',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}>
                    {icon}
                </div>
            </div>
        );
    };

    const regulationUsed = props.site?.configOptions?.algorithm;
    const regulationOptions = props.site?.configOptions?.options?.strings;
    const rows = [];

    const seriesByName = groupBy(props.series, 'name');
    for (let key in seriesByName) {
        const seriesObj = seriesByName[key].reduce(
            (acc, { datapoints, name }) => {
                acc[name] = acc[name] || { datapoints: [], name };
                acc[name].datapoints = acc[name].datapoints.concat(datapoints);
                return acc;
            },
            {}
        );
        const firstKey = Object.keys(seriesObj)[0];
        const series = seriesObj[firstKey];
        if (isNullOrUndefined(series.name)) continue;

        const canBeRegulated = series.datapoints.some((d) =>
            ['valvePosition', 'flowRaw', 'flowOffset'].includes(d.measurement)
        );
        if (!canBeRegulated) continue;

        const row = [
            { link: null, text: `${series.name}`, value: series.name },
        ];
        for (const col of props.configOptions?.rows ?? []) {
            const latest = getLatest(
                series.datapoints.filter((d) => d.measurement === col)
            );

            row.push({
                link: null,
                text: `${latest?.y ?? '-'}`,
                value: latest?.y ?? '-',
            });
        }

        const stringRegulation = regulationOptions?.find(
            (o) => o.id === series.datapoints[0]?.strangId
        );
        if (editRegulation === stringRegulation?.id) {
            row.push({
                link: null,
                text: '',
                value: commonRegulationOnTable(
                    <TableTextEdit
                        autofocus
                        value={
                            regulationUsed === 0
                                ? stringRegulation?.valvePosition
                                : stringRegulation?.desiredTemperature
                        }
                        onChange={(txt) => onRegulationChanged(txt)}
                    />,
                    <>
                        <CheckIcon
                            onClick={() =>
                                onSaveRegulationValue(stringRegulation.id)
                            }
                            style={{ width: '18px', cursor: 'pointer' }}
                        />
                        <CloseIcon
                            onClick={() => setEditRegulation(-1)}
                            style={{ width: '18px', cursor: 'pointer' }}
                        />
                    </>
                ),
                flex: true,
            });
        } else {
            row.push({
                link: null,
                text: '',
                value: commonRegulationOnTable(
                    <span>
                        {regulationUsed === 0
                            ? stringRegulation?.valvePosition
                            : stringRegulation?.desiredTemperature}
                    </span>,
                    <EditIcon
                        onClick={() => setEditRegulation(stringRegulation?.id)}
                        style={{
                            width: '18px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                        }}
                    />
                ),
                flex: true,
            });
        }
        rows.push(row);
    }

    const propHeaders = props.configOptions?.headers ?? [];
    const headers = [
        'Name',
        ...propHeaders,
        regulationUsed === 0
            ? 'Desired Valve Position (%)'
            : 'Desired Temperature (ºC)',
    ];

    return <FilesTable columns={headers} rows={rows} />;
}
