import { SingleSelect, TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CreateSensorModal(props) {
    const [uuid, setUuid] = useState('');
    const [name, setName] = useState(props.name ?? '');
    const [type, setType] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const nameRef = useRef();
    const uuidRef = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        if (
            !isNullOrUndefined(props.creationError) &&
            isNullOrUndefined(errorMsg)
        ) {
            setErrorMsg(props.creationError);
        }
    }, [errorMsg, props.creationError]);

    const uuidChanged = (txt) => {
        const sensorQrContent = /ELT[a-zA-Z0-9+],EUI:[a-fA-F0-9]{16}/gm;
        const sensorReplace = /ELT[a-zA-Z0-9+],EUI:/gm;
        // regex to check if a valid UUID (16 hexadecimal characters) has been entered
        const sensorUUid = /^[A-Fa-f0-9]{16}/gm;
        if (new RegExp(sensorQrContent).test(txt)) {
            setUuid(txt.replace(sensorReplace, ''));
            nameRef?.current?.lastChild?.lastChild?.focus();
        } else if (new RegExp(sensorUUid).test(txt)) {
            setUuid(txt);
            nameRef?.current?.lastChild?.lastChild?.focus();
        } else {
            setUuid(txt);
        }
    };

    const nameChanged = (txt) => {
        const aquacloudQrContent =
            /AquaCloud,PN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+],SN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+]/gm;
        const aquacloudReplace =
            /AquaCloud,PN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+],SN=/gm;
        if (new RegExp(aquacloudQrContent).test(txt)) {
            setName(txt.replace(aquacloudReplace, ''));
        } else {
            setName(txt);
        }
    };

    const typeChanged = (tp) => {
        setType(tp);
    };

    const onOkClick = () => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== '' &&
            !isNullOrUndefined(type) &&
            type.trim() !== ''
        ) {
            const id = uuid;
            const sensorName = name;
            setUuid('');
            setName('');
            props.confirm(id, sensorName, type);
            setErrorMsg(null);
            uuidRef?.current?.lastChild?.lastChild?.focus();
        } else {
            setErrorMsg(t('pages_devices_createsensormodal_errormsg'));
        }
    };

    const keyDown = debounce((evt) => {
        if (evt.key === 'Enter') {
            onOkClick();
        }
    }, 300);

    const closeModal = () => {
        setErrorMsg(null);
        setName('');
        setUuid('');
        props.handleClose && props.handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => closeModal()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.edit
                    ? `${t('modal_edit')} '${props.name}'`
                    : t('pages_devices_createsensormodal_newsensor')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                {props.edit ? (
                    <></>
                ) : (
                    <div style={{ marginTop: '20px' }}>
                        <TextEdit
                            reference={uuidRef}
                            autoFocus
                            label={t('pages_devices_createsensormodal_uuidlbl')}
                            value={uuid}
                            placeholder="UUID"
                            onChange={uuidChanged}
                            onKeyDown={(evt) => keyDown(evt)}
                        />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        reference={nameRef}
                        label={t('pages_devices_createsensormodal_sensorsnlbl')}
                        value={name}
                        placeholder={t(
                            'pages_devices_createsensormodal_sensorsn'
                        )}
                        onChange={nameChanged}
                        onKeyDown={(evt) => keyDown(evt)}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <SingleSelect
                        title={t('pages_devices_createsensormodal_type')}
                        data={props.dataType}
                        onChange={typeChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => closeModal()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Create"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {props.edit ? t('modal_edit') : t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
