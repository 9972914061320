import React, { useEffect, useState } from 'react';
import {
    createContainer,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
} from 'victory';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { Legend, MovableLegend, NoData } from '@components';

import './graphstyles.css';

export function BarGraph(props) {
    const [zoomDomain] = useState(null);
    const [hiddenSeries, setHiddenSeries] = useState(new Set());
    const [timestamps, setTimestamps] = useState([]);
    const [toAdd, setToAdd] = useState([]);

    const { width, height } = props;

    useEffect(() => {
        if (!isNullOrUndefined(props.series)) {
            const ta = [];
            let index = 0;
            series.forEach((x) => {
                ta.push({ idx: index++, dt: new Date() });
            });
            setToAdd(ta);
        }
    }, [props.series]);

    const series = props.series || [];

    const buildEvents = () => {
        return series.map((_, idx) => {
            return {
                childName: ['legend'],
                target: 'data',
                eventKey: String(idx),
                eventHandlers: {
                    onClick: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: () => {
                                    // if we want to hide the item
                                    if (
                                        !hiddenSeries.has(idx) &&
                                        toAdd.find((x) => x.idx === idx) !==
                                            undefined &&
                                        new Date() -
                                            toAdd.find((x) => x.idx === idx)
                                                .dt >
                                            300
                                    ) {
                                        // Was not already hidden => add to set
                                        hiddenSeries.add(idx);
                                        const ts = toAdd
                                            .map((item) => item.idx)
                                            .indexOf(idx);
                                        ~ts && toAdd.splice(ts, 1);
                                        setToAdd(toAdd);
                                        setTimestamps([
                                            ...timestamps,
                                            { idx: idx, dt: new Date() },
                                        ]);
                                        // if we want to reveal the item
                                    } else if (
                                        timestamps.find(
                                            (x) => x.idx === idx
                                        ) !== undefined &&
                                        new Date() -
                                            timestamps.find(
                                                (x) => x.idx === idx
                                            ).dt >
                                            300
                                    ) {
                                        hiddenSeries.delete(idx);
                                        const ts = timestamps
                                            .map((item) => item.idx)
                                            .indexOf(idx);
                                        ~ts && timestamps.splice(ts, 1);
                                        setTimestamps(timestamps);
                                        setToAdd([
                                            ...toAdd,
                                            { idx: idx, dt: new Date() },
                                        ]);
                                    }

                                    setHiddenSeries(new Set(hiddenSeries));
                                    return null;
                                },
                            },
                        ];
                    },
                    onMouseOver: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: (props) => {
                                    return {
                                        style: {
                                            ...props.style,
                                            strokeWidth: 4,
                                            fillOpacity: 0.5,
                                        },
                                    };
                                },
                            },
                        ];
                    },
                    onMouseOut: () => {
                        return [
                            {
                                childName: ['area-' + idx],
                                target: 'data',
                                eventKey: 'all',
                                mutation: () => {
                                    return null;
                                },
                            },
                        ];
                    },
                },
            };
        });
    };
    const toVictoryData = (line) => {
        return line.datapoints.map((dp) => ({
            name: line.name,
            x: dp.x,
            y: dp.y,
        }));
    };

    const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');
    return series.length === 0 ? (
        <NoData />
    ) : (
        <>
            <div className="chart-wrapper">
                <VictoryChart
                    width={width}
                    height={height}
                    scale={{ x: 'time' }}
                    padding={{ top: 10, left: 50, right: 50, bottom: 50 }}
                    domainPadding={{ x: 100 }}
                    containerComponent={
                        <VictoryZoomVoronoiContainer
                            labels={({ datum }) =>
                                `${datum.name}:   ${datum.y}`
                            }
                            voronoiDimension="x"
                            labelComponent={
                                <Legend width={width} height={height} />
                            }
                            responsive={false}
                            zoomDimension="x"
                            zoomDomain={zoomDomain}
                        />
                    }>
                    <VictoryAxis
                        // tickValues={series[0]?.datapoints?.map(d => d.x)}
                        // tickFormat={(t) => t.toLocaleString("en-us", { month: "long" })}
                        style={{
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                        }}
                    />
                    <VictoryAxis
                        style={{
                            grid: { stroke: '#818e99', strokeWidth: 0.5 },
                        }}
                        dependentAxis
                    />
                    {series.map((s, idx) => {
                        if (hiddenSeries.has(idx)) {
                            return undefined;
                        }
                        return (
                            <VictoryBar
                                horizontal={props.horizontal ?? false}
                                key={'area-' + idx}
                                name={'area-' + idx}
                                barRatio={props.customStyle?.barRatio ?? 0.8}
                                data={toVictoryData(s)}
                                alignment={
                                    props.customStyle?.alignment ?? 'middle'
                                }
                                style={{
                                    data: {
                                        fillOpacity:
                                            props.customStyle?.fillOpacity ??
                                            0.5,
                                        fill: s.color,
                                        stroke: s.color,
                                        strokeWidth:
                                            props.customStyle?.strokeWidth ?? 2,
                                    },
                                    labels: { fill: s.color },
                                }}
                            />
                        );
                    })}
                </VictoryChart>
            </div>
            {props.showLegend ? (
                <MovableLegend
                    series={props.series}
                    allData={props.series}
                    hiddenSeries={hiddenSeries}
                    buildEvents={buildEvents}
                />
            ) : (
                <></>
            )}
        </>
    );
}
