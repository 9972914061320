import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ColumnToRow, Item } from '@mui-treasury/components/flex';
import { NavItem, NavMenu } from '@mui-treasury/components/menu/navigation';
import { usePlainNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/plain';
import cx from 'clsx';
import React from 'react';
import { customTheme } from '@/App';
import { useMediaQuery } from 'react-responsive';
import { getProperRoute } from '@utils/helpers/app.helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ typography, breakpoints }) => ({
    legalLink: {
        ...typography.caption,
        justifyContent: 'center',
        color:
            customTheme.palette.type === 'dark'
                ? 'rgba(255,255,255,0.57)'
                : customTheme.palette.secondary,
        position: 'relative',
        [breakpoints.up('sm')]: {
            '&:not(:first-of-type)': {
                '&:before': {
                    content: '"|"',
                    display: 'block',
                    position: 'absolute',
                    left: 0,
                },
            },
        },
    },
}));

export function AppFooter(props) {
    const classes = useStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
    const { t } = useTranslation();

    return (
        <Box
            pt={2}
            pb={10}
            style={{
                width:
                    props.active && !isMobile ? 'calc(100% - 256px)' : '100%',
                marginLeft: props.active && !isMobile ? '256px' : 0,
                marginTop: '10px',
                paddingBottom: '10px',
            }}>
            <ColumnToRow
                at={'md'}
                columnStyle={{ alignItems: 'center' }}
                rowStyle={{ alignItems: 'unset' }}>
                <Item
                    style={{ marginLeft: isMobile ? 0 : '50px' }}
                    grow
                    ml={-2}
                    shrink={0}>
                    <NavMenu useStyles={usePlainNavigationMenuStyles}>
                        <ColumnToRow at={'sm'}>
                            <NavItem
                                href={getProperRoute('cookie-policy')}
                                className={cx(classes.legalLink)}>
                                {t('pages_footer_cookie_policy')}
                            </NavItem>
                            <NavItem
                                href={getProperRoute('privacy-policy')}
                                className={cx(classes.legalLink)}>
                                {t('pages_footer_privacy_policy')}
                            </NavItem>
                            <NavItem
                                href={getProperRoute('impressum')}
                                className={cx(classes.legalLink)}>
                                {t('pages_footer_impressum')}
                            </NavItem>
                        </ColumnToRow>
                    </NavMenu>
                </Item>
                <Item style={{ marginRight: isMobile ? 0 : '50px' }}>
                    <Box py={1} textAlign={{ xs: 'center', md: 'right' }}>
                        <Typography
                            component={'p'}
                            variant={'caption'}
                            color={'textSecondary'}>
                            {`AurumCloud © ${new Date().getFullYear()} ${t(
                                'pages_footer_all_rights'
                            )}`}
                        </Typography>
                    </Box>
                </Item>
            </ColumnToRow>
        </Box>
    );
}
