import { MultipleSelect, SingleSelect, TextEdit } from '@components';
import MomentUtils from '@date-io/moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CreateAlarmModal(props) {
    const [mp, setMp] = useState([]);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [lower, setLower] = useState('0');
    const [upper, setUpper] = useState('0');
    const [lowerHyst, setLowerHyst] = useState('0');
    const [upperHyst, setUpperHyst] = useState('0');
    const [name, setName] = useState('');
    const [type, setType] = useState('temperature');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        setName(props.alarm?.name ?? '');
        setFrom(props.alarm?.from ?? new Date());
        setTo(props.alarm?.to ?? new Date());
        setLower(props.alarm?.lowerLimit?.toString() ?? '0');
        setUpper(props.alarm?.upperLimit?.toString() ?? '0');
        setLowerHyst(props.alarm?.hystLower?.toString() ?? '0');
        setUpperHyst(props.alarm?.hystUpper?.toString() ?? '0');
        setType(props.alarm?.type ?? 'temperature');
        setMp(props.alarmMps?.map((amp) => amp?.id) ?? []);
    }, [props.alarm, props.alarmMps, props.mpoints]);

    const nameChanged = (txt) => {
        setName(txt);
    };

    const lowerChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createalarmmodal_limiterror'));
        } else {
            if (
                isNumeric(lower) &&
                isNumeric(lowerHyst) &&
                isNumeric(upper) &&
                isNumeric(upperHyst)
            ) {
                setErrorMsg(null);
            }
            setLower(txt);
        }
    };

    const upperChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createalarmmodal_limiterror'));
        } else {
            if (
                isNumeric(lower) &&
                isNumeric(lowerHyst) &&
                isNumeric(upper) &&
                isNumeric(upperHyst)
            ) {
                setErrorMsg(null);
            }
            setUpper(txt);
        }
    };

    const lowerHystChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createalarmmodal_limiterror'));
        } else {
            if (
                isNumeric(lower) &&
                isNumeric(lowerHyst) &&
                isNumeric(upper) &&
                isNumeric(upperHyst)
            ) {
                setErrorMsg(null);
            }
            setLowerHyst(txt);
        }
    };

    const upperHystChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createalarmmodal_limiterror'));
        } else {
            if (
                isNumeric(lower) &&
                isNumeric(lowerHyst) &&
                isNumeric(upper) &&
                isNumeric(upperHyst)
            ) {
                setErrorMsg(null);
            }
            setUpperHyst(txt);
        }
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (
            !(
                isNumeric(lower) &&
                isNumeric(lowerHyst) &&
                isNumeric(upper) &&
                isNumeric(upperHyst)
            )
        ) {
            return;
        }

        if (!isNullOrUndefined(name) && name.trim() !== '') {
            props.confirm({
                name: name,
                from: from,
                to: to,
                isActive: props.alarm?.isActive ?? false,
                lowerLimit: parseFloat(lower),
                upperLimit: parseFloat(upper),
                hystLower: parseFloat(lowerHyst),
                hystUpper: parseFloat(upperHyst),
                type: type,
                mpoint: mp,
                measurementPoint: props.alarm?.measurementPoint,
                users: props.alarm?.users ?? [],
                id: props.alarm?.id,
            });
        } else {
            setErrorMsg(t('pages_sitesettings_createalarmmodal_nameerror'));
        }
    };

    const handleFrom = (date, value) => {
        setFrom(new Date(value));
    };

    const handleTo = (date, value) => {
        setTo(new Date(value));
    };

    const handleType = (tp) => {
        setType(tp);
    };

    const handleMp = (val) => {
        setMp(val);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.edit
                    ? `${t('modal_edit')} '${props.alarm?.name}'`
                    : t('pages_sitesettings_createalarmmodal_new')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_dashboard_createdashboardmodal_name'
                                )}
                                placeholder="Alarm 01"
                                value={name}
                                onChange={nameChanged}
                            />
                        </div>
                        <div className="col-md-6">
                            <SingleSelect
                                title={t('pages_devices_devicepage_type')}
                                value={type}
                                onChange={handleType}
                                data={[
                                    {
                                        value: 'temperature',
                                        description: t(
                                            'pages_devices_devicespage_temperature'
                                        ),
                                    },
                                    {
                                        value: 'chloride',
                                        description: t(
                                            'pages_devices_devicespage_chlorine'
                                        ),
                                    },
                                    {
                                        value: 'battery',
                                        description: t(
                                            'pages_addgraph_settingstab_battery'
                                        ),
                                    },
                                    {
                                        value: 'distance',
                                        description: 'Distance',
                                    },
                                    {
                                        value: 'digitalInput',
                                        description: t(
                                            'pages_devices_devicespage_dinput'
                                        ),
                                    },
                                    {
                                        value: 'flowrate',
                                        description: t(
                                            'pages_addgraph_settingstab_flowrate'
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_alarmentrypage_lower'
                                )}
                                placeholder="20"
                                value={lower}
                                onChange={lowerChanged}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_alarmentrypage_upper'
                                )}
                                placeholder="25"
                                value={upper}
                                onChange={upperChanged}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_alarmentrypage_hlow'
                                )}
                                placeholder="0"
                                value={lowerHyst}
                                onChange={lowerHystChanged}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_alarmentrypage_hupp'
                                )}
                                placeholder="0"
                                value={upperHyst}
                                onChange={upperHystChanged}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className="row">
                            <div className="col-md-6">
                                <KeyboardDatePicker
                                    style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    margin="normal"
                                    label={t(
                                        'pages_sitesettings_alarmentrypage_fromtxt'
                                    )}
                                    value={from}
                                    onChange={handleFrom}
                                />
                            </div>
                            <div className="col-md-6">
                                <KeyboardDatePicker
                                    style={{ width: '95%', marginLeft: '15px' }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    margin="normal"
                                    label={t(
                                        'pages_sitesettings_alarmentrypage_totxt'
                                    )}
                                    value={to}
                                    onChange={handleTo}
                                />
                            </div>
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <MultipleSelect
                                title={t('pages_devices_devicepage_mp')}
                                onChange={handleMp}
                                value={mp}
                                data={props.mpoints}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {props.edit ? t('modal_edit') : t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
