import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { customTheme } from '@/App';
import { hexToRgba } from '@utils/helpers/colors.helpers';

const useStyles = makeStyles(() => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.6),
    },
}));

export function LinearProgressWithLine(props) {
    const styles = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress
                    className={styles.root + ' ' + styles.bar}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
