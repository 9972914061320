import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';

export default function CustomerServerPage(props) {
    const [customer, setCustomer] = useState('');
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {}, []);

    const removeCustomerServer = () => {};

    return isNullOrUndefined(customer) ? (
        <></>
    ) : (
        <>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`admin/customers`),
                            text: 'Admin Area',
                        },
                        {
                            link: getProperRoute(`admin/customers`),
                            text: 'Customer Servers',
                        },
                        { link: getProperRoute('/'), text: 'IBB' },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'admin')}>
                    {userIsAtLeast(props.user, 'admin') ? (
                        <>
                            <MenuItem onClick={() => removeCustomerServer()}>
                                Edit Customer Server
                            </MenuItem>
                            <MenuItem onClick={() => removeCustomerServer()}>
                                Delete Customer Server
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr /> <br />
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_diagnostics_diagnosticspage_value'),
                ]}
                rows={[
                    [
                        {
                            link: null,
                            text: t('pages_devices_devicepage_name'),
                            value: t('pages_devices_devicepage_name'),
                        },
                        { link: null, text: 'IBB', value: 'IBB' },
                    ],
                    [
                        {
                            link: null,
                            text: 'Domain Name',
                            value: 'Domain Name',
                        },
                        {
                            link: null,
                            text: 'ibb.aquacloud.de',
                            value: 'ibb.aquacloud.de',
                        },
                    ],
                    [
                        { link: null, text: 'Private IP', value: 'Private IP' },
                        { link: null, text: '10.60.1.3', value: '10.60.1.3' },
                    ],
                    [
                        {
                            link: null,
                            text: 'Server Type',
                            value: 'Server Type',
                        },
                        { link: null, text: 'cx11', value: 'cx11' },
                    ],
                    [
                        { link: null, text: 'Role', value: 'Role' },
                        { link: null, text: 'customer', value: 'customer' },
                    ],
                    [
                        { link: null, text: 'Location', value: 'Location' },
                        { link: null, text: 'nbg1', value: 'nbg1' },
                    ],
                ]}
            />
        </>
    );
}
