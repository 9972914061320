import { TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SiteTcCreateCirculationModal(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [name, setName] = useState('');

    const { t } = useTranslation();

    useEffect(() => {}, [props]);

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const isConfirmBtnDisabled = () => {
        return name?.trim() === '';
    };

    const onOkClick = () => {
        if (isConfirmBtnDisabled()) {
            return;
        }
        props.confirm && props.confirm(name);
        props.handleClose && props.handleClose();
        setName('');
    };

    const keyDownCallback = useCallback((evt) => keyDown(evt), []);
    const dialogCloseCallback = useCallback(() => {
        setName('');
        props.handleClose && props.handleClose();
    }, [props.handleClose]);
    const dialogConfirmCallback = useCallback(
        () => props.confirm && onOkClick(),
        [props.confirm, name]
    );
    const alertCloseCallback = useCallback(() => setErrorMsg(null), []);
    const nameChangedCallback = useCallback((txt) => setName(txt), []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={keyDownCallback}
            onClose={dialogCloseCallback}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_sitetc_settings_circucreate')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert onClose={alertCloseCallback} severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <TextEdit
                    label={t('common_filestable_namecol')}
                    placeholder={t('common_filestable_namecol')}
                    autoFocus
                    value={name}
                    onKeyDown={keyDownCallback}
                    onChange={nameChangedCallback}
                />
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={dialogCloseCallback}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Save"
                    disabled={isConfirmBtnDisabled()}
                    onClick={dialogConfirmCallback}
                    color="primary">
                    {t('pages_pbisettings_save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
