import { SiteApi } from '@aurum/nucleus-client-api';
import { configureStore } from '@reduxjs/toolkit';

import {
    createSiteStart,
    getAllSitesFailure,
    getAllSitesStart,
    getAllSitesSuccess,
    sitesSlice,
} from './sites.slice';

export const getAllSites = () => {
    return (dispatch, getState) => {
        dispatch(getAllSitesStart());

        const { sites } = getState();
        if (sites.sitesLoaded) return;

        new SiteApi().siteGetSites(0, function (error, data, _response) {
            if (error) {
                dispatch(getAllSitesFailure(error.message));
            } else {
                dispatch(getAllSitesSuccess(data));
            }
        });
    };
};

export const createSite = (site) => {
    return (dispatch) => {
        dispatch(createSiteStart(site));
    };
};

const store = configureStore({
    reducer: {
        sites: sitesSlice.reducer,
    },
});

export default store;
