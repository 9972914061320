import { Legend, MovableLegend, NoData } from '@components';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    createContainer,
    VictoryArea,
    VictoryAxis,
    VictoryBrushContainer,
    VictoryChart,
    VictoryLine,
    VictoryScatter,
} from 'victory';

import './graphstyles.css';

export function LineGraph(props) {
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [hiddenSeries, setHiddenSeries] = useState(new Set());
    const [timestamps, setTimestamps] = useState([]);
    const [chartData, setChartData] = useState({
        allData: null,
        visibleData: null,
        minX: null,
        maxX: null,
        absoluteMinX: null,
        absoluteMaxX: null,
        min: null,
        max: null,
        minData: null,
        maxData: null,
        absoluteMin: null,
        absoluteMax: null,
        toAdd: null,
    });

    const { width, height } = props;

    useEffect(() => {
        setChartData(initializeChartData(props.series));
    }, [props.series]);

    const getMinMaxValues = (data) => {
        const values = [].concat(
            ...data.map((dp) => dp.datapoints.map((d) => d.y))
        );

        let min = Math.min(...values) - 0.1;
        let max = Math.max(...values) + 0.1;
        max =
            max === Number.POSITIVE_INFINITY || max === Number.NEGATIVE_INFINITY
                ? 1
                : max;
        min =
            min === Number.POSITIVE_INFINITY || min === Number.NEGATIVE_INFINITY
                ? 0
                : min;

        return [min, max];
    };

    const getMinMaxDate = (datapoints) => {
        const dates = [].concat(
            ...datapoints.map((dp) => dp.datapoints.map((d) => d.x))
        );

        const minDate = new Date(Math.min.apply(null, dates));
        const maxDate = new Date(Math.max.apply(null, dates));
        return [minDate, maxDate];
    };

    const initializeChartData = (series) => {
        const [minY, maxY] = getMinMaxValues(series);
        const [minX, maxX] = getMinMaxDate(series);
        const toAdd = series.map((x, index) => ({
            idx: index,
            dt: new Date(),
        }));
        return {
            allData: series,
            visibleData: series,
            minX,
            maxX,
            absoluteMinX: minX,
            absoluteMaxX: maxX,
            min: minY,
            max: maxY,
            minData: minY,
            maxData: maxY,
            absoluteMin: minY,
            absoluteMax: maxY,
            toAdd,
        };
    };

    const handleBrush = (domain) => {
        const visible = [];
        chartData.allData.forEach((d, idx) => {
            visible.push({
                name: d.name,
                color: d.color,
                datapoints: d.datapoints?.filter(
                    (dp) => dp.x >= domain.x[0] && dp.x <= domain.x[1]
                ),
            });
        });

        const [minY, maxY] = getMinMaxValues(visible);
        const [minX, maxX] = getMinMaxDate(visible);

        setChartData((prevState) => ({
            ...prevState,
            visibleData: visible,
            minX,
            maxX,
            minData: domain.y[0],
            maxData: domain.y[1],
            min: minY,
            max: maxY,
        }));
        setSelectedDomain(domain);
    };

    const buildEvents = () => {
        const toggleSeriesVisibility = (idx) => {
            const now = new Date();
            const ts = chartData.toAdd.findIndex((item) => item.idx === idx);
            if (
                !hiddenSeries.has(idx) &&
                ts !== -1 &&
                now - chartData.toAdd[ts].dt > 300
            ) {
                // Was not already hidden => add to set
                hiddenSeries.add(idx);
                chartData.toAdd.splice(ts, 1);
                setChartData((prevState) => ({
                    ...prevState,
                    toAdd: [...chartData.toAdd],
                }));
                setTimestamps([...timestamps, { idx, dt: now }]);
            } else if (
                timestamps.find((x) => x.idx === idx) !== undefined &&
                now - timestamps.find((x) => x.idx === idx).dt > 300
            ) {
                hiddenSeries.delete(idx);
                const ts = timestamps.findIndex((item) => item.idx === idx);
                timestamps.splice(ts, 1);
                setTimestamps([...timestamps]);
                setChartData((prevState) => ({
                    ...prevState,
                    toAdd: [...chartData.toAdd, { idx, dt: now }],
                }));
            }
            setHiddenSeries(new Set(hiddenSeries));
        };

        const highlightSeries = (idx) => ({
            childName: [`area-${idx}`],
            target: 'data',
            eventKey: 'all',
            mutation: (props) => ({
                style: { ...props.style, strokeWidth: 4, fillOpacity: 0.5 },
            }),
        });

        const resetSeriesHighlight = (idx) => ({
            childName: [`area-${idx}`],
            target: 'data',
            eventKey: 'all',
            mutation: () => null,
        });

        return chartData.allData.map((_, idx) => ({
            childName: ['legend'],
            target: 'data',
            eventKey: String(idx),
            eventHandlers: {
                onClick: () => [toggleSeriesVisibility(idx)],
                onMouseOver: () => [highlightSeries(idx)],
                onMouseOut: () => [resetSeriesHighlight(idx)],
            },
        }));
    };

    const toVictoryData = (line, name = true) => {
        const { datapoints } = line;
        const filtered = datapoints?.sort((a, b) => a.x - b.x);

        const domain =
            selectedDomain ??
            (filtered?.length > 0
                ? { x: [filtered[0].x, filtered[filtered.length - 1].x] }
                : null);

        const intervalLength =
            domain?.x[0]?.getTime && domain?.x[1]?.getTime
                ? Math.ceil(
                      (domain.x[1].getTime() - domain.x[0].getTime()) /
                          (1000 * 3600)
                  )
                : 1;

        const precisionLookup = {
            [24 * 2]: 20 * 60 * 1000,
            [24 * 4]: 40 * 60 * 1000,
            [24 * 7]: 120 * 60 * 1000,
            [24 * 15]: 360 * 60 * 1000,
            [24 * 30]: 60 * 12 * 60 * 1000,
            [24 * 90]: 60 * 24 * 60 * 1000,
        };
        let precision =
            precisionLookup[intervalLength] ??
            (props?.measurement === 'chloride'
                ? 1 * 60 * 1000
                : 10 * 60 * 1000);

        const groupedResults = _.groupBy(filtered, (item) =>
            moment(Math.floor(item.x / precision) * precision)
        );
        const newdata = Object.entries(groupedResults).map(
            ([dateStr, items]) => {
                const yvalues = items.map(({ y }) => y);
                const averageY =
                    yvalues.reduce((sum, value) => sum + value, 0) /
                    yvalues.length;
                return { x: new Date(dateStr), y: averageY };
            }
        );

        if (name) {
            return newdata.map(({ x, y }) => ({ name: line.name, x, y }));
        }
        return newdata.map(({ x, y }) => ({ x, y }));
    };

    const toVictoryDataRaw = (line, name = true) => {
        line.datapoints?.sort(function (a, b) {
            return a.x - b.x;
        });
        if (name) {
            return line.datapoints?.map((dp) => ({
                name: line.name,
                x: dp.x,
                y: dp.y,
            }));
        }
        return line.datapoints?.map((dp) => ({ x: dp.x, y: dp.y }));
    };

    const toVictoryAll = (line) => {
        line.datapoints.sort(function (a, b) {
            return a.x - b.x;
        });
        const k = Math.ceil(line.datapoints.length / 30);
        return line.datapoints
            ?.filter((dp, idx) => idx % k === 0)
            ?.map((dp) => ({ name: line.name, x: dp.x, y: dp.y }));
    };

    const onGraphRef = (gRef) => {
        props.onGraphRef && props.onGraphRef(gRef);
    };

    const mouseEnterGraph = () => {
        document.body.classList.add('prevent-scroll');
    };

    const mouseLeaveGraph = () => {
        document.body.classList.remove('prevent-scroll');
    };

    const horizontalGrid = _.range(
        chartData.minData ?? 0,
        chartData.maxData ?? 1,
        ((chartData.maxData ?? 1) - (chartData.minData ?? 0)) / 25
    );
    const verticalGrid = _.range(
        chartData.minX?.getTime() ?? 0,
        chartData.maxX?.getTime() ?? 0,
        ((chartData.maxX?.getTime() ?? 0) - (chartData.minX?.getTime() ?? 0)) /
            25
    );

    const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');
    return chartData.allData?.length === 0 ? (
        <NoData />
    ) : (
        <>
            <div
                onMouseEnter={() => mouseEnterGraph()}
                onMouseLeave={() => mouseLeaveGraph()}
                className="chart-wrapper">
                <VictoryChart
                    style={{ zIndex: 99999 }}
                    width={width}
                    domain={{
                        y: [chartData.minData ?? 0, chartData.maxData ?? 0],
                    }}
                    height={height * 0.7}
                    scale={{ x: 'time' }}
                    padding={{ top: 10, left: 50, right: 50, bottom: 30 }}
                    containerComponent={
                        <VictoryZoomVoronoiContainer
                            labels={({ datum }) =>
                                `${datum.name}:   ${datum.y} ºC`
                            }
                            voronoiDimension="x"
                            labelComponent={
                                <Legend width={width} heigth={height * 0.9} />
                            }
                            containerRef={(ref) => onGraphRef(ref)}
                            responsive={false}
                            zoomDimension="x"
                            zoomDomain={{
                                x: [
                                    chartData.minX ?? new Date(),
                                    chartData.maxX ?? new Date(),
                                ],
                                y: [
                                    chartData.minData ?? 0,
                                    chartData.maxData ?? 1,
                                ],
                            }}
                        />
                    }>
                    {props.showGrid ? (
                        <VictoryAxis
                            tickValues={verticalGrid}
                            tickFormat={(_t) => {}}
                            style={{
                                grid: {
                                    stroke: 'rgba(200, 200, 200, 0.5)',
                                    strokeDasharray: 0,
                                },
                            }}
                        />
                    ) : (
                        <></>
                    )}

                    {props.showGrid ? (
                        <VictoryAxis
                            dependentAxis
                            tickValues={horizontalGrid}
                            tickFormat={(_t) => {}}
                            style={{
                                grid: {
                                    stroke: 'rgba(200, 200, 200, 0.5)',
                                    strokeDasharray: 0,
                                },
                            }}
                        />
                    ) : (
                        <></>
                    )}

                    <VictoryAxis
                        tickFormat={(t) => moment(t).format('DD.MM HH:mm')}
                        style={{
                            grid: {
                                stroke: props.showGrid
                                    ? 'transparent'
                                    : '#818e99',
                                strokeWidth: 0.5,
                            },
                        }}
                    />

                    <VictoryAxis
                        style={{
                            grid: {
                                stroke: props.showGrid
                                    ? 'transparent'
                                    : '#818e99',
                                strokeWidth: 0.5,
                            },
                        }}
                        dependentAxis
                    />
                    {chartData.visibleData?.map((s, idx) => {
                        if (hiddenSeries.has(idx)) {
                            return undefined;
                        }
                        return props.customStyle?.fill ? (
                            <VictoryArea
                                key={makeRandomStr()}
                                name={'area-' + idx}
                                data={
                                    props.customStyle?.usePoints
                                        ? toVictoryDataRaw(s, false)
                                        : toVictoryData(s)
                                }
                                interpolation={
                                    props.customStyle?.interpolation ?? 'linear'
                                }
                                style={{
                                    data: {
                                        fill: s.color,
                                        fillOpacity: 0.2,
                                        stroke: s.color,
                                        strokeWidth:
                                            props.customStyle?.strokeWidth ?? 2,
                                    },
                                    labels: { fill: s.color },
                                }}
                            />
                        ) : (
                            <VictoryLine
                                key={makeRandomStr()}
                                name={'area-' + idx}
                                data={
                                    props.customStyle?.usePoints
                                        ? toVictoryDataRaw(s, false)
                                        : toVictoryData(s)
                                }
                                interpolation={
                                    props.customStyle?.interpolation ?? 'linear'
                                }
                                groupComponent={<g />}
                                style={{
                                    data: {
                                        fillOpacity: 0.2,
                                        stroke: s.color,
                                        strokeWidth:
                                            props.customStyle?.strokeWidth ?? 2,
                                    },
                                    labels: { fill: s.color },
                                }}
                            />
                        );
                    })}
                    {props.customStyle?.usePoints &&
                        chartData.visibleData?.map((s, idx) => {
                            if (hiddenSeries.has(idx)) {
                                return undefined;
                            }
                            return (
                                <VictoryScatter
                                    key={makeRandomStr()}
                                    data={toVictoryDataRaw(s)}
                                    size={props.customStyle?.pointWidth ?? 2}
                                    style={{
                                        data: {
                                            fill: s.color,
                                            stroke: s.color,
                                        },
                                        labels: { fill: s.color },
                                    }}
                                />
                            );
                        })}
                    {props.customStyle.hline &&
                    ((chartData.minData ?? 0) <=
                        parseFloat(props.customStyle.hlineValue) ||
                        (chartData.maxData ?? 1) >=
                            parseFloat(props.customStyle.hlineValue)) ? (
                        <VictoryLine
                            domain={{
                                x: [
                                    chartData.minX ?? new Date(),
                                    chartData.maxX ?? new Date(),
                                ],
                                y: [
                                    parseFloat(props.customStyle.hlineValue),
                                    parseFloat(props.customStyle.hlineValue),
                                ],
                            }}
                            style={{
                                data: {
                                    stroke:
                                        props.customStyle?.hlineColor ??
                                        '#000000',
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                            y={() => parseFloat(props.customStyle.hlineValue)}
                        />
                    ) : (
                        <></>
                    )}
                    {props.customStyle.hline2 &&
                    ((chartData.min ?? 0) <=
                        parseFloat(props.customStyle.min) ||
                        (chartData.max ?? 1) >=
                            parseFloat(props.customStyle.hline2Value)) ? (
                        <VictoryLine
                            domain={{
                                x: [
                                    chartData.minX ?? new Date(),
                                    chartData.maxX ?? new Date(),
                                ],
                                y: [
                                    parseFloat(props.customStyle.hline2Value),
                                    parseFloat(props.customStyle.hline2Value),
                                ],
                            }}
                            style={{
                                data: {
                                    stroke:
                                        props.customStyle?.hline2Color ??
                                        '#000000',
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                            y={() => parseFloat(props.customStyle.hline2Value)}
                        />
                    ) : (
                        <></>
                    )}
                </VictoryChart>
            </div>
            <VictoryChart
                style={{ zIndex: 0 }}
                domain={{
                    x: [
                        chartData.absoluteMinX ?? new Date(),
                        chartData.absoluteMaxX ?? new Date(),
                    ],
                    y: [chartData.absoluteMin ?? 0, chartData.absoluteMax ?? 0],
                }}
                width={width}
                height={height * 0.3}
                scale={{ x: 'time' }}
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                containerComponent={
                    <VictoryBrushContainer
                        responsive={false}
                        brushDimension="xy"
                        brushDomain={{
                            x: [
                                chartData.absoluteMinX ?? new Date(),
                                chartData.absoluteMaxX ?? new Date(),
                            ],
                            y: [
                                chartData.absoluteMin ?? 0,
                                chartData.absoluteMax ?? 1,
                            ],
                        }}
                        onBrushDomainChange={handleBrush}
                    />
                }>
                <VictoryAxis tickValues={[]} tickFormat={(x) => {}} />

                {chartData.allData?.map((s, idx) => {
                    if (hiddenSeries.has(idx)) {
                        return undefined;
                    }
                    return (
                        <VictoryLine
                            groupComponent={<g />}
                            key={makeRandomStr()}
                            name={'area-' + idx}
                            data={toVictoryAll(s)}
                            style={{
                                data: {
                                    fillOpacity: 0.2,
                                    stroke: s.color,
                                    strokeWidth:
                                        props.customStyle?.strokeWidth ?? 2,
                                },
                            }}
                        />
                    );
                })}
            </VictoryChart>
            {props.showLegend ? (
                <MovableLegend
                    series={props.series}
                    allData={chartData.allData}
                    hiddenSeries={hiddenSeries}
                    buildEvents={buildEvents}
                />
            ) : (
                <></>
            )}
        </>
    );
}
