import { GroupApi } from '@aurum/nucleus-client-api';
import {
    addMemberPayloadGroup,
    isNullOrUndefined,
} from '@utils/helpers/app.helpers';
import GroupTreeStructure from '@views/groups/group-page/GroupTreeStructure';
import AssignUserModal from '@views/sites/settings/members/assign-member/AssignUserModal';
import React, { useEffect, useState } from 'react';

export default function GroupMembersPage(props) {
    const [group, setGroup] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [allGroupMembers, setAllGroupMembers] = useState({});
    const [assignUserModal, setAssignUserModal] = useState(false);
    const [forceGetMembers, setForceGetMembers] = useState(0);

    useEffect(() => {
        if (isNullOrUndefined(group)) return;
        getGroupMembers(group.id);
    }, [group]);

    const getGroupMembers = (groupId) => {
        new GroupApi().groupGetGroupMembers(
            groupId,
            function (_, data, response) {
                if (response.ok) {
                    const agm = allGroupMembers;
                    agm[groupId] = data;
                    setAllGroupMembers(agm);
                }
            }
        );
    };

    const assignUser = (uid) => {
        if (!isNullOrUndefined(uid) && uid.trim() !== '') {
            const user = allUsers?.find((usr) => usr.id === uid);
            if (!isNullOrUndefined(user) && !isNullOrUndefined(group)) {
                group.memberGroups = allGroupMembers[group.id]
                    .filter((u) => !u.inherited)
                    .map((m) => {
                        return { group: group.id, user: m };
                    });
                group.memberGroups.push({ group: group.id, user: user });
                const groupClone = addMemberPayloadGroup(group);
                new GroupApi().groupUpdateGroup(
                    group.id,
                    groupClone,
                    function (_, data, response) {
                        if (response.ok) {
                            setAllUsers(
                                allUsers?.filter((usr) => usr.id !== uid)
                            );
                            setAssignUserModal(false);
                            setForceGetMembers(forceGetMembers + 1);
                        }
                    }
                );
            }
        }
    };

    const onOpenAssignUser = (gr) => {
        if (!isNullOrUndefined(gr)) {
            new GroupApi().groupGetUnassignedUsersFromGroup(
                gr.id,
                function (_, data, response) {
                    if (response.ok) {
                        setAllUsers(data);
                    }
                }
            );
        }
        setAssignUserModal(true);
        setGroup(gr);
    };

    const unassignedData = [{}];
    allUsers?.forEach((usr) => {
        unassignedData.push({ value: usr.id, description: usr.userName });
    });

    return (
        <>
            <GroupTreeStructure
                user={props.user}
                groups={props.groups}
                allGroupMembers={allGroupMembers}
                getGroupMembers={(gid) => getGroupMembers(gid)}
                forceGetMembers={forceGetMembers}
                members
                assignMember={(gr) => onOpenAssignUser(gr)}
            />
            <AssignUserModal
                data={unassignedData}
                open={assignUserModal}
                handleClose={() => setAssignUserModal(false)}
                confirm={assignUser}
            />
        </>
    );
}
