import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { timeDifference } from '@utils/helpers/timestamp.helpers';
import JobStatusIndicator from '@views/infrastructure/pipelines/job-status/JobStatusIndicator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            props.site?.avatarPath
                ? 'transparent'
                : getAvatarColor(props.site?.id ?? 1),
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '0',
    },
    link: {
        height: '20px',
        alignSelf: 'center',
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
        },
    },
    avatarContainer: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        marginTop: '5px',
        float: 'left',
        marginRight: '20px',
    },
    textContainer: {
        minWidth: 0,
        alignItems: 'center',
        flex: '1 1 auto',
        display: 'flex',
    },
    infoContainer: {
        flexBasis: '100%',
        minWidth: 0,
        flexShrink: 1,
        flexGrow: 1,
        display: 'block',
    },
    iconsContainer: {
        justifyContent: 'space-between',
        flexShrink: 0,
        flexGrow: 1,
        flexWrap: 'wrap',
        display: 'flex',
    },
    iconContainer: {
        marginRight: '20px',
        marginTop: '15px',
    },
    icon: {
        width: '24px',
        height: '24px',
    },
}));

export default function SiteTableEntry(props) {
    const styles = useStyles(props);
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <Grid
            container
            spacing={2}
            style={{
                marginTop: '10px',
                marginBottom: '10px',
                display: 'block',
            }}>
            <div
                data-cy={`projentry-${props.site?.id}-avatar`}
                className={styles.avatarContainer}>
                {props.site?.avatarPath ? (
                    <img
                        alt="avatar"
                        className={styles.defaultAvatar}
                        src={props.site?.avatarPath}
                    />
                ) : (
                    <div className={styles.defaultAvatar}>
                        {isNullOrUndefined(props.site?.name)
                            ? 'D'
                            : props.site?.name[0]?.toUpperCase()}
                    </div>
                )}
            </div>
            <div
                data-cy={`projentry-${props.site?.id}-text`}
                className={styles.textContainer}>
                <div className={styles.infoContainer}>
                    <Link className={styles.link} to={props.link ?? ''}>
                        <p
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                marginTop: '15px',
                            }}>
                            {props.site?.name}
                        </p>
                    </Link>
                </div>
                <div className={styles.iconsContainer}>
                    {props.site?.type === 'temperatureControl' ? (
                        <JobStatusIndicator
                            status="warning"
                            statusText="Warning"
                        />
                    ) : (
                        <></>
                    )}
                    {isMobile ? (
                        <></>
                    ) : (
                        <p
                            className={styles.iconContainer}
                            style={{ marginLeft: '20px' }}>
                            {isNullOrUndefined(props.site?.lastUpdated)
                                ? ''
                                : `${t(
                                      'pages_sitesettings_sitetableentry_updated'
                                  )} ${timeDifference(
                                      props.site?.lastUpdated,
                                      props.user?.timeZone
                                  )}`}
                        </p>
                    )}
                </div>
            </div>
        </Grid>
    );
}
