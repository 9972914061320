import { RoundedButton } from '@components';
import MomentUtils from '@date-io/moment';
import {
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import LoopIcon from '@material-ui/icons/Loop';
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { utcDateToString } from '@utils/helpers/timestamp.helpers';
import { useInterval } from '@utils/hooks/useInterval';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    commonButton: {
        marginLeft: '0px',
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        height: '40px',
        paddingTop: '0px',
        paddingRight: '8px',
        lineHeight: '30px',
        fontWeight: 500,
        borderColor: 'gray',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        whiteSpace: 'nowrap',
        color: 'rgb(20, 24, 28)',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    generalButton: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '1px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
    },
    refreshButton: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderTopWidth: '2px',
        borderRightWidth: '1px',
        borderBottomWidth: '2px',
        borderLeftWidth: '0px',
    },
    expandRefreshButton: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '0px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '0px',
    },
    clockIcon: {
        overflow: 'hidden',
        verticalAlign: 'middle',
        display: 'inline-block',
        marginBottom: '2px',
        color: '#000',
    },
    textButton: {
        display: 'block',
        WebkitBoxFlex: 1,
        flexGrow: 1,
        paddingLeft: '8px',
        paddingRight: '4px',
    },
    spanText: {
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    generalMenu: {
        backgroundImage: 'initial',
        backgroundPositionX: 'initial',
        backgroundPositionY: 'initial',
        backgroundSize: 'initial',
        backgroundAttachment: 'initial',
        backgroundOrigin: 'initial',
        backgroundClip: 'initial',
        backgroundColor: '#fff',
        position: 'absolute',
        zIndex: 999999,
        width: '546px',
        top: '116%',
        display: 'flex',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        right: '0px',
    },
    leftSideMenu: {
        display: 'flex',
        flexDirection: 'column',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderRightColor: 'rgb(32,34,38)',
        width: '60%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        order: 0,
    },
    rightSideMenu: {
        position: 'relative',
        overflow: 'hidden',
        width: '40%',
        height: 'auto',
        minHeight: '0px',
        maxHeight: '100%',
        display: 'flex',
        flexGrow: 1,
        WebkitBoxFlex: 1,
    },
    rightSideSubItems: {
        width: '100%',
    },
    autoRefreshMenu: {
        border: '2px solid black',
        borderRadius: '4px',
        width: 'inherit',
        marginTop: '25px',
        zIndex: 999999,
    },
}));

export default function DashboardDatePicker(props) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [refreshOpen, setRefreshOpen] = useState(false);
    const [refreshIntervalSecs, setRefreshIntervalSecs] = useState(null);
    const [refreshInterval, setRefreshInterval] = useState('Off');
    const [selectionText, setSelectionText] = useState();

    const styles = useStyles(props);

    const refreshAnchorRef = React.useRef(null);

    useEffect(() => {
        setFrom(props.from);
        setTo(props.to);
        switch (props.timeSelection) {
            case 'last12hours':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last12h')
                );
                break;
            case 'last1day':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1d')
                );
                break;
            case 'last3days':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last3d')
                );
                break;
            case 'last1week':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1w')
                );
                break;
            case 'last1month':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1m')
                );
                break;
            case 'alltime':
                setSelectionText(t('pages_dashboard_dashboarddatepicker_all'));
                break;
            case 'none':
                setSelectionText(
                    `${utcDateToString(
                        props.from,
                        props.user.timeZone
                    )} to ${utcDateToString(props.to, props.user.timeZone)}`
                );
                break;
        }
    }, [props.from, props.to, props.timeSelection]);

    useInterval(() => {
        props.onRefresh && props.onRefresh();
    }, refreshIntervalSecs);

    const relativeSelection = (selection, txt) => {
        setSelectionText(txt);
        setAnchorEl(null);
        props.onRelativeSelection && props.onRelativeSelection(selection);
    };

    const absoluteSelection = () => {
        setSelectionText(
            `${utcDateToString(from, props.user.timeZone)} to ${utcDateToString(
                to,
                props.user.timeZone
            )}`
        );
        setAnchorEl(null);
        props.onAbsoluteSelection && props.onAbsoluteSelection(from, to);
    };

    const handleToggle = () => {
        setAnchorEl(null);
        setRefreshOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (
            refreshAnchorRef.current &&
            refreshAnchorRef.current.contains(event.target)
        ) {
            return;
        }
        setRefreshOpen(false);
    };

    const updateRefreshInterval = (interval, intervalSecs) => {
        setRefreshOpen(false);
        setAnchorEl(null);
        setRefreshInterval(interval);
        setRefreshIntervalSecs(intervalSecs);
    };

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    verticalAlign: 'middle',
                }}>
                <button
                    aria-label="SelectDate"
                    onClick={(event) =>
                        isNullOrUndefined(anchorEl)
                            ? setAnchorEl(event.currentTarget)
                            : setAnchorEl(null)
                    }
                    className={`${styles.commonButton} ${styles.generalButton}`}>
                    <div className={styles.clockIcon}>
                        <AccessTimeOutlinedIcon />
                    </div>
                    <div className={styles.textButton}>
                        <span className={styles.spanText}>
                            <span>{selectionText}</span>
                        </span>
                    </div>
                    <div className={styles.clockIcon}>
                        <ExpandMoreOutlinedIcon />
                    </div>
                </button>
                <div
                    className={styles.generalMenu}
                    style={{
                        visibility: isNullOrUndefined(anchorEl)
                            ? 'hidden'
                            : 'visible',
                    }}>
                    <div className={styles.leftSideMenu}>
                        <p
                            style={{
                                fontWeight: 'bold',
                                marginLeft: '20px',
                                marginTop: '20px',
                            }}>
                            {t('pages_dashboard_dashboarddatepicker_absolute')}
                        </p>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                style={{ marginLeft: '20px', width: '80%' }}
                                variant="inline"
                                format="MM/DD/yyyy HH:mm:ss"
                                margin="normal"
                                label={t(
                                    'pages_dashboard_dashboarddatepicker_from'
                                )}
                                value={from}
                                onChange={(val) => setFrom(val?.toDate())}
                            />
                            <KeyboardDateTimePicker
                                style={{ marginLeft: '20px', width: '80%' }}
                                variant="inline"
                                format="MM/DD/yyyy HH:mm:ss"
                                margin="normal"
                                label={t(
                                    'pages_dashboard_dashboarddatepicker_to'
                                )}
                                value={to}
                                onChange={(val) => setTo(val?.toDate())}
                            />
                            <div style={{ textAlign: 'center' }}>
                                <RoundedButton
                                    onClick={() => absoluteSelection()}
                                    marginTop="20px"
                                    marginLeft="20px"
                                    text={t(
                                        'pages_dashboard_dashboarddatepicker_apply'
                                    )}
                                    width="50%"
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={styles.rightSideMenu}>
                        <div className={styles.rightSideSubItems}>
                            <p
                                style={{
                                    fontWeight: 'bold',
                                    marginLeft: '20px',
                                    marginTop: '20px',
                                }}>
                                {t(
                                    'pages_dashboard_dashboarddatepicker_relative'
                                )}
                            </p>
                            <div>
                                <List>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'last12hours',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last12h'
                                                )
                                            )
                                        }>
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_last12h'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'last1day',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1d'
                                                )
                                            )
                                        }>
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_last1d'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'last3days',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last3d'
                                                )
                                            )
                                        }>
                                        {' '}
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_last3d'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'last1week',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1w'
                                                )
                                            )
                                        }>
                                        {' '}
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_last1w'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'last1month',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1m'
                                                )
                                            )
                                        }>
                                        {' '}
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_last1m'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() =>
                                            relativeSelection(
                                                'alltime',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_all'
                                                )
                                            )
                                        }>
                                        {' '}
                                        <ListItemText>
                                            {t(
                                                'pages_dashboard_dashboarddatepicker_all'
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    aria-label="Refresh"
                    onClick={() => props.onRefresh && props.onRefresh()}
                    className={`${styles.commonButton} ${styles.refreshButton}`}>
                    <div className={styles.clockIcon}>
                        <LoopIcon />
                    </div>
                </button>
                <button
                    aria-label="ExpandRefresh"
                    ref={refreshAnchorRef}
                    onClick={handleToggle}
                    className={`${styles.commonButton} ${styles.expandRefreshButton}`}>
                    <div className={styles.clockIcon}>
                        {refreshInterval}
                        <ExpandMoreOutlinedIcon />
                    </div>
                </button>
                <Popper
                    className={styles.autoRefreshMenu}
                    open={refreshOpen}
                    anchorEl={refreshAnchorRef.current}
                    role={undefined}
                    disablePortal>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                id="menu-select-refresh-interval"
                                style={{ width: '100%' }}>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('Off', null)
                                    }>
                                    Off
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('30s', 30000)
                                    }>
                                    30s
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('1m', 60000)
                                    }>
                                    1m
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('5m', 5 * 60000)
                                    }>
                                    5m
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('10m', 10 * 60000)
                                    }>
                                    10m
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        updateRefreshInterval('30m', 30 * 60000)
                                    }>
                                    30m
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}
