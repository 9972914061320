export const colors = [
    '#009966',
    '#8fbc8f',
    '#3cb371',
    '#00b140',
    '#013220',
    '#6699cc',
    '#0000ff',
    '#e6e6fa',
    '#9400d3',
    '#330066',
    '#808080',
    '#36454f',
    '#f7e7ce',
    '#c21e56',
    '#cc338b',
    '#dc143c',
    '#ff0000',
    '#cd5b45',
    '#eee600',
    '#ed9121',
    '#c39953',
];

export const graphColors = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#000000',
];
