import {
    StrangsStructureDTO,
    StrangStructureDTO,
    TemperatureControlApi,
} from '@aurum/nucleus-client-api';
import { FilesTable, TableTextEdit } from '@components';
import EditIcon from '@material-ui/icons/Edit';
import { isNullOrUndefined, userIsAtLeast } from '@utils/helpers/app.helpers';
import { useKeyPress } from '@utils/hooks/useKeyPressed';
import JobStatusIndicator from '@views/infrastructure/pipelines/job-status/JobStatusIndicator';
import UpdateStringModal from '@views/sites/settings/temperature-control/update-string/UpdateStringModal';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './site-tc-settings-page.css';

export default function SiteTcSettingsStrings(props) {
    const { id } = useParams();
    const [stringToUpdate, setStringToUpdate] = useState(null);
    const [siteStatus, _setSiteStatus] = useState([]);
    const [updateStringModal, setUpdateStringModal] = useState(false);

    const siteStatusRef = useRef(siteStatus);

    const setSiteStatus = (data) => {
        siteStatusRef.current = data;
        _setSiteStatus(data);
    };

    useKeyPress('Enter', () => onEnterPressed());
    useKeyPress('Escape', () => onEscapePressed());

    const { t } = useTranslation();

    useEffect(() => {
        new TemperatureControlApi().temperatureControlGetSiteStatus(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setSiteStatus(data);
                }
            }
        );
    }, [id]);

    const updateString = (stringMeasurements, newName) => {
        if (stringMeasurements?.length <= 0) {
            return;
        }
        stringToUpdate.measurements = stringMeasurements;
        stringToUpdate.name = newName;
        new TemperatureControlApi().temperatureControlUpdateString(
            parseInt(id, 10),
            stringToUpdate.circulationId,
            stringToUpdate.id,
            stringToUpdate,
            function (_, data, response) {}
        );
    };

    const createString = (name) => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(props.curCirculationRef.current)
        ) {
            new TemperatureControlApi().temperatureControlAddString(
                parseInt(id, 10),
                props.curCirculationRef.current.id,
                {
                    name: name,
                    index: props.curCirculationRef.current.strangs?.length ?? 0,
                    circulationId: props.curCirculationRef.current.id,
                    siteId: parseInt(id, 10),
                    measurements: [],
                },
                function (_, data, response) {
                    if (response.ok) {
                        setSiteStatus([
                            ...siteStatusRef.current,
                            {
                                strangId: data.id,
                                status: 0,
                            },
                        ]);
                        props.onStringCreated && props.onStringCreated(data);
                    }
                }
            );
        }
    };

    const statusEnumToStatus = (enumValue) => {
        switch (enumValue) {
            case 0:
                return 'success';
            case 1:
                return 'warning';
            case 2:
                return 'error';
            default:
                return 'error';
        }
    };

    const editString = (name) => {};

    const onDeleteString = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
            }
        });
    };

    const stringBeingAddedChanged = (txt) => {
        props.setStringBeingAdded && props.setStringBeingAdded(txt);
    };

    const onEnterPressed = () => {
        if (!isNullOrUndefined(props.stringBeingAddedRef.current)) {
            createString(props.stringBeingAddedRef.current);
            props.setStringBeingAdded && props.setStringBeingAdded('');
        }
    };

    const onEscapePressed = () => {
        props.setStringBeingAdded && props.setStringBeingAdded(null);
    };

    const dragAndDropUpdate = (newArray) => {
        const newStringArray = newArray?.map(
            (a, idx) => new StrangStructureDTO(a[0].id, idx)
        );
        const stringStructure = new StrangsStructureDTO();
        stringStructure.structure = newStringArray;
        new TemperatureControlApi().temperatureControlUpdateStringStructure(
            parseInt(id, 10),
            props.curCirculationRef.current.id,
            stringStructure,
            function (_, data, response) {}
        );
    };

    const getValueFromStringMeasurement = (strang, measurement) => {
        return (
            strang?.measurements?.find((m) => m.measurement === measurement)
                ?.sensor?.name ??
            t('pages_sitetc_settings_strings_notattached').toUpperCase()
        );
    };

    const openEditStringDialog = (string) => {
        setUpdateStringModal(true);
        setStringToUpdate(string);
    };

    const rows = [];

    // show all the strings for the circulation that has been selected
    props.curCirculationRef.current?.strangs
        ?.sort((a, b) => a.index - b.index)
        ?.forEach((a) => {
            const row = {
                id: a.id,
                text: a.name,
                value: a.name,
                idx: a.index,
            };
            rows.push([
                row,
                {
                    value: getValueFromStringMeasurement(a, 'flowTemperature'),
                },
                {
                    value: getValueFromStringMeasurement(a, 'circuTemperature'),
                },
                {
                    value: (
                        <div style={{ width: '100%', display: 'inline-flex' }}>
                            <span>
                                {getValueFromStringMeasurement(
                                    a,
                                    'valvePosition'
                                )}
                            </span>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    alignContent: 'center',
                                    justifyContent: 'flex-end',
                                }}>
                                <JobStatusIndicator
                                    status={`${statusEnumToStatus(
                                        siteStatus.find(
                                            (s) => s.strangId === a.id
                                        )?.status
                                    )}`}
                                    statusText="Warning"
                                />
                                <EditIcon
                                    onClick={() => openEditStringDialog(a)}
                                    style={{
                                        width: '18px',
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                    }}
                                />
                            </div>
                        </div>
                    ),
                },
            ]);
        });

    if (!isNullOrUndefined(props.stringBeingAdded)) {
        rows.push([
            {
                link: null,
                text: '',
                value: (
                    <TableTextEdit
                        autofocus
                        value={props.stringBeingAdded}
                        onChange={stringBeingAddedChanged}
                    />
                ),
                flex: true,
            },
            '',
            '',
        ]);
    }

    return (
        <>
            <div id="strings-table" className="strings-table">
                <FilesTable
                    dragAndDrop={userIsAtLeast(props.user, 'analyst')}
                    onDragAndDropUpdate={(newArray) =>
                        dragAndDropUpdate(newArray)
                    }
                    columns={[
                        t('common_filestable_namecol'),
                        t('pages_sitetc_settings_strings_flowtemperature'),
                        t('pages_sitetc_settings_strings_circutemperature'),
                        t('pages_sitetc_settings_strings_valveposition'),
                    ]}
                    rows={rows}
                />
            </div>
            <UpdateStringModal
                open={updateStringModal}
                stringToUpdate={stringToUpdate}
                sensors={props.allSensors}
                handleClose={() => setUpdateStringModal(false)}
                confirm={updateString}
            />
        </>
    );
}
