import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getFullGroups,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function GatewayEntryPage(props) {
    const [gateway, setGateway] = useState(null);
    const { groupId, id, gwId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        new GatewayApi().gatewayGetGateway(gwId, function (_, data, response) {
            if (response.ok) {
                if (isNullOrUndefined(data.uuid)) {
                    window.location.href = getProperRoute(
                        props.site?.type === 'filter'
                            ? `groups/${groupId}/sites/filter/${id}/gateways`
                            : `groups/${groupId}/sites/${id}/gateways`
                    );
                } else {
                    setGateway(data);
                }
            } else {
                window.location.href = getProperRoute(
                    props.site?.type === 'filter'
                        ? `groups/${groupId}/sites/filter/${id}/gateways`
                        : `groups/${groupId}/sites/${id}/gateways`
                );
            }
        });
    }, [gwId]);

    useEffect(() => {
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/gateways/${gwId}`
        );
    }, [props]);

    const removeGateway = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_sitesettings_gatewayentrypage_sure'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && !isNullOrUndefined(gateway)) {
                gateway.site = null;
                new GatewayApi().gatewayUpdateGateway(
                    gwId,
                    gateway,
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.href = getProperRoute(
                                props.site?.type === 'filter'
                                    ? `groups/${groupId}/sites/filter/${id}/gateways`
                                    : `groups/${groupId}/sites/${id}/gateways`
                            );
                        }
                    }
                );
            }
        });
    };

    const openCloseVpnTunnel = () => {
        if (
            isNullOrUndefined(gateway?.gatewayStatus?.vpnStatus) ||
            gateway?.gatewayStatus?.vpnStatus === '0'
        ) {
            // the vpn tunnel is closed. Open it
            new GatewayApi().gatewayCreateVpnTunnel(
                gateway.uuid,
                function (_, data, response) {
                    if (response.ok) {
                        window.location.reload();
                    }
                }
            );
        } else if (gateway?.gatewayStatus?.vpnStatus === '1') {
            // the vpn tunnel is opened. Close it
            new GatewayApi().gatewayRemoveVpnTunnel(
                gateway.uuid,
                function (_, data, response) {
                    if (response.ok) {
                        window.location.reload();
                    }
                }
            );
        }
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    return isNullOrUndefined(gateway) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: props.site?.name,
                    },
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: t('pagelayout_sidebarsite_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_gateways'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(
                                props.site?.type === 'filter'
                                    ? `groups/${groupId}/sites/filter/${id}/gateways`
                                    : `groups/${groupId}/sites/${id}/gateways`
                            ),
                            text: t('pagelayout_sidebarsite_gateways'),
                        },
                        { link: getProperRoute('/'), text: gateway.name },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem onClick={() => removeGateway()}>
                                {t(
                                    'pages_sitesettings_gatewayentrypage_remove'
                                )}
                            </MenuItem>
                            <MenuItem onClick={() => openCloseVpnTunnel()}>
                                {gateway?.gatewayStatus?.vpnStatus === '1'
                                    ? t(
                                          'pages_sitesettings_gatewayentrypage_closevpn'
                                      )
                                    : t(
                                          'pages_sitesettings_gatewayentrypage_openvpn'
                                      )}
                            </MenuItem>
                            {/* <MenuItem>{t('pages_sitesettings_gatewayentrypage_update')}</MenuItem> */}
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_diagnostics_diagnosticspage_value'),
                ]}
                rows={[
                    [
                        { link: null, text: 'ID', value: 'ID' },
                        {
                            link: null,
                            text: gateway.description,
                            value: gateway.description,
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_devices_devicepage_name'),
                            value: t('pages_devices_devicepage_name'),
                        },
                        { link: null, text: gateway.name, value: gateway.name },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_wlan'),
                            value: t('pages_groups_gatewaygrouppage_wlan'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.WLAN ?? '',
                            value: gateway.gatewayStatus?.WLAN ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_eth'),
                            value: t('pages_groups_gatewaygrouppage_eth'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.ETH ?? '',
                            value: gateway.gatewayStatus?.ETH ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_vpn'),
                            value: t('pages_groups_gatewaygrouppage_vpn'),
                        },
                        {
                            link: null,
                            text:
                                gateway.gatewayStatus?.vpnStatus === '1'
                                    ? t('pages_devices_devicepage_active')
                                    : t('pages_devices_devicepage_inactive'),
                            value:
                                gateway.gatewayStatus?.vpnStatus === '1'
                                    ? t('pages_devices_devicepage_active')
                                    : t('pages_devices_devicepage_inactive'),
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_uptime'),
                            value: t('pages_groups_gatewaygrouppage_uptime'),
                        },
                        {
                            link: null,
                            text: gateway.upTime ?? '',
                            value: gateway.upTime ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_cpu'),
                            value: t('pages_groups_gatewaygrouppage_cpu'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.cpuLoad ?? '',
                            value: gateway.gatewayStatus?.cpuLoad ?? '',
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_groups_gatewaygrouppage_sw'),
                            value: t('pages_groups_gatewaygrouppage_sw'),
                        },
                        {
                            link: null,
                            text: gateway.gatewayStatus?.swVersion ?? '',
                            value: gateway.gatewayStatus?.swVersion ?? '',
                        },
                    ],
                ]}
            />
        </>
    );
}
