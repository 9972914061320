import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

export function CircleButton(props) {
    return (
        <Tooltip enterDelay={1000} title={props.tooltip}>
            <IconButton
                onClick={() => props.onClick()}
                aria-label={props.tooltip}>
                {props.icon}
            </IconButton>
        </Tooltip>
    );
}
