// import { AdministrationInfrastructureApi } from '@aurum/nucleus-client-api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import PipelineTableElement from '@views/infrastructure/pipelines/pipeline-entry/PipelineTableElement';

export default function PipelinesPage(props) {
    const [pipelines, setPipelines] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        // new AdministrationInfrastructureApi().administrationInfrastructureGetPipelines(function (_, data, response) {
        //     if (response.ok) {
        //         setPipelines(data);
        //     }
        // });
    }, []);

    const valuesTopGroup = [];
    pipelines?.forEach((pipeline) => {
        valuesTopGroup.push([
            {
                text: pipeline?.id,
                value: <PipelineTableElement pipeline={pipeline} />,
            },
        ]);
    });

    const links = [
        { link: getProperRoute(`admin/customers`), text: 'Admin Area' },
        { link: getProperRoute('admin/pipelines'), text: 'Pipelines' },
    ];
    return isNullOrUndefined(pipelines) ? (
        <> </>
    ) : (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs breadcrumbs={links} />
                <MoreSettingsMenu
                    disabled={
                        !userIsAtLeast(props.user, 'admin')
                    }></MoreSettingsMenu>
            </div>
            <hr /> <br />
            <FilesTable
                pagination
                search
                columns={['Pipeline']}
                rows={valuesTopGroup}
            />
        </div>
    );
}
