export function validateEmail(email) {
    if (typeof email !== 'string') {
        throw new Error('validateEmail: Provided parameter must be string');
    }
    const mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return email.match(mailFormat);
}

export function validatePassword(password) {
    if (typeof password !== 'string') {
        throw new Error('validatePassword: Provided parameter must be string');
    }
    const passwordFormat =
        /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~])[\w!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]{8,}$/;
    return password.match(passwordFormat);
}
