import React from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@components';

export default function DashboardSpinner(props) {
    const { t } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
            }}>
            <div style={{ marginTop: '10px', marginRight: '5px' }}>
                <span>{t('pages_dashboard_dashboardpage_spinnerfetch')}</span>
            </div>
            <Spinner />
        </div>
    );
}
