import { customTheme } from '@/App';
import { DraggableRow, DraggableWrapper } from '@components';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LayersIcon from '@material-ui/icons/Layers';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import PersonIcon from '@material-ui/icons/Person';
import RouterIcon from '@material-ui/icons/Router';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { hexToRgba } from '@utils/helpers/colors.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((props) => ({
    inputSearch: {
        display: 'inline-block',
        position: 'relative',
        borderRadius: '4px',
        padding: '6px 10px',
        fontSize: '0.875rem',
        width: '100%',
        border: '1px solid #dbdbdb',
        height: '34px',
        fontWeight: 400,
        lineHeight: '1.5',
        color: '#303030',
        backgroundColor: '#fff',
        transition:
            'border-color 0.15s ease-in-out, box-xhadow 0.15s ease-in-out',
        marginBottom: '10px',
        '&:focus': {
            outline: 'none',
        },
    },
    wrapper: {
        margin: 0,
        overflow: 'hidden',
        border: (props) => (props.isMobile ? '' : '1px solid #dbdbdb'),
        borderRadius: '4px',
    },
    table: {
        marginBottom: 0,
        tableLayout: 'fixed',
        width: '100%',
        color: '#303030',
        borderSpacing: 0,
        border: (props) => (props.isMobile ? 0 : ''),
        borderCollapse: 'collapse',
        display: (props) => (props.isMobile ? '' : 'table'),
    },
    thead: {
        display: (props) => (props.isMobile ? 'none' : 'table-header-group'),
        verticalAlign: 'middle',
        borderTopColor: 'inherit',
        borderRightColor: 'inherit',
        borderBottomColor: 'inherit',
        borderLeftColor: 'inherit',
    },
    tr: {
        borderBottomColor: 'fff',
        border: (props) => (props.isMobile ? '1px solid #dbdbdb' : ''),
        display: (props) => (props.isMobile ? 'block' : 'table-row'),
        marginBottom: (props) => (props.isMobile ? '15px' : 0),
        borderBottom: (props) => (props.isMobile ? '1px solid #dbdbdb' : ''),
        fontSize: '15px',
        verticalAlign: 'inherit',
        borderTopColor: 'inherit',
        borderRightColor: 'inherit',
    },
    th: {
        border: 0,
        lineHeight: '21px',
        backgroundColor: '#f0f0f0',
        borderBottom: 0,
        padding: '10px 16px',
        verticalAlign: 'middle',
        textAlign: 'inherit',
        fontWeight: 'bold',
        position: (props) => (props.resizeHeader ? 'sticky' : null),
        top: (props) => (props.resizeHeader ? 0 : null),
        resize: (props) => (props.resizeHeader ? 'horizontal' : null),
        overflow: (props) => (props.resizeHeader ? 'hidden' : null),
        textOverflow: (props) => (props.resizeHeader ? 'ellipsis' : null),
    },
    thSortable: {
        cursor: 'pointer',
    },
    td: {
        verticalAlign: 'middle',
        borderColor: '#dbdbdb',
        lineHeight: '21px',
        padding: '10px 16px',
        fontSize: '15px',
        overflow: 'hidden',
        borderTop: (props) => (props.isMobile ? '' : '1px solid #dbdbdb'),
        borderBottom: (props) => (props.isMobile ? '1px doted #dbdbdb' : ''),
        display: (props) => (props.isMobile ? 'block' : 'table-cell'),
    },
    ellipsis: {
        textOverflow: 'ellipsis',
    },
    link: {
        color: '#303030',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '82%',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            color: '#303030',
        },
    },
    spanlink: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    span: {
        zIndex: 2,
        color: '#505050',
        marginRight: '0.25rem',
        position: 'relative',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spanFlex: {
        zIndex: 2,
        color: '#505050',
        marginRight: '0.25rem',
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '15px',
    },
    pagination: {
        marginTop: '0.5rem',
        fontSize: '0.875rem',
        display: 'block',
    },
    paginationList: {
        justifyContent: 'center !important',
        display: 'flex',
        paddingLeft: 0,
        listStyle: 'none',
        borderRadius: '0.25rem',
        marginTop: 0,
        marginBottom: '1rem',
    },
    active: {
        backgroundColor: '#1f75cb',
        borderColor: '#1068bf',
        color: '#fff',
    },
    disabled: {
        pointerEvents: 'none',
        cursor: 'auto',
        backgroundColor: '#fafafa',
        color: '#868686',
        borderColor: '#bfbfbf',
    },
    paginationFirst: {
        borderTopLeftRadius: '0.25rem',
        borderBottomLeftRadius: '0.25rem',
        border: '1px solid #999',
    },
    paginationLast: {
        borderTopRightRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
        border: '1px solid #999',
    },
    paginationItemButton: {
        display: 'list-item',
        textAlign: '-webkit-match-parent',
        lineHeight: '1rem',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        textDecoration: 'none',
        tabSize: 8,
        position: 'relative',
        display: 'block',
        padding: '6px 16px',
        borderTop: '1px solid #999',
        borderBottom: '1px solid #999',
    },
    paginationItemClickable: {
        '&:hover': {
            color: '#303030',
            boxShadow: 'inset 0 0 0 2px #868686,0 2px 2px 0 rgba(0,0,0,0.08)',
            background: '#f0f0f0',
            zIndex: 0,
            cursor: 'pointer',
        },
    },
    paginationText: {
        zIndex: 2,
        '&:hover': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    sortingIcon: {
        width: '16px',
        height: '16px',
        marginLeft: '10px',
    },
    dragAndDropIcon: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
}));

const checkBoxStyles = makeStyles((theme) => ({
    root: {
        '&$checked': {
            color: customTheme.palette.primary.main,
            '&:hover': {
                backgroundColor: hexToRgba(
                    customTheme.palette.primary.main,
                    0.1
                ),
            },
        },
        '&:hover': {
            backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.1),
        },
    },
    checked: {},
}));

export function FilesTable(props) {
    const [searchText, setSearchText] = useState('');
    const [curPage, setCurPage] = useState(1);
    const [numOfPages, setNumOfPages] = useState(1);
    const [visibleRows, setVisibleRows] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState(-1);
    const [sortDirection, setSortDirection] = useState(false); // false for descending, true for ascending
    const [dragActiveItem, setDragActiveItem] = useState(null);

    const isTabletPortrait = useMediaQuery({ query: '(max-width: 850px)' });
    const styles = useStyles({
        isMobile: isTabletPortrait && props.responsive,
        resizeHeader: props.resizeHeader,
    });
    const cbstyles = checkBoxStyles();

    const { t } = useTranslation();

    Array.prototype.skip = function skip(count) {
        return this.filter((_, i) => i >= count);
    };

    Array.prototype.take = function take(count) {
        return this.filter((_, i) => i < count);
    };

    useEffect(() => {
        if (searchText !== '') {
            const newRows = props.rows?.filter(
                (r) =>
                    r?.filter((v) =>
                        v.text
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(searchText.toLowerCase())
                    )?.length > 0
            );
            setVisibleRows(newRows ?? []);
            if (props.pagination) {
                setNumOfPages(Math.ceil(newRows?.length / itemsPerPage ?? 1));
                setCurPage(1);
            }
        } else {
            setVisibleRows(props.rows);
            if (props.pagination) {
                setNumOfPages(
                    Math.ceil(props.rows?.length / itemsPerPage ?? 1)
                );
            }
        }
    }, [props.rows, searchText]);

    const handleCheckbox = (event, id) => {
        let type = '';
        props.rows.forEach((row) => {
            if (row[0].id === id || id === -1) {
                type = row[0]?.icon;
            }
        });
        props.onCheckbox && props.onCheckbox(event.target.checked, type, id);
    };

    const searchTextChanged = (e) => {
        const aquacloudQrContent =
            /AquaCloud,PN=[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,},SN=[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}/gm;
        const aquacloudReplace =
            /AquaCloud,PN=[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,}-[a-zA-Z0-9]{1,},SN=/gm;
        if (new RegExp(aquacloudQrContent).test(e.target.value)) {
            setSearchText(e.target.value.replace(aquacloudReplace, ''));
        } else {
            setSearchText(e.target.value);
        }
    };

    const onColumnSort = (col) => {
        if (props.sort) {
            if (sortColumn === col) {
                setSortDirection(!sortDirection);
            } else {
                setSortDirection(false);
                setSortColumn(col);
            }
        }
    };

    const dndSensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = (event) => {
        const { active } = event;
        let startIdx = visibleRows.findIndex(
            (i) => i[0].idx === parseInt(active.id, 10)
        );
        startIdx = startIdx !== -1 ? startIdx : parseInt(active.id, 10);
        setDragActiveItem(rows[startIdx]);
    };

    const handleDragOver = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setVisibleRows((items) => {
                let startIdx = items.findIndex(
                    (i) => i[0].idx === parseInt(active.id, 10)
                );
                startIdx = startIdx !== -1 ? startIdx : parseInt(active.id, 10);
                let endIdx = items.findIndex(
                    (i) => i[0].idx === parseInt(over.id, 10)
                );
                endIdx = endIdx !== -1 ? endIdx : parseInt(over.id, 10);
                return arrayMove(items, startIdx, endIdx);
            });
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        setVisibleRows((items) => {
            let startIdx = items.findIndex(
                (i) => i[0].idx === parseInt(active.id, 10)
            );
            startIdx = startIdx !== -1 ? startIdx : parseInt(active.id, 10);
            let endIdx = items.findIndex(
                (i) => i[0].idx === parseInt(over.id, 10)
            );
            endIdx = endIdx !== -1 ? endIdx : parseInt(over.id, 10);
            props.onDragAndDropUpdate &&
                props.onDragAndDropUpdate(arrayMove(items, startIdx, endIdx));
            return arrayMove(items, startIdx, endIdx);
        });
        setDragActiveItem(null);
    };

    let columns = [];
    const rows = [];
    if (!isNullOrUndefined(props.columns) && !isNullOrUndefined(visibleRows)) {
        // add drag/drop column, if drag/drop is activated
        if (props.dragAndDrop) {
            columns.push(
                <th
                    className={styles.th}
                    style={{ width: '50px' }}
                    id="drag-and-drop"
                    scope="col"
                />
            );
        }
        // add columns to the table
        props.columns.forEach((col, ind) => {
            columns.push(
                <th
                    className={
                        styles.th + ` ${props.sort ? styles.thSortable : ''}`
                    }
                    id={isNullOrUndefined(props.ids) ? col : props.ids[ind]}
                    scope="col">
                    {visibleRows.length > 0 &&
                    visibleRows.some((vr) => vr[ind]?.addCheckbox) &&
                    props.onCheckbox ? (
                        <div style={{ display: 'flex' }}>
                            <div style={{ float: 'left' }}>
                                <Checkbox
                                    classes={{
                                        root: cbstyles.root,
                                        checked: cbstyles.checked,
                                    }}
                                    checked={visibleRows
                                        .filter((r) => r[ind].addCheckbox)
                                        .every((r) => r[ind].checked)}
                                    onChange={(event) =>
                                        handleCheckbox(event, -1)
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    float: 'left',
                                    height: 'inherit',
                                    position: 'relative',
                                    top: '50%',
                                    transform: 'translateY(25%)',
                                }}
                                onClick={() => onColumnSort(ind)}>
                                {col}
                                {sortColumn === ind ? (
                                    sortDirection ? (
                                        <ArrowUpwardIcon
                                            className={styles.sortingIcon}
                                        />
                                    ) : (
                                        <ArrowDownwardIcon
                                            className={styles.sortingIcon}
                                        />
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div onClick={() => onColumnSort(ind)}>
                            {col}
                            {sortColumn === ind ? (
                                sortDirection ? (
                                    <ArrowUpwardIcon
                                        className={styles.sortingIcon}
                                    />
                                ) : (
                                    <ArrowDownwardIcon
                                        className={styles.sortingIcon}
                                    />
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </th>
            );
        });
        // order rows, according to user's choice
        if (sortColumn !== -1 && visibleRows.length > 0) {
            if (typeof visibleRows[0][sortColumn]?.text === 'number') {
                visibleRows.sort((a, b) =>
                    sortDirection
                        ? a[sortColumn]?.text - b[sortColumn]?.text
                        : b[sortColumn]?.text - a[sortColumn]?.text
                );
            } else if (typeof visibleRows[0][sortColumn]?.text === 'string') {
                visibleRows.sort((a, b) =>
                    sortDirection
                        ? a[sortColumn]?.text.localeCompare(b[sortColumn]?.text)
                        : b[sortColumn]?.text.localeCompare(a[sortColumn]?.text)
                );
            } else if (typeof visibleRows[0][sortColumn]?.text === 'object') {
                visibleRows.sort((a, b) =>
                    sortDirection
                        ? a[sortColumn]?.text?.getTime() -
                          b[sortColumn]?.text?.getTime()
                        : b[sortColumn]?.text?.getTime() -
                          a[sortColumn]?.text?.getTime()
                );
            }
        }
        // add rows to the table
        let rowsToIterate = visibleRows;
        if (props.pagination) {
            rowsToIterate = visibleRows
                .skip((curPage - 1) * itemsPerPage)
                .take(itemsPerPage);
        }
        rowsToIterate?.forEach((row, rowInd) => {
            const rowValues = [];
            if (props.dragAndDrop) {
                rowValues.push(
                    <td rowType="drag-and-drop" className={`${styles.td}`}>
                        <DragIndicatorIcon className={styles.dragAndDropIcon} />
                    </td>
                );
            }
            row.forEach((val, ind) => {
                if (!isNullOrUndefined(val)) {
                    let icon = <></>;
                    if (val.icon === 'mp') {
                        icon = (
                            <svg
                                className={styles.icon}
                                viewBox="0 0 512 512"
                                width="512"
                                height="512">
                                <rect x="48" y="376" width="16" height="48" />
                                <rect x="80" y="376" width="16" height="16" />
                                <rect x="80" y="408" width="16" height="16" />
                                <path d="M196.46,182.055C212.493,188.468,233.638,192,256,192s43.507-3.532,59.54-9.945C333.893,174.714,344,164.041,344,152s-10.107-22.714-28.46-30.055C299.507,115.532,278.362,112,256,112s-43.507,3.532-59.54,9.945C178.107,129.286,168,139.959,168,152S178.107,174.714,196.46,182.055ZM256,128c43.952,0,72,14.214,72,24s-28.048,24-72,24-72-14.214-72-24S212.048,128,256,128Z" />
                                <path d="M432,232h-8V152c0-26.556-18.115-51.144-51.008-69.235C341.611,65.505,300.062,56,256,56s-85.611,9.5-116.992,26.765C106.115,100.856,88,125.444,88,152V264c0,26.556,18.115,51.144,51.008,69.235A184.955,184.955,0,0,0,162.485,344H24a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8H432a64.072,64.072,0,0,0,64-64V296A64.072,64.072,0,0,0,432,232ZM112,440H32V360h80ZM408,232H349.515a184.955,184.955,0,0,0,23.477-10.765c14.741-8.107,26.5-17.523,35.008-27.832ZM146.719,96.784C175.777,80.8,214.588,72,256,72s80.223,8.8,109.281,24.784C392.829,111.936,408,131.544,408,152s-15.171,40.064-42.719,55.216C336.223,223.2,297.412,232,256,232s-80.223-8.8-109.281-24.784C119.171,192.064,104,172.456,104,152S119.171,111.936,146.719,96.784Zm0,222.432C119.171,304.064,104,284.455,104,264V193.4c8.5,10.309,20.267,19.725,35.008,27.832q6.234,3.429,12.992,6.442V264h16V234.011a233.64,233.64,0,0,0,24,6.9V264h16V244.1c7.823,1.307,15.841,2.291,24,2.945V296h16V247.894q3.984.1,8,.106h16v16h16V248h24v16h16V248h24v48h16V248h24v16h16V248h24v16h16V250.752A48,48,0,0,1,432,344H256C214.588,344,175.777,335.2,146.719,319.216ZM480,392a48.055,48.055,0,0,1-48,48H128V360h24v16h16V360h24v16h16V360h24v48h16V360h24v16h16V360h24v16h16V360h24v48h16V360h24v16h16V360h24v16h16V357.975a64.071,64.071,0,0,0,32-19.711Z" />
                            </svg>
                        );
                    } else if (val.icon === 'group') {
                        icon = <LayersIcon className={styles.icon} />;
                    } else if (val.icon === 'user') {
                        icon = <PersonIcon className={styles.icon} />;
                    } else if (val.icon === 'project') {
                        icon = <AppsIcon className={styles.icon} />;
                    } else if (val.icon === 'sensor') {
                        icon = (
                            <svg
                                className={styles.icon}
                                height="512"
                                viewBox="0 0 512 512"
                                width="512">
                                <g>
                                    <path d="m437.066 74.981c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213c42.656 42.655 66.147 99.409 66.147 159.806s-23.491 117.151-66.147 159.806c-5.858 5.857-5.858 15.355 0 21.213 5.868 5.868 15.365 5.848 21.213 0 48.322-48.321 74.934-112.608 74.934-181.019s-26.612-132.698-74.934-181.019z" />
                                    <path d="m391.646 120.4c-5.858-5.857-15.356-5.857-21.213 0-5.857 5.858-5.857 15.355 0 21.213 30.533 30.531 47.349 71.155 47.349 114.387s-16.815 83.855-47.349 114.387c-5.858 5.858-5.858 15.355 0 21.213 5.856 5.856 15.353 5.859 21.213 0 36.2-36.198 56.136-84.355 56.136-135.601s-19.937-99.402-56.136-135.599z" />
                                    <path d="m346.193 165.852c-5.858-5.857-15.356-5.857-21.213 0-5.858 5.858-5.857 15.356 0 21.213 18.4 18.398 28.533 42.88 28.533 68.934s-10.133 50.536-28.533 68.934c-5.858 5.857-5.858 15.355 0 21.213 5.857 5.857 15.354 5.859 21.213 0 24.066-24.065 37.32-56.08 37.32-90.148s-13.254-66.081-37.32-90.146z" />
                                    <path d="m30 256c0-60.397 23.491-117.151 66.147-159.806 5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.354-5.858-21.213 0-48.322 48.321-74.934 112.608-74.934 181.019s26.612 132.698 74.934 181.019c5.856 5.857 15.355 5.859 21.213 0 5.858-5.858 5.858-15.355 0-21.213-42.656-42.655-66.147-99.409-66.147-159.806z" />
                                    <path d="m94.219 256c0-43.232 16.815-83.855 47.349-114.387 5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.857-21.213 0-36.2 36.197-56.136 84.355-56.136 135.6s19.936 99.403 56.136 135.601c5.866 5.865 15.363 5.85 21.213 0 5.857-5.858 5.857-15.355 0-21.213-30.534-30.532-47.349-71.156-47.349-114.388z" />
                                    <path d="m187.021 165.853c-5.857-5.858-15.355-5.858-21.213 0-24.066 24.065-37.32 56.08-37.32 90.148s13.254 66.083 37.32 90.148c5.866 5.865 15.363 5.85 21.213 0 5.858-5.858 5.857-15.356 0-21.213-18.4-18.398-28.533-42.88-28.533-68.934s10.133-50.536 28.533-68.934c5.857-5.859 5.857-15.357 0-21.215z" />
                                    <path d="m256 192.8c-34.849 0-63.2 28.352-63.2 63.2s28.352 63.2 63.2 63.2 63.2-28.352 63.2-63.2-28.351-63.2-63.2-63.2zm0 96.4c-18.307 0-33.2-14.894-33.2-33.2s14.894-33.2 33.2-33.2 33.2 14.894 33.2 33.2-14.893 33.2-33.2 33.2z" />
                                </g>
                            </svg>
                        );
                    } else if (val.icon === 'gateway') {
                        icon = <RouterIcon className={styles.icon} />;
                    }

                    if (!isNullOrUndefined(val.link)) {
                        if (typeof val.value !== 'String') {
                            rowValues.push(
                                <td
                                    className={`${styles.td} ${styles.ellipsis}`}
                                    searchTxt={val.text}>
                                    {props.onCheckbox && val.addCheckbox ? (
                                        <Checkbox
                                            classes={{
                                                root: cbstyles.root,
                                                checked: cbstyles.checked,
                                            }}
                                            checked={val.checked}
                                            onChange={(event) =>
                                                handleCheckbox(event, val.id)
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {typeof val.value !== 'object' &&
                                    isTabletPortrait &&
                                    props.responsive ? (
                                        <span
                                            className={`${styles.span} ${styles.ellipsis}`}>
                                            <b>{props.columns[ind]}:</b>
                                            <Link
                                                className={styles.link}
                                                to={val.link}>
                                                <span
                                                    className={`${styles.span} ${styles.ellipsis}`}>
                                                    {icon}
                                                    {val.value}
                                                </span>
                                            </Link>
                                        </span>
                                    ) : (
                                        <Link
                                            className={styles.link}
                                            to={val.link}>
                                            <span
                                                className={`${styles.span} ${styles.ellipsis}`}>
                                                {icon}
                                                {val.value}
                                            </span>
                                        </Link>
                                    )}
                                </td>
                            );
                        } else {
                            rowValues.push(
                                <td
                                    className={`${styles.td} ${styles.ellipsis}`}
                                    searchTxt={val.text}>
                                    {typeof val.value !== 'object' &&
                                    isTabletPortrait &&
                                    props.responsive ? (
                                        <span
                                            className={`${styles.span} ${styles.ellipsis}`}>
                                            <b>{props.columns[ind]}:</b>
                                            <Link
                                                className={styles.link}
                                                to={val.link}>
                                                <span
                                                    className={`${styles.span} ${styles.ellipsis}`}>
                                                    {icon}
                                                    {val.value}
                                                </span>
                                            </Link>
                                        </span>
                                    ) : (
                                        <Link
                                            className={styles.link}
                                            to={val.link}>
                                            <span
                                                className={`${styles.span} ${styles.ellipsis}`}>
                                                {icon}
                                                {val.value}
                                            </span>
                                        </Link>
                                    )}
                                </td>
                            );
                        }
                    } else if (
                        !isNullOrUndefined(val.onClick) &&
                        !isNullOrUndefined(val.id)
                    ) {
                        rowValues.push(
                            <td
                                className={`${styles.td} ${styles.ellipsis}`}
                                searchTxt={val.text}>
                                {props.onCheckbox && val.addCheckbox ? (
                                    <Checkbox
                                        classes={{
                                            root: cbstyles.root,
                                            checked: cbstyles.checked,
                                        }}
                                        checked={val.checked}
                                        onChange={(event) =>
                                            handleCheckbox(event, val.id)
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                                {typeof val.value !== 'object' &&
                                isTabletPortrait &&
                                props.responsive ? (
                                    <span
                                        className={styles.spanlink}
                                        onClick={() => val.onClick(val.id)}>
                                        <b>{props.columns[ind]}:</b>
                                        <span
                                            className={`${styles.span} ${styles.ellipsis}`}>
                                            {icon}
                                            {val.value}
                                        </span>
                                    </span>
                                ) : (
                                    <span
                                        className={styles.spanlink}
                                        onClick={() => val.onClick(val.id)}>
                                        <span
                                            className={`${styles.span} ${styles.ellipsis}`}>
                                            {icon}
                                            {val.value}
                                        </span>
                                    </span>
                                )}
                            </td>
                        );
                    } else {
                        rowValues.push(
                            <td
                                className={styles.td}
                                style={
                                    typeof val.value !== 'object'
                                        ? {}
                                        : val.value?.props?.children?.type ===
                                          'a'
                                        ? {}
                                        : { padding: '0px 16px' }
                                }
                                searchTxt={val.text}>
                                {props.onCheckbox && val.addCheckbox ? (
                                    <Checkbox
                                        classes={{
                                            root: cbstyles.root,
                                            checked: cbstyles.checked,
                                        }}
                                        checked={val.checked}
                                        onChange={(event) =>
                                            handleCheckbox(event, val.id)
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                                {typeof val.value !== 'object' &&
                                isTabletPortrait &&
                                props.responsive ? (
                                    <span
                                        className={`${styles.span} ${styles.ellipsis}`}>
                                        <b>{props.columns[ind]}:</b> {val.value}
                                    </span>
                                ) : val.flex ? (
                                    <span className={styles.spanFlex}>
                                        {icon}
                                        {val.value}
                                    </span>
                                ) : (
                                    <span className={styles.span}>
                                        {icon}
                                        {val.value}
                                    </span>
                                )}
                            </td>
                        );
                    }
                } else {
                    rowValues.push(
                        <td className={`${styles.td} ${styles.ellipsis}`} />
                    );
                }
            });
            rows.push(
                <DraggableRow
                    ind={row[0]?.idx?.toString() ?? rowInd.toString()}
                    key={row[0]?.idx?.toString() ?? rowInd.toString()}
                    className={styles.tr}>
                    {rowValues}
                </DraggableRow>
            );
        });
    }

    // if the rows are empty, add a row to indicate that no entries were found
    if (rows.length === 0) {
        // if the drag&drop is activated, remove the drag&drop column
        if (props.dragAndDrop) {
            columns = columns.slice(1);
        }
        rows.push(
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '30px',
                    marginBottom: '30px',
                    width: `${props.columns.length * 100}%`,
                }}>
                <InfoOutlinedIcon style={{ width: '40px', height: '40px' }} />
                <h5>{t('common_filestable_empty')}</h5>
            </div>
        );
    }

    return (
        <>
            {props.search ? (
                <div
                    className="row"
                    style={{
                        borderBottom: '1px solid #dbdbdb',
                        marginBottom: '20px',
                        position: 'relative',
                        display: 'flex',
                    }}>
                    <div className="col-xl-10 col-lg-9"></div>
                    <div className="col-xl-2 col-lg-3">
                        <input
                            data-cy="table-search"
                            className={styles.inputSearch}
                            type="search"
                            placeholder={t('common_filestable_search')}
                            spellCheck="false"
                            value={searchText}
                            onChange={searchTextChanged}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className={styles.wrapper}>
                <DndContext
                    sensors={dndSensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}>
                    <table
                        data-cy={props.datacy ?? ''}
                        className={styles.table}>
                        <thead className={styles.thead}>
                            <tr className={styles.tr}>{columns}</tr>
                        </thead>
                        <DraggableWrapper>{rows}</DraggableWrapper>
                    </table>
                    <DragOverlay>
                        <div
                            style={{
                                backgroundColor: 'rgba(0, 142, 203, 0.8)',
                                borderRadius: '4px',
                                boxShadow:
                                    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(34,33,81,0.15)',
                            }}>
                            {dragActiveItem}
                        </div>
                    </DragOverlay>
                </DndContext>
            </div>
            {props.pagination ? (
                <div className={styles.pagination}>
                    <ul className={styles.paginationList}>
                        <li
                            data-cy="table-pagination-prev"
                            disabled={curPage === 1}
                            className={
                                styles.paginationItemButton +
                                ' ' +
                                styles.paginationFirst +
                                ' ' +
                                styles.paginationItemClickable +
                                ' ' +
                                ` ${curPage !== 1 ? '' : styles.disabled}`
                            }>
                            <div
                                className={
                                    styles.paginationText +
                                    ' ' +
                                    ` ${curPage !== 1 ? '' : styles.disabled}`
                                }
                                onClick={() =>
                                    curPage !== 1
                                        ? setCurPage(curPage - 1)
                                        : setCurPage(curPage)
                                }>
                                <NavigateBeforeOutlinedIcon
                                    style={{ width: '16px', height: '16px' }}
                                />
                                {t('common_filestable_previous')}
                            </div>
                        </li>
                        <li
                            data-cy="table-pagination-text"
                            className={
                                styles.paginationItemButton +
                                ' ' +
                                styles.active
                            }>
                            <div
                                className={
                                    styles.paginationText + ' ' + styles.active
                                }>
                                {`${curPage} ${t(
                                    'common_filestable_outof'
                                )} ${numOfPages}`}
                            </div>
                        </li>
                        <li
                            data-cy="table-pagination-next"
                            disabled={curPage >= numOfPages}
                            className={
                                styles.paginationItemButton +
                                ' ' +
                                styles.paginationLast +
                                ' ' +
                                styles.paginationItemClickable +
                                ` ${
                                    curPage < numOfPages ? '' : styles.disabled
                                }`
                            }>
                            <div
                                className={
                                    styles.paginationText +
                                    ` ${
                                        curPage < numOfPages
                                            ? ''
                                            : styles.disabled
                                    }`
                                }
                                onClick={() =>
                                    curPage < numOfPages
                                        ? setCurPage(curPage + 1)
                                        : setCurPage(curPage)
                                }>
                                {t('common_filestable_next')}
                                <NavigateNextOutlinedIcon
                                    style={{ width: '16px', height: '16px' }}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
