import { customTheme } from '@/App';
import {
    InputAdornment,
    ListSubheader,
    TextField,
    Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { hexToRgba } from '@utils/helpers/colors.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    select: {
        minWidth: 50,
        background: 'white',
        color: 'black',
        fontWeight: 200,
        borderRadius: 40,
        border: '1px solid #bdbdbd',
        paddingLeft: 24,
        paddingTop: 10,
        height: '20px',
        '&:focus': {
            borderRadius: 40,
            borderColor: hexToRgba(customTheme.palette.primary.main, 0.2),
        },
    },
    label: {
        marginLeft: '0.75rem',
        color: '#616161',
    },
    icon: {
        color: '#bdbdbd',
        right: 12,
        position: 'absolute',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    paper: {
        borderRadius: 14,
        marginTop: 8,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        background: 'white',
        '& li': {
            fontWeight: 200,
            height: '40px',
            paddingTop: 12,
            paddingBottom: 12,
        },
        '& li:hover': {
            background: hexToRgba(customTheme.palette.primary.main, 0.2),
        },
        '& li.Mui-selected': {
            color: 'white',
            background: hexToRgba(customTheme.palette.primary.main, 0.6),
        },
        '& li.Mui-selected:hover': {
            background: customTheme.palette.primary.main,
        },
    },
}));

export function SingleSelect(props) {
    const [val, setVal] = useState(null);
    const [searchText, setSearchText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.value)) {
            setVal(props.value);
        }
    }, [props.value]);

    const styles = useStyles();

    const handleChange = (event) => {
        props.onChange(event.target.value);
        if (isNullOrUndefined(props.rawValue)) {
            setVal(event.target.value);
        }
    };

    const containsText = (text, searchText) =>
        text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;

    const displayedOptions = props.data?.filter((option) =>
        containsText(option.description, searchText)
    );

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + ' ' + styles.icon} />
        );
    };

    const menuProps = {
        autoFocus: false,
        classes: {
            paper: styles.paper,
            list: styles.list,
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const items = [];
    displayedOptions?.forEach((d) => {
        items.push(
            <MenuItem key={makeRandomStr()} value={d.value}>
                {d.description}
            </MenuItem>
        );
    });
    const labelId = makeRandomStr();

    return (
        <FormControl
            fullWidth
            data-cy={props.datacy}
            style={{ width: props.width ?? '100%' }}>
            <Typography id={labelId} className={styles.label}>
                {props.title}
            </Typography>
            <Select
                id={makeRandomStr()}
                labelId={labelId}
                disableUnderline
                disabled={props.disabled ?? false}
                native={false}
                displayEmpty={true}
                classes={{ root: styles.select }}
                style={{ width: '100%' }}
                MenuProps={menuProps}
                IconComponent={iconComponent}
                renderValue={() =>
                    props.data?.find((d) => d.value === val)?.description ??
                    props.rawValue
                }
                onClose={() => setSearchText('')}
                onChange={handleChange}>
                <ListSubheader
                    style={{ marginBottom: '20px', pointerEvents: 'none' }}>
                    <TextField
                        size="small"
                        autoFocus
                        placeholder={t('single_select_type_to_search')}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            props.onKeyDown && props.onKeyDown(e);
                            if (e.key !== 'Escape') {
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
                {items}
            </Select>
        </FormControl>
    );
}
