import React from 'react';
import { useTranslation } from 'react-i18next';

import './nodata.css';

export function NoData() {
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <div className="top">
                <h3 className="ghosttxt">{t('common_nodata_nodata')}</h3>
            </div>
            <div className="completeghost">
                <div className="ghost-copy">
                    <div className="one"></div>
                    <div className="two"></div>
                    <div className="three"></div>
                    <div className="four"></div>
                </div>
                <div className="ghost">
                    <div className="face">
                        <div className="eye"></div>
                        <div className="eye-right"></div>
                        <div className="mouth"></div>
                    </div>
                </div>
                <div className="shadow"></div>
            </div>
        </div>
    );
}
