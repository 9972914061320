import {
    DashboardApi,
    MeasurementPointApi,
    SiteGroupApi,
    TemperatureControlApi,
} from '@aurum/nucleus-client-api';
import {
    AquaFilterAppDashboard,
    CustomBreadcrumbs,
    MoreSettingsMenu,
    TemperatureAppDashboard,
    TemperatureControlAppDashboard,
} from '@components';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    getFullGroups,
    getProperRoute,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    siteTypeIncludes,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import {
    calcWindowPeriodForDuration,
    millisecondsToDuration,
} from '@utils/helpers/timestamp.helpers';
import DashboardDatePicker from '@views/sites/dashboards/components/DashboardDatePicker';
import DashboardDatePickerMobile from '@views/sites/dashboards/components/DashboardDatePickerMobile';
import DashboardSpinner from '@views/sites/dashboards/components/DashboardSpinner';
import DashboardWindowPeriodPicker from '@views/sites/dashboards/components/DashboardWindowPeriodPicker';
import CreateDashboardModal from '@views/sites/dashboards/create-dashboard/CreateDashboardModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: 'unset',
    },
}));

export default function DashboardPage(props) {
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [windowPeriod, setWindowPeriod] = useState();
    const [windowPeriodMode, setWindowPeriodMode] = useState();
    const [dashboard, setDashboard] = useState(null);
    const [groups, setGroups] = useState(null);
    const [circulations, setCirculations] = useState(null);
    const [mps, setMps] = useState(null);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [editDbModal, setEditDbModal] = useState(false);
    const [timeSelection, setTimeSelection] = useState();
    const [counter, setCounter] = useState(0);
    const { groupId, id, dbId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const styles = useStyles();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const paramGid = query.get('gid');
    const paramMp = query.get('mp');

    useEffect(() => {
        const dashboardOpts = JSON.parse(
            localStorage.getItem('DashboardOptions') ?? '{}'
        );
        if (dashboardOpts.projectId !== id) {
            setFrom(
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 1,
                    new Date().getHours(),
                    new Date().getMinutes(),
                    new Date().getSeconds()
                )
            );
            setTo(new Date());
            setWindowPeriod('4m');
            setWindowPeriodMode(1);
            setTimeSelection('last1day');
            localStorage.setItem('DashboardOptions', '{}');
        } else {
            setFrom(new Date(dashboardOpts.from));
            setTo(new Date(dashboardOpts.to));
            setWindowPeriod(dashboardOpts.windowPeriod);
            setWindowPeriodMode(dashboardOpts.windowPeriodMode);
            setTimeSelection(dashboardOpts.timeSelection);
        }
    }, []);

    useEffect(() => {
        new SiteGroupApi().siteGroupGetGroups(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setGroups(data);
                }
            }
        );
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(data);
                }
            }
        );
    }, [id]);

    useEffect(() => {
        new DashboardApi().dashboardGetDashboard(
            parseInt(id, 10),
            parseInt(dbId, 10),
            function (_, data, response) {
                if (response.ok) {
                    setDashboard(response.body);
                } else {
                    window.location.href = getProperRoute(
                        `groups/${groupId}/sites/${id}/dashboards`
                    );
                }
            }
        );
    }, [dbId]);

    useEffect(() => {
        if (
            siteTypeIncludes(props.site?.type, 'temperatureControl') &&
            isNullOrUndefined(circulations)
        ) {
            new TemperatureControlApi().temperatureControlGetCirculationsFromSite(
                parseInt(id, 10),
                {},
                function (_, data, response) {
                    if (response.ok) {
                        setCirculations(data);
                    }
                }
            );
        }
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/dashboards/${dbId}`
        );
    }, [props]);

    const deleteDashboard = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                new DashboardApi().dashboardDeleteDashboard(
                    parseInt(id, 10),
                    parseInt(dbId, 10),
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.href = getProperRoute(
                                `groups/${groupId}/sites/${id}/dashboards`
                            );
                        }
                    }
                );
            }
        });
    };

    const editDashboard = (newName) => {
        if (!isNullOrUndefined(newName) && newName.trim() !== '') {
            dashboard.name = newName;
            new DashboardApi().dashboardUpdateDashboard(
                parseInt(id, 10),
                dashboard.id,
                dashboard,
                function (_, data, response) {
                    if (response.ok) {
                        setEditDbModal(false);
                    }
                }
            );
        }
    };

    const absoluteSelection = (fr, t) => {
        setRefreshCounter(refreshCounter + 1);
        setCounter(0);
        setTimeSelection('none');
        setWindowPeriod(
            millisecondsToDuration(calcWindowPeriodForDuration(t - fr))
        );
        setFrom(fr);
        setTo(t);
        localStorage.setItem(
            'DashboardOptions',
            JSON.stringify({
                from: fr,
                to: t,
                windowPeriod: millisecondsToDuration(
                    calcWindowPeriodForDuration(t - fr)
                ),
                windowPeriodMode: windowPeriodMode,
                timeSelection: 'none',
                projectId: id,
            })
        );
    };

    const relativeSelection = (selection) => {
        let dt = new Date();
        setTimeSelection(selection);
        setRefreshCounter(refreshCounter + 1);
        setCounter(0);
        let fromDate, toDate;
        let windowPeriodTxt = windowPeriod;

        switch (selection) {
            case 'last12hours':
                dt.setHours(dt.getHours() - 12);
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = millisecondsToDuration(
                        calcWindowPeriodForDuration(new Date() - dt)
                    );
                }
                fromDate = dt;
                toDate = new Date();
                break;
            case 'last1day':
                dt.setDate(dt.getDate() - 1);
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = millisecondsToDuration(
                        calcWindowPeriodForDuration(new Date() - dt)
                    );
                }
                fromDate = dt;
                toDate = new Date();
                break;
            case 'last3days':
                dt.setDate(dt.getDate() - 3);
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = millisecondsToDuration(
                        calcWindowPeriodForDuration(new Date() - dt)
                    );
                }
                fromDate = dt;
                toDate = new Date();
                break;
            case 'last1week':
                dt.setDate(dt.getDate() - 7);
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = millisecondsToDuration(
                        calcWindowPeriodForDuration(new Date() - dt)
                    );
                }
                fromDate = dt;
                toDate = new Date();
                break;
            case 'last1month':
                dt.setMonth(dt.getMonth() - 1);
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = millisecondsToDuration(
                        calcWindowPeriodForDuration(new Date() - dt)
                    );
                }
                fromDate = dt;
                toDate = new Date();
                break;
            case 'alltime':
                if (windowPeriodMode === 1) {
                    windowPeriodTxt = '1d';
                }
                fromDate = new Date(0);
                toDate = new Date();
                break;
        }

        setWindowPeriod(windowPeriodTxt);
        setFrom(fromDate);
        setTo(toDate);

        localStorage.setItem(
            'DashboardOptions',
            JSON.stringify({
                from: fromDate,
                to: toDate,
                windowPeriod: windowPeriodTxt,
                windowPeriodMode: windowPeriodMode,
                timeSelection: selection,
                projectId: id,
            })
        );
    };

    const windowPeriodSelection = (mode, interval) => {
        if (mode === 1) {
            interval = millisecondsToDuration(
                calcWindowPeriodForDuration(to - from)
            );
        }
        if (windowPeriod !== interval) {
            setCounter(0);
            setRefreshCounter(refreshCounter + 1);
            setWindowPeriod(interval);
        }

        localStorage.setItem(
            'DashboardOptions',
            JSON.stringify({
                from: from,
                to: to,
                windowPeriod: interval,
                windowPeriodMode: mode,
                timeSelection: timeSelection,
                projectId: id,
            })
        );
        setWindowPeriodMode(mode);
    };

    const refreshData = () => {
        if (timeSelection !== 'none') {
            relativeSelection(timeSelection);
        }
        setRefreshCounter((refreshCounter) => refreshCounter + 1);
        setCounter(0);
    };

    const fetchingProgress = (counter, total) => {
        if (counter === -1) {
            setCounter(-1);
        }
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
    const isTabletLandscape = useMediaQuery({ query: '(max-width: 1500px)' });

    let dashboardElement = (
        <TemperatureAppDashboard
            queryMp={
                isNullOrUndefined(paramMp) || !isNumeric(paramMp)
                    ? undefined
                    : parseInt(paramMp, 10)
            }
            queryGroup={
                isNullOrUndefined(paramGid) || !isNumeric(paramGid)
                    ? undefined
                    : parseInt(paramGid, 10)
            }
            user={props.user}
            dashboard={dashboard}
            groups={groups}
            mps={mps}
            from={from}
            to={to}
            loading={counter !== -1}
            windowPeriod={windowPeriod}
            refreshCounter={refreshCounter}
            fetchingProgress={(counter, total) =>
                fetchingProgress(counter, total)
            }
        />
    );
    if (
        (siteTypeIncludes(props.site?.type, 'filter') &&
            !dashboard?.configOptions?.type) ||
        dashboard?.configOptions?.type === 'filter'
    ) {
        dashboardElement = (
            <AquaFilterAppDashboard
                user={props.user}
                dashboard={dashboard}
                groups={groups}
                mps={mps}
                from={from}
                to={to}
                windowPeriod={windowPeriod}
                loading={counter !== -1}
                refreshCounter={refreshCounter}
                fetchingProgress={(counter, total) =>
                    fetchingProgress(counter, total)
                }
            />
        );
    }
    if (
        (siteTypeIncludes(props.site?.type, 'temperatureControl') &&
            !dashboard?.configOptions?.type) ||
        dashboard?.configOptions?.type === 'temperatureControl'
    ) {
        dashboardElement = (
            <TemperatureControlAppDashboard
                user={props.user}
                dashboard={dashboard}
                circulations={circulations}
                site={props.site}
                mps={mps}
                from={from}
                to={to}
                windowPeriod={windowPeriod}
                loading={counter !== -1}
                refreshCounter={refreshCounter}
                fetchingProgress={(counter, total) =>
                    fetchingProgress(counter, total)
                }
            />
        );
    }

    let menuOptions = <></>;
    if (
        userIsAtLeast(props.user, 'analyst') &&
        dashboard?.configOptions?.secured
    ) {
        menuOptions = (
            <MenuItem className={styles.root}>
                {t('pages_dashboard_dashboardpage_protected')}{' '}
            </MenuItem>
        );
    } else if (
        userIsAtLeast(props.user, 'analyst') &&
        !dashboard?.configOptions?.secured
    ) {
        menuOptions = (
            <>
                <MenuItem
                    onClick={() =>
                        (window.location.href = getProperRoute(
                            `groups/${groupId}/sites/${id}/dashboards/${dbId}/graphs/create`
                        ))
                    }>
                    {t('pages_dashboard_dashboardpage_create')}
                </MenuItem>
                <MenuItem onClick={() => setEditDbModal(true)}>
                    {t('pages_dashboard_dashboardpage_edit')}
                </MenuItem>
                <MenuItem onClick={() => deleteDashboard()}>
                    {t('pages_dashboard_dashboardpage_delete')}
                </MenuItem>
            </>
        );
    }

    return isNullOrUndefined(dashboard) ? (
        <></>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: isTabletLandscape ? 'column' : 'row',
                    width: '100%',
                }}>
                <div style={{ width: isTabletLandscape ? '100%' : '75%' }}>
                    <CustomBreadcrumbs
                        breadcrumbs={[
                            ...fullGroups,
                            {
                                link: getProperRoute(
                                    `groups/${groupId}/sites/${id}/settings`
                                ),
                                text: props.site?.name,
                            },
                            {
                                link: getProperRoute(
                                    `groups/${groupId}/sites/${id}/dashboards`
                                ),
                                text: t(
                                    'pages_dashboard_dashboardpage_dashboards'
                                ),
                            },
                            { link: getProperRoute('/'), text: dashboard.name },
                        ]}
                    />
                </div>
                {counter !== -1 ? (
                    <div
                        style={{
                            width: isTabletLandscape ? '100%' : '',
                            marginTop: isTabletLandscape ? '10px' : '0px',
                        }}>
                        <DashboardSpinner />
                    </div>
                ) : (
                    <div
                        style={{
                            width: isTabletLandscape ? '100%' : '',
                            marginTop: isTabletLandscape ? '10px' : '0px',
                        }}>
                        <></>
                    </div>
                )}
                <div
                    style={{
                        width: isTabletLandscape ? '100%' : '',
                        alignItems: 'flex-end',
                        marginTop: isTabletLandscape ? '10px' : '0px',
                        marginRight: isTabletLandscape ? '0px' : '10px',
                        justifyContent: isTabletLandscape
                            ? 'flex-start'
                            : 'flex-end',
                    }}>
                    <DashboardWindowPeriodPicker
                        onWindowPeriodChange={(mode, interval) =>
                            windowPeriodSelection(mode, interval)
                        }
                        windowPeriod={windowPeriod}
                        windowSelected={windowPeriodMode}
                        user={props.user}
                        from={from}
                        to={to}
                    />
                </div>
                <div
                    style={{
                        width: isTabletLandscape ? '100%' : '',
                        alignItems: 'flex-end',
                        marginTop: isTabletLandscape ? '10px' : '0px',
                        justifyContent: isTabletLandscape
                            ? 'flex-start'
                            : 'flex-end',
                    }}>
                    {isMobile ? (
                        <DashboardDatePickerMobile
                            timeSelection={timeSelection}
                            onRefresh={() => refreshData()}
                            onRelativeSelection={(selection) =>
                                relativeSelection(selection)
                            }
                        />
                    ) : (
                        <DashboardDatePicker
                            from={from}
                            to={to}
                            timeSelection={timeSelection}
                            user={props.user}
                            onRefresh={() => refreshData()}
                            onRelativeSelection={(selection) =>
                                relativeSelection(selection)
                            }
                            onAbsoluteSelection={(fr, t) =>
                                absoluteSelection(fr, t)
                            }
                        />
                    )}
                </div>
                <div
                    style={{
                        width: isTabletLandscape ? '100%' : '10%',
                        marginTop: isTabletLandscape ? '10px' : '0px',
                        display: 'flex',
                        justifyContent: isTabletLandscape
                            ? 'flex-start'
                            : 'flex-end',
                    }}>
                    <MoreSettingsMenu
                        disabled={!userIsAtLeast(props.user, 'analyst')}>
                        {menuOptions}
                    </MoreSettingsMenu>
                </div>
            </div>
            <hr /> <br />
            {dashboardElement}
            <CreateDashboardModal
                edit
                name={dashboard.name}
                open={editDbModal}
                handleClose={() => setEditDbModal(false)}
                confirm={editDashboard}
            />
        </>
    );
}
