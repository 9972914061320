import React from 'react';
import { VictoryPie } from 'victory';

import { NoData } from '@components';

import './graphstyles.css';

export function PieGraph(props) {
    const colors = [
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
    ];

    const series = props.series || [];

    const toVictoryData = (line) => {
        return {
            name: line.name,
            x: line.datapoints[0].x,
            y: line.datapoints[0].y,
        };
    };

    const alldata = [];
    series.forEach((d) => {
        alldata.push(toVictoryData(d));
    });

    return series.length === 0 ? (
        <NoData />
    ) : (
        <div className={'chart-wrapper'}>
            <VictoryPie
                width={props.width * 2}
                height={props.height * 2}
                cornerRadius={5}
                data={alldata}
                colorScale={colors}
                labels={({ datum }) => `${datum.name}\n${datum.y}`}
                style={{ labels: { fontSize: 30 } }}
            />
        </div>
    );
}
