import { SingleSelect, TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function UpdateStringModal(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [stringName, setStringName] = useState(null);
    const [tempSensors, setTempSensors] = useState([]);
    const [valves, setValves] = useState([]);
    const [flowTemp, setFlowTemp] = useState(null);
    const [flowOutput, setFlowOutput] = useState(null);
    const [circuTemp, setCircuTemp] = useState(null);
    const [circuOutput, setCircuOutput] = useState(null);
    const [valvePosition, setValvePosition] = useState(null);
    const [valvePositionOutput, setValvePositionOutput] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (isNullOrUndefined(props.sensors)) {
            return;
        }
        setTempSensors(getTempSensors(props.sensors));
        setValves(getValveSensors(props.sensors));
        const flowTempStrangMeasurement =
            getMeasurementFromString('flowTemperature');
        if (isNullOrUndefined(flowTempStrangMeasurement)) {
            setFlowTemp(tempSensors[0]?.value ?? null);
            setFlowOutput(getDataForSelectedSensor(0)[0]?.value ?? null);
        } else {
            setFlowTemp(flowTempStrangMeasurement.sensor.uuid);
            setFlowOutput(flowTempStrangMeasurement.sensorOutput);
        }
        const circuTempStrangMeasurement =
            getMeasurementFromString('circuTemperature');
        if (isNullOrUndefined(circuTempStrangMeasurement)) {
            setCircuTemp(tempSensors[0]?.value ?? null);
            setCircuOutput(getDataForSelectedSensor(1)[0]?.value ?? null);
        } else {
            setCircuTemp(circuTempStrangMeasurement.sensor.uuid);
            setCircuOutput(circuTempStrangMeasurement.sensorOutput);
        }
        const valvePositionStrangMeasurement =
            getMeasurementFromString('valvePosition');
        if (isNullOrUndefined(valvePositionStrangMeasurement)) {
            setValvePosition(valves[0]?.value ?? null);
            setValvePositionOutput(
                getDataForSelectedSensor(2)[0]?.value ?? null
            );
        } else {
            setValvePosition(valvePositionStrangMeasurement.sensor.uuid);
            setValvePositionOutput(valvePositionStrangMeasurement.sensorOutput);
        }
    }, [props]);

    useEffect(() => {
        setStringName(props.stringToUpdate?.name);
    }, [props.stringToUpdate]);

    const getMeasurementFromString = (measurement) => {
        return props.stringToUpdate?.measurements?.find(
            (m) => m.measurement === measurement
        );
    };

    const getTempSensors = (sensors) => {
        return [
            { value: 'none', description: 'No sensor selected' },
            ...(sensors
                ?.filter(
                    (s) =>
                        s.type.startsWith('temperature') ||
                        s.type.startsWith('valve')
                )
                ?.map((s) => ({ value: s.uuid, description: s.name })) ?? []),
        ];
    };

    const getValveSensors = (sensors) => {
        return [
            { value: 'none', description: 'No sensor selected' },
            ...(sensors
                ?.filter((s) => s.type.startsWith('valve'))
                ?.map((s) => ({ value: s.uuid, description: s.name })) ?? []),
        ];
    };

    const sensorMeasurements = {
        temperature_eltLite: [
            { value: 'none', description: 'No output selected' },
            { value: 'temperature', description: 'Temperature' },
        ],
        temperature_elt2: [
            { value: 'none', description: 'No output selected' },
            { value: 'temperature', description: 'Temperature' },
            { value: 'temperature2', description: 'Temperature Probe 2' },
        ],
        valve_mlr003: [
            { value: 'none', description: 'No output selected' },
            { value: 'temperature', description: 'Temperature' },
            { value: 'temperature2', description: 'Temperature Probe 2' },
            { value: 'valvePosition', description: 'Position' },
        ],
        '': [{ value: 'none', description: 'No output selected' }],
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const getDataForSelectedSensor = (index) => {
        if (index === 0) {
            const sensor = props.sensors?.find((s) => s.uuid === flowTemp);
            return (
                sensorMeasurements[sensor?.type ?? '']?.filter(
                    (v) =>
                        v.value.startsWith('temperature') || v.value === 'none'
                ) ?? [{ value: 'none', description: 'No output selected' }]
            );
        } else if (index === 1) {
            const sensor = props.sensors?.find((s) => s.uuid === circuTemp);
            return (
                sensorMeasurements[sensor?.type ?? '']?.filter(
                    (v) =>
                        v.value.startsWith('temperature') || v.value === 'none'
                ) ?? [{ value: 'none', description: 'No output selected' }]
            );
        } else if (index === 2) {
            const sensor = props.sensors?.find((s) => s.uuid === valvePosition);
            return (
                sensorMeasurements[sensor?.type ?? '']?.filter(
                    (v) =>
                        v.value.startsWith('valvePosition') ||
                        v.value === 'none'
                ) ?? [{ value: 'none', description: 'No output selected' }]
            );
        }
        return [{ value: 'none', description: 'No output selected' }];
    };

    const isConfirmBtnDisabled = () => {
        return (
            isNullOrUndefined(props.stringToUpdate?.id) ||
            isNullOrUndefined(flowTemp) ||
            isNullOrUndefined(flowOutput) ||
            isNullOrUndefined(circuTemp) ||
            isNullOrUndefined(circuOutput) ||
            isNullOrUndefined(valvePosition) ||
            isNullOrUndefined(valvePositionOutput)
        );
    };

    const stringNameChanged = (txt) => {
        setStringName(txt);
    };

    const onOkClick = () => {
        if (isConfirmBtnDisabled()) {
            return;
        }

        if (isNullOrUndefined(stringName) || stringName?.trim() === '') {
            setErrorMsg(
                'The name of the string is mandatory and must be specified'
            );
            return;
        }

        const stringMeasurements = [];
        if (flowTemp !== 'none' && flowOutput !== 'none') {
            stringMeasurements.push({
                stringId: props.stringToUpdate.id,
                sensor: {
                    uuid: flowTemp,
                    name: props.sensors.find((s) => s.uuid === flowTemp)?.name,
                },
                measurement: 'flowTemperature',
                sensorOutput: flowOutput,
            });
        }
        if (circuTemp !== 'none' && circuOutput !== 'none') {
            stringMeasurements.push({
                stringId: props.stringToUpdate.id,
                sensor: {
                    uuid: circuTemp,
                    name: props.sensors.find((s) => s.uuid === circuTemp)?.name,
                },
                measurement: 'circuTemperature',
                sensorOutput: circuOutput,
            });
        }
        if (valvePosition !== 'none' && valvePositionOutput !== 'none') {
            stringMeasurements.push({
                stringId: props.stringToUpdate.id,
                sensor: {
                    uuid: valvePosition,
                    name: props.sensors.find((s) => s.uuid === valvePosition)
                        ?.name,
                },
                measurement: 'valvePosition',
                sensorOutput: valvePositionOutput,
            });
        }
        props.confirm && props.confirm(stringMeasurements, stringName);
        closeModal();
    };

    const closeModal = () => {
        setErrorMsg(null);
        setStringName(props.stringToUpdate?.name);
        props.handleClose && props.handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => closeModal()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`Update "${
                props.stringToUpdate?.name ?? ''
            }"`}</DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <TextEdit
                                label={'Edit string name'}
                                value={stringName}
                                onChange={stringNameChanged}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-md-4"></div>
                        <div
                            className="col-md-5"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            Sensor
                        </div>
                        <div
                            className="col-md-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            Output
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div
                            className="col-md-4"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Flow Temperature</span>
                        </div>
                        <div className="col-md-5">
                            <SingleSelect
                                data={tempSensors}
                                value={flowTemp}
                                onChange={(sensor) => {
                                    setFlowTemp(sensor);
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <SingleSelect
                                data={getDataForSelectedSensor(0)}
                                value={flowOutput}
                                onChange={(output) => {
                                    setFlowOutput(output);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div
                            className="col-md-4"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Circulation Temperature</span>
                        </div>
                        <div className="col-md-5">
                            <SingleSelect
                                data={tempSensors}
                                value={circuTemp}
                                onChange={(sensor) => {
                                    setCircuTemp(sensor);
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <SingleSelect
                                data={getDataForSelectedSensor(1)}
                                value={circuOutput}
                                onChange={(output) => {
                                    setCircuOutput(output);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div
                            className="col-md-4"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Valve Position</span>
                        </div>
                        <div className="col-md-5">
                            <SingleSelect
                                data={valves}
                                value={valvePosition}
                                onChange={(sensor) => {
                                    setValvePosition(sensor);
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <SingleSelect
                                data={getDataForSelectedSensor(2)}
                                value={valvePositionOutput}
                                onChange={(output) => {
                                    setValvePositionOutput(output);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => closeModal()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    disabled={isConfirmBtnDisabled()}
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}
