import { MetricPlot } from '@components';
import { Button, Tooltip } from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { dateToString } from '@utils/helpers/timestamp.helpers';
import FiltrationLiveProcess from './ActiveProcess/FiltrationLiveProcess';
import FlushLiveProcess from './ActiveProcess/FlushLiveProcess';
import PreTestLiveProcess from './ActiveProcess/PreTestLiveProcess';

import { FilterApi } from '@aurum/nucleus-client-api';
import { useParams } from 'react-router-dom';
import './pbiStyling.css';

export function PbiHomeScreen(props) {
    const [inletPressure, setInletPressure] = useState(0);
    const [outPressure, setOutPressure] = useState(0);
    const [filterActive, setFilterActive] = useState(0);
    const [displaySecTime, setDisplaySecTime] = useState(0);
    const [progStep, setProgStep] = useState(0);

    const { t } = useTranslation();
    const { id } = useParams();

    const menuIcon = 'filter/menu.png';
    const pbiLogoIcon = 'pbi/logo.png';
    const startButtonIcon = 'filter/Start_bg.png';
    const {
        height,
        onFetchMeasurements,
        fetchingInProgress,
        lastFetchingTime,
    } = props;

    useEffect(() => {
        setInletPressure(getRealMeasurementValue('INLET_PRES'));
        setOutPressure(getRealMeasurementValue('PERMEAT_PRES'));
        setFilterActive(getRealMeasurementValue('FILTER_ACTIVE'));
        setProgStep(getRealMeasurementValue('PROG_STEP'));
        setDisplaySecTime(getRealMeasurementValue('DISPLAY_SEC_TIME'));
    }, [props.realTimeMeasurements]);

    const getImageFromErrorCode = (errCode) => {
        if (errorCode >= 1 && errorCode <= 99) {
            return getProperRoute('filter/errors/1.png');
        } else if (errorCode >= 101 && errorCode <= 199) {
            return getProperRoute('filter/errors/100.png');
        } else {
            return getProperRoute(`filter/errors/${errCode}.png`);
        }
    };

    const getDescriptionFromErrorCode = (errCode) => {
        const descriptions = [
            {
                code: 201,
                description: t('pages_pbihome_pressurelow'),
            },
            {
                code: 202,
                description: t('pages_pbihome_pressurehigh'),
            },
            {
                code: 205,
                description: t('pages_pbihome_pressuredifference'),
            },
            { code: 206, description: t('pages_pbihome_pretestfail') },
            { code: 211, description: t('pages_pbihome_flushpostpone') },
            { code: 212, description: t('pages_pbihome_integritypostpone') },
            { code: 213, description: t('pages_pbihome_serviceinterval') },
            { code: 999, description: t('pages_pbihome_startscreen') },
        ];
        if (errorCode >= 1 && errorCode <= 99) {
            return t('pages_pbihome_integrityerror', { number: errCode });
        } else if (errorCode >= 101 && errorCode <= 199) {
            return t('pages_pbihome_valveerror', { number: errCode - 100 });
        } else {
            return (
                descriptions.find((d) => d.code === errCode)?.description ?? ''
            );
        }
    };

    const getParameterValue = (paramName) => {
        return props.parameters?.find((p) => p.name === paramName)?.value ?? '';
    };

    const getRealMeasurementValue = (measurement) => {
        return (
            props.realTimeMeasurements?.find(
                (p) => p.measurement === measurement
            )?.value ?? 0
        );
    };

    const getLiveProcess = () => {
        if (progStep === 0) {
            return <FiltrationLiveProcess style={{ height: '100%' }} />;
        } else if (progStep === 1) {
            return (
                <PreTestLiveProcess
                    style={{ height: '90%' }}
                    displaySecTime={displaySecTime}
                />
            );
        } else if ((progStep >= 2 && progStep <= 4) || progStep === 11) {
            return (
                <FlushLiveProcess
                    style={{ height: '90%' }}
                    displaySecTime={displaySecTime}
                    filterActive={filterActive}
                    progStep={progStep}
                />
            );
        } else if (progStep === 999) {
            return (
                <img
                    className="clickable-icon"
                    style={{
                        width: '150px',
                        height: '75px',
                    }}
                    src={`${getProperRoute(startButtonIcon)}`}></img>
            );
        }
    };

    const forceFetchData = () => {
        new FilterApi().filterFetchParametersFromPlc(
            parseInt(id, 10),
            {},
            () => {
                onFetchMeasurements?.();
            }
        );
    };

    const hour = getParameterValue('RealKlok_Uur');
    const minutes = getParameterValue('RealKlok_Minuut');
    const serviceHours = getParameterValue('URENTELLER');
    const tempAvg = getParameterValue('TEMPERATUUR_AVG');
    const blockedPercentage = getParameterValue('MAX_VERVUILINGSGRAAD');

    const menuClick = () => {
        props.triggerChange && props.triggerChange(1);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const errorCode = props.data
        ?.find((dt) => dt.name === 'ERROR_CODE')
        ?.datapoints?.at(-1)?.y;

    return (
        <>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-6 start-just full">
                    <img
                        style={{ height: '100%', maxWidth: '100%' }}
                        src={`${getProperRoute(pbiLogoIcon)}`}></img>
                </div>
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="col-md-2 full">
                    {`${String(hour).padStart(2, '0')} : ${String(
                        minutes
                    ).padStart(2, '0')}`}
                </div>
                <div className="col-md-2 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_pbihome_reload')}>
                        <Button
                            className="pbi-button"
                            style={{ width: 'auto' }}
                            aria-label="Reload"
                            disabled={fetchingInProgress}
                            onClick={() => forceFetchData()}
                            color="primary">
                            <CachedIcon
                                className={
                                    fetchingInProgress ? 'spin-icon' : ''
                                }
                            />
                        </Button>
                    </Tooltip>
                </div>
                <div className="col-md-1 end-just full">
                    {isNullOrUndefined(errorCode) || errorCode === 0 ? (
                        <></>
                    ) : (
                        <Tooltip
                            enterDelay={1000}
                            title={getDescriptionFromErrorCode(errorCode)}>
                            <img
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    pointerEvents: 'all',
                                }}
                                src={getImageFromErrorCode(errorCode)}></img>
                        </Tooltip>
                    )}
                </div>
                <div className="col-md-1 end-just full">
                    <Tooltip enterDelay={1000} title={t('pages_pbimenu_menu')}>
                        <img
                            className="clickable-icon"
                            style={{ width: '40px', height: '40px' }}
                            onClick={() => menuClick()}
                            src={`${getProperRoute(menuIcon)}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${(height / 6) * 4}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-8 full middle">{getLiveProcess()}</div>
                <div className="col-md-4 full">
                    <div className="row end-just" style={{ height: '40%' }}>
                        <div className="full">
                            <MetricPlot
                                removeText
                                data={[{ datapoints: [{ y: inletPressure }] }]}
                            />
                        </div>
                    </div>
                    <div className="row middle" style={{ height: '10%' }}>
                        IN: {inletPressure} Bar
                    </div>
                    <div className="row end-just" style={{ height: '40%' }}>
                        <div className="full">
                            <MetricPlot
                                removeText
                                data={[{ datapoints: [{ y: outPressure }] }]}
                            />
                        </div>
                    </div>
                    <div className="row middle" style={{ height: '10%' }}>
                        OUT: {outPressure} Bar
                    </div>
                </div>
            </div>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-4 end-just">{`${serviceHours}${' '}${t(
                    'pages_pbihome_hourunit'
                )}`}</div>
                <div className="col-md-4 end-just">{`${t(
                    'pages_pbihome_blocked'
                )}: ${blockedPercentage}%`}</div>
                <div className="col-md-4 end-just">{`${t(
                    'pages_pbihome_temp'
                )}: ${tempAvg / 10}ºC`}</div>
            </div>
            {lastFetchingTime ? (
                <div
                    className="row"
                    style={{
                        background: 'rgb(223,226,227)',
                        flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                    }}>
                    <p
                        style={{
                            color: 'red',
                            textAlign: 'center',
                            width: '100%',
                        }}>
                        {t('pages_parameters_lastfetched')}: &nbsp;&nbsp;
                        {dateToString(lastFetchingTime, props.user?.timeZone)}
                    </p>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
