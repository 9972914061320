import { AccountApi } from '@aurum/nucleus-client-api';
import { MoreSettingsMenu, NavButton } from '@components';
import {
    Avatar,
    Button,
    Grid,
    MenuItem,
    MenuList,
    Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    getPathToLogos,
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { alphabetPosition } from '@utils/helpers/text.helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const styles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    container: {
        width: '100%',
        margin: 'auto',
    },
    buttonFontSize: {
        fontSize: '13px',
        color: '#fff',
        height: '40px',
        textTransform: 'none',
    },
    AppBar: {
        backgroundColor: '#fff',
        backgroundSize: 'cover',
    },
    mainLogo: {
        justifyContent: 'left',
        '&:hover': {
            background: 'transparent',
        },
    },
    avatar: {
        height: '50px',
        width: '60px',
        borderRadius: 0,
    },
    link: {
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
    profileImg: {
        border: 0,
        height: '35px',
        width: '35px',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            isNullOrUndefined(props.user)
                ? 'transparent'
                : getAvatarColor(alphabetPosition(props.user.email[0])),
        fontSize: '20px',
        lineHeight: '35px',
        borderRadius: '50%',
        marginLeft: '-9px',
        marginTop: '-7px',
        color: 'black',
    },
    divProfile: {
        height: '35px',
        width: '35px',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: 'white',
        fontSize: '20px',
        lineHeight: '35px',
        marginLeft: '10px',
        borderRadius: '50%',
        border: '1px solid #fff',
    },
}));

export function Navbar(props) {
    const classes = styles(props);
    const { t } = useTranslation();

    const logout = () => {
        new AccountApi().accountLogout(function (_, data, response) {
            window.location.reload();
        });
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
            {isMobile ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}>
                    <Button
                        style={{ justifySelf: 'flex-start', width: 'inherit' }}
                        aria-label="Projects"
                        className={[classes.mainLogo]}>
                        <Link
                            className={classes.link}
                            to={getProperRoute('sites')}>
                            <Avatar
                                alt="logo"
                                src={getProperRoute(
                                    `${getPathToLogos()}/logo_small.png`
                                )}
                                className={classes.avatar}
                            />
                        </Link>
                    </Button>
                    <MoreSettingsMenu color="white" background="white">
                        <MenuList id="menu-user">
                            <Link
                                className={classes.link}
                                to={getProperRoute('sites')}>
                                <MenuItem>
                                    {t('pagelayout_navbar_viewallprojects')}
                                </MenuItem>
                            </Link>
                            {!userIsAtLeast(props.user, 'maintainer') &&
                            (isNullOrUndefined(props.groups) ||
                                props.groups?.length <= 1) ? (
                                <></>
                            ) : (
                                <Link
                                    className={classes.link}
                                    to={getProperRoute('groups')}>
                                    <MenuItem>
                                        {t('pagelayout_navbar_viewallgroups')}
                                    </MenuItem>
                                </Link>
                            )}
                            <hr />
                            <Link
                                className={classes.link}
                                to={getProperRoute('users')}>
                                <MenuItem>
                                    {t('pagelayout_navbar_users')}
                                </MenuItem>
                            </Link>
                            <Link
                                className={classes.link}
                                to={getProperRoute('devices')}>
                                <MenuItem>
                                    {t('pagelayout_navbar_devices')}
                                </MenuItem>
                            </Link>
                            {userIsAtLeast(props.user, 'maintainer') ? (
                                <Link
                                    className={classes.link}
                                    to={getProperRoute('diagnostics')}>
                                    <MenuItem>Admin</MenuItem>
                                </Link>
                            ) : (
                                <></>
                            )}
                            <hr />
                            <Link
                                className={classes.link}
                                to={getProperRoute('profile')}>
                                <MenuItem>
                                    {t('pagelayout_navbar_editprofile')}
                                </MenuItem>
                            </Link>
                            <hr />
                            <MenuItem onClick={() => logout()}>
                                {t('pagelayout_navbar_signout')}
                            </MenuItem>
                        </MenuList>
                    </MoreSettingsMenu>
                </div>
            ) : (
                <Grid item sm={12} xs={12} className={classes.container}>
                    <Toolbar>
                        <Grid className={classes.grow}>
                            <Button
                                data-cy="navbar-logo"
                                aria-label="Projects"
                                className={[classes.mainLogo]}>
                                <Link
                                    className={classes.link}
                                    to={getProperRoute('sites')}>
                                    <Avatar
                                        alt="logo"
                                        src={getProperRoute(
                                            `${getPathToLogos()}/logo_small.png`
                                        )}
                                        className={classes.avatar}
                                    />
                                </Link>
                            </Button>
                            <NavButton
                                datacy="navbar-projects"
                                padding
                                id="menu-projects"
                                text={t('pagelayout_navbar_projects')}
                                icon={<ExpandMoreIcon />}>
                                <MenuList id="menu-projects">
                                    <Link
                                        className={classes.link}
                                        to={getProperRoute('sites')}>
                                        <MenuItem>
                                            {t(
                                                'pagelayout_navbar_viewallprojects'
                                            )}
                                        </MenuItem>
                                    </Link>
                                </MenuList>
                            </NavButton>
                            {!userIsAtLeast(props.user, 'maintainer') &&
                            (isNullOrUndefined(props.groups) ||
                                props.groups?.length <= 1) ? (
                                <></>
                            ) : (
                                <NavButton
                                    datacy="navbar-groups"
                                    id="menu-groups"
                                    text={t('pagelayout_navbar_groups')}
                                    icon={<ExpandMoreIcon />}>
                                    <MenuList id="menu-groups">
                                        <Link
                                            className={classes.link}
                                            to={getProperRoute('groups')}>
                                            <MenuItem>
                                                {t(
                                                    'pagelayout_navbar_viewallgroups'
                                                )}
                                            </MenuItem>
                                        </Link>
                                    </MenuList>
                                </NavButton>
                            )}
                            <NavButton
                                datacy="navbar-more"
                                id="menu-more"
                                text={t('pagelayout_navbar_more')}
                                icon={<ExpandMoreIcon />}>
                                <MenuList id="menu-more">
                                    <Link
                                        className={classes.link}
                                        to={getProperRoute('users')}>
                                        <MenuItem>
                                            {t('pagelayout_navbar_users')}
                                        </MenuItem>
                                    </Link>
                                    <Link
                                        className={classes.link}
                                        to={getProperRoute('devices')}>
                                        <MenuItem>
                                            {t('pagelayout_navbar_devices')}
                                        </MenuItem>
                                    </Link>
                                    {/* {userIsAtLeast(props.user, 'maintainer') ? <Link className={classes.link} to={getProperRoute('accounting')}><MenuItem> {t('pagelayout_navbar_accounting')}</MenuItem></Link> : <></>} */}
                                    {userIsAtLeast(props.user, 'maintainer') ? (
                                        <Link
                                            className={classes.link}
                                            to={getProperRoute('diagnostics')}>
                                            <MenuItem>Admin</MenuItem>
                                        </Link>
                                    ) : (
                                        <></>
                                    )}
                                </MenuList>
                            </NavButton>
                        </Grid>
                        <div className={classes.divProfile}>
                            <NavButton
                                datacy="navbar-user"
                                id="menu-user"
                                width=""
                                text={
                                    <div className={classes.profileImg}>
                                        {props.user?.email[0]?.toUpperCase()}
                                    </div>
                                }
                                icon={<ExpandMoreIcon />}>
                                <MenuList
                                    id="menu-user"
                                    style={{ width: '400px', maxWidth: 'vw' }}>
                                    <div>
                                        <p
                                            data-cy="profile-username"
                                            style={{
                                                marginLeft: '15px',
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                            }}>
                                            {props.user?.userName}
                                        </p>
                                        <p
                                            data-cy="profile-email"
                                            style={{
                                                marginLeft: '15px',
                                                fontSize: '15px',
                                            }}>
                                            {props.user?.email}
                                        </p>
                                    </div>
                                    <hr />
                                    <Link
                                        data-cy="profile-edit"
                                        className={classes.link}
                                        to={getProperRoute('profile')}>
                                        <MenuItem>
                                            {t('pagelayout_navbar_editprofile')}
                                        </MenuItem>
                                    </Link>
                                    <hr />
                                    <MenuItem
                                        data-cy="profile-logout"
                                        onClick={() => logout()}>
                                        {t('pagelayout_navbar_signout')}
                                    </MenuItem>
                                </MenuList>
                            </NavButton>
                        </div>
                    </Toolbar>
                </Grid>
            )}
        </>
    );
}
