import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { customTheme } from '@/App';

const tabStyles = makeStyles(() => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    indicator: () => ({
        height: 3,
        backgroundColor: 'rgba(0,0,0,0)',
        '& > div': {
            backgroundColor: 'rgba(0,0,0,0.6)',
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            height: 3,
            margin: '0 8px',
            backgroundColor: customTheme.palette.primary.main,
        },
    }),
}));

const tabItemStyles = makeStyles(() => ({
    root: {
        opacity: 1,
        '&:hover,&:focus': {
            outline: 0,
            '& $wrapper': {
                color: 'rgba(0,0,0,0.87)',
            },
        },
        minHeight: 56,
        flex: 1,
        maxWidth: 253,
        padding: '6px 16px',
    },
    labelIcon: {
        '& $wrapper > *:first-child': {
            marginBottom: 0,
            marginRight: 16,
            fontSize: 20,
        },
    },
    wrapper: () => ({
        color: 'rgba(0,0,0,0.6)',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        fontSize: 14,
        textTransform: 'initial',
        '& .MuiTabItem-labelGroup': {
            minWidth: 0,
        },
        '& .MuiTabItem-label': {
            display: 'flex',
            alignItems: 'center',
        },
    }),
    selected: () => ({
        '& $wrapper': {
            color: `${customTheme.palette.primary.main} !important`,
        },
    }),
}));

export function CustomTabs(props) {
    const [tabIndex, setTabIndex] = React.useState(0);

    const tabsStyle = tabStyles();
    const tabItemStyle = tabItemStyles();

    const onTabChanged = (index) => {
        setTabIndex(index);
        props.onTabChanged(index);
    };

    const tabs = [];
    props.tabs?.forEach((tab) => {
        tabs.push(
            <Tab
                classes={tabItemStyle}
                disableTouchRipple
                label={tab.text}
                icon={tab.icon}
            />
        );
    });

    return (
        <Tabs
            classes={tabsStyle}
            value={tabIndex}
            onChange={(e, index) => onTabChanged(index)}
            TabIndicatorProps={{
                children: <div className={`MuiIndicator-${tabIndex}`} />,
            }}>
            {tabs}
        </Tabs>
    );
}
