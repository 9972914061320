import { MeasurementPointApi, TemperatureApi } from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    FilesTable,
    MoreSettingsMenu,
    RoundedText,
} from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getFullGroups,
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import AssignSensorModal from '@views/sites/settings/measurement-points/assign-sensor/AssignSensorModal';
import CreateMpModal from '@views/sites/settings/measurement-points/create-measurement-point/CreateMpModal';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';

import './signalstrength.css';

export default function MeasurementPointPage(props) {
    const [mp, setMp] = useState(null);
    const [allsensors, setAllSensors] = useState(null);
    const [assignSensorModal, setAssignSensorModal] = useState(false);
    const [createMpModal, setCreateMpModal] = useState(false);
    const { id, groupId, gid, mpId } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        new TemperatureApi().temperatureGetSensors2(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setAllSensors(data.filter((sensor) => sensor.status === 0));
            }
        });
    }, [id]);

    useEffect(() => {
        new MeasurementPointApi().measurementPointGetMeasurementPoint(
            parseInt(id, 10),
            parseInt(mpId, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMp(response.body);
                } else {
                    window.location.href = getProperRoute(
                        `groups/${groupId}/sites/${id}/settings`
                    );
                }
            }
        );
    }, [mpId]);

    const deleteMp = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                new MeasurementPointApi().measurementPointDeleteMeasurementPoint(
                    parseInt(id, 10),
                    parseInt(mpId, 10),
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.href = getProperRoute(
                                `groups/${groupId}/sites/${id}/settings/${gid}`
                            );
                        }
                    }
                );
            }
        });
    };

    const assignSensor = (uuid) => {
        if (!isNullOrUndefined(uuid) && uuid.trim() !== '') {
            const sensor = allsensors.filter(
                (sensor) => sensor.uuid === uuid
            )[0];
            if (!isNullOrUndefined(sensor)) {
                mp.measuringElement = { uuid: uuid };
                new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
                    parseInt(id, 10),
                    parseInt(mpId, 10),
                    mp,
                    function (_, data, response) {
                        if (response.ok) {
                            setAllSensors(
                                allsensors.filter((s) => s.uuid !== uuid)
                            );
                            setMp(response.body);
                            setAssignSensorModal(false);
                        }
                    }
                );
            }
        }
    };

    const removeSensor = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_sitesettings_measurementpointpage_errortxt'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                const updatedMp = {
                    id: mp.id,
                    description: mp.description,
                    group: null,
                    measuringElement: { uuid: null },
                    types: mp.types,
                    configOptions: mp.configOptions,
                };
                new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
                    parseInt(id, 10),
                    parseInt(mpId, 10),
                    updatedMp,
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.href = getProperRoute(
                                `groups/${groupId}/sites/${id}/settings/${gid}`
                            );
                        }
                    }
                );
            }
        });
    };

    const editMpName = (name) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            const updatedMp = {
                id: mp.id,
                description: name,
                group: null,
                measuringElement: null,
                types: mp.types,
                configOptions: mp.configOptions,
            };

            new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
                parseInt(id, 10),
                parseInt(mpId, 10),
                updatedMp,
                function (_, data, response) {
                    if (response.ok) {
                        mp.description = name;
                        setCreateMpModal(false);
                    }
                }
            );
        }
    };

    let mpStatus = t('pages_sitesettings_measurementpointpage_incomplete');
    let mpStatusColor = 'info';
    if (!isNullOrUndefined(mp?.measuringElement?.uuid)) {
        if (mp.measuringElement.status === 2) {
            mpStatus = t('pages_sitesettings_measurementpointpage_active');
            mpStatusColor = 'success';
        } else if (mp.measuringElement.status === 1) {
            mpStatus = t('pages_sitesettings_measurementpointpage_inactive');
            mpStatusColor = 'danger';
        }
    }

    const unassignedSensors = [{}];
    allsensors?.forEach((sensor) => {
        unassignedSensors.push({
            value: sensor.uuid,
            description: sensor.name,
        });
    });

    const fullGroups = getFullGroups(props.groups, props.site);

    const valueRows = [];
    mp?.measuringElement?.value?.forEach((val, i) => {
        if (
            new RegExp(/temperature\d*/gm).test(
                mp.measuringElement.valueMeasurement[i]
            )
        ) {
            const numberOfTemps = mp.measuringElement.valueMeasurement.filter(
                (v) => v.startsWith('temperature')
            ).length;
            let probeNr = parseInt(
                mp.measuringElement.valueMeasurement[i].split('temperature')[1],
                10
            );
            // if there are several temperature measurements, but there is no probe number for the
            // first one (because measurement is 'temperature' instead of 'temperature1'), the probeNr is 1
            probeNr = isNaN(probeNr) && numberOfTemps > 1 ? 1 : probeNr;
            if (!isNaN(probeNr)) {
                valueRows.push([
                    {
                        link: null,
                        text: `${t(
                            'pages_addgraph_settingstab_temperature'
                        )} - Probe ${probeNr}`,
                        value: `${t(
                            'pages_addgraph_settingstab_temperature'
                        )} - Probe ${probeNr}`,
                    },
                    {
                        link: null,
                        text: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                        value: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                    },
                ]);
            } else {
                valueRows.push([
                    {
                        link: null,
                        text: `${t('pages_addgraph_settingstab_temperature')}`,
                        value: `${t('pages_addgraph_settingstab_temperature')}`,
                    },
                    {
                        link: null,
                        text: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                        value: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                    },
                ]);
            }
        }
        switch (mp.measuringElement.valueMeasurement[i]) {
            case 'chloride':
                valueRows.push([
                    {
                        link: null,
                        text: t('pages_addgraph_settingstab_chlorine'),
                        value: t('pages_addgraph_settingstab_chlorine'),
                    },
                    {
                        link: null,
                        text: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                        value: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                    },
                ]);
                break;
            case 'flow':
            case 'flowrate':
                valueRows.push([
                    {
                        link: null,
                        text: t('pages_addgraph_settingstab_flowrate'),
                        value: t('pages_addgraph_settingstab_flowrate'),
                    },
                    {
                        link: null,
                        text: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                        value: isNullOrUndefined(val)
                            ? t('common_nodata_nodata')
                            : `${val.toFixed(2)}${
                                  mp.measuringElement.valueUnit[i]
                              }`,
                    },
                ]);
                break;
        }
    });

    // get the signal strength of the sensors
    let classes = 'signal-bars mt1 sizing-box bad one-bar';
    switch (mp?.measuringElement?.signalStrength) {
        case 1:
            classes = 'signal-bars mt1 sizing-box bad two-bars';
            break;
        case 2:
            classes = 'signal-bars mt1 sizing-box ok three-bars';
            break;
        case 3:
            classes = 'signal-bars mt1 sizing-box ok four-bars';
            break;
        case 4:
            classes = 'signal-bars mt1 sizing-box good five-bars';
            break;
        case 5:
            classes = 'signal-bars mt1 sizing-box good';
            break;
    }

    const signalStrength = (
        <div className={classes}>
            <div className="first bar" /> <div className="second bar" />
            <div className="third bar" /> <div className="fourth bar" />
            <div className="fifth bar" /> <div className="sixth bar" />
        </div>
    );
    let signalStrengthElement = [];
    if (!isNullOrUndefined(mp?.measuringElement?.signalStrength)) {
        signalStrengthElement = [
            {
                link: null,
                text: t(
                    'pages_sitesettings_measurementpointpage_signalstrength'
                ),
                value: t(
                    'pages_sitesettings_measurementpointpage_signalstrength'
                ),
            },
            { link: null, text: mpStatus, value: signalStrength },
        ];
    }

    return isNullOrUndefined(mp) || isNullOrUndefined(props.site) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: t('pagelayout_sidebarsite_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_general'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(
                                `groups/${groupId}/sites/${id}/settings/${mp.group.id}`
                            ),
                            text: mp.group?.name,
                        },
                        { link: getProperRoute('/'), text: mp.description },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem
                                onClick={() =>
                                    isNullOrUndefined(mp.measuringElement)
                                        ? setAssignSensorModal(true)
                                        : removeSensor()
                                }>
                                {isNullOrUndefined(mp.measuringElement)
                                    ? t(
                                          'pages_sitesettings_measurementpointpage_assign'
                                      )
                                    : t(
                                          'pages_sitesettings_measurementpointpage_removesensor'
                                      )}
                            </MenuItem>
                            <MenuItem onClick={() => setCreateMpModal(true)}>
                                {t(
                                    'pages_sitesettings_measurementpointpage_editmp'
                                )}
                            </MenuItem>
                            <MenuItem onClick={() => deleteMp()}>
                                {t(
                                    'pages_sitesettings_measurementpointpage_deletemp'
                                )}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_diagnostics_diagnosticspage_value'),
                ]}
                rows={[
                    [
                        { link: null, text: 'ID', value: 'ID' },
                        { link: null, text: mp.id, value: mp.id },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_devices_devicepage_name'),
                            value: t('pages_devices_devicepage_name'),
                        },
                        {
                            link: null,
                            text: mp.description,
                            value: mp.description,
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t(
                                'pages_sitesettings_sitesettingspage_sensor'
                            ),
                            value: t(
                                'pages_sitesettings_sitesettingspage_sensor'
                            ),
                        },
                        {
                            link: null,
                            text: mp.measuringElement?.name ?? '',
                            value: mp.measuringElement?.name ?? '',
                        },
                    ],
                    ...valueRows,
                    [
                        {
                            link: null,
                            text: t(
                                'pages_sitesettings_measurementpointpage_lastbatt'
                            ),
                            value: t(
                                'pages_sitesettings_measurementpointpage_lastbatt'
                            ),
                        },
                        {
                            link: null,
                            text: isNullOrUndefined(
                                mp.measuringElement?.battery
                            )
                                ? t('common_nodata_nodata')
                                : `${mp.measuringElement.battery}mV`,
                            value: `${
                                isNullOrUndefined(mp.measuringElement?.battery)
                                    ? t('common_nodata_nodata')
                                    : `${mp.measuringElement.battery}mV`
                            }`,
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t(
                                'pages_sitesettings_measurementpointpage_lastmeas'
                            ),
                            value: t(
                                'pages_sitesettings_measurementpointpage_lastmeas'
                            ),
                        },
                        {
                            link: null,
                            text: isNullOrUndefined(
                                new Date(
                                    mp.measuringElement?.lastMeasurementTime
                                )
                            )
                                ? t('common_nodata_nodata')
                                : dateToString(
                                      new Date(
                                          mp.measuringElement?.lastMeasurementTime
                                      )
                                  ),
                            value: isNullOrUndefined(
                                new Date(
                                    mp.measuringElement?.lastMeasurementTime
                                )
                            )
                                ? t('common_nodata_nodata')
                                : dateToString(
                                      new Date(
                                          mp.measuringElement?.lastMeasurementTime
                                      )
                                  ),
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_devices_devicepage_status'),
                            value: t('pages_devices_devicepage_status'),
                        },
                        {
                            link: null,
                            text: mpStatus,
                            value: (
                                <RoundedText
                                    margin="10px"
                                    color={mpStatusColor}
                                    width="80px"
                                    text={mpStatus}
                                />
                            ),
                        },
                    ],
                    signalStrengthElement,
                ]}
            />
            <AssignSensorModal
                data={unassignedSensors}
                open={assignSensorModal}
                handleClose={() => setAssignSensorModal(false)}
                confirm={assignSensor}
            />
            <CreateMpModal
                edit
                name={mp.description}
                open={createMpModal}
                handleClose={() => setCreateMpModal(false)}
                confirm={editMpName}
            />
        </>
    );
}
