import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { validateEmail, validatePassword } from '@utils/helpers/forms.helpers';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { SingleSelect, TextEdit } from '@components';

export default function CreateUserModal(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('reporter');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    const nameChanged = (txt) => {
        setName(txt);
    };

    const emailChanged = (txt) => {
        setEmail(txt);
    };

    const passwordChanged = (txt) => {
        setPassword(txt);
    };

    const roleChanged = (txt) => {
        setRole(txt);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(email) &&
            email.trim() !== '' &&
            validateEmail(email) &&
            !isNullOrUndefined(password) &&
            password.trim() !== '' &&
            validatePassword(password)
        ) {
            props.confirm(name, email, password, role);
        } else {
            if (!validateEmail(email)) {
                setErrorMsg(t('pages_users_createusermodal_bademail'));
            } else if (!validatePassword(password)) {
                setErrorMsg(t('pages_users_createusermodal_badpassword'));
            } else {
                setErrorMsg(t('pages_users_createusermodal_allfields'));
            }
        }
    };

    const onClose = () => {
        props.handleClose && props.handleClose();
        setErrorMsg(null);
        setName('');
        setEmail('');
        setPassword('');
        setRole('');
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_users_createusermodal_newuser')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_login_loginpage_username')}
                        placeholder={t('pages_users_createusermodal_user')}
                        value={name}
                        onChange={nameChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_profile_profilepage_email')}
                        type="email"
                        placeholder="example@example.com"
                        value={email}
                        onChange={emailChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_login_loginpage_password')}
                        type="password"
                        onChange={passwordChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <SingleSelect
                        title={t('pages_users_createusermodal_role')}
                        data={[
                            { value: 'reporter', description: 'Reporter' },
                            { value: 'analyst', description: 'Analyst' },
                            { value: 'maintainer', description: 'Maintainer' },
                            { value: 'admin', description: 'Admin' },
                        ]}
                        onChange={roleChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => onClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
