import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { alphabetPosition } from '@utils/helpers/text.helpers';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((props) => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            isNullOrUndefined(props.user?.email)
                ? getAvatarColor(1)
                : getAvatarColor(alphabetPosition(props.user.email[0])),
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '10px',
    },
    link: {
        color: 'inherit',
        '&:hover': {
            textDecorations: 'none',
            color: 'inherit',
        },
    },
    deleteIcon: {
        width: '18px',
        marginLeft: '10px',
        color: 'rgba(244, 82, 73, 0.5)',
        cursor: 'pointer',
        '&:hover': {
            color: 'rgba(244, 82, 73, 0.9)',
        },
    },
}));

export default function MemberEntryTable(props) {
    const styles = useStyles(props);

    const onRemove = () => {
        props.onRemove && props.onRemove(props.user?.id);
    };

    return (
        <>
            <Grid
                style={{ marginTop: '10px', marginBottom: '10px' }}
                container
                spacing={2}>
                {props.avatar ? (
                    <img
                        alt="avatar"
                        className={styles.defaultAvatar}
                        src={props.avatar}
                    />
                ) : (
                    <div className={styles.defaultAvatar}>
                        {props.user?.userName?.at(0)?.toUpperCase()}
                    </div>
                )}
                <div
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <Link className={styles.link} to={props.link ?? ''}>
                        <span style={{ marginTop: '20px', marginLeft: '20px' }}>
                            {props.user?.userName}
                            {props.remove ? (
                                <Tooltip
                                    enterDelay={200}
                                    enterNextDelay={100}
                                    placement="bottom"
                                    title="Remove this watcher from the alarm">
                                    <DeleteOutlineOutlinedIcon
                                        onClick={() => onRemove()}
                                        className={styles.deleteIcon}
                                    />
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </span>
                    </Link>
                    <span style={{ marginLeft: '20px' }}>
                        {props.user?.email}
                    </span>
                </div>
            </Grid>
        </>
    );
}
