import { BackupApi } from '@aurum/nucleus-client-api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { LinearProgressWithLine } from '@components';

const signalR = require('@microsoft/signalr');

export default function BackupGenerationModal(props) {
    const [progress, setProgress] = useState(0);
    const [backupFileName, setBackupFileName] = useState(null);
    const [signalRConnection, setSignalRConnection] = useState(null);

    const { id } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.open) {
            new BackupApi().backupStartBackupGeneration(
                parseInt(id, 10),
                function (_, data, response) {
                    if (response.ok) {
                        const connection = new signalR.HubConnectionBuilder()
                            .withUrl('/hub/backupgenerationprogress', {
                                skipNegotiation: true,
                                transport: signalR.HttpTransportType.WebSockets,
                            })
                            .build();

                        connection.on('progress', progressNotificationReceived);
                        connection.on('finished', downloadFileReceived);

                        connection
                            .start()
                            .then(() =>
                                connection.invoke('AssociateJob', response.body)
                            )
                            .catch((err) =>
                                console.error('SignalR Connection Error: ', err)
                            );

                        setSignalRConnection(connection);
                    }
                }
            );
        }

        return () => {
            signalRConnection?.stop();
        };
    }, [props.open]);

    const progressNotificationReceived = (p) => {
        setProgress(p);
    };

    const downloadFileReceived = (fileName) => {
        setProgress(100);
        setBackupFileName(fileName.split('.')[0]);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    props.handleClose && props.handleClose();
                }
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {' '}
                {t('pages_sitesettings_backupgenerationmodal_generation')}{' '}
            </DialogTitle>
            <DialogContent>
                <p>{t('pages_sitesettings_backupgenerationmodal_backuptxt')}</p>
                <LinearProgressWithLine value={progress} />
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Download"
                    type="submit"
                    disabled={isNullOrUndefined(backupFileName)}
                    onClick={() =>
                        window.open(
                            `/api/file/download/${backupFileName}`,
                            '_blank'
                        )
                    }
                    color="primary">
                    {t('pages_sitesettings_backupgenerationmodal_download')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
