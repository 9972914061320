import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((props) => ({
    ulStyle: {
        paddingLeft: 0,
        listStyle: 'none',
    },
}));

export function SideList(props) {
    const styles = useStyles(props);

    return <ul className={styles.ulStyle}>{props.children}</ul>;
}
