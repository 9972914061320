import { TemperatureAlarmApi } from '@aurum/nucleus-client-api';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';

export const deleteAlarms = (alarms, id, callback) => {
    alarms?.forEach((alarm) => {
        new TemperatureAlarmApi().temperatureAlarmRemoveAlarm(
            parseInt(id, 10),
            alarm.id,
            function (_, data, response) {
                callback && callback();
            }
        );
    });
};

export const createAlarms = (alarm, users, mps, id, callback) => {
    if (mps?.length === 0) {
        return;
    }
    new TemperatureAlarmApi().temperatureAlarmCreateAlarms(
        parseInt(id, 10),
        {
            lowerValue: alarm.lowerLimit,
            upperValue: alarm.upperLimit,
            lowerHyst: alarm.hystLower,
            upperHyst: alarm.hystUpper,
            type: alarm.type,
            name: alarm.name,
            from: alarm.from,
            to: alarm.to,
            users: users,
            measurementPoints: mps,
        },
        function (_, data, response) {
            callback && callback();
        }
    );
};

export const editAlarm = (alarms, alarm, id, onEdited) => {
    if (isNullOrUndefined(alarm)) {
        return;
    }

    if (isNullOrUndefined(alarm.mpoint)) {
        alarm.mpoint = [alarm.measurementPoint.id];
    }
    let counter = 0;
    const alarmsToEdit = alarms?.filter((a) =>
        alarm.mpoint.includes(a.measurementPoint.id)
    );
    const alarmsToDelete = alarms?.filter(
        (a) => !alarm.mpoint.includes(a.measurementPoint.id)
    );
    const addAlarmsToMps = alarm.mpoint?.filter(
        (m) => !alarms.map((a) => a.measurementPoint.id).includes(m)
    );

    let users = alarm.users;
    if (isNullOrUndefined(users)) {
        users =
            alarmsToEdit.length > 0
                ? alarmsToEdit[0].users
                : alarmsToDelete > 0
                ? alarmsToDelete[0].users
                : [];
    }
    users = users.map((u) => u.id);

    // create the missing alarms
    createAlarms(alarm, users, addAlarmsToMps, id);
    // delete alarms that are not necessary anymore
    deleteAlarms(alarmsToDelete, id);
    // edit the existing alarms
    alarmsToEdit?.forEach((alr) => {
        new TemperatureAlarmApi().temperatureAlarmUpdateAlarm(
            parseInt(id, 10),
            alr.id,
            {
                lowerValue: alarm.lowerLimit,
                upperValue: alarm.upperLimit,
                lowerHyst: alarm.hystLower,
                upperHyst: alarm.hystUpper,
                type: alarm.type,
                name: alarm.name,
                from: alarm.from,
                to: alarm.to,
                users: users,
                measurementPoints: alarm.mpoint,
            },
            function (_, data, response) {
                counter += 1;
                if (counter === alarmsToEdit.length) {
                    onEdited && onEdited();
                }
            }
        );
    });
};
