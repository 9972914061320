import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isSafari } from '@utils/helpers/browser.helpers';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const options = {
    filterType: 'textField',
    download: false,
    downloadOptions: {
        filename: 'table.csv',
        separator: ',',
        filterOptions: {
            useDisplayedColumnsOnly: false,
            useDisplayedRowsOnly: false,
        },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    onRowsDelete: () => {
        return false;
    },
    search: false,
    draggableColumns: { enabled: true },
    elevation: 0,
    fixedHeader: false,
    jumpToPage: false,
    pagination: false,
    rowsPerPage: 10,
    rowHover: false,
    searchPlaceholder: 'Search:',
    textLabels: {},
};

export function CustomTable(props) {
    const { t } = useTranslation();

    const onDownload = (buildHead, buildBody, columns, data) => {
        // try to replace objects within the data fields
        data.forEach((el, idx1) => {
            el.data.forEach((prop, idx2) => {
                if (typeof prop === 'object') {
                    if (isNullOrUndefined(props?.props?.text)) {
                        data[idx].data[idx2] = '';
                    } else {
                        data[idx1].data[idx2] = prop.props.text;
                    }
                }
            });
        });
        return buildHead(columns) + buildBody(data);
    };

    options['onDownload'] = onDownload;

    if (props.download) {
        options['download'] = true;
    }
    if (props.filter) {
        options['filter'] = true;
    }
    if (props.jumpToPage) {
        options['jumpToPage'] = true;
    }
    if (props.pagination) {
        options['pagination'] = true;
    }
    if (props.print) {
        // in Safari, the print functionality doesn't work well
        if (!isSafari) {
            options['print'] = true;
        }
    }
    if (props.search) {
        options['search'] = true;
    }
    if (
        props.rowsPerPage !== undefined &&
        Number.isInteger(props.rowsPerPage)
    ) {
        options['rowsPerPage'] = props.rowsPerPage;
    }
    if (props.selectable) {
        options['selectableRows'] = 'multiple';
    }
    if (props.onRowsDelete) {
        options['onRowsDelete'] = props.onRowsDelete;
    }
    if (props.onFilterChange) {
        options['onFilterChange'] = props.onFilterChange;
    }
    if (!isNullOrUndefined(props.title)) {
        options.downloadOptions.filename = `${props.title}.csv`;
    }
    if (!isNullOrUndefined(props.hints)) {
        for (const [key, value] of Object.entries(props.hints)) {
            let col = props.headers.find((x) => x.name === key);
            if (!isNullOrUndefined(col)) {
                if (isNullOrUndefined(col.options)) {
                    col.options = {};
                }
                col.options.hint = value;
            }
        }
    }

    options['textLabels'] = {
        body: {
            noMatch: t('Table_Body_NoMatch'),
            toolTip: t('Table_Body_Tooltip'),
        },
        pagination: {
            next: t('Table_Pagination_Next'),
            previous: t('Table_Pagination_Previous'),
            rowsPerPage: t('Table_Pagination_RowsPerPage'),
            displayRows: t('Table_Pagination_DisplayRows'),
            jumpToPage: t('Table_Pagination_JumpToPage'),
        },
        toolbar: {
            search: t('Table_Toolbar_Search'),
            downloadCsv: t('Table_Toolbar_Downloadcsv'),
            print: t('Table_Toolbar_Print'),
            viewColumns: t('Table_Toolbar_ViewCols'),
            filterTable: t('Table_Toolbar_Filter'),
        },
        filter: {
            all: t('Table_Filter_All'),
            title: t('Table_Filter_Title'),
            reset: t('Table_Filter_Reset'),
        },
        viewColumns: {
            title: t('Table_Viewcols_Title'),
            titleAria: t('Table_Viewcols_Titlearia'),
        },
        selectedRows: {
            text: t('Table_Selectedrows_Text'),
            delete: t('Table_Selectedrows_Delete'),
            deleteAria: t('Table_Selectedrows_Deletearia'),
        },
    };

    const tableData = [];
    props.data?.forEach((dt) => {
        tableData.push({ name: dt.name, value: dt.datapoints?.at(-1)?.y });
    });

    return (
        <MUIDataTable
            title={props.title}
            data={tableData}
            columns={props.headers}
            options={options}
        />
    );
}
