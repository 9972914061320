import { GroupApi } from '@aurum/nucleus-client-api';
import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import { CustomBreadcrumbs, FilesTable } from '@components';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import DiagnosticsGatewayEvents from './DiagnosticGatewayEvents';
import DiagnosticGatewayGraph from './DiagnosticGatewayGraphs';

export default function DiagnosticsGatewayPage(props) {
    const [gateway, setGateway] = useState([]);
    const [group, setGroup] = useState(null);
    const [showPage, setShowPage] = useState(false);
    const { id } = useParams();

    const { t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.user)) {
            if (!userIsAtLeast(props.user, 'maintainer')) {
                window.location.href = getProperRoute(`sites`);
                return;
            }
            setShowPage(true);
            new GatewayApi().gatewayGetGateway(
                id,
                function (_, data, response) {
                    if (response.ok) {
                        if (isNullOrUndefined(data.uuid)) {
                            window.location.href =
                                getProperRoute(`diagnostics`);
                        }
                        if (!isNullOrUndefined(data.groupId)) {
                            new GroupApi().groupGetGroup(
                                data.groupId,
                                function (_, d, r) {
                                    if (r.ok) {
                                        setGroup(d);
                                    }
                                }
                            );
                        }
                        setGateway(data);
                    } else {
                        window.location.href = getProperRoute(`diagnostics`);
                    }
                }
            );
        }
    }, [props.user]);

    const gwRows = [
        [
            {
                link: null,
                text: gateway?.uuid,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        UUID: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {gateway?.uuid}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: gateway?.description,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_acid')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {gateway?.description}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: gateway?.name,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_name')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {gateway?.name}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: gateway?.connected,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_connected')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {gateway?.connected
                                ? t(
                                      'pages_diagnostics_diagnosticsgatewaypage_connected'
                                  )
                                : t(
                                      'pages_diagnostics_diagnosticsgatewaypage_disconnected'
                                  )}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: gateway?.upTime ?? '-',
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_uptime')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {gateway?.upTime ?? '-'}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text: isNullOrUndefined(gateway?.groupId) ? '-' : group?.name,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_assignedto')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(gateway?.groupId) ? (
                                '-'
                            ) : (
                                <Link
                                    to={getProperRoute(
                                        `groups/${gateway.groupId}/settings`
                                    )}>
                                    {group?.name}
                                </Link>
                            )}
                        </p>
                    </div>
                ),
            },
        ],
        [
            {
                link: null,
                text:
                    isNullOrUndefined(gateway?.site) || gateway?.site?.id === -1
                        ? '-'
                        : gateway.site.name,
                value: (
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}>
                        {t('pages_devices_devicepage_project')}: &nbsp;
                        <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                            {isNullOrUndefined(gateway?.site) ||
                            gateway?.site?.id === -1 ? (
                                '-'
                            ) : (
                                <Link
                                    to={getProperRoute(
                                        `groups/${gateway.site.groupId}/sites/${gateway.site.id}/settings`
                                    )}>
                                    {gateway.site.name}
                                </Link>
                            )}
                        </p>
                    </div>
                ),
            },
        ],
    ];

    const statusText = {
        WLAN: 'WLAN',
        ETH: 'ETH',
        memTotal: t('pages_diagnostics_diagnosticsgatewaypage_totalmemory'),
        Reboots: t('pages_diagnostics_diagnosticsgatewaypage_reboots'),
        memFree: t('pages_diagnostics_diagnosticsgatewaypage_freemem'),
        cpuLoad: t('pages_diagnostics_diagnosticsgatewaypage_cpuload'),
        VpnActiveSeconds: t(
            'pages_diagnostics_diagnosticsgatewaypage_vpnactive'
        ),
        VpnIpAddress: 'VPN IP',
        vpnStatus: 'VPN Status',
    };
    const statusRows = [];
    if (!isNullOrUndefined(gateway?.gatewayStatus)) {
        Object.entries(gateway.gatewayStatus)?.forEach((e) => {
            statusRows.push([
                {
                    link: null,
                    text: `${statusText[e[0]]}: ${e[1]}`,
                    value: `${statusText[e[0]]}: ${e[1]}`,
                },
            ]);
        });
    }

    return showPage ? (
        <div style={{ width: '100%', marginBottom: '30px' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute(`diagnostics`),
                            text: 'Admin Area',
                        },
                        {
                            link: getProperRoute(`diagnostics`),
                            text: t(
                                'pages_diagnostics_diagnosticsgatewaypage_diagnostics'
                            ),
                        },
                        {
                            link: getProperRoute('/'),
                            text: gateway?.description,
                        },
                    ]}
                />
            </div>
            <hr />
            <br />
            <div className="row">
                <div className="col-md-6">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticsgatewaypage_properties'
                                ),
                            ]}
                            rows={gwRows}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticsgatewaypage_status'
                                ),
                            ]}
                            rows={statusRows}
                        />
                    </div>
                </div>
            </div>
            <DiagnosticGatewayGraph user={props.user} />
            <DiagnosticsGatewayEvents user={props.user} />
        </div>
    ) : (
        <></>
    );
}
