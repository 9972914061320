import { CustomBreadcrumbs } from '@components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './graphstyles.css';

export function TCGraphContainerContent(props) {
    const [curCirculation, setCurCirculation] = useState(0);
    const [structure, setStructure] = useState([]);
    const [entriesToBeDisp, setEntriesToBeDisp] = useState([]);
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        setCurCirculation(props.curCirculation ?? 0);
    }, [props.curCirculation]);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const breadcrumbsClicked = () => {
        setEntriesToBeDisp(props.circulations);
        setOpen(true);
    };

    const onItemClick = (id) => {
        setCurCirculation(id);
        setStructure([...structure, id]);
        setOpen(false);
        props.onItemClick && props.onItemClick(id);
    };

    const onGroupClick = (id) => {
        const newStructure = [];
        if (id !== 0) {
            for (let item of structure) {
                if (item !== id) {
                    newStructure.push(item);
                } else {
                    newStructure.push(item);
                    break;
                }
            }
        }
        setStructure(newStructure);
        setCurCirculation(id);
        props.onGroupClick && props.onGroupClick(id);
    };

    const bc = [
        {
            text: 'Circulations',
        },
    ];
    const currentCircu = props.circulations?.find(
        (c) => c.id === curCirculation
    );
    if (!isNullOrUndefined(currentCircu)) {
        bc.push({
            onClick: onGroupClick,
            id: currentCircu.id,
            text: currentCircu.name,
        });
    }

    const menuItems = [];
    entriesToBeDisp?.forEach((item) => {
        menuItems.push(
            <MenuItem
                key={makeRandomStr()}
                onClick={() => onItemClick(item.id)}>
                {item.name}
            </MenuItem>
        );
    });

    return (
        <>
            <CustomBreadcrumbs
                ref={anchorRef}
                addmore={true}
                onAddClick={() => breadcrumbsClicked}
                breadcrumbs={bc}
            />
            <Popper
                style={{ zIndex: 999999 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow">
                                    {menuItems}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <br />
        </>
    );
}
