import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import React from 'react';

export function MenuDropdown(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleHover = (event) => {
        if (props.hover) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onMouseEnter={handleHover}
                onClick={handleClick}>
                <MoreVertIcon
                    style={{ width: '16px', height: '16px', color: 'black' }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}>
                {props.options.map((option) => (
                    <MenuItem
                        key={makeRandomStr()}
                        onClick={
                            isNullOrUndefined(option.props?.clickable) ||
                            option.props?.clickable
                                ? handleClose
                                : () => {}
                        }>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
