import { SiteApi } from '@aurum/nucleus-client-api';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import GroupTreeStructure from '@views/groups/group-page/GroupTreeStructure';
import MoveProjectsModal from '@views/groups/projects/move-projects/MoveProjectsModal';
import CreateSiteModal from '@views/sites/create-site/CreateSiteModal';
import SiteTableEntry from '@views/sites/site-entry/SiteTableEntry';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function GroupProjectsPage(props) {
    const [group, setGroup] = useState(null);
    const [moveProjects, setMoveProjects] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [triggerDeletion, setTriggerDeletion] = useState(0);
    const { groupId } = useParams();
    const [modalOpen, setModalOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (triggerDeletion > 0) {
            confirmDeleteProjects();
        }
    }, [triggerDeletion]);

    const confirmProjectCreation = (name, description, type) => {
        if (
            !isNullOrUndefined(group) &&
            !isNullOrUndefined(name) &&
            name.trim() !== ''
        ) {
            new SiteApi().siteAddSite(
                group.id,
                { name: name, description: description, type: type },
                {},
                function (_, data, response) {
                    if (response.ok) {
                        group?.sites?.push(data);
                        setModalOpen(false);
                    }
                }
            );
        }
    };

    const confirmMoveProjects = (newId) => {
        let totalMoved = 0;
        selectedProjects?.forEach((project) => {
            const oldGroupId = project.groupId;
            project.groupId = newId;
            new SiteApi().siteUpdateSite(
                oldGroupId,
                project.id,
                project,
                function (_, data, response) {
                    totalMoved += 1;
                    if (totalMoved === selectedProjects.length) {
                        onProjectsMoved();
                    }
                }
            );
        });
    };

    const confirmDeleteProjects = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupprojectspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult && selectedProjects.length > 0) {
                let totalDeleted = 0;
                selectedProjects?.forEach((project) => {
                    new SiteApi().siteDeleteSite(
                        project.groupId,
                        project.id,
                        function (_, data, response) {
                            totalDeleted += 1;
                            if (totalDeleted === selectedProjects.length) {
                                onProjectsDeleted();
                            }
                        }
                    );
                });
            }
        });
    };

    const onProjectsDeleted = () => {
        window.location.reload();
    };

    const onProjectsMoved = () => {
        window.location.reload();
    };

    const onCreateProject = (gr) => {
        setModalOpen(true);
        setGroup(gr);
    };

    const onMoveProjects = (projects) => {
        setMoveProjects(true);
        setSelectedProjects(projects);
    };

    const onDeleteProjects = (projects) => {
        setSelectedProjects(projects);
        setTriggerDeletion(triggerDeletion + 1);
    };

    const recursive = (levelGroups, total, items) => {
        if (isNullOrUndefined(levelGroups) || levelGroups?.length === 0) {
            return '';
        }
        let newTotal = total;
        levelGroups?.forEach((lg) => {
            newTotal = total + ' / ' + lg.name;
            items.push({ value: lg.id, description: newTotal });
            recursive(
                props.groups.filter((g) => g.parent?.id === lg.id),
                newTotal,
                items
            );
        });
    };

    const itemsToBeMoved = [];
    let levelGroups = props.groups?.filter((g) =>
        isNullOrUndefined(g.parent?.id)
    );
    let total = '';
    recursive(levelGroups, total, itemsToBeMoved);

    const rows = [];
    props.groups
        ?.find((g) => g.id === 1)
        ?.sites?.forEach((site) => {
            rows.push([
                {
                    value: (
                        <SiteTableEntry
                            link={getProperRoute(
                                `groups/${groupId}/sites/${site.id}/settings`
                            )}
                            site={site}
                        />
                    ),
                },
            ]);
        });

    return (
        <>
            <GroupTreeStructure
                user={props.user}
                groups={props.groups}
                projects
                createProject={(gr) => onCreateProject(gr)}
                moveProjects={(projects) => onMoveProjects(projects)}
                deleteProjects={(projects) => onDeleteProjects(projects)}
            />
            <CreateSiteModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                confirm={confirmProjectCreation}
            />
            <MoveProjectsModal
                data={itemsToBeMoved}
                open={moveProjects}
                handleClose={() => setMoveProjects(false)}
                confirm={confirmMoveProjects}
            />
        </>
    );
}
