import { colors, graphColors } from '@utils/constants/colors.constants';

export function hexToRgba(hex, a = 1) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return (
            'rgba(' +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
            ',' +
            a +
            ')'
        );
    }
    throw new Error('Bad Hex');
}

export function getAvatarColor(id, alpha) {
    if (typeof id === 'string') {
        return hexToRgba(
            colors[parseInt(id, 10) % colors.length],
            alpha ?? 0.3
        );
    } else if (typeof id === 'number') {
        return hexToRgba(colors[id % colors.length], alpha ?? 0.3);
    } else {
        throw new Error(
            'getAvatarColor: Provided parameter must be string or number'
        );
    }
}

export function getGraphColor(id, alpha) {
    if (typeof id === 'string') {
        return hexToRgba(
            graphColors[parseInt(id, 10) % graphColors.length],
            alpha ?? 0.3
        );
    } else if (typeof id === 'number') {
        return hexToRgba(graphColors[id % graphColors.length], alpha ?? 0.3);
    } else {
        throw new Error(
            'getAvatarColor: Provided parameter must be string or number'
        );
    }
}
