import { AccountApi } from '@aurum/nucleus-client-api';
import { TextEdit } from '@components';
import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { Lock, Person } from '@material-ui/icons';
import {
    getPathToLogos,
    getProperRoute,
    isNullOrUndefined,
} from '@utils/helpers/app.helpers';
import { msToTime } from '@utils/helpers/timestamp.helpers';
import ForgotPasswordModal from '@views/login/password-forgot/ForgotPasswordModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CookieConsentProvider } from '@views/cookies/CookieConsentContext';
import { CookieConsentBanner } from '@views/cookies/CookieConsentBanner';

const useStyles = makeStyles((theme, props) => ({
    paper: {
        margin: 'auto',
        width: (props) => (props.isMobile ? '80%' : '60%'),
        marginTop: '200px',
    },
    iconContainer: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        overflow: 'hidden',
        float: 'left',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '35px',
    },
    textContainer: {
        minWidth: 0,
        alignItems: 'center',
        flex: '1 1 auto',
        display: 'flex',
    },
    infoContainer: {
        flexBasis: '100%',
        minWidth: 0,
        flexShrink: 1,
        flexGrow: 1,
        display: 'block',
    },
}));

export default function LoginPage(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1100px)' });

    const styles = useStyles({ isMobile: isMobile || isTablet });
    const { t } = useTranslation();

    useEffect(() => {
        window.history.replaceState(null, '', getProperRoute('login'));
    }, []);

    const login = () => {
        if (
            !isNullOrUndefined(email) &&
            email.trim() !== '' &&
            !isNullOrUndefined(password) &&
            password.trim() !== ''
        ) {
            new AccountApi().accountLogin(
                { user: email, password: password },
                function (_, data, response) {
                    if (response.ok) {
                        window.location.href = getProperRoute('sites');
                    } else {
                        if (!isNullOrUndefined(response.body?.id)) {
                            // show attempts left before lockout (or lock out account)
                            if (response.body.locked) {
                                const diffTime = Math.abs(
                                    new Date(response.body.lockedUntil) -
                                        new Date()
                                );
                                const diffDays = Math.ceil(
                                    diffTime / (1000 * 60 * 60 * 24)
                                );
                                if (diffDays > 365) {
                                    // user locked forever
                                    setErrorText(
                                        'Unable to log in because your account has been locked out. Please, contact your administrator.'
                                    );
                                } else {
                                    setErrorText(
                                        'User locked out. Ends in ' +
                                            msToTime(diffTime)
                                    );
                                }
                            } else {
                                setErrorText(
                                    t('pages_login_loginpage_baddata') +
                                        '. Attempts before lock out: ' +
                                        (response.body.maxFailedAccessAttempts -
                                            response.body.accessFailedCount)
                                );
                            }
                        } else {
                            setErrorText(t('pages_login_loginpage_baddata'));
                        }
                        const interval = setInterval(() => {
                            setErrorText('');
                            clearInterval(interval);
                        }, 5000);
                    }
                }
            );
        } else {
            setErrorText(t('pages_login_loginpage_required'));
            const interval = setInterval(() => {
                setErrorText('');
                clearInterval(interval);
            }, 3000);
        }
    };

    const emailChanged = (txt) => {
        setEmail(txt);
    };

    const passwordChanged = (txt) => {
        setPassword(txt);
    };

    const onKeyPressed = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    return (
        <>
            <CookieConsentProvider>
                <Paper
                    className={styles.paper}
                    onKeyDown={(e) => onKeyPressed(e)}>
                    <img
                        style={{
                            width: isMobile ? '80%' : isTablet ? '60%' : '40%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            paddingTop: '30px',
                        }}
                        src={getProperRoute(`${getPathToLogos()}/logo.png`)}
                        alt="logo"
                    />
                    <br />
                    <p
                        style={{
                            color: 'red',
                            fontStyle: 'bold',
                            textAlign: 'center',
                        }}>
                        {' '}
                        {errorText}{' '}
                    </p>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <div className={styles.iconContainer}>
                            <Person />
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.infoContainer}>
                                <TextEdit
                                    onChange={emailChanged}
                                    width="98%"
                                    label={t('pages_login_loginpage_username')}
                                    placeholder="example@example.com"
                                    type="email"
                                    autoFocus
                                    required
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '30px' }}>
                        <div className={styles.iconContainer}>
                            <Lock />
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.infoContainer}>
                                <TextEdit
                                    onChange={passwordChanged}
                                    width="98%"
                                    label={t('pages_login_loginpage_password')}
                                    type="password"
                                    required
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ marginTop: '20px' }}>
                        <Grid item />
                        <Grid item>
                            <Button
                                aria-label="Forgot"
                                disableFocusRipple
                                disableRipple
                                style={{
                                    textTransform: 'none',
                                    outline: 'none',
                                    background: 'transparent',
                                }}
                                variant="text"
                                color="primary"
                                onClick={() => setForgotPasswordModal(true)}>
                                {t('pages_login_loginpage_forgot')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        style={{ marginTop: '10px' }}>
                        <Button
                            aria-label="Login"
                            variant="outlined"
                            color="primary"
                            style={{ textTransform: 'none' }}
                            onClick={() => login()}>
                            {t('pages_login_loginpage_login')}
                        </Button>
                    </Grid>
                </Paper>
                <ForgotPasswordModal
                    open={forgotPasswordModal}
                    handleClose={() => setForgotPasswordModal(false)}
                />
                <CookieConsentBanner />
            </CookieConsentProvider>
        </>
    );
}
