import { FilterApi } from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import {
    getFullGroups,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function AlertsSitePage(props) {
    const [alerts, setAlerts] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const { groupId, id } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        new FilterApi().filterGetErrorCodeAlerts(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setAlerts(data);
                }
            }
        );
    }, [id]);

    useEffect(() => {
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/alerts`,
            `groups/${groupId}/sites/filter/${id}/alerts`
        );
    }, [props]);

    const selectedRow = (selected, type, id) => {
        if (id === -1) {
            setNumSelected(selected ? alerts.length : 0);
        } else {
            setNumSelected(selected ? numSelected + 1 : numSelected - 1);
        }
        if (selected) {
            if (id === -1) {
                setSelectedAlerts([...alerts]);
            } else {
                const alert = alerts?.find((a) => a.errorCode === id);
                if (!isNullOrUndefined(alert)) {
                    setSelectedAlerts([...selectedAlerts, alert]);
                }
            }
        } else {
            id === -1
                ? setSelectedAlerts([])
                : setSelectedAlerts(
                      selectedAlerts.filter((a) => a.errorCode !== id)
                  );
        }
    };

    const fullGroups = getFullGroups(props.groups, props.site);

    const rows = [];
    // sort alerts by timestamp. Last alerts will be shown first
    alerts.sort((a, b) => b.timestamp - a.timestamp);
    alerts.forEach((alert) => {
        rows.push([
            {
                addCheckbox: true,
                id: alert.errorCode,
                checked: !isNullOrUndefined(
                    selectedAlerts?.find((s) => s.errorCode === alert.errorCode)
                ),
                text: dateToString(alert.timestamp, props.user?.timeZone),
                value: dateToString(alert.timestamp, props.user?.timeZone),
            },
            {
                text: alert.description,
                value: alert.description,
            },
            {
                text: alert.errorCode,
                value: alert.errorCode,
            },
        ]);
    });

    return isNullOrUndefined(props.site) ? (
        <></>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: `${props.site.name}`,
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_alerts_alerts'),
                    },
                ]}
            />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_alerts_alerts')}
                </span>
                <MoreSettingsMenu
                    disabled={
                        !userIsAtLeast(props.user, 'analyst')
                    }></MoreSettingsMenu>
            </div>
            <FilesTable
                responsive
                pagination
                onCheckbox={(selected, type, id) =>
                    selectedRow(selected, type, id)
                }
                columns={[
                    t('pages_alerts_timestamp'),
                    t('pages_sitesettings_createsitemainpagemodal_descr'),
                    t('pages_alerts_errorcode'),
                ]}
                rows={rows}
            />
        </>
    );
}
