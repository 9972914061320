import { customTheme } from '@/App';
import { FilterApi, UpdateParameterDTO } from '@aurum/nucleus-client-api';
import MomentUtils from '@date-io/moment';
import { Button, Checkbox, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { getProperRoute, userIsAtLeast } from '@utils/helpers/app.helpers';
import { hexToRgba } from '@utils/helpers/colors.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import './pbiStyling.css';

const useStyles = makeStyles(() => ({
    colorRed: { color: 'red' },
    colorBlack: { color: 'black' },
}));

const checkBoxStyles = makeStyles((theme) => ({
    root: {
        '&$checked': {
            color: customTheme.palette.primary.main,
            '&:hover': {
                backgroundColor: hexToRgba(
                    customTheme.palette.primary.main,
                    0.1
                ),
            },
        },
        '&:hover': {
            backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.1),
        },
    },
    checked: {},
}));

const createDefaultDateParam = () => {
    const val = new Date();
    return { value: val, updatedValue: val };
};

export function PbiSettingsScreen(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [forwardDuration, setForwardDuration] = useState(null);
    const [backwardDuration, setBackwardDuration] = useState(null);
    const [flushDelay, setFlushDelay] = useState(null);
    const [integrityDelay, setIntegrityDelay] = useState(null);
    const [intervalMin, setIntervalMin] = useState(null);
    const [currentDateTime, setCurrentDateTime] = useState(
        createDefaultDateParam()
    );
    const [integrityTime, setIntegrityTime] = useState(
        createDefaultDateParam()
    );
    const [flushTime1, setFlushTime1] = useState(createDefaultDateParam());
    const [flushTime2, setFlushTime2] = useState(createDefaultDateParam());
    const [flushTime3, setFlushTime3] = useState(createDefaultDateParam());
    const [flushTime4, setFlushTime4] = useState(createDefaultDateParam());
    const [flushTime5, setFlushTime5] = useState(createDefaultDateParam());
    const [flushTime6, setFlushTime6] = useState(createDefaultDateParam());
    const [flushTime7, setFlushTime7] = useState(createDefaultDateParam());
    const [flushTime8, setFlushTime8] = useState(createDefaultDateParam());
    const [flushTime1Active, setFlushTime1Active] = useState(null);
    const [flushTime2Active, setFlushTime2Active] = useState(null);
    const [flushTime3Active, setFlushTime3Active] = useState(null);
    const [flushTime4Active, setFlushTime4Active] = useState(null);
    const [flushTime5Active, setFlushTime5Active] = useState(null);
    const [flushTime6Active, setFlushTime6Active] = useState(null);
    const [flushTime7Active, setFlushTime7Active] = useState(null);
    const [flushTime8Active, setFlushTime8Active] = useState(null);

    const { height } = props;
    const backIcon = 'filter/return.png';
    const styles = useStyles();
    const cbstyles = checkBoxStyles();

    useEffect(() => {
        if (props.parameters?.length > 0) {
            setForwardDuration(getParameter('FF_TIME'));
            setBackwardDuration(getParameter('BW_TIME'));
            setFlushDelay(getParameter('FL_INT'));
            setIntegrityDelay(getParameter('IN_INT'));
            setIntervalMin(getParameter('SWITCH_FREQ'));
            setFlushTime1Active(getParameter('SWITCH_FLUSH1'));
            setFlushTime2Active(getParameter('SWITCH_FLUSH2'));
            setFlushTime3Active(getParameter('SWITCH_FLUSH3'));
            setFlushTime4Active(getParameter('SWITCH_FLUSH4'));
            setFlushTime5Active(getParameter('SWITCH_FLUSH5'));
            setFlushTime6Active(getParameter('SWITCH_FLUSH6'));
            setFlushTime7Active(getParameter('SWITCH_FLUSH7'));
            setFlushTime8Active(getParameter('SWITCH_FLUSH8'));
            initializeFullDate(
                currentDateTime,
                getParameterValue('RealKlok_Jaar') + 2000,
                getParameterValue('RealKlok_Maand') - 1,
                getParameterValue('RealKlok_Dag'),
                getParameterValue('RealKlok_Uur'),
                getParameterValue('RealKlok_Minuut')
            );
            initializeFullTime(
                integrityTime,
                getParameterValue('Tijd_Integriteit_uur'),
                getParameterValue('Tijd_Integriteit_min')
            );
            initializeFullTime(
                flushTime1,
                getParameterValue('Freq_Spoel_Tijd_Uur_1'),
                getParameterValue('Freq_Spoel_Tijd_Min_1')
            );
            initializeFullTime(
                flushTime2,
                getParameterValue('Freq_Spoel_Tijd_Uur_2'),
                getParameterValue('Freq_Spoel_Tijd_Min_2')
            );
            initializeFullTime(
                flushTime3,
                getParameterValue('Freq_Spoel_Tijd_Uur_3'),
                getParameterValue('Freq_Spoel_Tijd_Min_3')
            );
            initializeFullTime(
                flushTime4,
                getParameterValue('Freq_Spoel_Tijd_Uur_4'),
                getParameterValue('Freq_Spoel_Tijd_Min_4')
            );
            initializeFullTime(
                flushTime5,
                getParameterValue('Freq_Spoel_Tijd_Uur_5'),
                getParameterValue('Freq_Spoel_Tijd_Min_5')
            );
            initializeFullTime(
                flushTime6,
                getParameterValue('Freq_Spoel_Tijd_Uur_6'),
                getParameterValue('Freq_Spoel_Tijd_Min_6')
            );
            initializeFullTime(
                flushTime7,
                getParameterValue('Freq_Spoel_Tijd_Uur_7'),
                getParameterValue('Freq_Spoel_Tijd_Min_7')
            );
            initializeFullTime(
                flushTime8,
                getParameterValue('Freq_Spoel_Tijd_Uur_8'),
                getParameterValue('Freq_Spoel_Tijd_Min_8')
            );
        }
    }, [props.parameters]);

    const getParameter = (paramName) => {
        const theParameter = props.parameters?.find(
            (p) => p.name === paramName
        );
        return { ...theParameter, updatedValue: theParameter?.value ?? 0 };
    };

    const initializeFullDate = (param, year, month, date, hours, minutes) => {
        param.value.setFullYear(year);
        param.updatedValue.setFullYear(year);
        param.value.setMonth(month);
        param.updatedValue.setMonth(month);
        param.value.setDate(date);
        param.updatedValue.setDate(date);
        param.value.setHours(hours);
        param.updatedValue.setHours(hours);
        param.value.setMinutes(minutes);
        param.updatedValue.setMinutes(minutes);
    };

    const initializeFullTime = (param, hours, minutes) => {
        param.value.setHours(hours);
        param.updatedValue.setHours(hours);
        param.value.setMinutes(minutes);
        param.updatedValue.setMinutes(minutes);
    };

    const getParameterValue = (paramName) => {
        return getParameter(paramName)?.value ?? '';
    };

    const goBack = () => {
        props.triggerChange && props.triggerChange(1);
    };

    const forwardDurationChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let fwdDuration = { ...forwardDuration };
        fwdDuration.updatedValue = parseAndGetValue(
            e.target.value,
            forwardDuration
        );
        setForwardDuration(fwdDuration);
    };

    const backwardDurationChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let bwDuration = { ...backwardDuration };
        bwDuration.updatedValue = parseAndGetValue(
            e.target.value,
            backwardDuration
        );
        setBackwardDuration(bwDuration);
    };

    const flushDelayChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let fDelay = { ...flushDelay };
        fDelay.updatedValue = parseAndGetValue(e.target.value, flushDelay);
        setFlushDelay(fDelay);
    };

    const integrityDelayChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let iDelay = { ...integrityDelay };
        iDelay.updatedValue = parseAndGetValue(e.target.value, integrityDelay);
        setIntegrityDelay(iDelay);
    };

    const intervalMinChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let iMin = { ...intervalMin };
        iMin.updatedValue = parseAndGetValue(e.target.value, intervalMin);
        setIntervalMin(iMin);
    };

    const checkboxChanged = (e, param, paramChangeFunction) => {
        const checked = e.target.checked;
        let updatedParam = { ...param };
        updatedParam.updatedValue = checked ? 1 : 0;
        paramChangeFunction && paramChangeFunction(updatedParam);
    };

    const parseAndGetValue = (val, param) => {
        let value = val === '' ? 0 : parseInt(val, 10);
        if (value > param.maxValue) {
            value = param.maxValue;
        }
        if (value < param.minValue) {
            value = param.minValue;
        }
        return value;
    };

    const onSaveChanges = () => {
        if (!userIsAtLeast(props.user, 'maintainer')) return;
        triggerStartEndSavingChanges();
    };

    const triggerStartEndSavingChanges = (start = true) => {
        const params = [];
        const xSetClockParam = new UpdateParameterDTO();
        xSetClockParam.name = 'xSetClock';
        xSetClockParam.value = start ? 1 : 0;
        params.push(xSetClockParam);
        // save the changes
        new FilterApi().filterUpdateParameterValues(
            parseInt(id, 10),
            params,
            function (_, data, response) {
                if (response.ok) {
                    if (start) {
                        triggerSaveChangesApi();
                    } else {
                        props.forceParamUpdate && props.forceParamUpdate();
                    }
                }
            }
        );
    };

    const triggerSaveChangesApi = () => {
        const paramsToUpdate = [];
        checkAndAddParamIfUpdated(paramsToUpdate, forwardDuration);
        checkAndAddParamIfUpdated(paramsToUpdate, backwardDuration);
        checkAndAddParamIfUpdated(paramsToUpdate, flushDelay);
        checkAndAddParamIfUpdated(paramsToUpdate, integrityDelay);
        checkAndAddParamIfUpdated(paramsToUpdate, intervalMin);
        checkAndAddCurrentDateTime(paramsToUpdate);
        checkAndAddTimeParamIfUpdated(
            paramsToUpdate,
            integrityTime,
            'Tijd_Integriteit_uur',
            'Tijd_Integriteit_min'
        );
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime1Active);
        if (flushTime1Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime1,
                'Freq_Spoel_Tijd_Uur_1',
                'Freq_Spoel_Tijd_Min_1'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime2Active);
        if (flushTime2Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime2,
                'Freq_Spoel_Tijd_Uur_2',
                'Freq_Spoel_Tijd_Min_2'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime3Active);
        if (flushTime3Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime3,
                'Freq_Spoel_Tijd_Uur_3',
                'Freq_Spoel_Tijd_Min_3'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime4Active);
        if (flushTime4Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime4,
                'Freq_Spoel_Tijd_Uur_4',
                'Freq_Spoel_Tijd_Min_4'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime5Active);
        if (flushTime5Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime5,
                'Freq_Spoel_Tijd_Uur_5',
                'Freq_Spoel_Tijd_Min_5'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime6Active);
        if (flushTime6Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime6,
                'Freq_Spoel_Tijd_Uur_6',
                'Freq_Spoel_Tijd_Min_6'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime7Active);
        if (flushTime7Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime7,
                'Freq_Spoel_Tijd_Uur_7',
                'Freq_Spoel_Tijd_Min_7'
            );
        }
        checkAndAddParamIfUpdated(paramsToUpdate, flushTime8Active);
        if (flushTime8Active.updatedValue === 1) {
            checkAndAddTimeParamIfUpdated(
                paramsToUpdate,
                flushTime8,
                'Freq_Spoel_Tijd_Uur_8',
                'Freq_Spoel_Tijd_Min_8'
            );
        }

        new FilterApi().filterUpdateParameterValues(
            parseInt(id, 10),
            paramsToUpdate,
            function (_, data, response) {
                if (response.ok) {
                    triggerStartEndSavingChanges(false);
                }
            }
        );
    };

    const checkAndAddParamIfUpdated = (paramsList, param) => {
        const paramObj = new UpdateParameterDTO();
        if (param?.value !== param?.updatedValue) {
            paramObj.name = param.name;
            paramObj.value = param.updatedValue;
            paramsList.push(paramObj);
        }
    };

    const checkAndAddTimeParamIfUpdated = (
        paramsList,
        param,
        hourName,
        minName
    ) => {
        if (param.value.getHours() !== param.updatedValue.getHours()) {
            const paramObj = new UpdateParameterDTO();
            paramObj.name = hourName;
            paramObj.value = param.updatedValue.getHours();
            paramsList.push(paramObj);
        }
        if (param.value.getMinutes() !== param.updatedValue.getMinutes()) {
            const paramObj = new UpdateParameterDTO();
            paramObj.name = minName;
            paramObj.value = param.updatedValue.getMinutes();
            paramsList.push(paramObj);
        }
    };

    const checkAndAddCurrentDateTime = (paramsList) => {
        if (
            currentDateTime.value.getFullYear() !==
            currentDateTime.updatedValue.getFullYear()
        ) {
            const paramObj = new UpdateParameterDTO();
            paramObj.name = 'RealKlok_Jaar';
            paramObj.value = currentDateTime.updatedValue.getFullYear() - 2000;
            paramsList.push(paramObj);
        }
        if (
            currentDateTime.value.getMonth() !==
            currentDateTime.updatedValue.getMonth()
        ) {
            const paramObj = new UpdateParameterDTO();
            paramObj.name = 'RealKlok_Maand';
            paramObj.value = currentDateTime.updatedValue.getMonth() + 1;
            paramsList.push(paramObj);
        }
        if (
            currentDateTime.value.getDate() !==
            currentDateTime.updatedValue.getDate()
        ) {
            const paramObj = new UpdateParameterDTO();
            paramObj.name = 'RealKlok_Dag';
            paramObj.value = currentDateTime.updatedValue.getDate();
            paramsList.push(paramObj);
        }
        checkAndAddTimeParamIfUpdated(
            paramsList,
            currentDateTime,
            'RealKlok_Uur',
            'RealKlok_Minuut'
        );
    };

    const updateDateTime = (newVal, parameter, updateFunction) => {
        let updatedParam = { ...parameter };
        updatedParam.updatedValue = newVal;
        updateFunction && updateFunction(updatedParam);
    };

    const timesAreEqual = (param) => {
        return (
            param.value.getHours() === param.updatedValue.getHours() &&
            param.value.getMinutes() === param.updatedValue.getMinutes()
        );
    };

    const datesAreEqual = (param) => {
        return (
            param.value.getFullYear() === param.updatedValue.getFullYear() &&
            param.value.getMonth() === param.updatedValue.getMonth() &&
            param.value.getDate() === param.updatedValue.getDate() &&
            timesAreEqual(param)
        );
    };
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-9 start-just full">
                    <h2>{t('pagelayout_sidebargroup_settings')}</h2>
                </div>
                <div className="col-md-2 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_profile_profilepage_savechanges')}>
                        {userIsAtLeast(props.user, 'maintainer') ? (
                            <Button
                                className="pbi-button"
                                aria-label="Save"
                                onClick={() => onSaveChanges()}
                                color="primary">
                                {t('pages_pbisettings_save')}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Tooltip>
                </div>
                <div className="col-md-1 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_addgraph_addgraph_back')}>
                        <img
                            className="clickable-icon"
                            style={{ width: '40px', height: '40px' }}
                            onClick={() => goBack()}
                            src={`${getProperRoute(backIcon)}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row"
                style={{
                    paddingTop: '10px',
                    background: 'rgb(223,226,227)',
                    height: `${(height / 6) * 5}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                    overflow: 'auto',
                }}>
                <div className="col-md-12 full">
                    <div className="row">
                        <div className="col-md-3 start-just">
                            <TextField
                                InputProps={{
                                    classes: {
                                        input:
                                            forwardDuration?.updatedValue !==
                                            forwardDuration?.value
                                                ? styles.colorRed
                                                : styles.colorBlack,
                                    },
                                }}
                                style={{ marginLeft: '10px', width: '100%' }}
                                label={t('pages_pbisettings_fwdduration')}
                                placeholder="0"
                                value={forwardDuration?.updatedValue ?? 0}
                                variant="standard"
                                onChange={forwardDurationChanged}
                            />
                        </div>
                        <div className="col-md-3 start-just">
                            <TextField
                                InputProps={{
                                    classes: {
                                        input:
                                            backwardDuration?.updatedValue !==
                                            backwardDuration?.value
                                                ? styles.colorRed
                                                : styles.colorBlack,
                                    },
                                }}
                                style={{ width: '100%' }}
                                label={t('pages_pbisettings_bwdduration')}
                                placeholder="0"
                                value={backwardDuration?.updatedValue ?? 0}
                                variant="standard"
                                onChange={backwardDurationChanged}
                            />
                        </div>
                        <div className="col-md-3 start-just">
                            <TextField
                                InputProps={{
                                    classes: {
                                        input:
                                            flushDelay?.updatedValue !==
                                            flushDelay?.value
                                                ? styles.colorRed
                                                : styles.colorBlack,
                                    },
                                }}
                                style={{ width: '100%' }}
                                label={t('pages_pbisettings_flushdelay')}
                                placeholder="0"
                                value={flushDelay?.updatedValue ?? 0}
                                variant="standard"
                                onChange={flushDelayChanged}
                            />
                        </div>
                        <div className="col-md-3 start-just">
                            <TextField
                                InputProps={{
                                    classes: {
                                        input:
                                            integrityDelay?.updatedValue !==
                                            integrityDelay?.value
                                                ? styles.colorRed
                                                : styles.colorBlack,
                                    },
                                }}
                                style={{ width: '100%' }}
                                label={t('pages_pbisettings_integritydelay')}
                                placeholder="0"
                                value={integrityDelay?.updatedValue ?? 0}
                                variant="standard"
                                onChange={integrityDelayChanged}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 bottom-just">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDateTimePicker
                                    style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: datesAreEqual(
                                                currentDateTime
                                            )
                                                ? styles.colorBlack
                                                : styles.colorRed,
                                        },
                                    }}
                                    variant="inline"
                                    format="MM/DD/yyyy HH:mm"
                                    margin="normal"
                                    label={t('pages_pbisettings_datetime')}
                                    value={currentDateTime.updatedValue}
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            currentDateTime,
                                            setCurrentDateTime
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-4 bottom-just">
                            <TextField
                                InputProps={{
                                    classes: {
                                        input:
                                            intervalMin?.updatedValue !==
                                            intervalMin?.value
                                                ? styles.colorRed
                                                : styles.colorBlack,
                                    },
                                }}
                                style={{ marginBottom: '8px', width: '100%' }}
                                label={t('pages_pbisettings_intervalmin')}
                                placeholder="0"
                                value={intervalMin?.updatedValue ?? 0}
                                variant="standard"
                                onChange={intervalMinChanged}
                            />
                        </div>
                        <div className="col-md-4 bottom-just">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: datesAreEqual(integrityTime)
                                                ? styles.colorBlack
                                                : styles.colorRed,
                                        },
                                    }}
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={t('pages_pbisettings_integrity')}
                                    value={integrityTime.updatedValue}
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            integrityTime,
                                            setIntegrityTime
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            {t('pages_pbisettings_flushtimesettings')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime1Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime1Active,
                                        setFlushTime1Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime1) ||
                                                flushTime1Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime1Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 1`}
                                    value={
                                        flushTime1Active?.updatedValue === 1
                                            ? flushTime1.updatedValue
                                            : flushTime1.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime1,
                                            setFlushTime1
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime2Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime2Active,
                                        setFlushTime2Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime2) ||
                                                flushTime2Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime2Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 2`}
                                    value={
                                        flushTime2Active?.updatedValue === 1
                                            ? flushTime2.updatedValue
                                            : flushTime2.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime2,
                                            setFlushTime2
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime3Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime3Active,
                                        setFlushTime3Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime3) ||
                                                flushTime3Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime3Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 3`}
                                    value={
                                        flushTime3Active?.updatedValue === 1
                                            ? flushTime3.updatedValue
                                            : flushTime3.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime3,
                                            setFlushTime3
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime4Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime4Active,
                                        setFlushTime4Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime4) ||
                                                flushTime4Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime4Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 4`}
                                    value={
                                        flushTime4Active?.updatedValue === 1
                                            ? flushTime4.updatedValue
                                            : flushTime4.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime4,
                                            setFlushTime4
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime5Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime5Active,
                                        setFlushTime5Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime5) ||
                                                flushTime5Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime5Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 5`}
                                    value={
                                        flushTime5Active?.updatedValue === 1
                                            ? flushTime5.updatedValue
                                            : flushTime5.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime5,
                                            setFlushTime5
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime6Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime6Active,
                                        setFlushTime6Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime6) ||
                                                flushTime6Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime6Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 6`}
                                    value={
                                        flushTime6Active?.updatedValue === 1
                                            ? flushTime6.updatedValue
                                            : flushTime6.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime6,
                                            setFlushTime6
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime7Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime7Active,
                                        setFlushTime7Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime7) ||
                                                flushTime7Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime7Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 7`}
                                    value={
                                        flushTime7Active?.updatedValue === 1
                                            ? flushTime7.updatedValue
                                            : flushTime7.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime7,
                                            setFlushTime7
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1 bottom-just">
                            <Checkbox
                                classes={{
                                    root: cbstyles.root,
                                    checked: cbstyles.checked,
                                }}
                                checked={
                                    flushTime8Active?.updatedValue === 1 ??
                                    false
                                }
                                onChange={(event) =>
                                    checkboxChanged(
                                        event,
                                        flushTime8Active,
                                        setFlushTime8Active
                                    )
                                }
                            />
                        </div>
                        <div className="col-md-2 col-sm-3 bottom-just no-padding">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardTimePicker
                                    style={{
                                        width: '100%',
                                    }}
                                    InputProps={{
                                        classes: {
                                            input:
                                                timesAreEqual(flushTime8) ||
                                                flushTime8Active?.updatedValue ===
                                                    0
                                                    ? styles.colorBlack
                                                    : styles.colorRed,
                                        },
                                    }}
                                    disabled={
                                        flushTime8Active?.updatedValue === 0 ??
                                        true
                                    }
                                    variant="inline"
                                    format="HH:mm"
                                    margin="normal"
                                    label={`${t(
                                        'pages_pbisettings_flushtime'
                                    )} 8`}
                                    value={
                                        flushTime8Active?.updatedValue === 1
                                            ? flushTime8.updatedValue
                                            : flushTime8.value
                                    }
                                    onChange={(val) =>
                                        updateDateTime(
                                            val?.toDate(),
                                            flushTime8,
                                            setFlushTime8
                                        )
                                    }
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
