import { TemperatureControlApi } from '@aurum/nucleus-client-api';
import { TemperatureControlAppGraphContainer } from '@components';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { useWindowSize } from '@utils/hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { saveLayoutCommon } from './DashboardCommonFunctions';

const Wrapper = React.forwardRef((props, ref) => {
    if (props.style.transform) {
        const translateRegex = /translate\((.+),\s*(.+)\)/;
        const matches = translateRegex.exec(props.style.transform);
        props.style.left = `${parseFloat(matches[1])}px`;
        props.style.top = `${parseFloat(matches[2])}px`;
        for (let p in props.style) {
            if (
                props.style.hasOwnProperty(p) &&
                p.toLowerCase().includes('transform')
            ) {
                delete props.style[p];
            }
        }
    }

    let newChildren = React.Children.map(props.children, function (child) {
        return React.cloneElement(child, { forceRender: props.forceRender });
    });
    return (
        <div ref={ref} {...props}>
            {newChildren}
        </div>
    );
});

export function TemperatureControlAppDashboard(props) {
    const [width] = useWindowSize();
    const [graphData, setGraphData] = useState([]);
    const [forceRender, setForceRender] = useState(0);
    const [lastRefresh, setLastRefresh] = useState(-1);

    const ref = React.createRef();
    const { id } = useParams();

    const onLayoutChange = (layout) => {
        saveLayout(layout);
    };

    useEffect(() => {
        if (props.refreshCounter === lastRefresh) {
            return;
        }
        setLastRefresh(props.refreshCounter);

        if (isNullOrUndefined(props.from) || isNullOrUndefined(props.to))
            return;

        let from = props.from;
        let to = props.to;
        from.setMilliseconds(0);
        to.setMilliseconds(0);
        new TemperatureControlApi().temperatureControlGetMeasurements(
            parseInt(id, 10),
            {
                windowPeriod: props.windowPeriod,
                startTimestamp: from,
                endTimestamp: to,
            },
            function (_, data, response) {
                fetchingProgress(-1);
                setGraphData(data);
            }
        );
    }, [props.refreshCounter, props.from, props.to, props.windowPeriod]);

    const saveLayout = (value) => {
        const force = saveLayoutCommon(value, id, props.dashboard);
        if (force) {
            setForceRender(forceRender + 1);
        }
    };

    const fetchingProgress = (counter, total) => {
        props.fetchingProgress && props?.fetchingProgress(counter, total);
    };

    const graphs = [];
    let index = 0;

    props.dashboard?.graphs?.forEach((graph) => {
        const graphMeasurements = graph.measurement?.split(',');
        const gData = graphData?.filter(
            (g) =>
                graphMeasurements.some((gm) => g.measurement === gm) ||
                graph.measurement === 'temperatureControl'
        );

        graphs.push(
            <Wrapper ref={ref} key={`${++index}`} forceRender={forceRender}>
                <TemperatureControlAppGraphContainer
                    user={props.user}
                    data={gData}
                    loading={props.loading}
                    site={props.site}
                    protected={props.dashboard.configOptions?.secured}
                    graph={graph}
                    circulations={props.circulations}
                />
            </Wrapper>
        );
    });

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const widthWithoutSideBar = width > 960 ? width - 256 : width;

    return isNullOrUndefined(props.dashboard) ? (
        <></>
    ) : (
        <GridLayout
            className="layout"
            onResizeStop={onLayoutChange}
            onDragStop={onLayoutChange}
            layout={props.dashboard?.configOptions?.layout}
            minH={275}
            minW={275}
            cols={12}
            rowHeight={30}
            width={
                isMobile
                    ? width * 0.8 + 30
                    : widthWithoutSideBar - widthWithoutSideBar * 0.075
            }
            draggableHandle=".DraggableDashboard">
            {graphs}
        </GridLayout>
    );
}
