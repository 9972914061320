import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import DisplayTabBarOptions from '@views/sites/dashboards/graphs/create-graph-tabs/DisplayTabBarOptions';
import DisplayTabBoxPlotOptions from '@views/sites/dashboards/graphs/create-graph-tabs/DisplayTabBoxPlotOptions';
import DisplayTabLineOptions from '@views/sites/dashboards/graphs/create-graph-tabs/DisplayTabLineOptions';
import React from 'react';

export default function DisplayTab(props) {
    if (isNullOrUndefined(props.graph)) {
        return <></>;
    }

    switch (props.graph.type) {
        case 0:
        case 3:
        case 6:
            return (
                <DisplayTabLineOptions
                    graph={props.graph}
                    graphChanged={props.graphChanged}
                />
            );
        case 1:
        case 2:
            return (
                <DisplayTabBarOptions
                    graph={props.graph}
                    graphChanged={props.graphChanged}
                />
            );
        case 4:
            return (
                <DisplayTabBoxPlotOptions
                    graph={props.graph}
                    graphChanged={props.graphChanged}
                />
            );
        case 5:
            return <></>;
        default:
            return <></>;
    }
}
