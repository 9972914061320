import { CustomBreadcrumbs } from '@components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { dateToDateString } from '@utils/helpers/timestamp.helpers';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './graphstyles.css';

export function GraphContainerContent(props) {
    const [curGroup, setCurGroup] = useState(0);
    const [structure, setStructure] = useState([]);
    const [entriesToBeDisp, setEntriesToBeDisp] = useState([]);
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);
    const { t } = useTranslation();

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const breadcrumbsClicked = () => {
        if (curGroup === 0) {
            setEntriesToBeDisp(
                props.groups?.filter((g) => isNullOrUndefined(g.parent?.id))
            );
        } else {
            setEntriesToBeDisp(
                props.groups?.filter((g) => g.parent?.id === curGroup)
            );
        }
        setOpen(true);
    };

    const onItemClick = (id) => {
        setCurGroup(id);
        setStructure([...structure, id]);
        setOpen(false);
        props.onItemClick && props.onItemClick(id);
    };

    const onGroupClick = (id) => {
        const newStructure = [];
        if (id !== 0) {
            for (let item of structure) {
                if (item !== id) {
                    newStructure.push(item);
                } else {
                    newStructure.push(item);
                    break;
                }
            }
        }
        setStructure(newStructure);
        setCurGroup(id);
        props.onGroupClick && props.onGroupClick(id);
    };

    const bc = [
        {
            onClick: onGroupClick,
            id: 0,
            text: t('common_graphcontainer_groups'),
        },
    ];
    structure?.forEach((st) => {
        const gr = props.groups?.find((g) => g.id === st);
        if (!isNullOrUndefined(gr)) {
            bc.push({ onClick: onGroupClick, id: gr.id, text: gr.name });
        }
    });

    const menuItems = [];
    entriesToBeDisp?.forEach((item) => {
        menuItems.push(
            <MenuItem onClick={() => onItemClick(item.id)}>
                {item.name}
            </MenuItem>
        );
    });

    const addMore =
        curGroup === 0 ||
        props.groups?.filter((g) => g.parent?.id === curGroup)?.length > 0;
    let minTs = null;
    let maxTs = null;
    for (let mp of props.data) {
        if (mp.datapoints.length > 0) {
            if (isNullOrUndefined(minTs) || mp.datapoints[0].x < minTs) {
                minTs = mp.datapoints[0].x;
            }
            if (
                isNullOrUndefined(maxTs) ||
                mp.datapoints[mp.datapoints.length - 1].x > maxTs
            ) {
                maxTs = mp.datapoints[mp.datapoints.length - 1].x;
            }
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12 d-flex align-items-center justify-content-end">
                    {isNullOrUndefined(minTs) ||
                    isNullOrUndefined(maxTs) ||
                    props.width < 700 ||
                    props.graph.type !== 0
                        ? ''
                        : `${dateToDateString(minTs)} - ${dateToDateString(
                              maxTs
                          )}`}
                </div>
            </div>
            {props.protected ? (
                <>
                    <CustomBreadcrumbs
                        ref={anchorRef}
                        addmore={addMore}
                        onAddClick={() => breadcrumbsClicked}
                        breadcrumbs={bc}
                    />
                    <Popper
                        style={{ zIndex: 999999 }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}>
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow">
                                            {menuItems}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <br />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
