import { FilterApi, UpdateParameterDTO } from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    FilesTable,
    MoreSettingsMenu,
    SingleSelect,
    Spinner,
    TableTextEdit,
} from '@components';
import { MenuItem } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import {
    getFullGroups,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import { dateToString } from '@utils/helpers/timestamp.helpers';
import { useKeyPress } from '@utils/hooks/useKeyPressed';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SiteSettingsCommonModals from '../../../sites-page/SiteSettingsCommonModals';
import SiteSettingsCommonOptions from '../../../sites-page/SiteSettingsCommonOptions';

export default function ParametersSitePage(props) {
    const [parameters, setParameters] = useState([]);
    const [paramChanges, setParamChanges] = useState([]);
    const [paramGroup, setParamGroup] = useState('favorites');
    const [rowBeingUpdated, setRowBeingUpdated] = useState(-1);
    const [fetchingInProgress, setFetchingInProgress] = useState(false);
    const [lastFetchingTime, setLastFetchingTime] = useState(null);
    const [modalOpened, setModalOpened] = useState(-1);
    const { groupId, id } = useParams();

    const { t } = useTranslation();
    useKeyPress('Escape', () => onEscapePressed());

    useEffect(() => {
        if (isNullOrUndefined(paramGroup)) {
            return;
        }
        new FilterApi().filterGetParametersFromPlc(
            parseInt(id, 10),
            { group: paramGroup },
            function (_, data, response) {
                if (response.ok) {
                    setParameters(response.body);
                }
            }
        );
    }, [paramGroup]);

    /**
     * This hook will be invoked when the data fetching is in progress. It will poll (in 500ms intervals) the new
     * data until the last fetched timestamp is updated. Then, the interval will be cleared.
     */
    useEffect(() => {
        if (fetchingInProgress) {
            const interval = setInterval(() => {
                new FilterApi().filterGetParametersFromPlc(
                    parseInt(id, 10),
                    { group: paramGroup },
                    function (_, data, response) {
                        if (response.ok) {
                            const newDate = getLatestTimestamp(response.body);
                            if (lastFetchingTime < newDate) {
                                setFetchingInProgress(false);
                                setLastFetchingTime(newDate);
                                setParamChanges([]);
                                setParameters(response.body);
                                clearInterval(interval);
                            }
                        }
                    }
                );
            }, 500);
            return () => clearInterval(interval);
        }
    }, [fetchingInProgress]);

    const forceRefresh = () => {
        new FilterApi().filterFetchParametersFromPlc(
            parseInt(id, 10),
            { group: paramGroup },
            () => {
                const lastFetchedTimestamp = getLatestTimestamp(parameters);
                setLastFetchingTime(lastFetchedTimestamp);
                setFetchingInProgress(true);
            }
        );
    };

    const getLatestTimestamp = (params) => {
        return new Date(
            Math.max.apply(
                null,
                params?.map((p) => new Date(p.lastFetched))
            )
        );
    };

    const onEscapePressed = () => {
        setRowBeingUpdated(-1);
    };

    const changeParamGroup = (newParamGroup) => {
        setParamGroup(newParamGroup);
        setParamChanges([]);
    };

    const clickOnParamValue = (ev, idx) => {
        if (ev.detail === 2 && !parameters[idx].readOnly) {
            setRowBeingUpdated(idx);
        }
    };

    const getParamValue = (paramName) => {
        // if the value was updated, return the updated value. Otherwise, return the real value
        const paramChanged = paramChanges.find(
            (p) => p.name === paramName && p.saved
        );
        const realParam = parameters.find((p) => p.name === paramName);
        if (!isNullOrUndefined(paramChanged)) {
            debugger;
            if (isNumeric(paramChanged.value.toString())) {
                paramChanged.value =
                    paramChanged.value >= (realParam.minValue ?? 0)
                        ? parseInt(paramChanged.value, 10)
                        : realParam.minValue ?? 0;
                paramChanged.value =
                    paramChanged.value <= (realParam.maxValue ?? 1)
                        ? parseInt(paramChanged.value, 10)
                        : realParam.maxValue ?? 1;
                return {
                    value: paramChanged.value,
                    changed: paramChanged.value !== realParam.value,
                };
            }
            paramChanged.value = realParam.value;
            paramChanged.saved = false;
        }
        return {
            value: realParam.value,
            changed: false,
        };
    };

    const showParamEdit = (p, idx) => {
        const edit = (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                }}>
                <EditIcon
                    onClick={() => setRowBeingUpdated(idx)}
                    style={{ width: '18px', cursor: 'pointer' }}
                />
            </div>
        );
        const nothing = <></>;
        if (p.name === 'RESET_ERRORS2' || p.name === 'RESET_ERRORS1')
            return edit;
        if (p.readOnly) return nothing;
        return edit;
    };

    const editParamContent = (p, idx) => {
        if (rowBeingUpdated === idx) {
            return (
                <div style={{ width: '100%', display: 'inline-flex' }}>
                    <TableTextEdit
                        autofocus
                        value={getParamValue(p.name).value}
                        onChange={(e) => paramValueChange(e, p.name)}
                    />
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignContent: 'center',
                            justifyContent: 'flex-end',
                        }}>
                        <CheckIcon
                            onClick={() => paramChangeConfirm()}
                            style={{ width: '18px', cursor: 'pointer' }}
                        />
                        <CloseIcon
                            onClick={() => setRowBeingUpdated(-1)}
                            style={{ width: '18px', cursor: 'pointer' }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ width: '100%', display: 'inline-flex' }}>
                    <span
                        onClick={(ev) => clickOnParamValue(ev, idx)}
                        style={{
                            color: getParamValue(p.name).changed ? 'red' : '',
                        }}>
                        {getParamValue(p.name).value}
                    </span>
                    {showParamEdit(p, idx)}
                </div>
            );
        }
    };

    const paramValueChange = (txt, paramName) => {
        const paramChanged = paramChanges.find((p) => p.name === paramName);
        if (isNullOrUndefined(paramChanged)) {
            setParamChanges([
                ...paramChanges,
                { value: txt, name: paramName, saved: false },
            ]);
            return;
        }
        paramChanged.value = txt;
    };

    const paramChangeConfirm = () => {
        if (rowBeingUpdated < 0 || rowBeingUpdated >= parameters.length) {
            return;
        }
        const parameter = parameters[rowBeingUpdated];
        const paramChanged = paramChanges.find(
            (p) => p.name === parameter.name
        );
        if (!isNullOrUndefined(paramChanged)) {
            if (`${paramChanged.value}` === `${parameter.value}`) {
                setParamChanges(
                    paramChanges.filter((pc) => pc.name !== parameter.name)
                );
            } else {
                paramChanged.saved = true;
            }
            setRowBeingUpdated(-1);
        }
    };

    const updateParams = () => {
        const paramsToUpdate = [];
        paramChanges
            .filter((pc) => pc.saved)
            .forEach((el) => {
                const paramObj = new UpdateParameterDTO();
                paramObj.name = el.name;
                paramObj.value = el.value;
                paramsToUpdate.push(paramObj);
            });
        new FilterApi().filterUpdateParameterValues(
            parseInt(id, 10),
            paramsToUpdate,
            function (_, data, response) {
                if (response.ok) {
                    const lastFetchedTimestamp = getLatestTimestamp(parameters);
                    setLastFetchingTime(lastFetchedTimestamp);
                    setFetchingInProgress(true);
                    setParamChanges([]);
                }
            }
        );
    };

    const lastFetchedTimestamp = getLatestTimestamp(parameters);
    const rows = parameters?.map((p, idx) => [
        {
            link: null,
            text: t(p.name),
            value: t(p.name),
        },
        { link: null, text: p.unit, value: p.unit },
        {
            link: null,
            text: getParamValue(p.name).value,
            value: editParamContent(p, idx),
        },
    ]);

    const fullGroups = getFullGroups(props.groups, props.site);
    let moreSettingsMenuOpts = <></>;
    if (userIsAtLeast(props.user, 'analyst')) {
        moreSettingsMenuOpts = (
            <>
                <SiteSettingsCommonOptions
                    clickEditProject={() => setModalOpened(0)}
                    clickCreateSiteCopy={() => setModalOpened(1)}
                    clickBackupGeneration={() => setModalOpened(2)}
                />
                <MenuItem onClick={() => forceRefresh()}>
                    {t('pages_parameters_forcerefresh')}
                </MenuItem>
                <MenuItem onClick={() => updateParams()}>
                    {t('pages_parameters_saveparams')}
                </MenuItem>
                <MenuItem onClick={() => setParamChanges([])}>
                    {t('pages_parameters_discard')}
                </MenuItem>
            </>
        );
    }

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: t('pages_groups_gatewaygrouppage_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_parameters_parameters'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <div style={{ width: '100%' }}>
                    {fetchingInProgress ? (
                        <Spinner />
                    ) : (
                        <p style={{ color: 'red' }}>
                            {t('pages_parameters_lastfetched')}: &nbsp;&nbsp;
                            {dateToString(
                                lastFetchedTimestamp,
                                props.user?.timeZone
                            )}
                        </p>
                    )}
                </div>
                <div style={{ width: '100%' }}>
                    <SingleSelect
                        title={t('pages_parameters_group')}
                        onChange={changeParamGroup}
                        value={paramGroup}
                        data={[
                            {
                                value: 'favorites',
                                description: t('pages_parameters_favorites'),
                            },
                            {
                                value: 'alarmSettings',
                                description: t(
                                    'pages_parameters_alarmsettings'
                                ),
                            },
                            {
                                value: 'clock',
                                description: t(
                                    'pages_parameters_clocksettings'
                                ),
                            },
                            {
                                value: 'filterSettings',
                                description: t(
                                    'pages_parameters_filtersettings'
                                ),
                            },
                            {
                                value: 'flushControl',
                                description: t('pages_parameters_flushcontrol'),
                            },
                            {
                                value: 'flushSettings',
                                description: t(
                                    'pages_parameters_flushsettings'
                                ),
                            },
                            {
                                value: 'information',
                                description: t('pages_parameters_information'),
                            },
                            {
                                value: 'initialSettings',
                                description: t(
                                    'pages_parameters_initialsettings'
                                ),
                            },
                            {
                                value: 'serviceControl',
                                description: t(
                                    'pages_parameters_servicecontrol'
                                ),
                            },
                            {
                                value: 'measurements',
                                description: t('pages_parameters_measurements'),
                            },
                            {
                                value: 'reset',
                                description: t('pages_parameters_reset'),
                            },
                            {
                                value: 'timeSettings',
                                description: t('pages_parameters_timesettings'),
                            },
                        ]}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}>
                    <MoreSettingsMenu
                        disabled={!userIsAtLeast(props.user, 'analyst')}>
                        {moreSettingsMenuOpts}
                    </MoreSettingsMenu>
                </div>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <FilesTable
                    sort
                    search
                    columns={[
                        t('common_filestable_namecol'),
                        'Unit',
                        t('pages_groups_gatewaygrouppage_value'),
                    ]}
                    rows={rows}
                />
            </div>
            <SiteSettingsCommonModals
                opened={modalOpened}
                handleClose={(site) => {
                    props.site.name = site.name;
                    props.site.description = site.description;
                    setModalOpened(-1);
                }}
                site={props.site}
            />
        </>
    );
}
