import { TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function RemoveDeviceModal(props) {
    const [uuid, setUuid] = useState('');
    const [aquacloudId, setAquacloudId] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    const uuidChanged = (txt) => {
        setUuid(txt);
    };

    const aquacloudIdChanged = (txt) => {
        setAquacloudId(txt);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (
            isNullOrUndefined(uuid) ||
            uuid?.trim() === '' ||
            isNullOrUndefined(aquacloudId) ||
            aquacloudId?.trim() === ''
        ) {
            setErrorMsg(t('pages_login_loginpage_required'));
            return;
        }
        if (uuid !== props.device?.uuid) {
            setErrorMsg('Bad UUID');
            return;
        }
        if (aquacloudId !== props.device?.description) {
            setErrorMsg('Bad AquaCloud ID');
            return;
        }
        props.confirm();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_sitesettings_alarmentrypage_remove')}{' '}
                {isNullOrUndefined(props.device?.name) ||
                props?.device?.name?.trim() === ''
                    ? t('pages_devices_renamedevicemodal_device')
                    : props.device.name}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '10px' }}>
                    <Alert severity="info">
                        {t('pages_devices_devicepage_remove_confirmation_msg')}
                    </Alert>
                    {isNullOrUndefined(props.device?.measurementPoint) ? (
                        <></>
                    ) : (
                        <Alert style={{ marginTop: '10px' }} severity="warning">
                            {t(
                                'pages_devices_devicepage_remove_mp_attachment_msg'
                            )}
                        </Alert>
                    )}
                    {!isNullOrUndefined(props.device?.site) &&
                    props.device.site?.id !== -1 ? (
                        <Alert style={{ marginTop: '10px' }} severity="warning">
                            {t(
                                'pages_devices_devicepage_remove_gw_attachment_msg'
                            )}
                        </Alert>
                    ) : (
                        <></>
                    )}
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={'UUID'}
                        value={uuid}
                        onChange={uuidChanged}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_devices_devicepage_acid')}
                        value={aquacloudId}
                        onChange={aquacloudIdChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {' '}
                    {t('modal_cancel')}{' '}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {' '}
                    {t('pages_sitesettings_alarmentrypage_remove')}{' '}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
