import { FilterApi, UpdateParameterDTO } from '@aurum/nucleus-client-api';
import { Tooltip } from '@material-ui/core';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import './pbiStyling.css';
import { userIsAtLeast } from '../../utils/helpers/app.helpers';

export function PbiMenuScreen(props) {
    const { id } = useParams();
    const { t } = useTranslation();
    const backIcon = 'filter/return.png';
    const manualCycleIcon = 'filter/Extra_Forwardflush2.png';
    const alarmsIcon = 'filter/Alarm.png';
    const settingsIcon = 'filter/Functies.png';
    const maintenanceIcon = 'filter/Service.png';
    const maintenanceIconBlocked = 'filter/Service_blocked.png';

    const { height } = props;

    const getParameterValue = (paramName) => {
        return props.parameters?.find((p) => p.name === paramName)?.value ?? '';
    };
    const plcId1 = getParameterValue('PRODUCT_ID_1');
    const plcId2 = getParameterValue('PRODUCT_ID_2');
    const plcVersion = getParameterValue('VERSION_NUMBER');

    const goBack = () => {
        props.triggerChange && props.triggerChange(0);
    };

    const triggerIntegrityCycle = () => {
        if (!userIsAtLeast(props.user, 'analyst')) return;

        const manualCycle = props.parameters?.find(
            (p) => p.name === 'MANUAL_INT_CYCLE'
        );
        if (!isNullOrUndefined(manualCycle)) {
            const paramsToUpdate = [];
            const paramObj = new UpdateParameterDTO();
            paramObj.name = manualCycle.name;
            paramObj.value = 1;
            paramsToUpdate.push(paramObj);
            new FilterApi().filterUpdateParameterValues(
                parseInt(id, 10),
                paramsToUpdate,
                function (_, data, response) {
                    // TODO: implement this
                    goBack();
                }
            );
        }
    };

    const goToAlarmsPage = () => {
        props.triggerChange && props.triggerChange(2);
    };

    const goToSettingsPage = () => {
        props.triggerChange && props.triggerChange(3);
    };

    const goToServiceControlPage = () => {
        if (!userIsAtLeast(props.user, 'maintainer')) return;
        props.triggerChange && props.triggerChange(4);
    };

    const getServiceIcon = () => {
        if (userIsAtLeast(props.user, 'maintainer')) return maintenanceIcon;
        return maintenanceIconBlocked;
    };

    const getServiceText = () => {
        if (userIsAtLeast(props.user, 'maintainer'))
            return t('pages_pbimenu_open_service');
        return t('pages_pbimenu_not_available');
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-11 start-just full">
                    <h2>{t('pages_pbimenu_menu')}</h2>
                </div>
                <div className="col-md-1 end-just full">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_addgraph_addgraph_back')}>
                        <img
                            className="clickable-icon"
                            style={{ width: '40px', height: '40px' }}
                            onClick={() => goBack()}
                            src={`${getProperRoute(backIcon)}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${(height / 6) * 4}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-3 full middle">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_pbimenu_trigger_integrity')}>
                        <img
                            className={`${
                                userIsAtLeast(props.user, 'analyst')
                                    ? 'clickable-icon'
                                    : ''
                            }`}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                            onClick={() => triggerIntegrityCycle()}
                            src={`${getProperRoute(manualCycleIcon)}`}></img>
                    </Tooltip>
                </div>
                <div className="col-md-3 full middle">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_pbimenu_open_alarms')}>
                        <img
                            className="clickable-icon"
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                            onClick={() => goToAlarmsPage()}
                            src={`${getProperRoute(alarmsIcon)}`}></img>
                    </Tooltip>
                </div>
                <div className="col-md-3 full middle">
                    <Tooltip
                        enterDelay={1000}
                        title={t('pages_pbimenu_open_settings')}>
                        <img
                            className="clickable-icon"
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                            onClick={() => goToSettingsPage()}
                            src={`${getProperRoute(settingsIcon)}`}></img>
                    </Tooltip>
                </div>
                <div className="col-md-3 full middle">
                    <Tooltip enterDelay={1000} title={getServiceText()}>
                        <img
                            className="clickable-icon"
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                            onClick={() => goToServiceControlPage()}
                            src={`${getProperRoute(getServiceIcon())}`}></img>
                    </Tooltip>
                </div>
            </div>
            <div
                className="row"
                style={{
                    background: 'rgb(223,226,227)',
                    height: `${height / 6}px`,
                    flexWrap: `${isMobile ? 'nowrap' : 'wrap'}`,
                }}>
                <div className="col-md-6 start-just">{`${t(
                    'pages_pbimenu_productid'
                )}: ${plcId1}${plcId2}`}</div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12 end-just">{`PLC: V${
                            plcVersion / 100
                        }`}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 end-just">HMI: V8.15</div>
                    </div>
                </div>
            </div>
        </>
    );
}
