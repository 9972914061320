import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import { LineGraph } from '@components';
import { groupBy } from '@utils/helpers/app.helpers';
import { getGraphColor } from '@utils/helpers/colors.helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function DiagnosticGatewayGraph(props) {
    const [historicData, setHistoricData] = useState([]);
    const { id } = useParams();

    const graphRef = useRef();
    const networkGraphRef = useRef();
    const vpnStatusGraphRef = useRef();
    const memFreeGraphRef = useRef();
    const cpuLoadGraphRef = useRef();
    const { t } = useTranslation();

    const onGraphRef = (gRef) => {
        graphRef.current = gRef;
    };

    useEffect(() => {
        // get historic diagnostic data for this gateway
        new GatewayApi().gatewayGetHistoricDataFromGateway(
            id,
            function (_, data, response) {
                if (response.ok) {
                    setHistoricData(data);
                }
            }
        );
    }, [id]);

    const addSeriesData = (data, measurement, index) => {
        const series = {
            name: measurement,
            color: getGraphColor(index, 1),
            datapoints: [],
        };
        for (let dt of data) {
            // convert Bytes to MBs
            const value = ['_bytesIn', '_bytesOut'].includes(measurement)
                ? dt.value / 1000000
                : dt.value;
            series.datapoints.push({ x: dt.timestamp, y: value });
        }
        return series;
    };

    const series = [];
    const dataByMeasurements = groupBy(historicData, 'measurement');
    let index = 0;
    for (let key in dataByMeasurements) {
        series.push(addSeriesData(dataByMeasurements[key], key, index++));
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div
                        ref={networkGraphRef}
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            border: '1px solid rgb(219, 219, 219)',
                            borderRadius: '4px',
                        }}>
                        <span
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                                marginBottom: '20px',
                            }}>
                            Network Usage (B)
                        </span>
                        <LineGraph
                            user={props.user}
                            showLegend={false}
                            customStyle={{ usePoints: true }}
                            measurement=""
                            min={0}
                            series={series.filter((s) =>
                                ['_bytesIn', '_bytesOut'].includes(s.name)
                            )}
                            onGraphRef={onGraphRef}
                            width={
                                networkGraphRef?.current?.offsetWidth ?? 1414
                            }
                            height={300}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div
                        ref={vpnStatusGraphRef}
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            border: '1px solid rgb(219, 219, 219)',
                            borderRadius: '4px',
                        }}>
                        <span
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                                marginBottom: '20px',
                            }}>
                            VPN Connection status
                        </span>
                        <LineGraph
                            user={props.user}
                            showLegend={false}
                            customStyle={{
                                usePoints: true,
                                interpolation: 'stepAfter',
                            }}
                            measurement=""
                            min={0}
                            series={series.filter((s) =>
                                ['vpnStatus'].includes(s.name)
                            )}
                            onGraphRef={onGraphRef}
                            width={
                                vpnStatusGraphRef?.current?.offsetWidth ?? 1414
                            }
                            height={300}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div
                        ref={cpuLoadGraphRef}
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            border: '1px solid rgb(219, 219, 219)',
                            borderRadius: '4px',
                        }}>
                        <span
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                                marginBottom: '20px',
                            }}>
                            CPU Load (%)
                        </span>
                        <LineGraph
                            user={props.user}
                            showLegend={false}
                            customStyle={{ usePoints: true }}
                            measurement=""
                            min={0}
                            series={series.filter((s) =>
                                ['cpuLoad'].includes(s.name)
                            )}
                            onGraphRef={onGraphRef}
                            width={
                                cpuLoadGraphRef?.current?.offsetWidth ?? 1414
                            }
                            height={300}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div
                        ref={memFreeGraphRef}
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            border: '1px solid rgb(219, 219, 219)',
                            borderRadius: '4px',
                        }}>
                        <span
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                                marginBottom: '20px',
                            }}>
                            Free memory (MB)
                        </span>
                        <LineGraph
                            user={props.user}
                            showLegend={false}
                            customStyle={{
                                usePoints: true,
                            }}
                            measurement=""
                            min={0}
                            series={series.filter((s) =>
                                ['memFree'].includes(s.name)
                            )}
                            onGraphRef={onGraphRef}
                            width={
                                memFreeGraphRef?.current?.offsetWidth ?? 1414
                            }
                            height={300}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
