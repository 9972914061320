import { Grid, makeStyles, Typography } from '@material-ui/core';
import { getProperRoute } from '@utils/helpers/app.helpers';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        '&:hover': {
            color: '#000',
        },
    },
    subTitle: {
        color: 'gray',
    },
    grow: {
        flexGrow: 1,
        width: '100%',
        margin: 'auto',
    },
}));

export default function CustomerServerTableEntry(props) {
    const styles = useStyles();

    return (
        <>
            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-md-12 d-flex align-items-center justify-content-start">
                    <Link
                        className={styles.title}
                        to={getProperRoute(`admin/customers/ibb`)}>
                        IBB
                    </Link>
                </div>
            </div>
            <Grid className={styles.grow}>
                <Typography className={styles.subTitle}>
                    ibb.aquacloud.de
                </Typography>
            </Grid>
        </>
    );
}
