import DashboardApi from '@aurum/nucleus-client-api/dist/api/DashboardApi';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';

export function saveLayoutCommon(value, id, dashboard) {
    let force = false;
    if (isNullOrUndefined(dashboard)) return force;

    if (isNullOrUndefined(dashboard.configOptions)) {
        dashboard.configOptions = {};
    }

    let resized = false;
    let moved = false;
    dashboard.configOptions.layout?.forEach((l, ind) => {
        if (l.h !== value?.[ind]?.h || l.w !== value?.[ind]?.w) {
            resized = true;
        }
        if (l.x !== value?.[ind]?.x || l.y !== value?.[ind]?.y) {
            moved = true;
        }
    });
    dashboard.configOptions.layout = value;
    dashboard.configOptions.layout?.forEach((l) => {
        if (l.h === 1 && l.w === 1) {
            l.h = 12;
            l.w = 12;
        }
    });
    if (resized) {
        force = true;
    }
    if (moved || resized) {
        new DashboardApi().dashboardUpdateDashboard(
            parseInt(id, 10),
            dashboard.id,
            dashboard,
            function (_, data, response) {}
        );
    }
    return force;
}
