import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    colorBlack: { color: 'black' },
}));

export default function ValveAutoRegulation(props) {
    const { t } = useTranslation();
    const styles = useStyles();
    useEffect(() => {}, [props]);

    const onValveTemperatureChanged = (e) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
    };

    const valveTemperatureCallback = useCallback(
        (e) => onValveTemperatureChanged(e),
        []
    );

    const valveTemperatures = [];
    props.site?.configOptions?.strings?.forEach((str, idx) => {
        valveTemperatures.push(
            <TextField
                InputProps={{ classes: { input: styles.colorBlack } }}
                style={{ width: '100%' }}
                label={`Position ${idx + 1}`}
                placeholder="0"
                value={str.temperature ?? 0}
                variant="standard"
                onChange={valveTemperatureCallback}
            />
        );
    });

    return <>{valveTemperatures}</>;
}
