import {
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    commonButton: {
        marginLeft: '0px',
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        height: '40px',
        paddingTop: '0px',
        paddingRight: '8px',
        lineHeight: '30px',
        fontWeight: 500,
        borderColor: 'gray',
        borderTopStyle: 'solid',
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        whiteSpace: 'nowrap',
        color: 'rgb(20, 24, 28)',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    generalButton: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
    },
    refreshButton: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '0px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '0px',
    },
    clockIcon: {
        overflow: 'hidden',
        verticalAlign: 'middle',
        display: 'inline-block',
        marginBottom: '2px',
        color: '#000',
    },
    textButton: {
        display: 'block',
        WebkitBoxFlex: 1,
        flexGrow: 1,
        paddingLeft: '8px',
        paddingRight: '4px',
    },
    spanText: {
        display: 'flex',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    generalMenu: {
        backgroundImage: 'initial',
        backgroundPositionX: 'initial',
        backgroundPositionY: 'initial',
        backgroundSize: 'initial',
        backgroundAttachment: 'initial',
        backgroundOrigin: 'initial',
        backgroundClip: 'initial',
        backgroundColor: '#fff',
        position: 'absolute',
        zIndex: 999999,
        width: '546px',
        top: '116%',
        display: 'flex',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderImageSource: 'initial',
        borderImageSlice: 'initial',
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageRepeat: 'initial',
        right: '0px',
    },
    leftSideMenu: {
        display: 'flex',
        flexDirection: 'column',
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderRightColor: 'rgb(32,34,38)',
        width: '60%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        order: 0,
    },
    rightSideMenu: {
        position: 'relative',
        overflow: 'hidden',
        width: '40%',
        height: 'auto',
        minHeight: '0px',
        maxHeight: '100%',
        display: 'flex',
        flexGrow: 1,
        WebkitBoxFlex: 1,
    },
    rightSideSubItems: {
        width: '100%',
    },
}));

export default function DashboardDatePickerMobile(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectionText, setSelectionText] = useState();
    const styles = useStyles(props);
    const anchorRef = React.useRef(null);

    useEffect(() => {
        switch (props.timeSelection) {
            case 'last12hours':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last12h')
                );
                break;
            case 'last1day':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1d')
                );
                break;
            case 'last3days':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last3d')
                );
                break;
            case 'last1week':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1w')
                );
                break;
            case 'last1month':
                setSelectionText(
                    t('pages_dashboard_dashboarddatepicker_last1m')
                );
                break;
            case 'alltime':
                setSelectionText(t('pages_dashboard_dashboarddatepicker_all'));
                break;
        }
    }, [props.timeSelection]);

    const relativeSelection = (selection, txt) => {
        setSelectionText(txt);
        setOpen(false);
        props.onRelativeSelection && props.onRelativeSelection(selection);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <button
                aria-label="SelectDate"
                ref={anchorRef}
                onClick={handleToggle}
                className={`${styles.commonButton} ${styles.generalButton}`}>
                <div className={styles.clockIcon}>
                    <AccessTimeOutlinedIcon />
                </div>
                <div className={styles.textButton}>
                    <span className={styles.spanText}>
                        <span>{selectionText}</span>
                    </span>
                </div>
                <div className={styles.clockIcon}>
                    <ExpandMoreOutlinedIcon />
                </div>
            </button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ width: '95%', marginTop: '20px', zIndex: 10 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    id="menu-select-time-interval"
                                    style={{ width: '100%' }}>
                                    <b>
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_relative'
                                        )}
                                    </b>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'last12hours',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last12h'
                                                )
                                            )
                                        }>
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_last12h'
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'last1day',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1d'
                                                )
                                            )
                                        }>
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_last1d'
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'last3days',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last3d'
                                                )
                                            )
                                        }>
                                        {' '}
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_last3d'
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'last1week',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1w'
                                                )
                                            )
                                        }>
                                        {' '}
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_last1w'
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'last1month',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_last1m'
                                                )
                                            )
                                        }>
                                        {' '}
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_last1m'
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            relativeSelection(
                                                'alltime',
                                                t(
                                                    'pages_dashboard_dashboarddatepicker_all'
                                                )
                                            )
                                        }>
                                        {' '}
                                        {t(
                                            'pages_dashboard_dashboarddatepicker_all'
                                        )}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
