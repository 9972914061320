import { MeasurementPointApi } from '@aurum/nucleus-client-api';
import { SingleSelect } from '@components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import './site-tc-settings-page.css';

export default function SiteTcSettingsCirculation(props) {
    const [forceRefresh, setForceRefresh] = useState(0);
    const { t } = useTranslation();
    const { id } = useParams();

    const getTempSensors = (sensors) => {
        return [
            {
                value: 'none',
                description: t('pages_sitetc_settings_circulation_noselected'),
            },
            ...(sensors
                ?.filter((s) => s.type.startsWith('temperature'))
                ?.map((s) => ({ value: s.uuid, description: s.name })) ?? []),
        ];
    };

    const sensorAttachedToMpChanged = (sensor, mp) => {
        // attach the new sensor to the MP
        mp.measuringElement = { uuid: sensor };
        new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
            parseInt(id, 10),
            mp.measurementPointId,
            mp,
            function (_, data, response) {
                setForceRefresh(forceRefresh + 1);
            }
        );
    };

    const mps = props.circulationMps?.filter(
        (c) => c.circulationId === props.curCirculation?.id
    );
    const flowMp = mps.find((m) => m.circulationMeasurement === 'flow');
    const circuMp = mps.find((m) => m.circulationMeasurement === 'circulation');

    const sensors = getTempSensors(props.allSensors);
    const flowSensor = sensors.find(
        (s) => s.value === flowMp?.measuringElement?.uuid
    );
    const circuSensor = sensors.find(
        (s) => s.value === circuMp?.measuringElement?.uuid
    );

    return (
        <div className="row">
            {flowMp && circuMp ? (
                <>
                    <div
                        className="col-md-4"
                        style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <span>
                            {t('pages_sitetc_settings_circulation_circulation')}
                            {': '} {props.curCirculation?.name}
                        </span>
                    </div>
                    <div className="col-md-4" style={{ width: '75%' }}>
                        <SingleSelect
                            title={t(
                                'pages_sitetc_settings_circulation_flowsensor'
                            )}
                            data={sensors}
                            rawValue={
                                flowSensor?.description ??
                                t('pages_devices_devicepage_unassigned')
                            }
                            onChange={(sensor) =>
                                sensorAttachedToMpChanged(sensor, flowMp)
                            }
                        />
                    </div>
                    <div className="col-md-4" style={{ width: '75%' }}>
                        <SingleSelect
                            title={t(
                                'pages_sitetc_settings_circulation_circusensor'
                            )}
                            data={sensors}
                            rawValue={
                                circuSensor?.description ??
                                t('pages_devices_devicepage_unassigned')
                            }
                            onChange={(sensor) =>
                                sensorAttachedToMpChanged(sensor, circuMp)
                            }
                        />
                    </div>
                </>
            ) : (
                <div
                    className="col-md-12"
                    style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <span>
                        {t('pages_sitetc_settings_circulation_circulation')}
                        {': '}
                        {t('pages_sitetc_settings_circulation_nocircu')}
                    </span>
                </div>
            )}
        </div>
    );
}
