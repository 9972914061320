import {
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import '@components/Navbar/navbar.css';

const useStyles = makeStyles((props) => ({
    btn: {
        fontSize: '13px',
        color: (props) => props.color ?? '#fff',
        border: (props) =>
            props.background
                ? `2px solid ${
                      props.disabled ? 'rgba(0, 0, 0, 0.26)' : props.background
                  }`
                : '',
        backgroundColor: 'transparent',
        height: '40px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: (props) => (props.background ? 'f0f0f0' : ''),
        },
        '&:focus': {
            outline: '0',
        },
    },
}));

export function NavButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const styles = useStyles(props);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (props.closeonbutton) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Button
                data-cy={props.datacy}
                aria-label="NavButton"
                disabled={props.disabled}
                ref={anchorRef}
                aria-controls={open ? props.id : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
                className={styles.btn}
                style={{ marginLeft: `${props.padding ? '20px' : 0}` }}
                endIcon={props.icon}>
                {props.text}
            </Button>
            <Popper
                id="mypopper"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ marginTop: '20px', zIndex: 10 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}>
                        <Paper
                            style={{
                                border: '2px solid black',
                                borderRadius: '4px',
                            }}
                            onClick={handleClose}>
                            <ClickAwayListener onClickAway={handleClose}>
                                {props.children}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
