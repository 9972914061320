import { MenuItem } from '@material-ui/core';
import { getProperRoute } from '@utils/helpers/app.helpers';
import { deleteProjectHelper } from '@utils/helpers/projects.helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function SiteSettingsCommonOptions(props) {
    const { id, groupId } = useParams();

    const { t } = useTranslation();

    const onDeleteProject = () => {
        deleteProjectHelper(t, groupId, id, () => {
            window.location.href = getProperRoute(`groups/${groupId}/projects`);
        });
    };

    return (
        <>
            <MenuItem
                onClick={() =>
                    props.clickEditProject && props.clickEditProject()
                }>
                {t('pages_sitesettings_sitesettingspage_editproj')}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    props.clickCreateSiteCopy && props.clickCreateSiteCopy()
                }>
                {t('pages_sitesettings_sitesettingspage_copynew')}
            </MenuItem>
            {/* <MenuItem
                onClick={() =>
                    props.clickBackupGeneration && props.clickBackupGeneration()
                }>
                {t('pages_sitesettings_sitesettingspage_backup')}
            </MenuItem> */}
            <MenuItem onClick={() => onDeleteProject()}>
                {t('pages_sitesettings_sitesettingspage_deleteproj')}
            </MenuItem>
        </>
    );
}
