import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import React, { useEffect } from 'react';
import { customTheme } from '@/App';
import { hexToRgba } from '@utils/helpers/colors.helpers';

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;

const useStyles = makeStyles((theme) => ({
    root: {
        width,
        height,
        padding: 0,
        margin: theme.spacing(1),
        overflow: 'unset',
    },
    switchBase: {
        padding: pxToRem(gap),
        '&$checked': {
            color: '#fff',
            transform: `translateX(calc(${width} - ${size} - ${pxToRem(
                2 * gap
            )}))`,
            '& + $track': {
                backgroundColor: customTheme.palette.primary.main,
                opacity: 1,
                border: 'none',
            },
            '& $thumb': {
                backgroundColor: '#fff',
            },
        },
    },
    track: {
        borderRadius: 40,
        border: `solid ${hexToRgba(customTheme.palette.primary.main, 0.8)}`,
        borderWidth,
        backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.1),
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        boxSizing: 'border-box',
    },
    thumb: {
        boxShadow: 'none',
        backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.8),
        width: size,
        height: size,
    },
    checked: {},
    focusVisible: {},
}));

export function CustomSwitch(props) {
    const [toggled, setToggled] = React.useState(false);
    const styles = useStyles();

    useEffect(() => {
        setToggled(props.checked ?? false);
    }, [props.checked]);

    const switchChecked = (checked) => {
        setToggled(checked);
        props.onChecked(checked);
    };

    return (
        <Switch
            classes={styles}
            checked={toggled}
            onChange={(e) => switchChecked(e.target.checked)}
        />
    );
}
