import { SingleSelect } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MoveGroupsModal(props) {
    const [group, setGroup] = useState('');

    const { t } = useTranslation();

    const groupChange = (grp) => {
        setGroup(grp);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        props.confirm(group);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_groups_movegroupsmodal_move')}
            </DialogTitle>
            <DialogContent>
                <div style={{ marginTop: '20px' }}>
                    <SingleSelect
                        title={t('pages_groups_movegroupsmodal_moveto')}
                        data={props.data}
                        onChange={groupChange}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_move')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
