import { GroupApi } from '@aurum/nucleus-client-api';
import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import { FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import CreateGatewayModal from '@views/devices/create-gateway/CreateGatewayModal';
import CreateSensorModal from '@views/devices/create-sensor/CreateSensorModal';
import DeviceTableEntry from '@views/devices/device-entry/DeviceTableEntry';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DevicesPage(props) {
    const [gateways, setGateways] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [sensorCreationError, setSensorCreationError] = useState(null);
    const [groups, setGroups] = useState([]);
    const [createGatewayModal, setCreateGatewayModal] = useState(false);
    const [createSensorModal, setCreateSensorModal] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        new GatewayApi().gatewayGetAllGateways(function (_, data, response) {
            if (response.ok) {
                setGateways(data);
            }
        });
        new TemperatureApi().temperatureGetSensors2(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setSensors(data);
            }
        });
        new GroupApi().groupGetGroups(function (_, data, response) {
            if (response.ok) {
                setGroups(data);
            }
        });
    }, []);

    const sensorTypes = [
        {
            value: 'temperature_eltLite',
            description: `${t(
                'pages_devices_devicespage_temperature'
            )} - ELT Lite`,
        },
        {
            value: 'temperature_elt2',
            description: `${t(
                'pages_devices_devicespage_temperature'
            )} - ELT-2`,
        },
        {
            value: 'temperature_lsn50',
            description: `${t(
                'pages_devices_devicespage_temperature'
            )} - Dragino LSN50`,
        },
        {
            value: 'allInOneChlorine_lsn50',
            description: `All in one - Dragino LSN50`,
        },
        {
            value: 'chlorine_eltLite',
            description: `${t(
                'pages_devices_devicespage_chlorine'
            )} - ELT Lite`,
        },
        {
            value: 'digitalInput_eltLite',
            description: `${t('pages_devices_devicespage_dinput')} - ELT Lite`,
        },
        {
            value: 'valve_mlr003',
            description: `Valve actuator - MLR003`,
        },
        {
            value: 'flowrate',
            description: t('pages_addgraph_settingstab_flowrate'),
        },
        {
            value: 'ultrasonic_eltLite',
            description: 'Distance - ELT Lite',
        },
        {
            value: 'distance_lmds200',
            description: `Distance - Dragino LMDS200`,
        },
        {
            value: 'distance_ldds45',
            description: `Distance - Dragino LDDS45`,
        },
        {
            value: 'distance_ldds75',
            description: `Distance - Dragino LDDS75`,
        },
    ];

    const confirmGatewayCreation = (uuid, name) => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== ''
        ) {
            new GatewayApi().gatewayAddGateway(
                uuid,
                { uuid: uuid, description: name, name: name },
                function (_, data, response) {
                    if (response.ok) {
                        setGateways([data, ...gateways]);
                    }
                }
            );
        }
    };

    const confirmSensorCreation = (uuid, name, type) => {
        if (
            !isNullOrUndefined(name) &&
            name.trim() !== '' &&
            !isNullOrUndefined(uuid) &&
            uuid.trim() !== ''
        ) {
            new TemperatureApi().temperatureAddSensor(
                uuid,
                { uuid: uuid, description: name, name: name, type: type },
                function (_, data, response) {
                    if (response.ok) {
                        setSensors([data, ...sensors]);
                        setSensorCreationError(null);
                    } else if (response.statusCode === 400) {
                        setSensorCreationError(response.body);
                    } else if (response.statusCode === 500) {
                        setSensorCreationError(
                            t('pages_devices_devicespage_formaterror')
                        );
                    }
                }
            );
        }
    };

    const gatewayRows = gateways?.map((gw) => [
        {
            link: getProperRoute(`devices/gateways/${gw.uuid}`),
            text: gw.name,
            value: (
                <DeviceTableEntry
                    gateway
                    name={gw.name}
                    link={getProperRoute(`devices/gateways/${gw.uuid}`)}
                />
            ),
        },
        {
            link: null,
            text: gw.description?.split('-')[0],
            value: gw.description?.split('-')[0],
        },
        {
            link: isNullOrUndefined(gw.groupId)
                ? null
                : getProperRoute(`groups/${gw.groupId}/settings`),
            text: groups?.find((g) => g.id === gw.groupId)?.name,
            value: groups?.find((g) => g.id === gw.groupId)?.name,
        },
    ]);

    const sensorRows = sensors?.map((sensor) => [
        {
            link: getProperRoute(`devices/sensors/${sensor.uuid}`),
            text: sensor.name,
            value: (
                <DeviceTableEntry
                    sensor
                    name={sensor.name}
                    link={getProperRoute(`devices/sensors/${sensor.uuid}`)}
                />
            ),
        },
        {
            link: null,
            text:
                sensorTypes?.find((t) => t.value === sensor.type)
                    ?.description ?? sensor.type,
            value:
                sensorTypes?.find((t) => t.value === sensor.type)
                    ?.description ?? sensor.type,
        },
        {
            link: isNullOrUndefined(sensor.groupId)
                ? null
                : getProperRoute(`groups/${sensor.groupId}/settings`),
            text: groups?.find((g) => g.id === sensor.groupId)?.name,
            value: groups?.find((g) => g.id === sensor.groupId)?.name,
        },
    ]);

    const rows = [...gatewayRows, ...sensorRows];

    return (
        <div style={{ marginLeft: '5%', width: '85%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <span style={{ width: 'inherit', justifySelf: 'flex-start' }}>
                    {t('pages_devices_devicepage_devices')}
                </span>
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'admin')}>
                    {userIsAtLeast(props.user, 'admin') ? (
                        <>
                            <MenuItem
                                onClick={() => setCreateGatewayModal(true)}>
                                {t('pages_devices_devicespage_creategw')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => setCreateSensorModal(true)}>
                                {t('pages_devices_devicespage_createsensor')}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <hr />
            <br />
            <FilesTable
                responsive
                pagination
                search
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_addgraph_settingstab_type'),
                    t('pages_devices_devicespage_assigned'),
                ]}
                rows={rows}
            />
            <CreateGatewayModal
                open={createGatewayModal}
                handleClose={() => setCreateGatewayModal(false)}
                confirm={confirmGatewayCreation}
            />
            <CreateSensorModal
                creationError={sensorCreationError}
                open={createSensorModal}
                handleClose={() => setCreateSensorModal(false)}
                confirm={confirmSensorCreation}
                dataType={sensorTypes}
            />
        </div>
    );
}
