import React from 'react';

const BackwardFlushIcon = (props) => (
    <g>
        <rect
            x="768.185"
            y="71.505"
            width="146.51"
            height="103.054"
            style={{ fill: '#666' }}
        />
        <rect
            x="768.185"
            y="491.17"
            width="146.51"
            height="103.054"
            style={{ fill: '#666' }}
        />
        <path
            d="M781.843,388.426l36.627,0l0,-18.624l37.25,37.249l-37.25,37.248l0,-18.624l-36.627,-0l-0,56.804l119.194,-0l-0,-299.85l-119.194,-0l-0,205.797Z"
            style={{ fill: '#666', fillRule: 'nonzero' }}
        />
        <path
            d="M841.44,668.099l37.249,-37.247l-18.624,0l-0,-36.628l-37.249,-0l0,36.628l-18.624,0l37.248,37.247Z"
            style={{ fill: '#666', fillRule: 'nonzero' }}
        />
    </g>
);

export default BackwardFlushIcon;
