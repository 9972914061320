import { SiteApi } from '@aurum/nucleus-client-api';

export function deleteProjectHelper(t, groupId, id, okCallback) {
    swal({
        title: t('swal_sure'),
        text: t('swal_cannot_recover_resource'),
        icon: 'warning',
        buttons: [t('swal_no'), t('swal_yes')],
        dangerMode: true,
    }).then(function (confirmResult) {
        if (confirmResult) {
            new SiteApi().siteDeleteSite(
                parseInt(groupId, 10),
                parseInt(id, 10),
                function (_, data, response) {
                    if (response.ok) {
                        okCallback && okCallback();
                    } else if (response.statusCode >= 400) {
                        swal({
                            text: t(
                                'pages_sitesettings_sitesettingspage_empty'
                            ),
                            icon: 'error',
                        });
                    }
                }
            );
        }
    });
}

export function editProjectHelper(project, groupId, id, okCallback) {
    new SiteApi().siteUpdateSite(
        parseInt(groupId, 10),
        parseInt(id, 10),
        {
            name: project.name,
            description: project.description,
            type: project.type,
        },
        function (_, data, response) {
            if (response.ok) {
                okCallback && okCallback();
            }
        }
    );
}
