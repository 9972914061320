import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useGauge } from 'use-gauge';

import './graphstyles.css';

export function MetricPlot(props) {
    let value = props.data?.at(0)?.datapoints?.at(-1)?.y;
    value = Number.isInteger(value) ? value : value?.toFixed(2);

    const gauge = useGauge({
        domain: [0, 100],
        startAngle: 90,
        endAngle: 270,
        numTicks: 21,
        diameter: 300,
    });

    const needle = gauge.getNeedleProps({
        value: value * 10,
        baseRadius: 8,
        tipRadius: 2,
    });

    const getClassName = (val) => {
        let nameOfClass = 'stroke-gray-300';
        if (val < 6) nameOfClass = 'stroke-green-300';
        else if (val >= 6) nameOfClass = 'stroke-red-400';
        return nameOfClass;
    };

    const getSvgInfo = () => {
        return (
            <>
                <g id="ticks">
                    {gauge.ticks.map((angle) => {
                        const asValue = gauge.angleToValue(angle);
                        const showText = Number.isInteger(asValue / 10);
                        return (
                            <React.Fragment key={`tick-group-${angle}`}>
                                <line
                                    className={getClassName(asValue / 10)}
                                    strokeWidth={2}
                                    {...gauge.getTickProps({
                                        angle,
                                        length: 8,
                                    })}
                                />
                                {showText && (
                                    <text
                                        className="text-sm font-medium"
                                        {...gauge.getLabelProps({
                                            angle,
                                            offset: 20,
                                        })}>
                                        {asValue / 10}
                                    </text>
                                )}
                            </React.Fragment>
                        );
                    })}
                </g>
                <g id="needle">
                    <circle className="fill-gray-300" {...needle.base} r={12} />
                    <circle className="fill-gray-700" {...needle.base} />
                    <circle className="fill-gray-700" {...needle.tip} />
                    <polyline
                        className="fill-gray-700"
                        points={needle.points}
                    />
                    <circle className="fill-white" {...needle.base} r={4} />
                </g>
            </>
        );
    };

    const svgProps = gauge.getSVGProps();
    delete svgProps.height;
    delete svgProps.width;

    return (
        <>
            {props.removeText ? (
                <></>
            ) : (
                <div className="row" style={{ textAlign: 'center' }}>
                    <Typography style={{ width: '100%' }} variant="h4">
                        {value ?? ''}
                    </Typography>
                </div>
            )}
            {props.removeText ? (
                <div
                    className="row"
                    style={{
                        justifyContent: 'center',
                        height: props.height ?? '100%',
                        width: props.width ?? '100%',
                    }}>
                    <svg
                        style={{ width: '100%', height: '100%' }}
                        className="w-full overflow-visible p-2"
                        {...svgProps}>
                        {getSvgInfo()}
                    </svg>
                </div>
            ) : (
                <div
                    className="row"
                    style={{ justifyContent: 'center', height: '230px' }}>
                    <svg className="w-full overflow-visible p-2" {...svgProps}>
                        {getSvgInfo()}
                    </svg>
                </div>
            )}
        </>
    );
}
