import { customTheme } from '@/App';
import { Checkbox, ListItemText, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { hexToRgba } from '@utils/helpers/colors.helpers';
import { makeRandomStr } from '@utils/helpers/text.helpers';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
    select: {
        minWidth: 50,
        background: 'white',
        color: 'black',
        fontWeight: 200,
        borderRadius: 40,
        border: '1px solid #bdbdbd',
        paddingLeft: 24,
        paddingTop: 10,
        height: '20px',
        '&:focus': {
            borderRadius: 40,
            borderColor: hexToRgba(customTheme.palette.primary.main, 0.2),
        },
    },
    label: {
        marginLeft: '0.75rem',
        color: '#616161',
    },
    icon: {
        color: '#bdbdbd',
        right: 12,
        position: 'absolute',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    paper: {
        borderRadius: 14,
        marginTop: 8,
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        background: 'white',
        height: '400px',
        '& li': {
            fontWeight: 200,
            height: '40px',
            paddingTop: 12,
            paddingBottom: 12,
        },
        '& li:hover': {
            background: hexToRgba(customTheme.palette.primary.main, 0.2),
        },
    },
}));

const checkBoxStyles = makeStyles((theme) => ({
    root: {
        '&$checked': {
            color: customTheme.palette.primary.main,
            '&:hover': {
                backgroundColor: hexToRgba(
                    customTheme.palette.primary.main,
                    0.1
                ),
            },
        },
        '&:hover': {
            backgroundColor: hexToRgba(customTheme.palette.primary.main, 0.1),
        },
    },
    checked: {},
}));

export function MultipleSelect(props) {
    const [val, setVal] = useState(undefined);
    const styles = useStyles();
    const cbstyles = checkBoxStyles();

    useEffect(() => {
        if (isNullOrUndefined(val) && !isNullOrUndefined(props.value)) {
            setVal(props.value ?? []);
        }
    }, [props.value]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (isNullOrUndefined(val)) {
            return;
        }
        if (val.includes('allmps') && !value.includes('allmps')) {
            props.onChange([]);
            setVal([]);
        } else if (value.includes('allmps') && !val.includes('allmps')) {
            props.onChange(props.data.map((d) => d.value));
            setVal(['allmps', ...props.data.map((d) => d.value)]);
        } else {
            props.onChange(
                typeof value === 'string'
                    ? value.split(',')
                    : value.filter((v) => v !== 'allmps')
            );
            setVal(typeof value === 'string' ? value.split(',') : value);
        }
    };

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + ' ' + styles.icon} />
        );
    };

    const menuProps = {
        classes: {
            paper: styles.paper,
            list: styles.list,
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const items = [];
    items.push(
        <MenuItem key={makeRandomStr()} value="allmps">
            <Checkbox
                classes={{ root: cbstyles.root, checked: cbstyles.checked }}
                checked={val?.indexOf('allmps') > -1}
            />
            <ListItemText primary="All Measurement Points" />
        </MenuItem>
    );
    props.data?.forEach((d) => {
        items.push(
            <MenuItem key={makeRandomStr()} value={d.value}>
                <Checkbox
                    classes={{ root: cbstyles.root, checked: cbstyles.checked }}
                    checked={val?.indexOf(d.value) > -1}
                />
                <ListItemText primary={d.description} />
            </MenuItem>
        );
    });

    return (
        <FormControl style={{ width: props.width ?? '100%' }}>
            <Typography className={styles.label}>{props.title}</Typography>
            <Select
                multiple
                disableUnderline
                classes={{ root: styles.select }}
                style={{ width: '100%' }}
                renderValue={(selected) =>
                    selected
                        .map((id) =>
                            id == 'allmps'
                                ? ''
                                : props.data.find((d) => d.value === id)
                                      ?.description
                        )
                        .join(', ')
                }
                MenuProps={menuProps}
                IconComponent={iconComponent}
                value={val ?? []}
                onChange={handleChange}>
                {items}
            </Select>
        </FormControl>
    );
}
