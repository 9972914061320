import { customTheme } from '@/App';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { roundTextFieldStylesHook } from '@mui-treasury/styles/textField/round';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 40,
        border: '1px solid',
        borderColor: customTheme.palette.primary.main,
        overflow: 'hidden',
        backgroundColor: '#fff',
        '&:hover:not($disabled)': {
            borderColor: customTheme.palette.primary.main,
        },
        '& > svg': {
            color: customTheme.palette.primary.main,
        },
    },
    colorSecondary: {},
    focused: {
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.24)',
        borderColor: customTheme.palette.primary.main,
        '&:hover:not($disabled)': {
            borderColor: customTheme.palette.primary.main,
        },
        '&$colorSecondary': {
            borderColor: customTheme.palette.primary.main,
            '&:hover:not($disabled)': {
                borderColor: customTheme.palette.primary.main,
            },
        },
    },
    error: {
        backgroundColor: '#fff9f9',
        borderColor: '#ff5252',
        '&:hover:not($disabled)': {
            borderColor: '#ff5252',
        },
    },
    disabled: {
        backgroundColor: customTheme.palette.primary.main,
    },
    input: {
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
            paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
            paddingRight: '0.5rem',
        },
    },
}));

export function TextEdit(props) {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const styles = useStyles();
    const inputBaseStyles = roundTextFieldStylesHook.useInputBase();
    const inputLabelStyles = roundTextFieldStylesHook.useInputLabel();

    const handleChange = (event) => {
        setValue(event?.target?.value ?? '');
        props.onChange && props.onChange(event?.target?.value ?? '');
    };

    return (
        <TextField
            data-cy={props.datacy}
            ref={props.reference}
            className={styles}
            style={{ width: props.width ?? '100%' }}
            value={value}
            label={props.label}
            placeholder={props.placeholder}
            onChange={handleChange}
            margin="none"
            InputLabelProps={{ shrink: true, classes: inputLabelStyles }}
            InputProps={{ classes: inputBaseStyles, disableUnderline: true }}
            required={props.required}
            type={props.type}
            autoFocus={props.autoFocus}
            onKeyDown={(evt) => props.onKeyDown && props.onKeyDown(evt)}
        />
    );
}
