import { customTheme } from '@/App';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isNumeric, makeRandomStr } from '@utils/helpers/text.helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    colorBlack: { color: '#000' },
}));

export default function ValveManualRegulation(props) {
    const [manualStrings, _setManualStrings] = useState([]);
    const [curEditing, setCurEditing] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const { t } = useTranslation();
    const styles = useStyles();

    const manualStringsRef = useRef(manualStrings);
    const setManualStrings = (data) => {
        manualStringsRef.current = data;
        _setManualStrings(data);
    };

    useEffect(() => {
        setManualStrings(props.site?.configOptions?.options?.strings ?? []);
    }, [props.site]);

    const StylingTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: customTheme.palette.primary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customTheme.palette.primary.main,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: customTheme.palette.primary.main,
        },
    });

    const onValvePositionChanged = (e, idx) => {
        if (!isNumeric(e.target.value) && e.target.value !== '') {
            return;
        }
        let value = parseInt(e.target.value, 10);
        if (value > 100) value = 100;
        if (value < 0 || isNaN(value)) value = 0;

        manualStringsRef.current[idx].valvePosition = value;
        setCurEditing(idx);
        setRefreshFlag(!refreshFlag);
    };

    const valvePositionCallback = useCallback(
        (e, idx) => onValvePositionChanged(e, idx),
        [curEditing, refreshFlag]
    );

    const valvePositions = [];
    manualStringsRef.current?.forEach((str, idx) => {
        valvePositions.push(
            <StylingTextField
                key={makeRandomStr()}
                focused={curEditing === idx}
                InputProps={{ classes: { input: styles.colorBlack } }}
                style={{
                    width: '100%',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginTop: '20px',
                }}
                label={`Valve Position ${idx + 1} (%)`}
                placeholder="0"
                value={str.valvePosition ?? 0}
                variant="standard"
                onChange={(e) => valvePositionCallback(e, idx)}
            />
        );
    });

    return <>{valvePositions}</>;
}
