import { AdministrationApi } from '@aurum/nucleus-client-api';
import GatewayApi from '@aurum/nucleus-client-api/dist/api/GatewayApi';
import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import {
    CustomBreadcrumbs,
    RoundedText,
    SingleSelect,
    TextEdit,
} from '@components';
import { Button } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import swal from 'sweetalert';

export default function MoveDevicesPage(props) {
    const [gateways, setGateways] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [scanned, setScanned] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [forceReload, setForceReload] = useState(0);
    const [customerServer, setCustomerServer] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        new GatewayApi().gatewayGetAllGateways(function (_, data, response) {
            if (response.ok) {
                setGateways(data);
            }
        });
        new TemperatureApi().temperatureGetSensors2(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setSensors(data);
            }
        });
    }, [forceReload]);

    const onRemoveDevice = (name) => {
        setScanned(scanned.filter((d) => d !== name));
    };

    const serverChanged = (txt) => {
        setCustomerServer(txt);
    };

    const onDeviceScanned = (txt) => {
        const sensorQrContent = /ELT[a-zA-Z0-9+],EUI:[a-fA-F0-9]{16}/gm;
        const sensorReplace = /ELT[a-zA-Z0-9+],EUI:/gm;
        const aquacloudQrContent =
            /AquaCloud,PN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+],SN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+]/gm;
        const aquacloudReplace =
            /AquaCloud,PN=[a-zA-Z0-9+]-[a-zA-Z0-9+]-[a-zA-Z0-9+],SN=/gm;
        if (new RegExp(aquacloudQrContent).test(txt)) {
            addScanned(txt.replace(aquacloudReplace, ''));
        } else if (new RegExp(sensorQrContent).test(txt)) {
            addScanned(txt.replace(sensorReplace, ''));
        } else {
            addScanned(txt);
        }
    };

    const clearAll = () => {
        swal({
            title: t('swal_sure'),
            text: 'All devices will be removed from the list',
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                setScanned([]);
            }
        });
    };

    const moveDevices = () => {
        if (isNullOrUndefined(customerServer) || customerServer.trim() === '') {
            setErrorMsg('The customer server cannot be empty');
            return;
        }
        const serverValidate =
            /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
        if (!new RegExp(serverValidate).test(customerServer)) {
            setErrorMsg('The format of the customer server is not valid');
            return;
        }
        if (scanned.length === 0) {
            setErrorMsg('At least one device needs to be scanned');
            return;
        }
        swal({
            title: t('swal_sure'),
            text: "All devices will be moved to the indicated customer server and won't be available in this server anymore",
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                new AdministrationApi().administrationMoveDevices(
                    { customerServer: customerServer, deviceNames: scanned },
                    function (_, data, response) {
                        if (response.ok) {
                            setScanned([]);
                            setCustomerServer('');
                            setErrorMsg(null);
                            setForceReload(forceReload + 1);
                        } else {
                            setErrorMsg(response.body);
                        }
                    }
                );
            }
        });
    };

    const keyDown = (evt) => {
        if (evt.key === 'Enter') {
            onDeviceScanned(evt.target.value);
        }
    };

    const addScanned = (txt) => {
        let device = sensors.find((s) => s.description === txt);
        if (isNullOrUndefined(device)) {
            device = gateways.find((g) => g.description === txt);
        }
        if (
            !isNullOrUndefined(device) &&
            isNullOrUndefined(scanned.find((d) => d === device.description))
        ) {
            setScanned([...scanned, device.description]);
        }
    };

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    let allDevices = [
        ...gateways.map((g) => {
            return {
                value: g.description,
                description: g.description,
            };
        }),
        ...sensors.map((s) => {
            return {
                value: s.description,
                description: s.description,
            };
        }),
    ];
    allDevices = allDevices.filter((d) => !scanned.some((s) => s === d.value));

    const texts = [];
    scanned.forEach((d) => {
        texts.push(
            <div
                className="col-lg-2 col-md-3 col-sm-4 col-xs-6"
                style={{ marginTop: '10px' }}>
                <RoundedText
                    showClose
                    onClose={(name) => onRemoveDevice(name)}
                    resourceId={d}
                    text={d}
                    width={`130px`}
                    color="outlined"
                />
            </div>
        );
    });
    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute('diagnostics'),
                            text: 'Admin Area',
                        },
                        {
                            link: getProperRoute(`devices`),
                            text: t('pages_devices_devicepage_devices'),
                        },
                        {
                            link: getProperRoute('/'),
                            text: 'Move to Customer Server',
                        },
                    ]}
                />
            </div>
            <hr />
            <br />
            {isNullOrUndefined(errorMsg) ? (
                <></>
            ) : (
                <div>
                    <Alert onClose={() => setErrorMsg(null)} severity="error">
                        {errorMsg}
                    </Alert>
                    <br />
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}>
                <div style={{ width: isMobile ? '100%' : '50%' }}>
                    <TextEdit
                        label="Customer Server"
                        onChange={serverChanged}
                        value={customerServer}
                    />
                </div>
                <div
                    style={{
                        width: isMobile ? '100%' : '50%',
                        marginLeft: isMobile ? '0px' : '20px',
                        marginTop: isMobile ? '20px' : '0px',
                    }}>
                    <SingleSelect
                        title="Scan all devices that will be moved to the customer server"
                        data={allDevices}
                        onChange={onDeviceScanned}
                        onKeyDown={keyDown}
                    />
                </div>
            </div>
            <div
                className="row"
                style={{
                    marginTop: '50px',
                    height: '400px',
                    overflowY: 'scroll',
                    border: '1px solid #bdbdbd',
                    borderRadius: '8px',
                    paddingBottom: '10px',
                }}>
                {texts}
            </div>
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <Button
                    aria-label="Clear all"
                    style={{ marginRight: '100px' }}
                    onClick={() => clearAll()}
                    color="secondary">
                    Clear All
                </Button>
                <Button
                    aria-label="Move"
                    onClick={() => moveDevices()}
                    color="primary">
                    Move
                </Button>
            </div>
        </div>
    );
}
