import { AccountApi } from '@aurum/nucleus-client-api';
import { TextEdit } from '@components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordModal(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [token, setToken] = useState('');
    const [infoMsg, setInfoMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [confirmReset, setConfirmReset] = useState(false);

    const { t } = useTranslation();

    const emailChanged = (txt) => {
        setEmail(txt);
    };

    const passwordChanged = (txt) => {
        setPassword(txt);
    };

    const repeatPasswordChanged = (txt) => {
        setRepeatPassword(txt);
    };

    const tokenChanged = (txt) => {
        setToken(txt);
    };

    const closeDialog = () => {
        setErrorMsg(null);
        setInfoMsg(null);
        setEmail('');
        setPassword('');
        setRepeatPassword('');
        setToken('');
        setConfirmReset(false);
        props.handleClose && props.handleClose();
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (confirmReset) {
            if (
                isNullOrUndefined(email) ||
                email.trim() === '' ||
                isNullOrUndefined(password) ||
                password.trim() === '' ||
                isNullOrUndefined(repeatPassword) ||
                repeatPassword.trim() === '' ||
                isNullOrUndefined(token) ||
                token.trim() === ''
            ) {
                setInfoMsg(null);
                setErrorMsg(t('pages_users_createusermodal_allfields'));
                return;
            }
            new AccountApi().accountConfirmResetPassword(
                {
                    mail: email,
                    newPassword: password,
                    confirm: repeatPassword,
                    token: token,
                },
                function (_, data, response) {
                    if (response.ok) {
                        closeDialog();
                    } else {
                        setInfoMsg(null);
                        setErrorMsg(
                            t('pages_login_forgotpasswordmodal_verifyinputs')
                        );
                    }
                }
            );
        } else {
            if (isNullOrUndefined(email) || email.trim() === '') {
                setInfoMsg(null);
                setErrorMsg(t('pages_users_createusermodal_allfields'));
                return;
            }
            new AccountApi().accountResetPassword(
                { mail: email },
                function (_, data, response) {
                    if (response.ok) {
                        setConfirmReset(true);
                        setInfoMsg(t('pages_login_forgotpasswordmodal_info'));
                        setErrorMsg(null);
                    } else {
                        setInfoMsg(null);
                        setErrorMsg(t('pages_users_createusermodal_bademail'));
                    }
                }
            );
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => {}}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_login_forgotpasswordmodal_reset')}
            </DialogTitle>
            <DialogContent>
                {!isNullOrUndefined(infoMsg) ? (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="info">
                            {infoMsg}
                        </Alert>
                        <br />
                    </div>
                ) : !isNullOrUndefined(errorMsg) ? (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                ) : (
                    <></>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_login_forgotpasswordmodal_email')}
                        type="email"
                        required
                        placeholder="example@example.com"
                        value={email}
                        onChange={emailChanged}
                    />
                </div>
                {confirmReset ? (
                    <>
                        <div style={{ marginTop: '20px' }}>
                            <TextEdit
                                label={t(
                                    'pages_login_forgotpasswordmodal_newpass'
                                )}
                                required
                                type="password"
                                value={password}
                                onChange={passwordChanged}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <TextEdit
                                label={t(
                                    'pages_login_forgotpasswordmodal_repeatpass'
                                )}
                                required
                                type="password"
                                value={repeatPassword}
                                onChange={repeatPasswordChanged}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <TextEdit
                                label={t(
                                    'pages_login_forgotpasswordmodal_token'
                                )}
                                required
                                value={token}
                                onChange={tokenChanged}
                            />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => closeDialog()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => onOkClick()}
                    color="primary">
                    {confirmReset
                        ? t('pages_login_forgotpasswordmodal_resetbtn')
                        : t('pages_login_forgotpasswordmodal_sendtoken')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
