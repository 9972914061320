import TemperatureApi from '@aurum/nucleus-client-api/dist/api/TemperatureApi';
import { CustomBreadcrumbs, FilesTable, SingleSelect } from '@components';
import { Button } from '@material-ui/core';
import {
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { convertStringUtcToLocaleString } from '@utils/helpers/timestamp.helpers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

DiagnosticsSensorPage.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        role: PropTypes.string,
        timeZone: PropTypes.string,
    }),
};

const MicropeltSamplingIntervals = {
    Minutes5: 300, // 5 minutes in seconds
    Minutes10: 600, // 10 minutes in seconds
    Minutes60: 3600, // 60 minutes in seconds
    Minutes120: 7200, // 120 minutes in seconds
    Minutes480: 28800, // 480 minutes in seconds
};

export default function DiagnosticsSensorPage(props) {
    const [sensor, setSensor] = useState(null);
    const [showPage, setShowPage] = useState(false);
    const [samplingInterval, setSamplingInterval] = useState(600);
    const [valvePosition, setValvePosition] = useState(0);
    const { id } = useParams();
    const { t } = useTranslation();

    const fetchSensorDetails = useCallback(() => {
        try {
            new TemperatureApi().temperatureGetMeasuringElementForDiagnostic(
                id,
                function (_, data, response) {
                    if (
                        response.ok &&
                        !isNullOrUndefined(response.body?.uuid)
                    ) {
                        setSensor(response.body);
                    } else {
                        window.location.href = getProperRoute('diagnostics');
                    }
                }
            );
        } catch (error) {
            window.location.href = getProperRoute('diagnostics');
        }
    }, [id]);

    useEffect(() => {
        if (isNullOrUndefined(props.user)) return;

        if (userIsAtLeast(props.user, 'maintainer')) {
            setShowPage(true);
            fetchSensorDetails();
        } else {
            window.location.href = getProperRoute('sites');
        }
    }, [props.user, fetchSensorDetails]);

    const createSensorRows = (sensorData) => {
        return [
            // map or create rows based on sensorData
            // example structure given below, similar to original structure
            [
                {
                    link: null,
                    text: renderSensorText(sensorData?.uuid),
                    value: renderSensorValue('UUID', sensorData?.uuid),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensorData?.description),
                    value: renderSensorValue(
                        t('pages_devices_devicepage_acid'),
                        sensorData?.description
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.name),
                    value: renderSensorValue(
                        t('pages_devices_devicepage_name'),
                        sensor?.name
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(getSensorStatus(sensor?.status)),
                    value: renderSensorValue(
                        t('pages_devices_devicepage_status'),
                        getSensorStatus(sensor?.status)
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.lastRX, true),
                    value: renderSensorValue(
                        t('pages_diagnostics_diagnosticssensorpage_lastrx'),
                        sensor?.lastRX,
                        true
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.lastJoin, true),
                    value: renderSensorValue(
                        t('pages_diagnostics_diagnosticssensorpage_lastjoin'),
                        sensor?.lastJoin,
                        true
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.measurementPointAssigned),
                    value: renderSensorValue(
                        'Measurement Point',
                        sensor?.measurementPointAssigned
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.battery),
                    value: renderSensorValue(
                        t('pages_diagnostics_diagnosticspage_battery'),
                        sensor?.battery
                    ),
                },
            ],
            [
                {
                    link: null,
                    text: renderSensorText(sensor?.value),
                    value: renderSensorValue(
                        t('pages_diagnostics_diagnosticspage_value'),
                        sensor?.value
                    ),
                },
            ],
        ];
    };

    const getSensorStatus = (status) => {
        if (status === 1) {
            return t('pages_devices_devicepage_inactive');
        } else if (status === 2) {
            return t('pages_devices_devicepage_active');
        }
        return t('pages_devices_devicepage_unassigned');
    };

    const renderSensorText = (value, isDate = false) => {
        if (value && isDate) {
            value = convertStringUtcToLocaleString(
                value,
                props.user?.timeZone ?? 'Europe/London'
            );
        }
        return isNullOrUndefined(value) ? '-' : value;
    };

    const renderSensorValue = (label, value, isDate = false) => {
        if (value && isDate) {
            value = convertStringUtcToLocaleString(
                value,
                props.user?.timeZone ?? 'Europe/London'
            );
        }
        return (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                {label}: &nbsp;
                <p style={{ fontWeight: 'bold', marginTop: 'revert' }}>
                    {isNullOrUndefined(value) ? '-' : value}
                </p>
            </div>
        );
    };

    const handleIntervalChange = (val) => {
        setSamplingInterval(val);
    };

    const handleValvePositionChange = (event) => {
        setValvePosition(event.target.value);
    };

    const renderSamplingIntervalInput = () => {
        if (sensor?.type === 'valve_mlr003') {
            const sensorData = Object.entries(MicropeltSamplingIntervals).map(
                ([_key, value]) => ({ value: value, description: `${value}` })
            );
            return (
                <SingleSelect
                    width="150px"
                    data={sensorData}
                    onChange={handleIntervalChange}
                />
            );
        } else {
            return (
                <input
                    style={{ marginLeft: '20px' }}
                    type="number"
                    value={samplingInterval}
                    onChange={handleIntervalChange}
                    min="30"
                    max="28800"
                />
            );
        }
    };

    const renderValvePositionInput = () => (
        <input
            style={{ marginLeft: '20px' }}
            type="number"
            value={valvePosition}
            onChange={handleValvePositionChange}
            min="0"
            max="100"
        />
    );

    const sendDownlink = () => {
        if (samplingInterval < 30) {
            setSamplingInterval(30);
        }
        if (samplingInterval > 28800) {
            setSamplingInterval(28800);
        }
        const options = { samplingInterval: samplingInterval };
        if (sensor?.type === 'valve_mlr003') {
            options['valvePosition'] = valvePosition;
        }
        new TemperatureApi().temperatureSendSensorDownlink(
            id,
            options,
            function (_, _data, response) {
                if (response.ok) {
                    fetchSensorDetails();
                }
            }
        );
    };

    const rssiRows =
        sensor?.signalQuality?.map((sq) => [
            { text: sq.gateway, value: sq.gateway },
            { text: sq.rssi, value: sq.rssi },
            { text: sq.datr, value: sq.datr },
        ]) || [];

    const healthAlerts =
        sensor?.healthAlerts?.map((ha) => [{ text: ha, value: ha }]) || [];

    const downlinks = sensor?.downlinks?.map((dl) => [
        { text: dl.datetime, value: dl.datetime },
        { text: dl.txData.port, value: dl.txData.port },
        { text: dl.txData.data, value: dl.txData.data },
    ]);

    return showPage && sensor ? (
        <div style={{ width: '100%' }}>
            <CustomBreadcrumbs
                breadcrumbs={[
                    {
                        link: getProperRoute('diagnostics'),
                        text: 'Admin Area',
                    },
                    {
                        link: getProperRoute('diagnostics'),
                        text: t(
                            'pages_diagnostics_diagnosticsgatewaypage_diagnostics'
                        ),
                    },
                    {
                        link: getProperRoute('/'),
                        text: sensor?.description,
                    },
                ]}
            />
            <hr />
            <br />
            <div className="row">
                <div className="col-md-6">
                    <FilesTable
                        columns={[
                            t(
                                'pages_diagnostics_diagnosticsgatewaypage_properties'
                            ),
                        ]}
                        rows={createSensorRows(sensor)}
                    />
                </div>
                <div className="col-md-6">
                    <div style={{ width: '100%' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_gateway'
                                ),
                                'RSSI',
                                'Data Rate',
                            ]}
                            rows={rssiRows}
                        />
                    </div>
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <FilesTable
                            columns={[
                                t(
                                    'pages_diagnostics_diagnosticssensorpage_halerts'
                                ),
                            ]}
                            rows={healthAlerts}
                        />
                    </div>
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <h5>Downlink Controls</h5>
                        <div style={{ display: 'flex' }}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '20px',
                                }}>
                                Sampling Interval:
                            </span>
                            {renderSamplingIntervalInput()}
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '20px',
                                }}>
                                seconds
                            </span>
                        </div>
                        {sensor?.type === 'valve_mlr003' && (
                            <div style={{ marginTop: '20px' }}>
                                <span>Valve Position:</span>
                                {renderValvePositionInput()}
                            </div>
                        )}
                        <Button
                            style={{ marginTop: '10px' }}
                            aria-label={`sendDownlink`}
                            onClick={() => sendDownlink()}
                            color="primary">
                            Send Downlink
                        </Button>
                    </div>
                    <div style={{ width: '100%', marginTop: '20px' }}>
                        <h5>Scheduled Downlinks</h5>
                        <FilesTable
                            columns={['Creation Time', 'Port', 'Data']}
                            rows={downlinks}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
