import {
    MeasurementPointApi,
    SiteApi,
    SiteGroupApi,
    TemperatureApi,
} from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    FilesTable,
    MoreSettingsMenu,
    NavButton,
    SingleSelect,
    TableTextEdit,
    TreeStructure,
} from '@components';
import { MenuItem, MenuList } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    getFullGroups,
    getProperRoute,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    siteTypeIncludes,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import { useKeyPress } from '@utils/hooks/useKeyPressed';
import CreateSiteGroupModal from '@views/sites/create-site/CreateSiteGroupModal';
import MoveMpGroupsModal from '@views/sites/settings/measurement-points/move-measurement-point/MoveMpGroupsModal';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SiteSettingsCommonModals from './SiteSettingsCommonModals';
import SiteSettingsCommonOptions from './SiteSettingsCommonOptions';

export default function SiteSettingsPage(props) {
    const { id, groupId, gid } = useParams();

    const [groups, _setGroups] = useState(null); // all groups within this site
    const [mps, _setMps] = useState(null); // all measurement points within this site
    const [curGroup, _setCurGroup] = useState(
        isNullOrUndefined(gid) ? undefined : parseInt(gid, 10)
    ); // current group that is being displayed (if any)
    const [viewAsTree, setViewAsTree] = useState(false);
    const [editGroupModal, setEditGroupModal] = useState(false);
    const [moveItems, setMoveItems] = useState(false);
    const [numSelected, setNumSelected] = useState(0);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedMps, setSelectedMps] = useState([]);
    const [installation, setInstallation] = useState(0);
    const [allSensors, setAllSensors] = useState(null);
    const [mpBeingAdded, _setMpBeingAdded] = useState(null);
    const [groupBeingAdded, _setGroupBeingAdded] = useState(null);
    const [modalOpened, setModalOpened] = useState(-1);

    const mpBeingAddedRef = useRef(mpBeingAdded);
    const groupBeingAddedRef = useRef(groupBeingAdded);
    const mpsRef = useRef(mps);
    const groupsRef = useRef(groups);
    const curGroupRef = useRef(curGroup);
    const setMpBeingAdded = (data) => {
        mpBeingAddedRef.current = data;
        _setMpBeingAdded(data);
    };
    const setMps = (data) => {
        mpsRef.current = data;
        _setMps(data);
    };
    const setGroupBeingAdded = (data) => {
        groupBeingAddedRef.current = data;
        _setGroupBeingAdded(data);
    };
    const setGroups = (data) => {
        groupsRef.current = data;
        _setGroups(data);
    };
    const setCurGroup = (data) => {
        curGroupRef.current = data;
        _setCurGroup(data);
    };

    useKeyPress('Enter', () => onEnterPressed());
    useKeyPress('Escape', () => onEscapePressed());

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const _ = undefined;
        new SiteGroupApi().siteGroupGetGroups(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setGroups(response.body);
                }
            }
        );
        fetchMpsAndSensors();
    }, [id]);

    useEffect(() => {
        if (siteTypeIncludes(props.site?.type ?? '', 'filter')) {
            if (
                siteTypeIncludes(props.site?.type ?? '', 'temperatureControl')
            ) {
                navigate(
                    getProperRoute(
                        `groups/${groupId}/sites/temperature-control/${id}/settings`
                    )
                );
                return;
            }
        }

        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/settings`,
            `groups/${groupId}/sites/filter/${id}/parameters`
        );
    }, [props]);

    const fetchMpsAndSensors = () => {
        new MeasurementPointApi().measurementPointGetMeasurementPoints(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setMps(response.body);
                }
            }
        );
        new TemperatureApi().temperatureGetSensors2(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setAllSensors(data.filter((sensor) => sensor.status === 0));
            }
        });
    };

    // get the current group, if any
    const group = groups?.find((g) => g.id === curGroup);

    const onGroupClick = (gid) => {
        if (gid === null) {
            setCurGroup(undefined);
            window.history.replaceState(
                null,
                '',
                getProperRoute(`groups/${groupId}/sites/${id}/settings`)
            );
            return;
        }
        window.history.replaceState(
            null,
            '',
            getProperRoute(`groups/${groupId}/sites/${id}/settings/${gid}`)
        );
        // set the id of the current group being displayed
        setCurGroup(gid);
        setSelectedGroups([]);
        setSelectedMps([]);
        setNumSelected(0);
    };

    const createGroup = (name) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            const parent = isNullOrUndefined(curGroupRef.current)
                ? null
                : { id: curGroupRef.current };
            new SiteGroupApi().siteGroupAddGroup(
                parseInt(id, 10),
                { name: name, parent: parent },
                function (_, data, response) {
                    if (response.ok) {
                        setGroups([...groupsRef.current, data]);
                    }
                }
            );
        }
    };

    const createMeasurementPoint = (name) => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            const parent = isNullOrUndefined(curGroupRef.current)
                ? null
                : { id: curGroupRef.current };
            new MeasurementPointApi().measurementPointAddMeasurementPoint(
                parseInt(id, 10),
                { description: name, group: parent },
                function (_, data, response) {
                    if (response.ok) {
                        setMps([...mpsRef.current, data]);
                    }
                }
            );
        }
    };

    const editGroup = (name) => {
        if (
            !isNullOrUndefined(group) &&
            !isNullOrUndefined(name) &&
            name.trim() !== ''
        ) {
            if (name !== group.name) {
                new SiteGroupApi().siteGroupUpdateGroup(
                    parseInt(id, 10),
                    curGroup,
                    { name: name, configOptions: group.configOptions },
                    function (_, data, response) {
                        if (response.ok) {
                            group.name = name;
                            setEditGroupModal(false);
                        }
                    }
                );
            } else {
                setEditGroupModal(false);
            }
        }
    };

    const onDeleteGroup = () => {
        swal({
            title: t('swal_sure'),
            text: t('swal_cannot_recover_resource'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                new SiteGroupApi().siteGroupDeleteGroup(
                    parseInt(id, 10),
                    curGroup,
                    function (_, data, response) {
                        if (response.ok) {
                            window.location.href = getProperRoute(
                                `groups/${groupId}/sites/${id}/settings`
                            );
                        } else if (response.statusCode >= 400) {
                            swal({
                                text: t(
                                    'pages_sitesettings_sitesettingspage_empty'
                                ),
                                icon: 'error',
                            });
                        }
                    }
                );
            }
        });
    };

    const selectedRow = (selected, type, id) => {
        if (id === -1) {
            selected
                ? setNumSelected(
                      groups.filter((g) => g.parent?.id === curGroup).length +
                          mps.filter((m) => m.group?.id === curGroup).length
                  )
                : setNumSelected(0);
            selected
                ? setSelectedGroups([
                      ...groups.filter((g) => g.parent?.id === curGroup),
                  ])
                : setSelectedGroups([]);
            selected
                ? setSelectedMps([
                      ...mps.filter((m) => m.group?.id === curGroup),
                  ])
                : setSelectedMps([]);
        } else {
            setNumSelected(selected ? numSelected + 1 : numSelected - 1);
            if (type === 'group') {
                if (selected) {
                    const grp = groups.find((g) => g.id === id);
                    if (!isNullOrUndefined(grp)) {
                        setSelectedGroups([...selectedGroups, grp]);
                    }
                } else {
                    setSelectedGroups(
                        selectedGroups.filter((g) => g.id !== id)
                    );
                }
            } else if (type === 'mp') {
                if (selected) {
                    const mpoint = mps.find((m) => m.id === id);
                    if (!isNullOrUndefined(mpoint)) {
                        setSelectedMps([...selectedMps, mpoint]);
                    }
                } else {
                    setSelectedMps(selectedMps.filter((m) => m.id !== id));
                }
            }
        }
    };

    const deleteSelected = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_groups_groupprojectspage_info'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            if (confirmResult) {
                let totalDeleted = 0;
                let totalWrong = 0;
                selectedGroups?.forEach((g) => {
                    new SiteGroupApi().siteGroupDeleteGroup(
                        parseInt(id, 10),
                        g.id,
                        function (_, data, response) {
                            if (response.ok) {
                                totalDeleted += 1;
                            } else {
                                totalWrong += 1;
                            }
                            if (
                                totalDeleted + totalWrong ===
                                (selectedGroups?.length ?? 0) +
                                    (selectedMps?.length ?? 0)
                            ) {
                                onGroupsDeleted(totalDeleted);
                            }
                        }
                    );
                });
                selectedMps?.forEach((m) => {
                    new MeasurementPointApi().measurementPointDeleteMeasurementPoint(
                        parseInt(id, 10),
                        m.id,
                        function (_, data, response) {
                            if (response.ok) {
                                totalDeleted += 1;
                            } else {
                                totalWrong += 1;
                            }
                            if (
                                totalDeleted ===
                                (selectedGroups?.length ?? 0) +
                                    (selectedMps?.length ?? 0)
                            ) {
                                onGroupsDeleted(totalDeleted);
                            }
                        }
                    );
                });
            }
        });
    };

    const moveSelected = (newId) => {
        let count = 0;
        selectedGroups?.forEach((grp) => {
            if (isNullOrUndefined(grp.parent)) {
                grp.parent = {};
            }
            if (isNullOrUndefined(newId)) {
                grp.parent.id = null;
            } else {
                grp.parent.id = newId;
            }
            grp.configOptions = {};
            new SiteGroupApi().siteGroupUpdateGroup(
                parseInt(id, 10),
                grp.id,
                grp,
                function (_, data, response) {
                    count += 1;
                    onGroupsMoved(count, newId);
                }
            );
        });
        selectedMps?.forEach((m) => {
            if (isNullOrUndefined(m.group)) {
                m.group = {};
            }
            if (isNullOrUndefined(newId)) {
                m.group.id = null;
            } else {
                m.group.id = newId;
            }
            m.configOptions = {};
            new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
                parseInt(id, 10),
                m.id,
                m,
                function (_, data, response) {
                    count += 1;
                    onGroupsMoved(count, newId);
                }
            );
        });
    };

    const onGroupsMoved = (count, newId) => {
        if (
            count ===
            (selectedGroups?.length ?? 0) + (selectedMps?.length ?? 0)
        ) {
            groups
                ?.filter((g) => g.parent?.id === newId)
                ?.forEach((grp) => updateGroupPosition(grp.id, null));
            mps?.filter((m) => m.group?.id === newId)?.forEach((m) =>
                updateMpPosition(m.id, null)
            );
            window.location.href = getProperRoute(
                `groups/${groupId}/sites/${id}/settings${
                    isNullOrUndefined(newId) ? '' : `/${newId}`
                }`
            );
        }
    };

    const onGroupsDeleted = (totalDeleted) => {
        if (totalDeleted === 0) {
            swal({
                text: t('pages_groups_grouptreestructure_empty'),
                icon: 'error',
            });
            setSelectedGroups([]);
            setSelectedMps([]);
            return;
        }
        window.location.reload();
    };

    const freeAllDevices = () => {
        swal({
            title: t('swal_sure'),
            text: t('pages_sitesettings_sitesettingspage_confirmation'),
            icon: 'warning',
            buttons: [t('swal_no'), t('swal_yes')],
            dangerMode: true,
        }).then(function (confirmResult) {
            new SiteApi().siteClearSite(
                parseInt(groupId, 10),
                parseInt(id, 10),
                function (_, data, response) {
                    if (response.ok) {
                        window.location.reload();
                    }
                }
            );
        });
    };

    const onCreateMp = () => {
        if (isNullOrUndefined(mpBeingAdded)) {
            setMpBeingAdded('');
        }
    };

    const onCreateGroup = () => {
        if (isNullOrUndefined(groupBeingAdded)) {
            setGroupBeingAdded('');
        }
    };

    const mpBeingAddedChanged = (txt) => {
        setMpBeingAdded(txt);
    };

    const groupBeingAddedChanged = (txt) => {
        setGroupBeingAdded(txt);
    };

    const onEnterPressed = () => {
        if (!isNullOrUndefined(mpBeingAddedRef.current)) {
            createMeasurementPoint(mpBeingAddedRef.current);
            setMpBeingAdded('');
        }
        if (!isNullOrUndefined(groupBeingAddedRef.current)) {
            createGroup(groupBeingAddedRef.current);
            setGroupBeingAdded('');
        }
    };

    const onEscapePressed = () => {
        setMpBeingAdded(null);
        setGroupBeingAdded(null);
    };

    const updateMpPosition = (mpIdToUpdate, newPos) => {
        const mp = mps?.find((m) => m.id === mpIdToUpdate);
        if (isNullOrUndefined(mp)) {
            return;
        }
        if (isNullOrUndefined(mp.configOptions)) {
            mp.configOptions = {};
        }
        if (isNullOrUndefined(newPos)) {
            mp.configOptions = {};
        } else {
            mp.configOptions.sortIdx = newPos;
        }
        new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
            parseInt(id, 10),
            mpIdToUpdate,
            mp,
            function (_, data, response) {
                // don't do anything
            }
        );
    };

    const updateGroupPosition = (gidToUpdate, newPos) => {
        const gp = groups?.find((g) => g.id === gidToUpdate);
        if (isNullOrUndefined(gp)) {
            return;
        }
        if (isNullOrUndefined(gp.configOptions)) {
            gp.configOptions = {};
        }
        if (isNullOrUndefined(newPos)) {
            gp.configOptions = {};
        } else {
            gp.configOptions.sortIdx = newPos;
        }
        new SiteGroupApi().siteGroupUpdateGroup(
            parseInt(id, 10),
            gidToUpdate,
            gp,
            function (_, data, response) {
                // don't do anything
            }
        );
    };

    const dragAndDropUpdate = (newArray) => {
        newArray?.forEach((item, ind) => {
            if (item[0].icon === 'group') {
                updateGroupPosition(item[0].id, ind);
            } else if (item[0].icon === 'mp') {
                updateMpPosition(item[0].id, ind);
            }
        });
    };

    const startStopInstallationMode = () => {
        if (!props.site.reducedSamplingIntervalActive) {
            swal({
                title: t('swal_sure'),
                text: t('pages_sitesettings_sitetitle_warntext'),
                icon: 'warning',
                buttons: [t('swal_no'), t('swal_yes')],
                dangerMode: true,
            }).then(function (confirmResult) {
                new SiteApi().siteStartInstallationMode(
                    parseInt(groupId, 10),
                    parseInt(id, 10),
                    function (_, data, response) {
                        if (response.ok) {
                            props.site.reducedSamplingIntervalActive = true;
                            setInstallation(installation + 1);
                        }
                    }
                );
            });
        } else {
            new SiteApi().siteStopInstallationMode(
                parseInt(groupId, 10),
                parseInt(id, 10),
                function (_, data, response) {
                    if (response.ok) {
                        props.site.reducedSamplingIntervalActive = false;
                        setInstallation(installation + 1);
                    }
                }
            );
        }
    };

    const sensorAttachedToMpChanged = (sensor, mp) => {
        // attach the new sensor to the MP
        mp.measuringElement = { uuid: sensor };
        new MeasurementPointApi().measurementPointUpdateMeasurementPoint(
            parseInt(id, 10),
            mp.id,
            mp,
            function (_, data, response) {
                if (response.ok) {
                    fetchMpsAndSensors();
                }
            }
        );
    };

    const rows = [];

    // only the groups with the current group as parent, will be displayed
    // if current group is null, the groups without parent will be displayed
    let applicable = [];
    if (isNullOrUndefined(curGroup)) {
        applicable =
            groups?.filter((g) => isNullOrUndefined(g.parent?.id)) ?? [];
    } else {
        applicable = groups?.filter((g) => g.parent?.id === curGroup) ?? [];
    }

    applicable = [
        ...applicable,
        ...(mps?.filter((mp) => mp.group?.id === curGroup) ?? []),
    ];
    let alreadySorted = applicable.filter(
        (a) => !isNullOrUndefined(a.configOptions?.sortIdx)
    );
    let notSorted = applicable.filter((a) =>
        isNullOrUndefined(a.configOptions?.sortIdx)
    );
    applicable = [
        ...alreadySorted.sort(
            (a, b) => a.configOptions.sortIdx - b.configOptions.sortIdx
        ),
        ...notSorted,
    ];

    applicable?.forEach((a) => {
        if (!isNullOrUndefined(a.treeLevel)) {
            const row = {
                onClick: onGroupClick,
                id: a.id,
                text: a.name,
                value: a.name,
                icon: 'group',
                idx: a.configOptions?.sortIdx,
            };
            if (userIsAtLeast(props.user, 'analyst')) {
                row.addCheckbox = true;
                row.checked = !isNullOrUndefined(
                    selectedGroups?.find((g) => g.id === a.id)
                );
            }
            rows.push([row, '', '']);
        } else {
            let mpStatus = t(
                'pages_sitesettings_measurementpointpage_incomplete'
            );
            if (!isNullOrUndefined(a?.measuringElement?.uuid)) {
                mpStatus =
                    a.measuringElement.status === 2
                        ? t('pages_sitesettings_measurementpointpage_active')
                        : t('pages_sitesettings_measurementpointpage_inactive');
            }
            const mpRow = {
                idx: a.configOptions?.sortIdx,
                link: getProperRoute(
                    `groups/${groupId}/sites/${id}/groups/${a.group.id}/mps/${a.id}`
                ),
                id: a.id,
                text: a.description,
                value: a.description,
                icon: 'mp',
            };
            if (userIsAtLeast(props.user, 'analyst')) {
                mpRow.addCheckbox = true;
                mpRow.checked = !isNullOrUndefined(
                    selectedMps?.find((m) => m.id === a.id)
                );
            }
            rows.push([
                mpRow,
                {
                    link: null,
                    text: a.measuringElement?.name,
                    value: userIsAtLeast(props.user, 'analyst') ? (
                        <SingleSelect
                            disabled={!isNullOrUndefined(a.measuringElement)}
                            rawValue={a.measuringElement?.name ?? 'Unassigned'}
                            data={allSensors?.map((s) => {
                                return {
                                    value: s.uuid,
                                    description: s.name,
                                };
                            })}
                            onChange={(sensor) =>
                                sensorAttachedToMpChanged(sensor, a)
                            }
                        />
                    ) : (
                        a.measuringElement?.name
                    ),
                },
                { link: null, text: mpStatus, value: mpStatus },
            ]);
        }
    });

    if (!isNullOrUndefined(mpBeingAdded)) {
        rows.push([
            {
                link: null,
                text: '',
                value: (
                    <TableTextEdit
                        autofocus
                        value={mpBeingAdded}
                        onChange={mpBeingAddedChanged}
                    />
                ),
                icon: 'mp',
                flex: true,
            },
            '',
            '',
        ]);
    } else if (!isNullOrUndefined(groupBeingAdded)) {
        rows.push([
            {
                link: null,
                text: '',
                value: (
                    <TableTextEdit
                        autofocus
                        value={groupBeingAdded}
                        onChange={groupBeingAddedChanged}
                    />
                ),
                icon: 'group',
                flex: true,
            },
            '',
            '',
        ]);
    }

    const recursive = (levelGroups, total, items) => {
        if (isNullOrUndefined(levelGroups) || levelGroups?.length === 0) {
            return '';
        }
        let newTotal = total;
        for (let lg of levelGroups) {
            if (selectedGroups?.some((g) => g.id === lg.id)) {
                continue;
            }
            newTotal = total + ' / ' + lg.name;
            items.push({ value: lg.id, description: newTotal });
            recursive(
                groups.filter((g) => g.parent?.id === lg.id),
                newTotal,
                items
            );
        }
    };

    const itemsToBeMoved = [{ value: null, description: '/' }];
    let levelGroups = groups?.filter((g) => isNullOrUndefined(g.parent?.id));
    let total = '';
    recursive(levelGroups, total, itemsToBeMoved);

    // create the breadcrumbs structure
    const curGroupPath = [];
    let cur = groups?.find((g) => g.id === curGroup);
    while (!isNullOrUndefined(cur)) {
        curGroupPath.push({
            onClick: onGroupClick,
            id: cur.id,
            text: cur.name,
        });
        cur = groups?.find((g) => g.id === cur.parent?.id);
    }
    curGroupPath.push({
        onClick: onGroupClick,
        id: null,
        text: t('pagelayout_navbar_groups'),
    });
    curGroupPath.reverse();

    const fullGroups = getFullGroups(props.groups, props.site);

    const addSubTreeItems = (item) => {
        if (isNullOrUndefined(item) || item.length === 0) {
            return null;
        }
        const tree = [];
        item?.forEach((g) => {
            // push all measurement points that whose parent is this group
            const subMps =
                mps
                    ?.filter((mp) => mp.group?.id === g.id)
                    ?.map(function (mp) {
                        return {
                            id: mp.id,
                            name: mp.description,
                            items: null,
                            type: 'mp',
                        };
                    }) ?? [];
            const subItems =
                addSubTreeItems(
                    groups?.filter((gr) => gr?.parent?.id === g.id)
                ) ?? [];
            tree.push({
                id: g.id,
                name: g.name,
                type: 'group',
                items:
                    subMps.length === 0 && subItems.length === 0
                        ? null
                        : [...subMps, ...subItems],
            });
        });
        return tree;
    };
    let treeStructure = [];
    if (!isNullOrUndefined(groups) && !isNullOrUndefined(mps)) {
        treeStructure = addSubTreeItems(
            groups?.filter((g) => isNullOrUndefined(g.parent))
        );
    }

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: t('pagelayout_sidebarsite_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_general'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        ...curGroupPath,
                        {
                            link: getProperRoute('/'),
                            text: (
                                <NavButton
                                    disabled={
                                        !userIsAtLeast(props.user, 'analyst')
                                    }
                                    color="black"
                                    background="gray"
                                    id="menu-add-group"
                                    text={<AddIcon />}
                                    icon={<ExpandMoreIcon />}>
                                    {userIsAtLeast(props.user, 'analyst') ? (
                                        <MenuList id="menu-add-group">
                                            <MenuItem
                                                onClick={() => onCreateGroup()}>
                                                {t(
                                                    'pages_groups_grouptreestructure_creategroup'
                                                )}
                                            </MenuItem>
                                            {isNullOrUndefined(curGroup) ? (
                                                <></>
                                            ) : (
                                                <MenuItem
                                                    onClick={() =>
                                                        onCreateMp()
                                                    }>
                                                    {t(
                                                        'pages_sitesettings_sitesettingspage_createmp'
                                                    )}
                                                </MenuItem>
                                            )}
                                        </MenuList>
                                    ) : (
                                        <></>
                                    )}
                                </NavButton>
                            ),
                        },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            {numSelected === 0 ? (
                                <></>
                            ) : (
                                <>
                                    <MenuItem
                                        onClick={() => setMoveItems(true)}>
                                        {t(
                                            'pages_sitesettings_sitesettingspage_movesel'
                                        )}
                                        {numSelected}
                                        {t(
                                            'pages_sitesettings_sitesettingspage_moveitems'
                                        )}
                                    </MenuItem>
                                    <MenuItem onClick={() => deleteSelected()}>
                                        {t(
                                            'pages_sitesettings_sitesettingspage_deletesel'
                                        )}
                                        {numSelected}
                                        {t(
                                            'pages_sitesettings_sitesettingspage_deleteitems'
                                        )}
                                    </MenuItem>
                                    <hr />
                                </>
                            )}
                            <SiteSettingsCommonOptions
                                clickEditProject={() => setModalOpened(0)}
                                clickCreateSiteCopy={() => setModalOpened(1)}
                                clickBackupGeneration={() => setModalOpened(2)}
                            />
                            <MenuItem
                                onClick={() => setViewAsTree(!viewAsTree)}>
                                {viewAsTree
                                    ? t(
                                          'pages_sitesettings_sitesettingspage_viewtable'
                                      )
                                    : t(
                                          'pages_sitesettings_sitesettingspage_viewtree'
                                      )}
                            </MenuItem>
                            <MenuItem
                                onClick={() => startStopInstallationMode()}>
                                {props.site.reducedSamplingIntervalActive
                                    ? t('pages_sitesettings_sitetitle_stop1')
                                    : t('pages_sitesettings_sitetitle_start1')}
                                {t(
                                    'pages_sitesettings_sitetitle_installationmode'
                                )}
                                {props.site.reducedSamplingIntervalActive
                                    ? t('pages_sitesettings_sitetitle_stop2')
                                    : t('pages_sitesettings_sitetitle_start2')}
                            </MenuItem>
                            <MenuItem onClick={() => freeAllDevices()}>
                                {t('pages_sitesettings_sitesettingspage_free')}
                            </MenuItem>
                            {isNullOrUndefined(group) ? (
                                <> </>
                            ) : (
                                <>
                                    <MenuItem
                                        onClick={() => setEditGroupModal(true)}>
                                        {t(
                                            'pages_sitesettings_sitesettingspage_editgroup'
                                        )}
                                    </MenuItem>
                                    <MenuItem onClick={() => onDeleteGroup()}>
                                        {t(
                                            'pages_sitesettings_sitesettingspage_delgroup'
                                        )}
                                    </MenuItem>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            {viewAsTree ? (
                <TreeStructure items={treeStructure} site={props.site} />
            ) : userIsAtLeast(props.user, 'analyst') ? (
                <FilesTable
                    dragAndDrop
                    responsive
                    onDragAndDropUpdate={(newArray) =>
                        dragAndDropUpdate(newArray)
                    }
                    columns={[
                        t('common_filestable_namecol'),
                        t('pages_sitesettings_sitesettingspage_sensor'),
                        t('pages_devices_devicepage_status'),
                    ]}
                    rows={rows}
                    onCheckbox={(selected, type, id) =>
                        selectedRow(selected, type, id)
                    }
                />
            ) : (
                <FilesTable
                    responsive
                    columns={[
                        t('common_filestable_namecol'),
                        t('pages_sitesettings_sitesettingspage_sensor'),
                        t('pages_devices_devicepage_status'),
                    ]}
                    rows={rows}
                />
            )}
            <CreateSiteGroupModal
                edit
                name={group?.name}
                open={editGroupModal}
                handleClose={() => setEditGroupModal(false)}
                confirm={editGroup}
            />
            <MoveMpGroupsModal
                data={itemsToBeMoved}
                open={moveItems}
                handleClose={() => setMoveItems(false)}
                confirm={moveSelected}
            />
            <SiteSettingsCommonModals
                opened={modalOpened}
                handleClose={(site) => {
                    props.site.name = site.name;
                    props.site.description = site.description;
                    setModalOpened(-1);
                }}
                site={props.site}
            />
        </>
    );
}
