import { useSortable } from '@dnd-kit/sortable';
import React from 'react';

export function DraggableRow(props) {
    const { attributes, listeners, setNodeRef } = useSortable({
        id: props.ind,
    });
    const { children, key, ...restProps } = props;

    return (
        <tr ref={setNodeRef} {...attributes} {...restProps}>
            {children instanceof Array
                ? children.map((child) =>
                      child.props?.rowType === 'drag-and-drop' ? (
                          <td {...listeners} {...child.props}>
                              {child.props.children}
                          </td>
                      ) : (
                          child
                      )
                  )
                : children}
        </tr>
    );
}
