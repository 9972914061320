import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { TextEdit } from '@components';

export default function CreateMpModal(props) {
    const [name, setName] = useState(props.name ?? '');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        setErrorMsg(null);
        setName(props.name ?? '');
    }, [props.name, props.open]);

    const nameChanged = (txt) => {
        setName(txt);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (!isNullOrUndefined(name) && name.trim() !== '') {
            props.confirm(name);
        } else {
            setErrorMsg(t('pages_sitesettings_creatempmodal_errortxt'));
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => props.handleClose && props.handleClose()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.edit
                    ? `${t('modal_edit')} '${props.name}'`
                    : t('pages_sitesettings_creatempmodal_new')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <TextEdit
                        label={t('pages_sitesettings_creatempmodal_namelbl')}
                        placeholder={t('pages_groups_creategroupmodal_name')}
                        value={name}
                        onChange={nameChanged}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {props.edit ? t('modal_edit') : t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
