import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import { TextEdit } from '@components';

export default function DisplayTabBoxPlotOptions(props) {
    const { t } = useTranslation();

    const changeBoxWidth = (width) => {
        if (!isNumeric(width)) {
            return;
        }
        props.graph.configOptions.boxWidth = width;
        props.graphChanged && props.graphChanged(props.graph);
    };

    const changeWhiskerWidth = (width) => {
        if (!isNumeric(width)) {
            return;
        }
        props.graph.configOptions.whiskerWidth = parseFloat(width);
        props.graphChanged && props.graphChanged(props.graph);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 620px)' });
    const max1300 = useMediaQuery({ query: '(max-width: 1300px)' });
    const max1000 = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%',
                    marginTop: '40px',
                }}>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <TextEdit
                        label={t(
                            'pages_addgraph_displaytabboxplotoptions_boxwidth'
                        )}
                        onChange={changeBoxWidth}
                        value={
                            isNullOrUndefined(
                                props.graph?.configOptions?.boxWidth
                            )
                                ? '15'
                                : `${props.graph.configOptions.boxWidth}`
                        }
                    />
                </div>
                <div
                    style={{
                        width: isMobile
                            ? '100%'
                            : max1000
                            ? '47%'
                            : max1300
                            ? '31%'
                            : '23%',
                        marginBottom: '10px',
                        marginRight: '10px',
                    }}>
                    <TextEdit
                        label={t(
                            'pages_addgraph_displaytabboxplotoptions_whiskerwidth'
                        )}
                        onChange={changeWhiskerWidth}
                        value={
                            isNullOrUndefined(
                                props.graph?.configOptions?.whiskerWidth
                            )
                                ? '10'
                                : `${props.graph.configOptions.whiskerWidth}`
                        }
                    />
                </div>
            </div>
        </>
    );
}
