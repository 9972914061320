import {
    Card,
    CardActionArea,
    CardHeader,
    CardMedia,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { customTheme } from '@/App';
import { hexToRgba } from '@utils/helpers/colors.helpers';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '250px',
        width: '250px',
        '&:hover': {
            borderStyle: 'solid',
            borderColor: hexToRgba(customTheme.palette.primary.main, 0.4),
            borderSpacing: '15px',
        },
        '&:focus': {
            outline: 0,
        },
    },
    header: {
        textAlign: 'center',
        width: '100%',
        '&:focus': {
            outline: 0,
        },
    },
    media: {
        height: '100px',
        width: '100px',
        alignSelf: 'center',
        marginLeft: `${300 / 4}px`,
        marginBottom: (props) => (props.index === 2 ? '5px' : 0),
        marginTop: (props) => (props.index === 2 ? '-5px' : 0),
        transform: (props) => (props.index === 2 ? 'rotateZ(270deg)' : ''),
        '&:focus': {
            outline: 0,
        },
    },
    cardSelected: {
        borderStyle: (props) => (props.selected ? 'solid' : ''),
        borderColor: (props) =>
            props.selected ? customTheme.palette.primary.main : 'transparent',
    },
}));

export default function GraphCard(props) {
    const styles = useStyles(props);

    const cardClicked = () => {
        props.onClicked(props.index);
    };

    return (
        <Card className={[styles.root, styles.cardSelected]}>
            <CardActionArea onClick={() => cardClicked()}>
                <CardHeader
                    className={styles.header}
                    title={<Typography>{props.title}</Typography>}
                />
                <CardMedia
                    className={styles.media}
                    image={props.chart}
                    title={props.title}
                />
            </CardActionArea>
        </Card>
    );
}
