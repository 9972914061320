import { GroupApi, SiteApi } from '@aurum/nucleus-client-api';
import { CustomBreadcrumbs, FilesTable, MoreSettingsMenu } from '@components';
import { MenuItem } from '@material-ui/core';
import {
    addMemberPayload,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import {
    convertDateToUtc,
    dateToString,
} from '@utils/helpers/timestamp.helpers';
import GroupTitle from '@views/groups/group-entry/GroupTitle';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';

export default function MemberEntryPage(props) {
    const [user, setUser] = useState(null);
    const { groupId, id, memberId } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(
        () => {
            let usr = null;
            if (!isNullOrUndefined(props.site)) {
                usr = props.site.users?.find((u) => u.id === memberId);
            } else {
                usr = props.groups
                    ?.find((g) => g.id === parseInt(groupId, 10))
                    ?.memberGroups?.filter(
                        (u) => u.user.id === memberId
                    )[0]?.user;
            }
            setUser(usr);

            if (
                isNullOrUndefined(usr) &&
                isNullOrUndefined(props.site) &&
                isNullOrUndefined(props.groups)
            ) {
                // info is not yet available. wait until it is available
                return;
            }
            if (
                ((!isNullOrUndefined(props.site) ||
                    !isNullOrUndefined(props.groups)) &&
                    isNullOrUndefined(usr)) ||
                (groupId === '1' && isNullOrUndefined(props.site))
            ) {
                if (isNullOrUndefined(props.site)) {
                    window.location.href = getProperRoute(
                        `groups/${groupId}/members`
                    );
                } else {
                    window.location.href = getProperRoute(
                        props.site?.type === 'filter'
                            ? `groups/${groupId}/sites/filter/${id}/members`
                            : `groups/${groupId}/sites/${id}/members`
                    );
                }
            }
        },
        user,
        props.site
    );

    useEffect(() => {
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/members/${memberId}`
        );
    }, [props]);

    const group = props.groups?.filter(
        (g) => g.id === parseInt(groupId, 10)
    )[0];

    const removeMember = () => {
        if (!isNullOrUndefined(props.site)) {
            swal({
                title: t('swal_sure'),
                text: t('pages_sitesettings_memberentrypage_errortxt'),
                icon: 'warning',
                buttons: [t('swal_no'), t('swal_yes')],
                dangerMode: true,
            }).then(function (confirmResult) {
                if (confirmResult) {
                    props.site.users = props.site.users.filter(
                        (usr) => usr.id !== memberId
                    );
                    const siteClone = addMemberPayload(props.site);
                    new SiteApi().siteUpdateSite(
                        parseInt(groupId, 10),
                        parseInt(id, 10),
                        siteClone,
                        function (_, data, response) {
                            if (response.ok) {
                                window.location.href = getProperRoute(
                                    props.site?.type === 'filter'
                                        ? `groups/${groupId}/sites/filter/${id}/members`
                                        : `groups/${groupId}/sites/${id}/members`
                                );
                            }
                        }
                    );
                }
            });
        } else {
            swal({
                title: t('swal_sure'),
                text: t('pages_sitesettings_memberentrypage_errortxt'),
                icon: 'warning',
                buttons: [t('swal_no'), t('swal_yes')],
                dangerMode: true,
            }).then(function (confirmResult) {
                if (confirmResult) {
                    group.memberGroups = group.memberGroups.filter(
                        (mg) => mg.user.id !== memberId
                    );
                    new GroupApi().groupUpdateGroup(
                        parseInt(groupId, 10),
                        group,
                        function (_, data, response) {
                            if (response.ok) {
                                window.location.href = getProperRoute(
                                    `groups/${groupId}/members`
                                );
                            }
                        }
                    );
                }
            });
        }
    };

    let parentGroup = isNullOrUndefined(props.site)
        ? props.groups?.find((g) => g.id === parseInt(groupId))
        : props.groups?.find((g) => g.id === props.site?.groupId);
    const fullGroups = [];
    if (!isNullOrUndefined(parentGroup) && parentGroup?.id !== 1) {
        fullGroups.push({
            link: getProperRoute(`groups/${parentGroup.id}/settings`),
            text: parentGroup.name,
        });
        while (
            !isNullOrUndefined(parentGroup?.parent?.id) &&
            parentGroup?.parent?.id !== 1
        ) {
            fullGroups.push({
                link: getProperRoute(
                    `groups/${parentGroup.parent.id}/settings`
                ),
                text: parentGroup.parent.name,
            });
            parentGroup = props.groups?.filter(
                (g) => g.id === parentGroup.parent.id
            );
        }
    }
    fullGroups.reverse();

    return isNullOrUndefined(user) ||
        (groupId === '1' && isNullOrUndefined(props.site)) ? (
        <> </>
    ) : (
        <>
            {isNullOrUndefined(props.site) ? (
                <CustomBreadcrumbs
                    breadcrumbs={[
                        ...fullGroups,
                        {
                            link: getProperRoute(
                                getProperSiteSettingsPage(
                                    groupId,
                                    id,
                                    props.site?.type
                                )
                            ),
                            text: t('pagelayout_sidebarsite_settings'),
                        },
                        {
                            link: getProperRoute('/'),
                            text: t('pagelayout_sidebarsite_members'),
                        },
                    ]}
                />
            ) : (
                <CustomBreadcrumbs
                    breadcrumbs={[
                        ...fullGroups,
                        {
                            link: getProperRoute(
                                getProperSiteSettingsPage(
                                    groupId,
                                    id,
                                    props.site?.type
                                )
                            ),
                            text: props.site?.name,
                        },
                        {
                            link: getProperRoute(
                                getProperSiteSettingsPage(
                                    groupId,
                                    id,
                                    props.site?.type
                                )
                            ),
                            text: t('pagelayout_sidebarsite_settings'),
                        },
                        {
                            link: getProperRoute('/'),
                            text: t('pagelayout_sidebarsite_members'),
                        },
                    ]}
                />
            )}
            <hr /> <br />
            {isNullOrUndefined(props.site) ? (
                <GroupTitle
                    group={
                        props.groups?.filter(
                            (g) => g.id === parseInt(groupId, 10)
                        )[0]
                    }
                />
            ) : (
                <SiteTitle site={props.site} />
            )}
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: isNullOrUndefined(props.site)
                                ? getProperRoute(`groups/${groupId}/members`)
                                : getProperRoute(
                                      props.site?.type === 'filter'
                                          ? `groups/${groupId}/sites/filter/${id}/members`
                                          : `groups/${groupId}/sites/${id}/members`
                                  ),
                            text: t('pagelayout_sidebarsite_members'),
                        },
                        { link: getProperRoute('/'), text: user.userName },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <MenuItem onClick={() => removeMember()}>
                            {t('pages_sitesettings_memberentrypage_remove')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_diagnostics_diagnosticspage_value'),
                ]}
                rows={[
                    [
                        { link: null, text: 'ID', value: 'ID' },
                        { link: null, text: user.id, value: user.id },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_profile_profilepage_username'),
                            value: t('pages_profile_profilepage_username'),
                        },
                        {
                            link: null,
                            text: user.userName,
                            value: user.userName,
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t('pages_profile_profilepage_email'),
                            value: t('pages_profile_profilepage_email'),
                        },
                        { link: null, text: user.email, value: user.email },
                    ],
                    [
                        {
                            link: null,
                            text: t(
                                'pages_sitesettings_memberentrypage_created'
                            ),
                            value: t(
                                'pages_sitesettings_memberentrypage_created'
                            ),
                        },
                        {
                            link: null,
                            text: dateToString(
                                convertDateToUtc(
                                    user.createdAt,
                                    props.user?.timeZone
                                )
                            ),
                            value: dateToString(
                                convertDateToUtc(
                                    user.createdAt,
                                    props.user?.timeZone
                                )
                            ),
                        },
                    ],
                    [
                        {
                            link: null,
                            text: t(
                                'pages_sitesettings_memberentrypage_lastlogin'
                            ),
                            value: t(
                                'pages_sitesettings_memberentrypage_lastlogin'
                            ),
                        },
                        {
                            link: null,
                            text: dateToString(
                                convertDateToUtc(
                                    user.lastLogin,
                                    props.user?.timeZone
                                )
                            ),
                            value: dateToString(
                                convertDateToUtc(
                                    user.lastLogin,
                                    props.user?.timeZone
                                )
                            ),
                        },
                    ],
                ]}
            />
        </>
    );
}
