import { GatewayApi } from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    FilesTable,
    MoreSettingsMenu,
    RoundedText,
} from '@components';
import { MenuItem } from '@material-ui/core';
import {
    getFullGroups,
    getProperRoute,
    getProperSiteSettingsPage,
    isNullOrUndefined,
    redirectToSiteTypeApp,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import AssignGatewayModal from '@views/sites/settings/gateways/assign-gateway/AssignGatewayModal';
import GatewayTableEntry from '@views/sites/settings/gateways/gateway-entry/GatewayTableEntry';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function GatewaysSitePage(props) {
    const [gateways, setGateways] = useState(null);
    const [allgws, setAllGws] = useState(null);
    const [assignGwModal, setAssignGwModal] = useState(false);
    const { groupId, id } = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        new GatewayApi().gatewayGetGateways(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setGateways(data);
                }
            }
        );
        new GatewayApi().gatewayGetAllGateways(function (_, data, response) {
            if (response.ok) {
                setAllGws(data);
            }
        });
    }, [id]);

    useEffect(() => {
        redirectToSiteTypeApp(
            navigate,
            props.site,
            `groups/${groupId}/sites/${id}/gateways`
        );
    }, [props]);

    const assignGateway = (uuid) => {
        if (!isNullOrUndefined(uuid) && uuid.trim() !== '') {
            const gateway = allgws.find((x) => x.uuid === uuid);
            if (!isNullOrUndefined(gateway)) {
                gateway.site.id = parseInt(id, 10);
                delete gateway.lastActiveTime;
                delete gateway.gatewayStatus;
                delete gateway.site.createdAt;
                delete gateway.site.lastUpdated;
                new GatewayApi().gatewayUpdateGateway(
                    uuid,
                    gateway,
                    function (_, data, response) {
                        if (response.ok) {
                            const newAllGws = allgws.filter(
                                (x) => x.uuid !== uuid
                            );
                            setAllGws(newAllGws);
                            setGateways([...gateways, gateway]);
                            setAssignGwModal(false);
                        }
                    }
                );
            }
        }
    };

    const rows = [];
    gateways?.forEach((gw) => {
        let statusText = t('pages_devices_devicepage_inactive');
        let status = (
            <RoundedText
                margin="10px"
                color="secondary"
                width="120px"
                text={t('pages_devices_devicepage_inactive')}
            />
        );
        if (gw.connected) {
            statusText = t('pages_devices_devicepage_active');
            status = (
                <RoundedText
                    margin="10px"
                    color="success"
                    width="120px"
                    text={t('pages_devices_devicepage_active')}
                />
            );
        }
        rows.push([
            {
                link: null,
                text: gw?.name,
                value: <GatewayTableEntry gw={gw} />,
            },
            { link: null, text: statusText, value: status },
        ]);
    });

    const unassignedData = [{}];
    const unassignedGws = allgws?.filter((gw) => gw.site?.id === -1);
    unassignedGws?.forEach((gw) => {
        unassignedData.push({ value: gw.uuid, description: gw.name });
    });

    const fullGroups = getFullGroups(props.groups, props.site);

    return isNullOrUndefined(props.site) || isNullOrUndefined(gateways) ? (
        <> </>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            getProperSiteSettingsPage(
                                groupId,
                                id,
                                props.site?.type
                            )
                        ),
                        text: t('pages_groups_gatewaygrouppage_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pages_groups_gatewaygrouppage_gws'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute('/'),
                            text: t('pages_groups_gatewaygrouppage_gws'),
                        },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <MenuItem onClick={() => setAssignGwModal(true)}>
                            {t('pages_sitesettings_gatewayssitepage_assign')}
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <FilesTable
                columns={[
                    t('common_filestable_namecol'),
                    t('pages_devices_devicepage_status'),
                ]}
                rows={rows}
            />
            <AssignGatewayModal
                data={unassignedData}
                open={assignGwModal}
                handleClose={() => setAssignGwModal(false)}
                confirm={assignGateway}
            />
        </>
    );
}
