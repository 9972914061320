import MomentUtils from '@date-io/moment';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { isNumeric } from '@utils/helpers/text.helpers';
import { SingleSelect, TextEdit } from '@components';

export default function CreateManualMeasurementModal(props) {
    const [mp, setMp] = useState(0);
    const [dt, setDt] = useState(new Date());
    const [manualValue, setManualValue] = useState('0');
    const [displayReadout, setDisplayReadout] = useState('0');
    const [errorMsg, setErrorMsg] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (!isNullOrUndefined(props.errorMsg)) {
            setErrorMsg(props.errorMsg);
        }
        setMp(props.mpoints[0]?.value ?? 0);
    }, [props.mpoints, props.errorMsg]);

    const manualValueChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createmanualmodal_errortxt'));
        } else {
            if (isNumeric(manualValue) && isNumeric(displayReadout)) {
                setErrorMsg(null);
            }
            setManualValue(txt);
        }
    };

    const displayReadoutChanged = (txt) => {
        if (!isNumeric(txt)) {
            setErrorMsg(t('pages_sitesettings_createmanualmodal_errortxt'));
        } else {
            if (isNumeric(manualValue) && isNumeric(displayReadout)) {
                setErrorMsg(null);
            }
            setDisplayReadout(txt);
        }
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onOkClick();
        }
    };

    const onOkClick = () => {
        if (!(isNumeric(manualValue) && isNumeric(displayReadout))) {
            return;
        }

        props.confirm({
            testerTimestamp: dt,
            manualValue: parseFloat(manualValue),
            displayReadoutValue: parseFloat(displayReadout),
            measurementPoint: { id: mp },
        });
    };

    const handleDate = (_, value) => {
        setDt(new Date(value));
    };

    const handleMp = (val) => {
        setMp(val);
    };

    const closeModal = () => {
        setManualValue('0');
        setDisplayReadout('0');
        setErrorMsg(null);
        setMp(props.mpoints[0]?.value ?? 0);
        props.handleClose && props.handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={() => closeModal()}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('pages_sitesettings_createmanualmodal_new')}
            </DialogTitle>
            <DialogContent>
                {isNullOrUndefined(errorMsg) ? (
                    <></>
                ) : (
                    <div>
                        <Alert
                            onClose={() => setErrorMsg(null)}
                            severity="error">
                            {errorMsg}
                        </Alert>
                        <br />
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className="row">
                            <div className="col-md-6">
                                <KeyboardDatePicker
                                    style={{
                                        marginLeft: '10px',
                                        width: '100%',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/yyyy"
                                    margin="normal"
                                    label={t(
                                        'pages_sitesettings_createmanualmodal_date'
                                    )}
                                    value={dt}
                                    onChange={handleDate}
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleSelect
                                    title={t('pages_devices_devicepage_mp')}
                                    onChange={handleMp}
                                    data={props.mpoints}
                                />
                            </div>
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_createmanualmodal_manual'
                                )}
                                placeholder="20"
                                value={manualValue}
                                onChange={manualValueChanged}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextEdit
                                label={t(
                                    'pages_sitesettings_createmanualmodal_display'
                                )}
                                placeholder="25"
                                value={displayReadout}
                                onChange={displayReadoutChanged}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => closeModal()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Ok"
                    onClick={() => props.confirm && onOkClick()}
                    color="primary">
                    {t('modal_create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
