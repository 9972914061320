import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Impressum(props) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <h2>{t('Impressum_GeneralInfoLaw')}</h2>
                <p>Aurum Cloud GmbH</p>
                <p>Am Schammacher Feld 23</p>
                <p>85567 Grafing bei München</p>
                <br />
                <p>Handelsregister: HRB 176241</p>
                <p>{t('Impressum_Court')}</p> <br />
                <b>{t('Impressum_Represented')}</b>
                <p>Dipl.-Ing. Ulf Meinke</p>
                <h2>{t('Impressum_Contact')}</h2>
                <p>{t('Impressum_Phone')}</p>
                <p>Telefax: +49 8092 3049934</p>
                <p>E-Mail: info@aurum-cloud.de</p>
                <h2>{t('Impressum_VatId')}</h2>
                <p>{t('Impressum_VatIdSubtitle')}</p>
                <p>DE262212763</p>
                <h2>{t('Impressum_DisputeResolution')}</h2>
                <p>{t('Impressum_DisputeResolutionFirst')}</p>
                <p>{t('Impressum_DisputeResolutionSecond')}</p>
                <p>{t('Impressum_DisputeResolutionThird')}</p>
                <h3>{t('Impressum_Liability')}</h3>
                <p>{t('Impressum_LiabilityFirst')}</p>
                <p>{t('Impressum_LiabilitySecond')}</p>
                <h3>{t('Impressum_LiabilityLinks')}</h3>
                <p>{t('Impressum_LiabilityLinksFirst')}</p>
                <p>{t('Impressum_LiabilityLinksSecond')}</p>
                <h3>{t('Impressum_Copyright')}</h3>
                <p>{t('Impressum_CopyrightFirst')}</p>
                <p>{t('Impressum_CopyrightSecond')}</p>
            </CardContent>
        </Card>
    );
}
