import { makeStyles } from '@material-ui/core/styles';
import { getDrawerSidebar, getSidebarContent } from '@mui-treasury/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getProperRoute, isNullOrUndefined } from '@utils/helpers/app.helpers';
import { SideList, SideListItem } from '@components';

const SidebarContent = getSidebarContent(styled);
const DrawerSidebar = getDrawerSidebar(styled);

const useStyles = makeStyles(({ breakpoints }) => ({
    collapseBtn: {
        color: '#fff',
        minWidth: 0,
        width: 40,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0.24)',
        margin: '0 auto 16px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.38)',
        },
    },
    sidebar: {
        backgroundColor: '#fbfbfb',
        width: '100%',
        border: 'none',
        [breakpoints.down('md')]: {
            display: 'block',
            width: '70px',
        },
    },
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        margin: 0,
        alignSelf: 'center',
        border: '1px solid black',
        backgroundColor: 'transparent',
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '10px',
    },
}));

export function SidebarGroup(props) {
    const { groupId } = useParams();
    const { t } = useTranslation();
    const styles = useStyles({ id: groupId });

    return isNullOrUndefined(props.groups) ? (
        <></>
    ) : (
        <DrawerSidebar
            sidebarId={'primarySidebar'}
            PaperProps={{ className: styles.sidebar }}>
            <SidebarContent style={{ overflowX: 'hidden' }}>
                <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div className={styles.defaultAvatar}>G</div>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                        <p>{t('pagelayout_sidebargroup_groups')}</p>
                    </div>
                </div>
                <hr />
                <SideList>
                    <SideListItem
                        link={
                            isNullOrUndefined(groupId)
                                ? getProperRoute('groups')
                                : getProperRoute(`groups/${groupId}/settings`)
                        }
                        active={props.active?.includes('settings')}
                        text={t('pagelayout_sidebargroup_settings')}
                        icon="settings">
                        <SideList>
                            <SideListItem
                                link={
                                    isNullOrUndefined(groupId)
                                        ? getProperRoute('groups')
                                        : getProperRoute(
                                              `groups/${groupId}/settings`
                                          )
                                }
                                active={props.active === 'settings/general'}
                                nested
                                text={t('pagelayout_sidebargroup_general')}
                            />
                            <SideListItem
                                link={
                                    isNullOrUndefined(groupId)
                                        ? getProperRoute('groups/projects')
                                        : getProperRoute(
                                              `groups/${groupId}/projects`
                                          )
                                }
                                active={props.active === 'settings/projects'}
                                nested
                                text={t('pagelayout_sidebargroup_projects')}
                            />
                            <SideListItem
                                link={
                                    isNullOrUndefined(groupId)
                                        ? getProperRoute('groups/members')
                                        : getProperRoute(
                                              `groups/${groupId}/members`
                                          )
                                }
                                active={props.active === 'settings/members'}
                                nested
                                text={t('pagelayout_sidebargroup_members')}
                            />
                            <SideListItem
                                link={
                                    isNullOrUndefined(groupId)
                                        ? getProperRoute('groups/devices')
                                        : getProperRoute(
                                              `groups/${groupId}/devices`
                                          )
                                }
                                active={props.active === 'settings/devices'}
                                nested
                                text={t('pagelayout_sidebargroup_devices')}
                            />
                        </SideList>
                    </SideListItem>
                </SideList>
            </SidebarContent>
        </DrawerSidebar>
    );
}
