import { createSlice } from '@reduxjs/toolkit';

export const sitesSlice = createSlice({
    name: 'sites',
    initialState: {
        sites: null,
        site: null,
        sitesLoaded: false,
        loading: false,
        error: null,
    },
    reducers: {
        getAllSitesStart(state) {
            if (state.sitesLoaded) return;
            state.loading = true;
            state.error = null;
        },
        getAllSitesSuccess(state, action) {
            if (state.sitesLoaded) return;
            state.loading = false;
            state.sitesLoaded = true;
            state.sites = action.payload;
        },
        getAllSitesFailure(state, action) {
            if (state.sitesLoaded) return;
            state.loading = false;
            state.error = action.payload;
        },
        createSiteStart(state, action) {
            state.sites = [...state.sites, action.payload];
        },
    },
});

export const {
    getAllSitesStart,
    getAllSitesSuccess,
    getAllSitesFailure,
    createSiteStart,
} = sitesSlice.actions;
