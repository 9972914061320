import { SiteApi } from '@aurum/nucleus-client-api';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';
import { editProjectHelper } from '@utils/helpers/projects.helpers';
import BackupGenerationModal from '@views/sites/backup-site/create-backup/BackupGenerationModal';
import CreateSiteCopyInfoModal from '@views/sites/create-site/CreateSiteCopyInfoModal';
import CreateSiteModal from '@views/sites/create-site/CreateSiteModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function SiteSettingsCommonModals(props) {
    const { id, groupId } = useParams();
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [backupGenerationModal, setBackupGenerationModal] = useState(false);
    const [createSiteCopyInfo, setCreateSiteCopyInfo] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (props.opened === -1) {
            setEditProjectModal(false);
            setCreateSiteCopyInfo(false);
            setBackupGenerationModal(false);
        } else if (props.opened === 0) {
            setEditProjectModal(true);
        } else if (props.opened === 1) {
            setCreateSiteCopyInfo(true);
        } else if (props.opened === 2) {
            setBackupGenerationModal(true);
        }
    }, [props.opened]);

    const editProject = (name, description, type) => {
        // check the parameters
        if (isNullOrUndefined(name) || name?.trim() === '') {
            return;
        }
        // check if the properties changed at all
        if (
            name === props.site.name &&
            description === props.site.description
        ) {
            props.handleClose && props.handleClose(props.site);
        }
        editProjectHelper(
            { name: name, description: description, type: type },
            groupId,
            id,
            () => {
                props.site.name = name;
                props.site.description = description;
                props.handleClose && props.handleClose(props.site);
            }
        );
    };

    const createSiteCopyingData = (name, description) => {
        if (isNullOrUndefined(name) || name.trim() === '') {
            return;
        }
        new SiteApi().siteAddSite(
            parseInt(groupId, 10),
            { name: name, description: description, type: props.site.type },
            { siteToCopyFromId: props.site.id },
            function (_, data, response) {
                if (response.ok) {
                    props.handleClose && props.handleClose(props.site);
                    swal({
                        text: t(
                            'pages_sitesettings_createsitemainpagemodal_success'
                        ),
                        icon: 'success',
                    });
                }
            }
        );
    };

    return (
        <>
            <CreateSiteModal
                edit
                name={props.site.name}
                description={props.site.description}
                open={editProjectModal}
                handleClose={() =>
                    props.handleClose && props.handleClose(props.site)
                }
                confirm={editProject}
            />

            <CreateSiteCopyInfoModal
                open={createSiteCopyInfo}
                handleClose={() =>
                    props.handleClose && props.handleClose(props.site)
                }
                confirm={(name, description) =>
                    createSiteCopyingData(name, description)
                }
            />

            <BackupGenerationModal
                open={backupGenerationModal}
                handleClose={() =>
                    props.handleClose && props.handleClose(props.site)
                }
            />
        </>
    );
}
