import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from '@utils/helpers/app.helpers';

const useStyles = makeStyles(() => ({
    closeIcon: {
        fontSize: '12px',
        color: 'black',
        backgroundColor: 'white',
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: '1px solid black',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            color: 'white',
            cursor: 'pointer',
        },
        '&::before': {
            content: '" "',
            backgroundColor: 'black',
            position: 'absolute',
            display: 'block',
            width: '1px',
            left: '7px',
            top: '2px',
            bottom: '2px',
            transform: 'rotate(45deg)',
        },
        '&::after': {
            content: '" "',
            position: 'absolute',
            backgroundColor: 'black',
            display: 'block',
            height: '1px',
            top: '6px',
            left: '2px',
            right: '2px',
            transform: 'rotate(45deg)',
        },
    },
}));

export function RoundedText(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const closeClick = () => {
        if (!isNullOrUndefined(props.onClose)) {
            // invoke the close callback
            props.onClose(props.resourceId);
        }
    };

    // set the color of the background
    let color = '#5bc0de';
    let borderColor = color;
    let textColor = 'black';
    if (props.color === 'success') {
        color = '#22bb33';
        borderColor = color;
    } else if (props.color === 'danger') {
        color = '#bb2124';
        textColor = 'white';
        borderColor = color;
    } else if (props.color === 'info') {
        color = '#f0ad4e';
        borderColor = color;
    } else if (props.color === 'outlined') {
        color = 'transparent';
        borderColor = '#303030';
        textColor = '#303030';
    }

    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                marginTop: `${props.margin ?? 0}`,
                position: 'relative',
                border: `1px solid ${borderColor}`,
                background: `${color}`,
                borderRadius: '100px',
                fontSize: '12px',
                display: 'inline-block',
                lineHeight: '20px',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                color: textColor,
                width: `${props.width}`,
            }}>
            {props.showClose ? (
                <button
                    className={classes.closeIcon}
                    onClick={() => closeClick()}
                />
            ) : (
                <></>
            )}
            {props.text}
        </div>
    );
}
