import { useEffect } from 'react';

export const useKeyPress = (targetKey, callback) => {
    // If pressed key is our target key then set to true
    const downHandler = ({ key }) => {
        if (key === targetKey) {
            callback();
        }
    };

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, []);
};
