import React from 'react';

const ForwardFlushIcon = (props) => (
    <g>
        <rect
            x="765.171"
            y="489.863"
            width="142.682"
            height="100.361"
            style={{ fill: '#666' }}
        />
        <rect
            x="778.471"
            y="189.384"
            width="116.08"
            height="292.015"
            style={{ fill: '#666' }}
        />
        <path
            d="M854.649,81.163l0,35.672l18.139,-0l-36.276,36.274l-36.275,-36.274l18.137,-0l0,-35.672l-53.203,0l-0,100.361l142.682,0l-0,-100.361l-53.204,0Z"
            style={{ fill: '#666', fillRule: 'nonzero' }}
        />
        <path
            d="M836.512,662.17l36.276,-36.276l-18.139,0l0,-35.67l-36.275,0l0,35.67l-18.137,0l36.275,36.276Z"
            style={{ fill: '#666', fillRule: 'nonzero' }}
        />
    </g>
);

export default ForwardFlushIcon;
