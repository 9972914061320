import React from 'react';

const getXCoordinateText = (secsLeft) => {
    switch (`${secsLeft}`.length) {
        case 1:
            return '252px';
        case 2:
            return '229px';
        case 3:
            return '205px';
        default:
            return '252px';
    }
};

const getStyleArrowsFirst = (secsLeft) => {
    if (secsLeft % 2 === 0) {
        return { fill: '#dfe2e3', fillRule: 'nonzero' };
    } else {
        return { fill: '#666', fillRule: 'nonzero' };
    }
};

const getStyleArrowsSecond = (secsLeft) => {
    if (secsLeft % 2 !== 0 || secsLeft === 0) {
        return { fill: '#dfe2e3', fillRule: 'nonzero' };
    } else {
        return { fill: '#666', fillRule: 'nonzero' };
    }
};

const PreTestLiveProcess = (props) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 1084 709"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}>
        <g id="Laag-1">
            <g>
                <g>
                    <path
                        d="M990.358,127.638l-0,470.282l-363.685,-0l-0,-378.022l77.887,-91.454l0.748,-0.806l285.05,-0Z"
                        style={{
                            fill: '#cccbcb',
                            fillRule: 'nonzero',
                            strokeWidth: '6.25px',
                            stroke: '#979797',
                        }}
                    />
                    <path
                        d="M708.362,130.467l0.197,100.633l-81.046,-0l80.849,-100.633Z"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            strokeWidth: '6.25px',
                            stroke: '#979797',
                        }}
                    />
                    <path
                        d="M720.751,302.229c0,-10.654 -8.649,-19.303 -19.302,-19.303c-10.654,-0 -19.303,8.649 -19.303,19.303c-0,10.654 8.649,19.304 19.303,19.304c10.653,-0 19.302,-8.65 19.302,-19.304Z"
                        style={{
                            fill: 'none',
                            stroke: '#808080',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M670.381,273.732l31.067,28.498l53.868,-57.177"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#37b34a',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M720.215,389.176c-0,-10.655 -8.649,-19.305 -19.303,-19.305c-10.654,0 -19.303,8.65 -19.303,19.305c0,10.654 8.649,19.304 19.303,19.304c10.654,-0 19.303,-8.65 19.303,-19.304Z"
                        style={{
                            fill: 'none',
                            stroke: '#808080',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M669.845,360.678l31.068,28.499l53.868,-57.177"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#37b34a',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M720.215,469.682c-0,-10.654 -8.649,-19.304 -19.303,-19.304c-10.654,-0 -19.303,8.65 -19.303,19.304c0,10.654 8.649,19.304 19.303,19.304c10.654,0 19.303,-8.65 19.303,-19.304Z"
                        style={{
                            fill: 'none',
                            stroke: '#808080',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M669.845,441.183l31.068,28.499l53.868,-57.176"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#37b34a',
                            strokeWidth: '6.25px',
                            strokeLinejoin: 'miter',
                        }}
                    />
                    <path
                        d="M820.327,336.322c-17.736,-1.132 -28.07,-16.325 -28.07,-32.836c0,-17.925 15.429,-33.59 32.245,-33.59c29.819,0 34.277,26.203 35.265,30.206c0.993,4 0.443,111.14 0.443,111.14"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#999',
                            strokeWidth: '6.25px',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                        }}
                    />
                    <path
                        d="M918.343,283.107c17.738,-1.131 28.07,-16.321 28.07,-32.836c0,-17.926 -15.427,-33.591 -32.245,-33.591c-29.815,-0 -34.275,26.205 -35.267,30.206c-0.987,3.999 -0.592,164.356 -0.592,164.356"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#999',
                            strokeWidth: '6.25px',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                        }}
                    />
                    <path
                        d="M936.274,369.161c17.739,-1.134 28.073,-16.325 28.073,-32.84c-0,-17.926 -15.429,-33.589 -32.249,-33.589c-29.815,-0 -34.274,26.204 -35.26,30.206c-0.995,3.996 -0.668,78.305 -0.668,78.305"
                        style={{
                            fill: 'none',
                            fillRule: 'nonzero',
                            stroke: '#999',
                            strokeWidth: '6.25px',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'miter',
                            strokeMiterlimit: 10,
                        }}
                    />
                </g>
                <rect
                    x="834.224"
                    y="371.557"
                    width="16.303"
                    height="152.998"
                    style={{ fill: '#666' }}
                />
                <rect
                    x="906.961"
                    y="371.557"
                    width="15.049"
                    height="152.998"
                    style={{ fill: '#666' }}
                />
            </g>
            <g>
                <path
                    d="M230.035,459.937c1.68,-1.717 3.434,-3.359 5.171,-5.013c1.954,-1.867 3.875,-3.775 5.804,-5.671c0.159,-0.158 0.471,-0.312 0.392,-0.521c-0.1,-0.27 -0.437,-0.133 -0.671,-0.133c-6.987,-0.008 -13.979,-0.008 -20.966,-0.008l-20.967,-0c-0.946,-0 -0.946,0.004 -0.946,0.941l0,39.926c0,0.133 -0.008,0.266 0.004,0.404c0.009,0.141 -0.046,0.333 0.138,0.396c0.171,0.062 0.271,-0.096 0.379,-0.201c0.879,-0.845 1.746,-1.704 2.633,-2.541c1.742,-1.65 3.467,-3.325 5.213,-4.971c2.179,-2.05 4.312,-4.15 6.471,-6.225c0.591,-0.567 1.2,-1.112 1.787,-1.687c0.233,-0.221 0.404,-0.2 0.629,0.008c0.525,0.496 1.117,0.921 1.675,1.375c1.746,1.425 3.546,2.762 5.4,4.042c1.696,1.17 3.429,2.275 5.192,3.345c3.412,2.071 6.962,3.867 10.612,5.475c2.167,0.959 4.363,1.83 6.592,2.613c5.479,1.925 11.088,3.342 16.821,4.287c4.617,0.763 9.262,1.18 13.937,1.309c3.834,0.108 7.663,-0.054 11.484,-0.404c2.187,-0.2 4.358,-0.467 6.525,-0.788c2.508,-0.375 4.987,-0.908 7.466,-1.429c2.425,-0.508 4.342,-1.767 5.434,-3.975c1.096,-2.221 1.021,-4.488 -0.421,-6.617c-1.825,-2.683 -4.388,-3.733 -7.525,-3.204c-2.779,0.467 -5.521,1.142 -8.317,1.546c-3.021,0.437 -6.054,0.733 -9.1,0.833c-4.675,0.146 -9.346,-0.033 -13.991,-0.637c-3.863,-0.509 -7.68,-1.242 -11.434,-2.254c-3.537,-0.955 -7.004,-2.138 -10.371,-3.588c-4.17,-1.792 -8.195,-3.871 -12.016,-6.35c-2.484,-1.608 -4.863,-3.354 -7.179,-5.187c-0.621,-0.496 -0.621,-0.517 -0.105,-1.017c1.417,-1.363 2.875,-2.675 4.25,-4.079"
                    style={getStyleArrowsSecond(props.displaySecTime ?? 0)}
                />
                <path
                    d="M202.915,351.853c-1.883,2.3 -3.562,4.759 -5.233,7.213c-0.942,1.387 -1.733,2.866 -2.596,4.304c-1.025,1.717 -1.896,3.525 -2.8,5.313c-0.908,1.779 -1.596,3.662 -2.408,5.491c-1.096,2.434 -1.8,5.021 -2.625,7.554c-1.196,3.68 -1.854,7.505 -2.563,11.3c-0.65,3.492 -0.9,7.038 -1.012,10.58c-0.1,3.166 -0.092,6.333 0.175,9.5c0.242,2.879 0.446,5.758 1.05,8.6c0.45,2.125 0.771,4.279 1.283,6.387c0.313,1.288 0.579,2.563 1.438,3.683c1.779,2.334 4.1,3.263 6.946,2.996c3.633,-0.337 6.354,-3.483 6.445,-6.946c0.025,-0.954 -0.325,-1.82 -0.55,-2.72c-1.183,-4.771 -1.85,-9.621 -2.062,-14.525c-0.171,-3.984 -0.138,-7.98 0.346,-11.946c0.441,-3.704 0.958,-7.396 2.066,-10.988c0.838,-2.708 1.534,-5.454 2.659,-8.079c0.991,-2.312 1.887,-4.658 3.116,-6.867c0.734,-1.312 1.421,-2.641 2.196,-3.937c1.742,-2.9 3.659,-5.658 5.759,-8.304c0.937,-1.179 1.92,-2.317 2.925,-3.438c0.208,-0.237 0.329,-0.216 0.541,-0.012c1.338,1.271 2.713,2.508 4.034,3.804c1.704,1.667 3.475,3.258 5.212,4.887c2.117,1.98 4.196,4 6.296,6c0.183,0.18 0.367,0.517 0.625,0.413c0.267,-0.113 0.146,-0.479 0.146,-0.738c0.004,-6.645 0.004,-13.291 0.004,-19.937l-0,-20.05c-0,-0.804 -0.033,-0.833 -0.783,-0.833c-14.034,-0 -28.071,-0 -42.105,0.004c-0.183,-0 -0.445,-0.092 -0.529,0.087c-0.112,0.234 0.167,0.354 0.304,0.488c1.838,1.771 3.68,3.541 5.53,5.304c1.366,1.3 2.745,2.583 4.108,3.887c2.054,1.963 4.087,3.946 6.15,5.9c0.375,0.355 0.342,0.592 -0,0.93c-1.492,1.45 -2.775,3.091 -4.088,4.695"
                    style={getStyleArrowsFirst(props.displaySecTime ?? 0)}
                />
                <path
                    d="M348.67,466.003c1.754,-1.987 3.491,-3.983 5.116,-6.075c1.875,-2.425 3.575,-4.983 5.242,-7.558c0.942,-1.458 1.696,-3.021 2.587,-4.508c1.034,-1.738 1.813,-3.613 2.68,-5.434c0.987,-2.095 1.825,-4.275 2.612,-6.458c1.213,-3.375 2.183,-6.825 2.988,-10.312c0.804,-3.492 1.291,-7.042 1.641,-10.613c0.238,-2.367 0.313,-4.742 0.5,-7.112c0.117,-1.48 0.146,-2.988 -0.137,-4.496c-0.175,-0.921 -0.084,-1.9 -0.138,-2.85c-0.108,-1.884 -0.266,-3.767 -0.525,-5.634c-0.508,-3.695 -1.096,-7.37 -2.112,-10.979c-0.854,-3.037 -2.7,-4.908 -5.759,-5.516c-3.037,-0.6 -5.595,0.329 -7.487,2.795c-1.125,1.471 -1.546,3.217 -1.179,5.042c0.466,2.35 1.121,4.663 1.566,7.021c0.638,3.333 0.955,6.717 1.063,10.108c0.079,2.421 0.096,4.846 -0.067,7.271c-0.229,3.379 -0.516,6.746 -1.283,10.063c-0.783,3.395 -1.433,6.82 -2.721,10.087c-0.867,2.192 -1.546,4.454 -2.637,6.567c-0.955,1.841 -1.721,3.775 -2.834,5.541c-0.854,1.35 -1.537,2.8 -2.496,4.096c-0.916,1.238 -1.695,2.567 -2.637,3.796c-1.15,1.5 -2.321,2.971 -3.55,4.404c-0.425,0.492 -0.838,0.992 -1.275,1.467c-0.225,0.25 -0.446,0.337 -0.754,0.017c-1.596,-1.638 -3.296,-3.167 -4.946,-4.75c-1.763,-1.688 -3.513,-3.388 -5.292,-5.055c-1.529,-1.437 -3.008,-2.925 -4.516,-4.383c-0.205,-0.2 -0.442,-0.358 -0.655,-0.55c-0.283,-0.258 -0.387,-0.158 -0.391,0.175c-0.004,0.121 -0.004,0.233 -0.004,0.346l-0,40.096c-0,0.858 0.004,0.858 0.858,0.858l41.875,0c0.15,0 0.304,0.008 0.458,-0.004c0.125,-0.004 0.288,0.05 0.359,-0.096c0.079,-0.158 -0.08,-0.246 -0.171,-0.329c-0.334,-0.308 -0.692,-0.592 -1.017,-0.913c-1.671,-1.67 -3.412,-3.27 -5.117,-4.904c-1.858,-1.779 -3.708,-3.571 -5.579,-5.337c-1.483,-1.4 -2.925,-2.846 -4.387,-4.263c-0.113,-0.108 -0.238,-0.191 -0.359,-0.283c-0.458,-0.342 -0.462,-0.533 -0.033,-0.888c0.175,-0.145 0.363,-0.279 0.513,-0.45"
                    style={getStyleArrowsFirst(props.displaySecTime ?? 0)}
                />
                <path
                    d="M357.638,323.806c-0.155,-0.075 -0.246,0.067 -0.338,0.154c-0.487,0.479 -1.012,0.929 -1.5,1.404c-1.754,1.7 -3.529,3.375 -5.292,5.059c-1.75,1.675 -3.487,3.362 -5.254,5.016c-1.462,1.371 -2.891,2.775 -4.3,4.196c-0.383,0.387 -0.658,0.408 -1.05,0.058c-0.821,-0.733 -1.675,-1.433 -2.554,-2.108c-1.679,-1.283 -3.387,-2.529 -5.121,-3.742c-2.3,-1.608 -4.687,-3.079 -7.125,-4.454c-2.904,-1.642 -5.892,-3.129 -8.954,-4.462c-2.363,-1.034 -4.75,-1.988 -7.188,-2.821c-4.512,-1.542 -9.108,-2.779 -13.808,-3.629c-2.233,-0.404 -4.471,-0.738 -6.725,-0.996c-1.954,-0.221 -3.908,-0.371 -5.871,-0.467c-3.55,-0.175 -7.096,-0.154 -10.641,0.071c-2.621,0.171 -5.234,0.471 -7.838,0.862c-3.187,0.475 -6.354,1.055 -9.475,1.855c-1.929,0.495 -3.425,1.679 -4.329,3.404c-1.387,2.641 -1.246,5.241 0.742,7.612c1.816,2.167 4.154,2.984 6.896,2.559c2.054,-0.317 4.062,-0.859 6.112,-1.205c3.258,-0.554 6.533,-0.937 9.829,-1.054c3.754,-0.137 7.513,-0.091 11.263,0.275c3.687,0.363 7.333,0.934 10.937,1.775c4.467,1.038 8.813,2.459 13.038,4.246c2.475,1.046 4.887,2.229 7.258,3.504c3.025,1.638 5.917,3.475 8.713,5.463c1.587,1.125 3.129,2.316 4.616,3.566c0.229,0.192 0.2,0.225 0.009,0.4c-1.038,0.934 -2.005,1.934 -3.017,2.888c-1.704,1.612 -3.392,3.242 -5.092,4.854c-2.287,2.163 -4.537,4.363 -6.792,6.554c-0.2,0.192 -0.587,0.375 -0.483,0.646c0.104,0.292 0.513,0.142 0.783,0.142c6.95,0.004 13.9,0.004 20.85,0.004l20.792,-0c1.021,-0 1.021,-0 1.021,-1.004c0.004,-13.284 0.004,-26.575 -0,-39.863c-0,-0.137 0.008,-0.271 -0,-0.404c-0.013,-0.125 0.033,-0.287 -0.113,-0.358"
                    style={getStyleArrowsSecond(props.displaySecTime ?? 0)}
                />
            </g>
            <path
                d="M1083.33,0l-1081.25,0l0,708.333l1081.25,0l0,-708.333Zm-8.333,700l-1064.58,0l-0,-691.667l1064.58,0l0,691.667Z"
                style={{ fill: '#666', fillRule: 'nonzero' }}
            />
            <rect
                x="0"
                y="0"
                width="541.667"
                height="120.833"
                style={{ fill: '#666' }}
            />
            <text
                x="20.335px"
                y="91.325px"
                style={{
                    fontWeight: 500,
                    fontSize: '84.55px',
                    fill: '#dfe2e3',
                }}>
                LIVE PROCES
            </text>
            <text
                x="123.405px"
                y="246.977px"
                style={{
                    fontWeight: 500,
                    fontSize: '84.55px',
                    fill: '#666',
                }}>
                Pre test
            </text>
            <text
                x={getXCoordinateText(props.displaySecTime ?? 0)}
                y="434px"
                style={{
                    fontWeight: 500,
                    fontSize: '84.55px',
                    fill: '#666',
                }}>
                {props.displaySecTime ?? 0}
            </text>
        </g>
    </svg>
);

export default PreTestLiveProcess;
