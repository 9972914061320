import { SideList, SideListItem } from '@components';
import { makeStyles } from '@material-ui/core/styles';
import { getDrawerSidebar, getSidebarContent } from '@mui-treasury/layout';
import BuildIcon from '@mui/icons-material/Build';
import { getProperRoute, userIsAtLeast } from '@utils/helpers/app.helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SidebarContent = getSidebarContent(styled);
const DrawerSidebar = getDrawerSidebar(styled);

const useStyles = makeStyles(({ breakpoints }) => ({
    collapseBtn: {
        color: '#fff',
        minWidth: 0,
        width: 40,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0.24)',
        margin: '0 auto 16px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.38)',
        },
    },
    sidebar: {
        backgroundColor: '#fbfbfb',
        width: '100%',
        border: 'none',
        [breakpoints.down('md')]: {
            display: 'block',
            width: '70px',
        },
    },
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        height: '40px',
        width: '40px',
        margin: 0,
        alignSelf: 'center',
        border: '1px solid black',
        backgroundColor: 'transparent',
        fontSize: '20px',
        lineHeight: '40px',
        marginLeft: '10px',
        marginTop: '10px',
    },
}));

export function SidebarAdmin(props) {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <DrawerSidebar
            sidebarId={'primarySidebar'}
            PaperProps={{ className: styles.sidebar }}>
            <SidebarContent style={{ overflowX: 'hidden' }}>
                <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
                    <div style={{ textAlign: 'center' }}>
                        <div className={styles.defaultAvatar}>
                            <BuildIcon
                                style={{
                                    marginTop: '-5px',
                                    height: '50%',
                                    width: '50%',
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                        <p>Admin Area</p>
                    </div>
                </div>
                <hr />
                <SideList>
                    <SideListItem
                        link={getProperRoute('diagnostics')}
                        active={props.active?.includes('diagnostics')}
                        text={t('pagelayout_navbar_diagnostics')}
                        icon="diagnostics"
                    />
                    {userIsAtLeast(props.user, 'admin') ? (
                        <SideListItem
                            link={getProperRoute('devices/move')}
                            active={props.active?.includes('move')}
                            text={t('pages_groups_movedevicesmodal_move')}
                            icon="move"
                        />
                    ) : (
                        <></>
                    )}
                </SideList>
            </SidebarContent>
        </DrawerSidebar>
    );
}
