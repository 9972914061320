import {
    TemperatureApi,
    TemperatureControlApi,
} from '@aurum/nucleus-client-api';
import {
    CustomBreadcrumbs,
    MoreSettingsMenu,
    NavButton,
    TreeStructure,
} from '@components';
import { MenuItem, MenuList } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    getFullGroups,
    getProperRoute,
    isNullOrUndefined,
    userIsAtLeast,
} from '@utils/helpers/app.helpers';
import {
    deleteProjectHelper,
    editProjectHelper,
} from '@utils/helpers/projects.helpers';
import CreateSiteModal from '@views/sites/create-site/CreateSiteModal';
import SiteTitle from '@views/sites/site-entry/SiteTitle';
import SiteTcRegulationModal from '@views/sites/sites-page/temperature-control/regulation/SiteTcRegulationModal';
import SiteTcSettingsCirculation from '@views/sites/sites-page/temperature-control/SiteTcSettingsCirculation';
import SiteTcSettingsStrings from '@views/sites/sites-page/temperature-control/SiteTcSettingsStrings';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SiteTcCreateCirculationModal from './SiteTcCreateCirculationModal';

import './site-tc-settings-page.css';

export default function SiteTcSettingsPage(props) {
    const { id, groupId } = useParams();
    const [allSensors, setAllSensors] = useState(null);
    const [curCirculation, _setCurCirculation] = useState(null);
    const [circulationSelectedIndex, setCirculationSelectedIndex] = useState(0);
    const [circulations, setCirculations] = useState([]);
    const [circuMps, setCircuMps] = useState([]);
    const [collapseMenuStatus, setCollapseMenuStatus] = useState(true);
    const [editProjectModal, setEditProjectModal] = useState(false);
    const [regulationModal, setRegulationModal] = useState(false);
    const [createCirculationModal, setCreateCirculationModal] = useState(false);
    const [stringBeingAdded, _setStringBeingAdded] = useState(null);

    const { t } = useTranslation();

    const stringBeingAddedRef = useRef(stringBeingAdded);
    const curCirculationRef = useRef(curCirculation);

    const setStringBeingAdded = (data) => {
        stringBeingAddedRef.current = data;
        _setStringBeingAdded(data);
    };
    const setCurCirculation = (data) => {
        curCirculationRef.current = data;
        _setCurCirculation(data);
    };

    useEffect(() => {
        new TemperatureControlApi().temperatureControlGetCirculationsFromSite(
            parseInt(id, 10),
            {},
            function (_, data, response) {
                if (response.ok) {
                    setCirculations(data);
                    setCurCirculation(data[0]);
                }
            }
        );

        new TemperatureApi().temperatureGetSensors2(function (
            _,
            data,
            response
        ) {
            if (response.ok) {
                setAllSensors(data);
            }
        });

        forceUpdateCirculationMps();
    }, [id]);

    useEffect(() => {}, [props]);

    const editProject = (name, description, type) => {
        // check the parameters
        if (isNullOrUndefined(name) || name?.trim() === '') {
            return;
        }
        // check if the properties changed at all
        if (
            name === props.site.name &&
            description === props.site.description
        ) {
            setEditProjectModal(false);
        }
        editProjectHelper(
            { name: name, description: description, type: type },
            groupId,
            id,
            () => {
                props.site.name = name;
                props.site.description = description;
                setEditProjectModal(false);
            }
        );
    };

    const onDeleteProject = () => {
        deleteProjectHelper(t, groupId, id, () => {
            window.location.href = getProperRoute(`groups/${groupId}/projects`);
        });
    };

    const onCreateString = () => {
        if (isNullOrUndefined(stringBeingAdded)) {
            setStringBeingAdded('');
        }
    };

    const onCreateCirculation = (name) => {
        new TemperatureControlApi().temperatureControlAddCirculation(
            parseInt(id, 10),
            { name: name },
            function (_, data, response) {
                if (response.ok) {
                    setCirculations([...circulations, data]);
                    forceUpdateCirculationMps();
                }
            }
        );
    };

    const forceUpdateCirculationMps = () => {
        new TemperatureControlApi().temperatureControlGetMp(
            parseInt(id, 10),
            function (_, data, response) {
                if (response.ok) {
                    setCircuMps(data);
                }
            }
        );
    };

    const onStringCreated = (strang) => {
        if (isNullOrUndefined(curCirculationRef.current)) return;
        curCirculationRef.current.strangs?.push(strang);
    };

    const onSaveProjectRegulation = () => {};

    const treeStructure = [];
    circulations.forEach((circulation) => {
        treeStructure.push({
            id: circulation.id,
            name: circulation.name,
            type: 'group',
            items: [],
            raw: circulation,
        });
    });

    const fullGroups = getFullGroups(props.groups, props.site);

    const openRegulationModalCallback = useCallback(
        () => setRegulationModal(true),
        []
    );
    const closeRegulationModalCallback = useCallback(
        () => setRegulationModal(false),
        []
    );
    const closeCirculationModalCallback = useCallback(
        () => setCreateCirculationModal(false),
        []
    );
    const saveRegulationModalCallback = useCallback(() =>
        onSaveProjectRegulation()
    );
    const saveCirculationCallback = useCallback(
        (name) => onCreateCirculation(name),
        [circulations]
    );
    const deleteProjectCallback = useCallback(() => onDeleteProject(), []);
    const openEditProjectModalCallback = useCallback(
        () => setEditProjectModal(true),
        []
    );
    const openCreateCirculationCallback = useCallback(
        () => setCreateCirculationModal(true),
        []
    );
    const createStringCallback = useCallback(() => onCreateString());

    return isNullOrUndefined(props.site) ? (
        <> </>
    ) : (
        <>
            <CustomBreadcrumbs
                breadcrumbs={[
                    ...fullGroups,
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: props.site.name,
                    },
                    {
                        link: getProperRoute(
                            `groups/${groupId}/sites/${id}/settings`
                        ),
                        text: t('pagelayout_sidebarsite_settings'),
                    },
                    {
                        link: getProperRoute('/'),
                        text: t('pagelayout_sidebarsite_general'),
                    },
                ]}
            />
            <hr /> <br />
            <SiteTitle site={props.site} />
            <hr /> <br />
            <SiteTcSettingsCirculation
                allSensors={allSensors}
                curCirculation={curCirculation}
                circulationMps={circuMps}
            />
            <hr /> <br />
            <div
                style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: '10px',
                }}>
                <CustomBreadcrumbs
                    breadcrumbs={[
                        {
                            link: getProperRoute('/'),
                            text: (
                                <NavButton
                                    disabled={
                                        !userIsAtLeast(props.user, 'analyst')
                                    }
                                    color="black"
                                    background="gray"
                                    id="menu-add-group"
                                    text={<AddIcon />}
                                    icon={<ExpandMoreIcon />}>
                                    <MenuList id="menu-add-group">
                                        <MenuItem
                                            onClick={
                                                openCreateCirculationCallback
                                            }>
                                            {t(
                                                'pages_sitetc_settings_circucreate'
                                            )}
                                        </MenuItem>
                                        {isNullOrUndefined(curCirculation) ? (
                                            <></>
                                        ) : (
                                            <MenuItem
                                                onClick={createStringCallback}>
                                                {t(
                                                    'pages_sitetc_settings_stringcreate'
                                                )}
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </NavButton>
                            ),
                        },
                    ]}
                />
                <MoreSettingsMenu
                    disabled={!userIsAtLeast(props.user, 'analyst')}>
                    {userIsAtLeast(props.user, 'analyst') ? (
                        <>
                            <MenuItem onClick={openEditProjectModalCallback}>
                                {t(
                                    'pages_sitesettings_sitesettingspage_editproj'
                                )}
                            </MenuItem>
                            <MenuItem onClick={deleteProjectCallback}>
                                {t(
                                    'pages_sitesettings_sitesettingspage_deleteproj'
                                )}
                            </MenuItem>
                            <MenuItem onClick={openRegulationModalCallback}>
                                {t('pages_sitetc_settings_projectregulation')}
                            </MenuItem>
                        </>
                    ) : (
                        <></>
                    )}
                </MoreSettingsMenu>
            </div>
            <div
                className={`circulations-string-grid ${
                    collapseMenuStatus ? 'collapsed' : 'expanded'
                }`}>
                <div id="circulations-section" className="circulations-section">
                    <TreeStructure
                        items={treeStructure}
                        selectedIndex={circulationSelectedIndex}
                        itemClicked={(item, idx) => {
                            setCirculationSelectedIndex(idx);
                            setCurCirculation(item.raw);
                        }}
                        site={props.site}
                    />
                </div>
                <div id="horizontal-drag-bar" className="horizontal-drag-bar">
                    {collapseMenuStatus ? (
                        <ArrowRightIcon
                            className="icon-collapse"
                            onClick={() => setCollapseMenuStatus(false)}
                        />
                    ) : (
                        <ArrowLeftIcon
                            className="icon-collapse"
                            onClick={() => setCollapseMenuStatus(true)}
                        />
                    )}
                </div>
                {isNullOrUndefined(curCirculation) ? (
                    <></>
                ) : (
                    <SiteTcSettingsStrings
                        allSensors={allSensors}
                        curCirculationRef={curCirculationRef}
                        circulations={circulations}
                        user={props.user}
                        stringBeingAddedRef={stringBeingAddedRef}
                        stringBeingAdded={stringBeingAdded}
                        setStringBeingAdded={setStringBeingAdded}
                        onStringCreated={(strang) => onStringCreated(strang)}
                    />
                )}
            </div>
            <CreateSiteModal
                edit
                name={props.site.name}
                description={props.site.description}
                open={editProjectModal}
                handleClose={() => setEditProjectModal(false)}
                confirm={editProject}
            />
            <SiteTcRegulationModal
                open={regulationModal}
                site={props.site}
                handleClose={closeRegulationModalCallback}
                confirm={saveRegulationModalCallback}
            />
            <SiteTcCreateCirculationModal
                open={createCirculationModal}
                handleClose={closeCirculationModalCallback}
                confirm={saveCirculationCallback}
            />
        </>
    );
}
