import { BackupApi } from '@aurum/nucleus-client-api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    CustomSwitch,
    InputDropZone,
    LinearProgressWithLine,
    SingleSelect,
} from '@components';

const signalR = require('@microsoft/signalr');

export default function ImportBackupModal(props) {
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState(null);
    const [signalRConnection, setSignalRConnection] = useState(null);
    const [importing, setImporting] = useState(false);
    const [filesToImport, setFilesToImport] = useState([]);
    const [group, setGroup] = useState(1);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [includeData, setIncludeData] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (props.open) {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl('/hub/backupgenerationprogress', {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .build();

            connection.on('importprogress', progressNotificationReceived);
            connection.on('importstatus', statusNotificationReceived);

            setSignalRConnection(connection);
        } else {
            setFilesToImport([]);
            setStatusMessage(null);
            setProgress(0);
            setImporting(false);
            setGroup(1);
            setIncludeData(false);
            signalRConnection?.stop();
        }

        return () => {
            signalRConnection?.stop();
        };
    }, [props.open]);

    const progressNotificationReceived = (p) => {
        setProgress(p);
    };

    const statusNotificationReceived = (msg) => {
        setStatusMessage(msg);
    };

    const groupChanged = (gr) => {
        setGroup(gr);
    };

    const keyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            importFiles();
        }
    };

    const importFiles = () => {
        if (importing) {
            return;
        }
        setBtnDisabled(true);
        filesToImport.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                // first of all, import the file into the server
                fetch(`https://${window.location.hostname}/api/file/upload`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'text/xml; charset=utf-8',
                        'Content-Length': reader.result.length,
                    },
                    body: reader.result,
                })
                    .then((res) => res.text())
                    .then((data) => {
                        // once the file has been imported, start the project backup import
                        var opts = { importData: includeData, fileId: data };
                        new BackupApi().backupImport(
                            group,
                            opts,
                            function (_, dt, response) {
                                if (response.ok) {
                                    signalRConnection
                                        ?.start()
                                        .then(function () {
                                            signalRConnection.invoke(
                                                'AssociateJob',
                                                response.body
                                            );
                                            setImporting(true);
                                        })
                                        .catch(function () {});
                                }
                            }
                        );
                    });
            };
            reader.readAsText(file);
        });
    };

    const onFilesDropped = (files) => {
        if (files.length === 0) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
        setFilesToImport(files);
    };

    const groupsToAttach = [];
    props.groups?.forEach((group) => {
        if (group.id !== 1) {
            groupsToAttach.push({ value: group.id, description: group.name });
        }
    });
    if (groupsToAttach.length > 0 && group === 1) {
        setGroup(groupsToAttach[0].value);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={props.open}
            onKeyDown={(evt) => keyDown(evt)}
            onClose={(_, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    props.handleClose && props.handleClose();
                }
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {' '}
                {t('pages_sitesettings_importbackupmodal_import')}{' '}
            </DialogTitle>
            <DialogContent>
                {importing ? (
                    <>
                        <p style={{ whiteSpace: 'pre-line' }}>
                            {statusMessage}
                        </p>
                        <LinearProgressWithLine value={progress} />
                    </>
                ) : (
                    <>
                        <div className="row">
                            {groupsToAttach.length > 0 ? (
                                <div
                                    className="col-md-10"
                                    style={{ marginBottom: '20px' }}>
                                    <SingleSelect
                                        title={t(
                                            'pages_sitesettings_createsitemainpagemodal_attach'
                                        )}
                                        data={groupsToAttach}
                                        onChange={groupChanged}
                                    />{' '}
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="col-md-2">
                                <Typography style={{ color: '#616161' }}>
                                    {t(
                                        'pages_sitesettings_importbackupmodal_include'
                                    )}
                                </Typography>
                                <CustomSwitch
                                    checked={includeData}
                                    onChecked={() =>
                                        setIncludeData(!includeData)
                                    }
                                />
                            </div>
                        </div>
                        <InputDropZone
                            onFilesDropped={(files) => onFilesDropped(files)}
                        />
                        <br />
                        <p>
                            <b>
                                {t(
                                    'pages_sitesettings_importbackupmodal_files'
                                )}
                                :{' '}
                            </b>
                            {filesToImport.map((f) => f.name).join(', ')}
                        </p>
                    </>
                )}
            </DialogContent>
            <DialogActions style={{ marginTop: '30px' }}>
                <Button
                    aria-label="Cancel"
                    onClick={() => props.handleClose && props.handleClose()}
                    color="secondary">
                    {t('modal_cancel')}
                </Button>
                <Button
                    aria-label="Import"
                    disabled={btnDisabled}
                    onClick={() => importFiles()}
                    color="default">
                    {t('pages_sitesettings_importbackupmodal_import')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
