import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAvatarColor } from '@utils/helpers/colors.helpers';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles(() => ({
    defaultAvatar: {
        borderRadius: '20%',
        border: 0,
        width: (props) => (props.isMobile ? '50px' : '80px'),
        textAlign: 'center',
        margin: 0,
        alignSelf: 'center',
        backgroundColor: (props) =>
            props.avatar ? 'transparent' : getAvatarColor(props.group?.id ?? 1),
        fontSize: (props) => (props.isMobile ? '25px' : '40px'),
        lineHeight: (props) => (props.isMobile ? '50px' : '80px'),
        marginLeft: '10px',
        marginTop: '10px',
    },
    icon: {
        marginLeft: '10px',
        cursor: 'pointer',
        width: '24px',
        height: '24px',
    },
    avatarContainer: {
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        overflow: 'hidden',
        float: 'left',
        marginRight: '20px',
    },
    textContainer: {
        minWidth: 0,
        alignItems: 'center',
        flex: '1 1 auto',
        display: 'flex',
    },
    infoContainer: {
        flexBasis: '100%',
        minWidth: 0,
        flexShrink: 1,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
    },
}));

export default function GroupTitle(props) {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const newProps = Object.assign({}, props);
    newProps.isMobile = isMobile;
    const styles = useStyles(newProps);
    const { t } = useTranslation();

    return (
        <div
            style={{
                flexDirection: isMobile ? 'column' : 'row',
                marginTop: '10px',
            }}>
            <Grid container spacing={2}>
                <div className={styles.avatarContainer}>
                    {props.avatar ? (
                        <img
                            alt="avatar"
                            className={styles.defaultAvatar}
                            src={props.avatar}
                        />
                    ) : (
                        <div className={styles.defaultAvatar}>
                            {props.group?.name[0]?.toUpperCase()}
                        </div>
                    )}
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.infoContainer}>
                        <b
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}>
                            <span
                                style={{
                                    fontSize: isMobile ? '20px' : '26px',
                                    marginTop: '20px',
                                    marginLeft: isMobile ? '0' : '20px',
                                }}>
                                {props.group?.name}
                            </span>
                        </b>
                        <span style={{ marginLeft: isMobile ? '0' : '20px' }}>
                            {t('pages_groups_grouptitle_id')}: {props.group?.id}
                        </span>
                    </div>
                </div>
            </Grid>
            <p
                style={{
                    marginTop: '25px',
                    marginLeft: isMobile ? '0' : '15px',
                }}>
                {' '}
                {props.group?.description}{' '}
            </p>
        </div>
    );
}
